import { getPreviousServices } from 'api/babysitters/babysitters.api';
import { Text } from 'design';
import { ApprovedPayIcon } from 'design/Icon/AppprovedPayIcon';
import { AwaitingForPayIcon } from 'design/Icon/AwaitingForPayIcon';
import { selectUser } from 'global-state/selectors';
import { getDayMonthyear } from 'helpers/date.helpers';
import { getUTCDate } from 'helpers/getUTCDate';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { PreviousServicesType } from 'types/babysitter-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledTitleTextH6 = styled(Text.h6)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 0.8rem;
`;

const StyledTitleDiv = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  margin-top: 0.5rem;
  justify-content: space-between;
`;

const StyledDataTextLabel = styled(Text.label)`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 400;
`;

const StyledDataTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  font-size: 1rem;
`;

const StyledServicesContentDiv = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: calc(100vw - 4rem);
  }
`;

const StyledHr = styled.hr`
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

const StyledNumberSpan = styled.span`
  border-radius: 50%;
  padding: 0.2rem 0.4rem;
  font-size: 0.7rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  margin-right: 0.2rem;
`;

const StyledStepperSpan = styled.span`
  font-size: 0.7rem;
`;

const StyledPayDiv = styled.div`
  display: flex;
`;

const StyledDiv = styled.div`
  padding: 0 1rem;
`;

const StyledDateDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledTextP = styled(Text.p)`
  padding: 0 2rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledNoServicesDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BabysitterPreviousServices: FC = () => {
  const [previousServicesData, setPreviousServicesData] = useState<
    PreviousServicesType[]
  >([]);

  const user = useSelector(selectUser);

  const getPreviousServicesData = async (id: string) => {
    const response = await getPreviousServices(id);
    setPreviousServicesData(response);
  };
  useEffect(() => {
    if (user && user?.babysitter) {
      const id = user?.babysitter.id;
      if (id) {
        getPreviousServicesData(id);
      }
    }
  }, [user?.babysitter, user]);
  const weekday = new Array(7);

  weekday[0] = intl.translate({ id: 'Domingo' });
  weekday[1] = intl.translate({ id: 'Lunes' });
  weekday[2] = intl.translate({ id: 'Martes' });
  weekday[3] = intl.translate({ id: 'Miércoles' });
  weekday[4] = intl.translate({ id: 'Jueves' });
  weekday[5] = intl.translate({ id: 'Viernes' });
  weekday[6] = intl.translate({ id: 'Sábado' });

  return (
    <StyledServicesContentDiv>
      {previousServicesData.length !== 0 ? (
        previousServicesData.map((data) => {
          const startTimeDate = getUTCDate(new Date(data.startTime));
          const endTimeDate = getUTCDate(new Date(data.endTime));
          const dayOfServiceStart = weekday[startTimeDate.getUTCDate()];
          const dayOfServiceEnd = weekday[endTimeDate.getUTCDate()];
          const getHours = startTimeDate.getUTCHours();
          const getHoursEnd = endTimeDate.getUTCHours();
          const getHoursStart = startTimeDate.getUTCHours();
          const getDateEnd = getDayMonthyear(data.endTime);
          const getDateStart = getDayMonthyear(data.startTime);
          return (
            <div key={data.id}>
              <StyledTitleDiv>
                <StyledTitleTextH6>
                  <strong>
                    {intl.translate({ id: 'Servicios Realizados' })}
                  </strong>
                </StyledTitleTextH6>
                <StyledStepperSpan>
                  <StyledNumberSpan>
                    {previousServicesData.indexOf(data) + 1}
                  </StyledNumberSpan>
                  {intl.translate({ id: 'de' })} {previousServicesData.length}
                </StyledStepperSpan>
              </StyledTitleDiv>
              <StyledHr />
              <StyledDiv>
                <StyledDataTextLabel>
                  {intl.translate({ id: 'Cliente:' })}
                </StyledDataTextLabel>
                <StyledDataTextP>
                  {data.name} {data.lastname}
                </StyledDataTextP>
                <StyledDataTextLabel>
                  {intl.translate({ id: 'Día:' })}
                </StyledDataTextLabel>
                <StyledDateDiv>
                  <StyledDataTextP>
                    <StyledDataTextP>{dayOfServiceStart}</StyledDataTextP>
                    {getDateStart} - {getDateEnd}
                  </StyledDataTextP>
                  <StyledTextP>{intl.translate({ id: 'al' })}</StyledTextP>
                  <StyledDataTextP>
                    <StyledDataTextP>{dayOfServiceEnd}</StyledDataTextP>
                    {getDateEnd}
                  </StyledDataTextP>
                </StyledDateDiv>
                <StyledDataTextLabel>
                  {intl.translate({ id: 'Horario:' })}
                </StyledDataTextLabel>
                <StyledDataTextP>
                  {intl.translate(
                    {
                      id: '{getHours}hs a {getHours}hs',
                    },
                    { getHours, getHoursStart },
                  )}
                  {intl.translate(
                    {
                      id: '{getHours}hs a {getHoursEnd}hs',
                    },
                    { getHours, getHoursEnd },
                  )}
                </StyledDataTextP>
                <StyledDataTextLabel>
                  {intl.translate({ id: 'Dirección:' })}
                </StyledDataTextLabel>
                <StyledDataTextP>{data.location}</StyledDataTextP>
              </StyledDiv>
              <StyledDiv>
                <StyledDataTextLabel>
                  {intl.translate({ id: 'Pago:' })}
                </StyledDataTextLabel>
                <StyledPayDiv>
                  <StyledDataTextP>
                    {intl.translate({ id: 'Ingresado' })}
                  </StyledDataTextP>
                  <ApprovedPayIcon color={'success'} />
                  <AwaitingForPayIcon color={'secondary'} />
                </StyledPayDiv>
              </StyledDiv>
              <StyledHr />
            </div>
          );
        })
      ) : (
        <StyledNoServicesDiv>
          <Text.p>
            {intl.translate({
              id: 'Actualmente no se registran servicios anteriores',
            })}
          </Text.p>
        </StyledNoServicesDiv>
      )}
    </StyledServicesContentDiv>
  );
};
