import { updateBabysitterInfo } from 'api/babysitters/babysitters.api';
import { updateUserInfo } from 'api/users/users.api';
import { BabysitterSchedule } from 'components/Form/AvailavilityDaysOfWeekForm/BabysitterSchedule';
import { Form } from 'components/Form/Form';
import { mapId } from 'config/google.config';
import { Button, Card, Text, TextInput } from 'design';
import { Loading } from 'design/Button/LoadingButton';
import { palette } from 'design/theme/palette';
import { getHoursInUTC } from 'helpers/getHoursInUTC';
import { FC, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from 'react-google-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { IUserDataResponse } from 'types/babysitter-props';
import { User } from 'types/user';
import { intl } from 'utilities/i18n/intl.utility';
import * as Yup from 'yup';

const StyledSpaceDiv = styled.div`
  height: 1rem;
  width: 100%;
`;

const StyledButtonRegisterTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledInputDiv = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
  }
`;

const StyledAlignTopInputDiv = styled.div`
  display: flex;
  align-items: flex-start;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
  }
`;

const StyledLabelTextP = styled(Text.p)`
  width: 30%;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 800;
  color: #555c;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: auto;
  }
`;

const StyledAddressDiv = styled.div`
  width: 100%;
`;

const StyledButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const StyledCardBody = styled(Card.Body)`
  max-width: 100%;
  padding: 1rem 0;
`;

const StyledDiv = styled.div`
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    align-self: center;
    align-items: center;
  }
`;

interface PanelTopProps {
  userInfo: User | null;
  userFullData: IUserDataResponse;
}
interface Place {
  label: string;
  value: {
    description: string;
    matched_substrings: {
      length: number;
      offset: number;
    }[];
    place_id: string;
    reference: string;
    structured_formatting: {
      main_text: string;
      main_text_matched_substrings: {
        length: number;
        offset: number;
      }[];
      secondary_text: string;
    };
    terms: {
      offset: number;
      value: string;
    }[];
    types: string[];
  };
}

interface locationPinProps {
  addressText: string;
  lat: number;
  lng: number;
}

const errorMessage = intl.translate({
  id: 'La fecha y hora de finalización debe ser mayor a la fecha y hora de inicio',
});

const validationSchema = Yup.object({
  name: Yup.string().required(
    intl.translate({ id: 'Debe ingresar un nombre' }),
  ),
  address: Yup.string().required(
    intl.translate({ id: 'Debe ingresar el departamento' }),
  ),
  lat: Yup.number().required(
    intl.translate({ id: 'Debe existir latitud de la direccion' }),
  ),
  lng: Yup.number().required(
    intl.translate({ id: 'Debe existir longitud de la dirección' }),
  ),

  phone: Yup.string().required(
    intl.translate({ id: 'Debe ingresar su número de teléfono' }),
  ),
  email: Yup.string().required(
    intl.translate({ id: 'Debe ingresar su número de teléfono' }),
  ),
  check_Lunes: Yup.bool(),
  check_Martes: Yup.bool(),
  check_Miercoles: Yup.bool(),
  check_Jueves: Yup.bool(),
  check_Viernes: Yup.bool(),
  check_Sabado: Yup.bool(),
  check_Domingo: Yup.bool(),
  startTimeAvailabilityMonday: Yup.date().required(errorMessage),
  endTimeAvailabilityMonday: Yup.date().required(errorMessage),
  startTimeAvailabilityTuesday: Yup.date().required(errorMessage),
  endTimeAvailabilityTuesday: Yup.date().required(errorMessage),
  startTimeAvailabilityWednesday: Yup.date().required(errorMessage),
  endTimeAvailabilityWednesday: Yup.date().required(errorMessage),
  startTimeAvailabilityThursday: Yup.date().required(errorMessage),
  endTimeAvailabilityThursday: Yup.date().required(errorMessage),
  startTimeAvailabilityFriday: Yup.date().required(errorMessage),
  endTimeAvailabilityFriday: Yup.date().required(errorMessage),
  startTimeAvailabilitySaturday: Yup.date().required(errorMessage),
  endTimeAvailabilitySaturday: Yup.date().required(errorMessage),
  startTimeAvailabilitySunday: Yup.date().required(errorMessage),
  endTimeAvailabilitySunday: Yup.date().required(errorMessage),
});

type FormValues = {
  userId: string;
  babysitterId: string;
  name: string;
  address: string;
  lat: number;
  lng: number;
  phone: string;
  email: string;
  check_Lunes: boolean;
  check_Martes: boolean;
  check_Miercoles: boolean;
  check_Jueves: boolean;
  check_Viernes: boolean;
  check_Sabado: boolean;
  check_Domingo: boolean;
  startTimeAvailabilityMonday: Date;
  endTimeAvailabilityMonday: Date;
  startTimeAvailabilityTuesday: Date;
  endTimeAvailabilityTuesday: Date;
  startTimeAvailabilityWednesday: Date;
  endTimeAvailabilityWednesday: Date;
  startTimeAvailabilityThursday: Date;
  endTimeAvailabilityThursday: Date;
  startTimeAvailabilityFriday: Date;
  endTimeAvailabilityFriday: Date;
  startTimeAvailabilitySaturday: Date;
  endTimeAvailabilitySaturday: Date;
  startTimeAvailabilitySunday: Date;
  endTimeAvailabilitySunday: Date;
};

export const UpdateBabysitterPersonalInfoForm: FC<PanelTopProps> = ({
  userInfo,
  userFullData,
}) => {
  const [modifyActive, setModifyActive] = useState(true);
  const [value, setValue] = useState<null | object>(null);
  const [buttonTitle, setButtonTitle] = useState<string | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const today = new Date();

  const initialValues = {
    userId: userFullData.id,
    babysitterId: userFullData.babysitter.id,
    name: userFullData.name,
    address: userFullData.babysitter.address,
    lat: userFullData.babysitter.locationLat,
    lng: userFullData.babysitter.locationLng,
    phone: userFullData.phone,
    email: userFullData.email,
    check_Lunes: userFullData.babysitter.check_Lunes,
    check_Martes: userFullData.babysitter.check_Martes,
    check_Miercoles: userFullData.babysitter.check_Miercoles,
    check_Jueves: userFullData.babysitter.check_Jueves,
    check_Viernes: userFullData.babysitter.check_Viernes,
    check_Sabado: userFullData.babysitter.check_Sabado,
    check_Domingo: userFullData.babysitter.check_Domingo,
    startTimeAvailabilityMonday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.startTimeAvailabilityMonday).getUTCHours(), 0),
    endTimeAvailabilityMonday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.endTimeAvailabilityMonday).getUTCHours(), 0),

    startTimeAvailabilityTuesday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.startTimeAvailabilityTuesday).getUTCHours(), 0),

    endTimeAvailabilityTuesday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.endTimeAvailabilityTuesday).getUTCHours(), 0),

    startTimeAvailabilityWednesday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.startTimeAvailabilityWednesday).getUTCHours(), 0),

    endTimeAvailabilityWednesday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.endTimeAvailabilityWednesday).getUTCHours(), 0),

    startTimeAvailabilityThursday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.startTimeAvailabilityThursday).getUTCHours(), 0),

    endTimeAvailabilityThursday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.endTimeAvailabilityThursday).getUTCHours(), 0),

    startTimeAvailabilityFriday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.startTimeAvailabilityFriday).getUTCHours(), 0),

    endTimeAvailabilityFriday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.endTimeAvailabilityFriday).getUTCHours(), 0),

    startTimeAvailabilitySaturday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.startTimeAvailabilitySaturday).getUTCHours(), 0),

    endTimeAvailabilitySaturday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.endTimeAvailabilitySaturday).getUTCHours(), 0),

    startTimeAvailabilitySunday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.startTimeAvailabilitySunday).getUTCHours(), 0),

    endTimeAvailabilitySunday:
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), new Date(userFullData.babysitter.endTimeAvailabilitySunday).getUTCHours(), 0),

  };

  const handleMapsData = (
    title: string,
    lat: number,
    lng: number,
    formik: any,
  ) => {
    setButtonTitle(title);
    formik.setFieldValue('address', title);
    formik.setFieldValue('lat', lat);
    formik.setFieldValue('lng', lng);
  };

  const handleCoordsChange = async (place: Place, formik: any) => {
    const id = place.value.place_id;
    const newCoords = await getCoords(id);
    const convertCoords: locationPinProps = {
      lat: newCoords.lat,
      lng: newCoords.lng,
      addressText: place.label,
    };
    setValue(place);

    handleMapsData(
      convertCoords.addressText,
      convertCoords.lat,
      convertCoords.lng,
      formik,
    );
  };

  const getCoords = async (id: string) => {
    try {
      const geoPlaceById = await geocodeByPlaceId(id);
      return await getLatLng(geoPlaceById[0]);
    } catch (error) {
      return { lat: 0, lng: 0 };
    }
  };

  const handleSubmit = async (values: FormValues) => {
    if (loading) {
      return;
    }
    const babysitterNewData = {
      babysitterId: values.babysitterId,
      address: values.address,
      locationLat: values.lat,
      locationLng: values.lng,
      check_Lunes: values.check_Lunes,
      check_Martes: values.check_Martes,
      check_Miercoles: values.check_Miercoles,
      check_Jueves: values.check_Jueves,
      check_Viernes: values.check_Viernes,
      check_Sabado: values.check_Sabado,
      check_Domingo: values.check_Domingo,
      startTimeAvailabilityMonday: getHoursInUTC(values.startTimeAvailabilityMonday),
      endTimeAvailabilityMonday: getHoursInUTC(values.endTimeAvailabilityMonday),
      startTimeAvailabilityTuesday: getHoursInUTC(values.startTimeAvailabilityTuesday),
      endTimeAvailabilityTuesday: getHoursInUTC(values.endTimeAvailabilityTuesday),
      startTimeAvailabilityWednesday: getHoursInUTC(values.startTimeAvailabilityWednesday),
      endTimeAvailabilityWednesday: getHoursInUTC(values.endTimeAvailabilityWednesday),
      startTimeAvailabilityThursday: getHoursInUTC(values.startTimeAvailabilityThursday),
      endTimeAvailabilityThursday: getHoursInUTC(values.endTimeAvailabilityThursday),
      startTimeAvailabilityFriday: getHoursInUTC(values.startTimeAvailabilityFriday),
      endTimeAvailabilityFriday: getHoursInUTC(values.endTimeAvailabilityFriday),
      startTimeAvailabilitySaturday: getHoursInUTC(values.startTimeAvailabilitySaturday),
      endTimeAvailabilitySaturday: getHoursInUTC(values.endTimeAvailabilitySaturday),
      startTimeAvailabilitySunday: getHoursInUTC(values.startTimeAvailabilitySunday),
      endTimeAvailabilitySunday: getHoursInUTC(values.endTimeAvailabilitySunday),
    };
    try {
      setLoading(true);

      await updateBabysitterInfo(babysitterNewData);
      await updateUserInfo(
        values.phone,
        values.email,
        values.name,
        values.userId,
      );
      window.location.reload();
    } catch (error) {
      toast.info(
        intl.translate({
          id: 'No se ha podido actualizar',
        }),
      );
      throw new Error('no se pudo actualizar');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <StyledCardBody>
          <StyledInputDiv>
            <StyledLabelTextP>
              {intl.translate({ id: 'Nombre' })}
            </StyledLabelTextP>
            <TextInput
              label={intl.translate({ id: 'Nombre' })}
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.errors.name}
              fullWidth
              disabled
              required
            />
          </StyledInputDiv>
          <StyledSpaceDiv />

          <StyledInputDiv>
            <StyledLabelTextP>
              {intl.translate({ id: 'Correo electrónico' })}
            </StyledLabelTextP>
            <TextInput
              label={intl.translate({ id: 'Correo electrónico' })}
              id="email"
              name="email"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.errors.email}
              fullWidth
              disabled
              required
            />
          </StyledInputDiv>
          <StyledSpaceDiv />

          <StyledInputDiv>
            <StyledLabelTextP>
              {intl.translate({ id: 'Dirección' })}
            </StyledLabelTextP>
            <StyledAddressDiv>
              <GooglePlacesAutocomplete
                apiKey={mapId}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['uy'],
                  },
                }}
                apiOptions={{ language: 'es' }}
                selectProps={{
                  value,
                  defaultInputValue: buttonTitle ?? formik.values.address,
                  onChange: (place: Place) => handleCoordsChange(place, formik),
                  styles: {
                    input: (provided: object) => ({
                      ...provided,
                      color: 'rgba(0, 0, 0, 0.38)',
                      height: 49,
                      border: '4px',
                    }),
                    option: (provided: object) => ({
                      ...provided,
                      color: palette.primary.main,
                      width: '100%',
                      border: '2px solid rgba(0, 0, 0, 0.38)',
                    }),
                    singleValue: (provided: object) => ({
                      ...provided,
                      color: palette.primary.main,
                    }),
                  },
                }}
              />
            </StyledAddressDiv>
          </StyledInputDiv>
          <StyledSpaceDiv />

          <StyledInputDiv>
            <StyledLabelTextP>
              {intl.translate({ id: 'Teléfono' })}
            </StyledLabelTextP>
            <TextInput
              label={intl.translate({ id: 'Teléfono' })}
              id="phone"
              name="phone"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.phone}
              error={formik.errors.phone}
              fullWidth
              disabled={!modifyActive}
              required
            />
          </StyledInputDiv>
          <StyledSpaceDiv />

          <StyledAlignTopInputDiv>
            <StyledLabelTextP>
              {intl.translate({ id: 'Disponibilidad' })}
            </StyledLabelTextP>
            {modifyActive ? (
              <StyledDiv>
                <BabysitterSchedule formik={formik} />
              </StyledDiv>
            ) : (
              <TextInput
                label={intl.translate({
                  id: 'Disponibilidad',
                })}
                id="email"
                name="email"
                type="text"
                onChange={formik.handleChange}
                value={intl.translate({
                  id: 'Para ver sus horarios disponibles, click en modificar',
                })}
                error={formik.errors.email}
                fullWidth
                disabled
                required
              />
            )}
          </StyledAlignTopInputDiv>
          <StyledSpaceDiv />

          <StyledButtonDiv>
            {loading ? (
              <Loading loading={loading} variant="outlined">
                {intl.translate({ id: 'Cargando...' })}
              </Loading>
            ) : (
              <Button type="submit" variant="outlined">
                <StyledButtonRegisterTextP>
                  {intl.translate({ id: 'Guardar' })}
                </StyledButtonRegisterTextP>
              </Button>
            )}
          </StyledButtonDiv>
        </StyledCardBody>
      )}
    </Form>
  );
};
