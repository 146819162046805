import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import SignupBackground from './assets/signup-background.svg';

const StyledSignupBackgroundDiv = styled.div`
  background-image: url(${SignupBackground});
  background-repeat: no-repeat;
  background-size: 80vh;
  background-position: top 0 left -300;
  background-position-y: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-size: 180vh;
    background-position: top 50 left -500;
  }
`;
export const SignupBackgroundSvg: FC<FcDefaultProps> = ({ children }) => {
  return <StyledSignupBackgroundDiv>{children}</StyledSignupBackgroundDiv>;
};
