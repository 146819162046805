import { BackgroundGeneral } from 'components/background/BackgroundGeneral';
import { BackgroundGuaranteesMobile } from 'components/background/BackgroundGuaranteesMobile';
import { HomeContainer } from 'components/Container/HomeContainer';
import { BlueEyeIcon } from 'components/icons/guaranteesIcons/BlueEyeIcon';
import { GradeHistory } from 'components/icons/guaranteesIcons/GradeHistory';
import { InterviewYourBabysitter } from 'components/icons/guaranteesIcons/InterviewYourBabysitter';
import { OtherUsersTestimonials } from 'components/icons/guaranteesIcons/OtherUsersTestimonials';
import { SelectionProcess } from 'components/icons/guaranteesIcons/SelectionProcess';
import { Text } from 'design';
import { FC } from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const StyledContainerSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: row;
  }
  @media (max-height: 850px) {
    height: 100%;
  }
  @media (max-width: 360px) {
    height: 100%;
  }
  @media (max-height: 850px) {
    @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      height: 90vh;
    }
  }
`;

const StyledAlignDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  min-width: 18rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 1rem;
    height: 6.25rem;
  }
`;

const StyledIconDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 3.75rem;
  height: 5.625rem;
  margin-right: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex: 0 0 5.625rem;
  }
`;

const StyledIconTextDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledH4 = styled(Text.h4)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.2rem;
  font-weight: 900;
  line-height: 1.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 1.3rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 1.5rem;
    font-weight: bolder;
    line-height: 1.2rem;
  }
  @media (max-height: 850px) {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: 0.9rem;
    }

    font-size: 0.6rem;
  }
`;

const StyledTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 0.8rem;
  font-weight: 400;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    margin-top: 1rem;
    font-size: 1.1rem;
  }
  @media (max-height: 850px) {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: 0.9rem;
    }

    font-size: 0.6rem;
  }
`;

const StyledTextH3 = styled(Text.h3)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 2rem;
  font-weight: 900;
  align-self: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 2.6rem;
    align-self: flex-start;
    padding: 0 0.5rem;
  }
`;

const StyledBlueLineHr = styled.hr`
  color: ${({ theme }) => theme.palette.primary.main};
  border-top: 4px solid ${({ theme }) => theme.palette.primary.main};
  width: 13rem;
  margin-bottom: 1rem;
  align-self: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 12rem;
    align-self: flex-start;
    margin-left: 0.5rem;
    border-top: 6px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledSpaceBr = styled.br`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    height: 1rem;
  }
`;

const StyledRightColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin: 0 2rem;
    width: 100%;
    align-items: center;
    z-index: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin: 0 2rem;
    width: 90vw;
    justify-content: space-evenly;
    align-items: center;
  }
`;

const StyledLeftColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin: 0 2rem;
    width: 100%;
    align-items: center;
    z-index: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin: 0 2rem;
    width: 90vw;
    justify-content: space-evenly;
    align-items: center;
  }
`;
const StyledTitleTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const GuaranteesView: FC = () => {
  return (
    <BackgroundGeneral>
      <BackgroundGuaranteesMobile>
        <HomeContainer>
          <StyledContainerSection>
            <StyledLeftColumnDiv>
              <StyledTitleTextDiv>
                <StyledTextH3>
                  {intl.translate({ id: 'Garantías' })}
                </StyledTextH3>
                <StyledBlueLineHr />
              </StyledTitleTextDiv>
              <StyledAlignDiv>
                <StyledIconDiv>
                  <BlueEyeIcon color="primary" />
                </StyledIconDiv>
                <StyledIconTextDiv>
                  <StyledH4>
                    {intl.translate({ id: 'Control durante el servicio' })}
                  </StyledH4>
                  <StyledTextP>
                    {intl.translate({
                      id: 'La empresa estará en monitoreo continuo de la niñera asegurando el cuidado de sus hijos en todo momento.',
                    })}
                  </StyledTextP>
                </StyledIconTextDiv>
              </StyledAlignDiv>
              <StyledSpaceBr />
              <StyledAlignDiv>
                <StyledIconDiv>
                  <GradeHistory color="primary" />
                </StyledIconDiv>
                <StyledIconTextDiv>
                  <StyledH4>
                    {intl.translate({ id: 'Historial de calificaciones' })}
                  </StyledH4>
                  <StyledTextP>
                    {intl.translate({
                      id: 'Con cada servicio todos los padres brindan una calificación a la niñera, y si el servicio no es de excelencia, la niñera no será asignada a nuevos cuidados.',
                    })}
                  </StyledTextP>
                </StyledIconTextDiv>
              </StyledAlignDiv>
              <StyledSpaceBr />
            </StyledLeftColumnDiv>
            <StyledRightColumnDiv>
              <StyledAlignDiv>
                <StyledIconDiv>
                  <SelectionProcess color="primary" />
                </StyledIconDiv>
                <StyledIconTextDiv>
                  <StyledH4>
                    {intl.translate({ id: 'Proceso de selección' })}
                  </StyledH4>
                  <StyledTextP>
                    {intl.translate({
                      id: 'Las niñeras son sometidas a un arduo proceso que las certifica técnica y psicológicamente para el cuidado de sus hijos.',
                    })}
                  </StyledTextP>
                </StyledIconTextDiv>
              </StyledAlignDiv>
              <StyledSpaceBr />
              <StyledAlignDiv>
                <StyledIconDiv>
                  <OtherUsersTestimonials color="primary" />
                </StyledIconDiv>
                <StyledIconTextDiv>
                  <StyledH4>
                    {intl.translate({ id: 'Testimonios de otros usuarios' })}
                  </StyledH4>
                  <StyledTextP>
                    {intl.translate({
                      id: 'La empresa cuenta con un sistema de calificaciones bilateral, donde podrá ver no solo comentarios de la empresa sino de otros padres sobre las niñeras.',
                    })}
                  </StyledTextP>
                </StyledIconTextDiv>
              </StyledAlignDiv>
              <StyledSpaceBr />
              <StyledAlignDiv>
                <StyledIconDiv>
                  <InterviewYourBabysitter color="primary" />
                </StyledIconDiv>
                <StyledIconTextDiv>
                  <StyledH4>
                    {intl.translate({
                      id: 'Contacto constante con OKids',
                    })}
                  </StyledH4>
                  <StyledTextP>
                    {intl.translate({
                      id: 'Durante el servicio podrá comunicarse con la empresa para conocer cómo se desarrolla el cuidado, y serán informados inmediatamente de cualquier situación.',
                    })}
                  </StyledTextP>
                </StyledIconTextDiv>
              </StyledAlignDiv>
              <StyledSpaceBr />
            </StyledRightColumnDiv>
          </StyledContainerSection>
        </HomeContainer>
      </BackgroundGuaranteesMobile>
    </BackgroundGeneral>
  );
};
