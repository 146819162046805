import { useIconColor } from 'design/hooks/use-icon-color.hook';
import { IconProps } from 'design/types/icon-props';
import { FC } from 'react';

export const Pulso: FC<IconProps & { width?: number | string }> = ({
  color,
  width = '100%',
  className,
  invert = false,
}) => {
  const _color = useIconColor(color, invert);

  return (
    <svg
      width={width}
      color={_color}
      className={className}
      viewBox="0 0 386 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.399994 59.9C0.399994 43.1 0.399994 26.3 0.399994 9.5C0.399994 7.1 0.7 6.79999 3 6.79999C20 6.79999 37 6.79999 54 6.79999C61.9 6.79999 69.3 8.59998 75.9 13.1C84 18.8 88.8 26.6 90.2 36.3C92 48 88.5 58.3 80.3 66.7C74.7 72.5 67.7 75.9 59.7 77.2C49.8 78.7 39.9 80.5 30 82.1C28.7 82.3 28.5 82.9 28.5 84.1C28.5 92.8 28.5 101.6 28.5 110.3C28.5 113 28.1 113.4 25.4 113.4C18 113.4 10.6 113.4 3.29999 113.4C0.799988 113.4 0.399994 113 0.399994 110.5C0.399994 93.6 0.399994 76.8 0.399994 59.9ZM28.5 45.2C28.5 49.9 28.5 54.6 28.5 59.4C28.5 60.4 28.8 60.8 29.9 60.6C36.6 59.6 43.4 58.6 50.1 57.2C53.1 56.5 55.8 55.1 57.8 52.7C61.6 48.1 61.7 42.9 59.4 37.7C57.1 32.6 52.9 30 47.3 29.9C41.6 29.8 35.9 29.9 30.2 29.8C29 29.8 28.6 30.1 28.6 31.4C28.6 36.1 28.5 40.6 28.5 45.2Z"
        fill="#1280FF"
      />
      <path
        d="M385.9 75.3C386.2 96.8 368.1 115.4 345.6 115.4C323.1 115.3 305.2 97 305.2 75C305.2 54 322.6 35 345.5 35C368.3 34.9 386.2 53.7 385.9 75.3ZM345.5 92.8C355.4 92.8 363.3 85.3 363.3 75.4C363.3 66 356.1 57.6999 345.7 57.5999C335.1 57.4999 328 66.0999 327.9 75.0999C327.8 84.9999 335.6 92.8 345.5 92.8Z"
        fill="#1280FF"
      />
      <path
        d="M101 60.9C101 53.9 101 46.9 101 39.9C101 37.8 101.4 37.3 103.5 37.3C109.8 37.3 116.1 37.3 122.5 37.3C124.8 37.3 125.2 37.7 125.2 40.1C125.2 53.5 125.2 66.9 125.2 80.3C125.2 87.4 129.8 92.7 136.9 93.9C143.2 94.9 149.6 91 151.7 84.6C152.2 83.1 152.4 81.5 152.4 79.9C152.4 66.5 152.4 53.1 152.4 39.8C152.4 37.5 152.7 37.2 155 37.2C161.2 37.2 167.4 37.2 173.6 37.2C175.9 37.2 176.3 37.6 176.3 39.9C176.2 53.8 176.6 67.7 176 81.5C175.5 94.8 168.9 104.9 157.1 111.1C144.5 117.6 131.5 117.4 119.2 110.5C107.6 104.1 101.1 93.7 100.8 80.1C101 73.9 101 67.4 101 60.9Z"
        fill="#1280FF"
      />
      <path
        d="M261.8 35C272.7 34.8 282 37.6 290.7 42.9C292.6 44 292.8 44.7 291.6 46.7C289.9 49.7 288.3 52.7 286.6 55.8C285.2 58.3 284.9 58.4 282.3 57.1C277.8 54.8 273.4 52.6 268.4 51.5C264.6 50.6 260.9 51 257.3 52C255.9 52.3 254.7 53.1 253.6 54C251.9 55.4 251.5 57.3 251.8 59.3C252.2 61.3 253.6 62.6 255.5 63.2C260.1 64.7 264.8 65.4 269.6 66.3C273 66.9 276.4 67.6 279.7 68.4C287.6 70.5 292.4 75.7 294.2 83.7C296 91.8 294.4 99.1 288.8 105.3C285.2 109.2 280.6 111.7 275.6 113.4C268.4 115.9 260.9 116.1 253.5 115.5C247.5 114.9 241.8 113.4 236.7 110.2C233.2 108.1 230.3 105.3 227.8 102.1C226.9 101 227 100.1 228 99.2C230.6 96.5 233.1 93.6 235.7 90.9C236.9 89.6 237.3 89.6 238.6 90.7C241.6 93.2 244.3 95.9 247.9 97.6C253.8 100.3 259.8 100.8 266.1 99C268.2 98.4 269.9 97.3 271.3 95.8C274.5 92.6 273.1 87.6 268.7 86.4C263.4 84.9 258 84.5 252.6 83.4C248.9 82.6 245.1 82.1 241.6 80.6C235.7 77.9 232.1 73.4 230.6 67.1C228.9 60.1 229.7 53.5 233.9 47.6C236.8 43.6 240.8 40.9 245.1 38.9C250.7 36 256.4 34.8 261.8 35Z"
        fill="#1280FF"
      />
      <path
        d="M215.2 57.1C215.2 75.3 215.2 93.5 215.2 111.6C215.2 113.7 215.2 113.7 213.1 113.7C206.8 113.7 200.5 113.7 194.2 113.7C191.7 113.7 191.4 113.4 191.4 110.9C191.4 77.2 191.4 43.5 191.4 9.79999C191.4 7.59999 191.7 7.1 193.8 6.5C199.9 4.5 206 2.60001 212.1 0.600011C212.6 0.400011 213 0.300017 213.6 0.200017C214.6 0.100017 215.2 0.500011 215.3 1.60001C215.4 2.10001 215.3 2.60001 215.3 3.10001C215.2 21 215.2 39 215.2 57.1Z"
        fill="#1280FF"
      />
    </svg>
  );
};
