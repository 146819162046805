import { StorageItem } from 'enums/storage-item.enum';
import { storage } from './storage.helpers';

export function isUserAuthenticated(): boolean {
  return !!storage.local.get(StorageItem.AUTH_TOKEN);
}

export function isUserAdmin(): boolean {
  return !!storage.local.get(StorageItem.USER);
}

export function getAuthToken(): string | null {
  return storage.local.get<string>(StorageItem.AUTH_TOKEN);
}
