import { Button, Text } from 'design';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

interface Props {
  comment: string;
}

const StyledP = styled(Text.p)`
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.3rem;
  color:  ${({ theme }) => theme.palette.secondary.light}
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 0.9rem;
  }
`;

const StyledButton = styled(Button)`
  padding: 1rem 0 0 !important;
`;

export const ReadMore: FC<Props> = ({ comment }) => {
  const [isReadMoreShown, setIsReadMoreShown] = useState(false);
  const toggleBtn = () => {
    setIsReadMoreShown((prevState) => !prevState);
  };

  return (
    <div>
      {isReadMoreShown ? (
        <StyledP color="dark">{comment}</StyledP>
      ) : (
        <StyledP color="dark">{comment.substring(0, 75)}...</StyledP>
      )}
      {isReadMoreShown ? null : (
        <StyledButton onClick={toggleBtn}>
          {intl.translate({ id: 'Leer testimonio completo' })}
        </StyledButton>
      )}
    </div>
  );
};
