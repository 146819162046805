import { styled as styledMui } from '@material-ui/core';
import { TextField } from '@mui/material';
import { DesignContext } from 'design/DesignContext';
import { DesignSystem } from 'design/enums/design-system.enum';
import { Text } from 'design/Text';
import { palette } from 'design/theme/palette';
import { InputProps } from 'design/types/input-props';
import { noop } from 'helpers/nodash.helpers';
import { Align } from 'layout';
import { ChangeEvent, useCallback, useContext } from 'react';
import { StatusText } from '../StatusText/StatusText';

const StyledTextField = styledMui(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '3px',
      borderColor: palette.primary.main,
      borderStyle: 'solid',
    },
    '&:hover fieldset': {
      borderWidth: '3px',
      borderColor: palette.primary.main,
      borderStyle: 'solid',
    },
  },
});

const StyledInvertedTextField = styledMui(TextField)({
  '& label.Mui-focused': {
    color: palette.primary.invert,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: palette.primary.invert,
  },
  '& label': {
    color: palette.primary.invert,
    fontWeight: 'medium',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: palette.primary.invert,
    },
    '&:hover fieldset': {
      borderColor: palette.primary.invert,
    },
    '&.Mui-focused fieldset': {
      borderColor: palette.primary.invert,
    },
  },
});

type Props = InputProps<string> & {
  type: 'text' | 'password' | 'email' | 'number';
};

export const TextInput = (props: Props): JSX.Element | null => {
  const {
    name,
    id,
    onChange = noop,
    label,
    value,
    type = 'text',
    disabled,
    required,
    helperText,
    onClick,
    error,
    fullWidth = false,
    size,
    placeHolder,
    className,
    invert = false,
    autoComplete = 'on',
    multiline = false,
    rows,
  } = props;

  const { system } = useContext(DesignContext);

  const handleChange = useCallback(
    (
      evt: ChangeEvent<
        HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
      >,
    ) => {
      onChange({
        target: {
          name,
          value: evt.target.value,
        },
      });
    },
    [name, onChange],
  );

  if (system === DesignSystem.MATERIAL_UI) {
    return invert ? (
      <StyledInvertedTextField
        id={id}
        label={label}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        type={type}
        onClick={onClick}
        required={required}
        helperText={error ? error.toString() : helperText}
        error={!!error}
        fullWidth={fullWidth}
        size={size}
        className={className}
        placeholder={placeHolder}
        autoComplete={autoComplete}
      />
    ) : (
      <StyledTextField
        id={id}
        label={label}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        type={type}
        onClick={onClick}
        required={required}
        helperText={error ? error.toString() : helperText}
        error={!!error}
        fullWidth={fullWidth}
        size={size}
        className={className}
        multiline={multiline}
        rows={rows}
        placeholder={placeHolder}
      />
    );
  }

  return (
    <Align column>
      <Text.label>
        <Text.p>{label}</Text.p>

        <input
          type={type}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          required={required}
          placeholder={placeHolder}
        />
      </Text.label>

      <StatusText error={error} helperText={helperText} />
    </Align>
  );
};
