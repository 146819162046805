import { createAction } from '@reduxjs/toolkit';
import { BillData } from 'global-state/reducers/auth.reducer';
import { BabysitterDataType } from 'types/babysitter-props';
import { ServiceDataProps } from 'types/service-props';
import { User } from 'types/user';

export const setAuthTokenAction = createAction(
  'SET_AUTH_TOKEN',
  (token: string) => {
    return { payload: token };
  },
);

export const removeAuthTokenAction = createAction('REMOVE_AUTH_TOKEN');

export const setUserDataAction = createAction('SET_USER', (user: User) => {
  return { payload: user };
});

export const removeUserDataAction = createAction('REMOVE_USER');

export const setBabysitterFullDataAction = createAction(
  'SET_BABYSITTER_FULL_DATA',
  (babysitterArray: BabysitterDataType[]) => {
    return { payload: babysitterArray };
  },
);

export const removeBabysitterFullDataAction = createAction(
  'REMOVE_BABYSITTER_FULL_DATA',
);
export const setSelectedBabysitterFullDataAction = createAction(
  'SET_SELECTED_BABYSITTER_FULL_DATA',
  (babysitterArray: BabysitterDataType) => {
    return { payload: babysitterArray };
  },
);

export const removeSelectedBabysitterFullDataAction = createAction(
  'REMOVE_SELECTED_BABYSITTER_FULL_DATA',
);

export const setBillDataAction = createAction(
  'SET_BILL_DATA',
  (billData: BillData) => {
    return { payload: billData };
  },
);
export const removeBillDataAction = createAction(
  'REMOVE_BILL_DATA',
);

export const setServiceDataAction = createAction(
  'SET_SERVICE_DATA',
  (babysitterArray: any) => {
    return { payload: babysitterArray };
  },
);

export const removeServiceDataAction = createAction('REMOVE_SERVICE_DATA');

export const setServiceDataWhileLoginAction = createAction(
  'SET_DATA_WHILE_LOGIN',
  (dataWhileLogin: ServiceDataProps) => {
    return { payload: dataWhileLogin };
  },
);

export const removeServiceDataWhileLoginAction = createAction(
  'REMOVE_DATA_WHILE_LOGIN',
);
