import { FC } from 'react';
import { ErrorDetailDescription } from './ErrorDetailDescription';
import { ErrorSectionMessage } from './ErrorSectionMessage';

export const MercadopagoFailure: FC = () => {
  return (
    <>
      <ErrorSectionMessage />
      <ErrorDetailDescription />
    </>
  );
};
