import { OkidsLogo } from 'components/icons/OkidsLogo';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import banner_medium from './assets/thanks_w_1388.jpg';
import banner from './assets/thanks_w_2560.jpg';
import banner_small from './assets/thanks_w_890.jpg';

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -90%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  overflowy: hidden;
  max-height: 100vh;
`;

const Image = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;

  @media (max-width: 450px) {
    object-fit: cover;
    object-position: left;
  }
`;

const StyledOkidsLogo = styled(OkidsLogo)`
  width: 20rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 15rem;
  }
`;

const StyledSpamThanks = styled.p`
  color: ${({ theme }) => theme.palette.white.main};
  padding: 2rem 0;
  font-weight: 600;
  font-family: ${({ theme }) => theme.components.text.h1.fontFamily};
  font-size: 4rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${({ theme }) => theme.components.text.h1.fontSize}rem;
  }
`;
const StyledSpamThanksText = styled.p`
  color: ${({ theme }) => theme.palette.white.main};
  font-weight: 400;
  max-width: 420px;
  text-align: center;
  font-family: ${({ theme }) => theme.components.text.h2.fontFamily};
  font-size: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${({ theme }) => theme.components.text.h3.fontSize}rem;
  }
`;

const ThanksPage = () => {
  return (
    <div>
      <Container>
        <StyledOkidsLogo invert={true} color={'primary'} />
        <StyledSpamThanks>{intl.translate({ id: 'Gracias' })}</StyledSpamThanks>
        <StyledSpamThanksText>
          {intl.translate({ id: 'Nos contactaremos' })}
        </StyledSpamThanksText>
      </Container>
      <BackgroundWrapper>
        <picture>
          <source media="(max-width: 576px)" srcSet={banner_small} />
          <source media="(max-width: 768px)" srcSet={banner_medium} />
          <source media="(max-width: 992px)" srcSet={banner} />
          <Image
            src={banner}
            srcSet={`${banner} 2560w, ${banner_medium} 1338w, ${banner_small} 890w`}
          />
        </picture>
      </BackgroundWrapper>
    </div>
  );
};
export default ThanksPage;
