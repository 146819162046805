import { MainContainer } from 'components/Container/MainContainer';
import { AppBar } from 'components/views/AppBar';
import { FC } from 'react';
import styled from 'styled-components';
import { PaymentTable } from './PaymentTable';

const StyledTableDesignDiv = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 3rem;
  }
  margin-top: 1.5rem;
`;

export const PaymentView: FC = () => {
    return (
        <>
            <AppBar />
            <MainContainer>
                <StyledTableDesignDiv>
                    <PaymentTable />

                </StyledTableDesignDiv>
            </MainContainer>
        </>
    );
};

