import { BussinessPackagesLeftContent } from 'components/BussinessPackages/BussinessPackagesLeftContent';
import { BussinessPackagesRightContent } from 'components/BussinessPackages/BussinessPackagesRightContent';
import { WorkWithUsBackground } from 'components/background/BackgroundWorkWithUs';
import { AppBar } from 'components/views/AppBar';
import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { Footer } from './Footer';

const StyledHeightSection = styled.section`
  max-width: 100vw;
  margin-top: 7rem;
  padding-top: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 7rem - 178px);
  @media (max-height: 850px) {
    height: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100vw;
    padding: 0;
    margin-top: 7rem;
    height: 100%;
  }
`;

const StyledContentDiv = styled.div`
  display: flex;
  align-items: flex-start;
  width: 85vw;
  height: auto;
  min-height: 36.25rem;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 95vw;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
  }
`;

export const BussinessPackagesView: FC<FcDefaultProps> = () => {
  return (
    <WorkWithUsBackground>
      <AppBar />
      <StyledHeightSection>
        <StyledContentDiv>
          <BussinessPackagesLeftContent />
          <BussinessPackagesRightContent />
        </StyledContentDiv>
      </StyledHeightSection>
      <Footer />
    </WorkWithUsBackground>
  );
};
