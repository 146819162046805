import {
  createServiceReview,
  getServiceData,
} from 'api/babysitters/babysitters.api';
import { Form } from 'components/Form/Form';
import { BackgroundBabysitterPanel } from 'components/background/BackgroundBabysitterPanel';
import { OkidsLogo } from 'components/icons/OkidsLogo';
import { Button, Text, TextInput } from 'design';
import { Loading } from 'design/Button/LoadingButton';
import { LoadingIcon } from 'design/Icon/LoadingIcon';
import { AppRoute } from 'enums/app-route.enum';
import { FormikHelpers } from 'formik';
import { getFullDate } from 'helpers/date.helpers';
import { genericErrorHandler } from 'helpers/error.helpers';
import { StarsRating } from 'rating/StarsRating';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ServiceUserBabysitterProps } from 'types/service-props';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'workbox-core/_private';
import * as Yup from 'yup';
import { AppBar } from './AppBar';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding: 1rem 0;
`;

const StyledNameTextP = styled(Text.p)`
  color: black;
  font-weight: 800;
  font-size: 1.6rem;
`;

const StyledDataTextP = styled(Text.p)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 600;
`;

const StyledLabelTextP = styled(Text.p)`
  width: 30%;
  font-size: 1.1rem;
  font-weight: 800;
  text-transform: uppercase;
  color: #555c;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5rem 0 0.2rem 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: auto;
  }
`;

const StyledMainDiv = styled.div`
  padding: 0 2rem;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 85vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 4rem;
    align-self: center;
  }
`;

const StyledTitleH1 = styled(Text.h1)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 2.2rem;
  padding-top: 1rem;
  font-weight: 600;
  align-self: center;
`;

const StyledTitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  margin-top: 8rem;
`;

const StyledNameDiv = styled.div`
  margin-left: 1rem;
`;

const StyledBabysitterProfileImageDiv = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50%;
  padding: 0.5rem;
`;

const StyledOkidsLogo = styled(OkidsLogo)`
  color: ${({ theme }) => theme.palette.primary.invert} !important;
  font-size: 1rem !important;
`;

const StyledButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const StyledTextInput = styled(TextInput)`
  padding: 0 !important;
`;

const StyledInputDiv = styled.div`
  margin: 0.5rem 0;
`;

const RightWebSectionDiv = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
  }
`;

const StyledWebLeftDiv = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
  }
`;

const StyledContainerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  height: calc(100vh - 7rem);
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: 7rem;
  }
`;

const validationSchema = Yup.object({
  comment: Yup.string().required(intl.translate({ id: 'Campo requerido' })),
});

type FormValues = {
  comment: string;
};

const initialValues = {
  comment: '',
};

interface Props {
  serviceId?: string;
}

export const BabysitterReviewView: FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get('serviceId')
  const [serviceInfo, setServiceInfo] = useState<ServiceUserBabysitterProps>();

  useEffect(() => {
    const serviceData = async () => {
      if (!serviceId) {
        navigate(AppRoute.HOME);
        toast.warning(
          intl.translate({
            id: 'post.review.babysitter.service.id.required',
          }),
        );
        return;
      }
      try {
        const serviceToReview = await getServiceData(serviceId);
        if (serviceToReview) {
          setServiceInfo(serviceToReview);
        }
      } catch (e: any) {
        logger.error(e.message);
        toast.warning(
          intl.translate({
            id: 'get.service.data.fail',
          }),
        );
      }
    };
    serviceData();
  }, []);

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);

      if (rating !== 0) {
        if (serviceId) {
          const response = await createServiceReview(
            rating,
            values.comment,
            serviceId,
          );
          if (response) {
            setRating(0);
            toast.success(
              intl.translate({ id: 'post.review.babysitter.success' }),
            );
            helpers.resetForm();
            navigate(AppRoute.HOME);
          }
        }
      } else {
        toast.warning(
          intl.translate({
            id: 'review.babysitter.rate.required',
          }),
        );
      }
    } catch (error) {
      genericErrorHandler(error);
      toast.warning(
        intl.translate({
          id: 'post.review.babysitter.fail',
        }),
      );
    } finally {
      setLoading(false);
    }
  };
  const handleRating = async (newRating: number) => {
    setRating(newRating * 2);
  };

  return serviceInfo ? (
    <>
      <BackgroundBabysitterPanel>
        <AppBar />
        <StyledContainerDiv>
          <StyledTitleDiv>
            <StyledTitleH1>
              {intl.translate({ id: 'babysitter.review.title' })}
            </StyledTitleH1>
          </StyledTitleDiv>
          <StyledMainDiv>
            <StyledWebLeftDiv>
              <StyledDiv>
                <StyledBabysitterProfileImageDiv>
                  <StyledOkidsLogo small />
                </StyledBabysitterProfileImageDiv>
                <StyledNameDiv>
                  <StyledLabelTextP>
                    {intl.translate({ id: 'babysitter' })}:
                  </StyledLabelTextP>
                  <StyledNameTextP>
                    {serviceInfo?.UserBabysitter?.user?.name}
                  </StyledNameTextP>
                </StyledNameDiv>
              </StyledDiv>
              <StyledInputDiv>
                <StyledLabelTextP>
                  {intl.translate({ id: 'service.date.title' })}
                </StyledLabelTextP>
                <StyledDataTextP>
                  {getFullDate(serviceInfo?.startTime)}
                  --
                  {getFullDate(serviceInfo?.endTime)}

                </StyledDataTextP>
              </StyledInputDiv>
              <StyledInputDiv>
                <StyledLabelTextP>
                  {intl.translate({ id: 'Rating' })}
                </StyledLabelTextP>
                <StarsRating allowFraction size={32} onChange={handleRating} />
              </StyledInputDiv>
            </StyledWebLeftDiv>
            <RightWebSectionDiv>
              <StyledInputDiv>
                <StyledLabelTextP>
                  {intl.translate({ id: 'comment.review.title' })}
                </StyledLabelTextP>

                <Form
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  {(formik) => (
                    <>
                      <StyledTextInput
                        label={intl.translate({
                          id: 'comment.review.input',
                        })}
                        id="comment"
                        name="comment"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.comment}
                        error={formik.errors.comment}
                        fullWidth
                        multiline
                        rows={6}
                        maxLength={600}
                        required
                      />
                      <StyledButtonDiv>
                        {loading ? (
                          <Loading
                            endIcon={<LoadingIcon />}
                            loading={loading}
                            loadingPosition="center"
                            variant="contained"
                          ></Loading>
                        ) : (
                          <Button
                            type="submit"
                            color="primary"
                            variant="filled"
                          >
                            {intl.translate({ id: 'Send' })}
                          </Button>
                        )}
                      </StyledButtonDiv>
                    </>
                  )}
                </Form>
              </StyledInputDiv>
            </RightWebSectionDiv>
          </StyledMainDiv>
        </StyledContainerDiv>
      </BackgroundBabysitterPanel>
    </>
  ) : (
    <></>
  );
};
