import { ForgotPasswordView } from 'components/ForgotPassword/ForgotPasswordView';
import { Privacy } from 'components/PDF/Privacy';
import { TermsAndConditions } from 'components/PDF/TermsAndConditions';
import { ProtectedCustomerRoute } from 'components/ProtectedRoute/ProtectedCustomerRoute';
import { ProtectedRoute } from 'components/ProtectedRoute/ProtectedRoute';
import { ProtectedRouteFromUser } from 'components/ProtectedRouteFromUser/ProtectedRouteFromUser';
import { LoginView } from 'components/SignIn/SignInView';
import { Register } from 'components/SignUp/SignUp';
import { AdminProtectedRoute } from 'components/adminRoute/AdminProtectedRoute';
import { AdditionalDataChildView } from 'components/views/AdditionalDataChildView';
import { BabysitterPanelView } from 'components/views/BabysitterPanelView';
import { BabysitterReviewView } from 'components/views/BabysitterReviewView';
import { BabysitterView } from 'components/views/BabysitterView';
import { BussinessPackagesView } from 'components/views/BusinessPackagesView';
import ContactPage from 'components/views/Contact';
import { CreateBabySitterAccountView } from 'components/views/CreateBabySitterAccountView';
import { Home } from 'components/views/Home/Home';
import { MercadopagoFailureView } from 'components/views/MercadopagoFailureView';
import { OurServiceView } from 'components/views/OurServiceView';
import { SelectBabySitterView } from 'components/views/SelectBabySitterView';
import { ServiceBeforePaymentView } from 'components/views/ServiceBeforePaymentView';
import ThanksPage from 'components/views/Thanks/Thanks';
import { UserPanerView } from 'components/views/UserPanelView';
import { WorkWithUsView } from 'components/views/WorkWithUsView';
import { ActiveServicesView } from 'components/views/backoffice/activeServices/ActiveServicesView';
import { ClientsView } from 'components/views/backoffice/clients/ClientsView';
import { ClosedServicesView } from 'components/views/backoffice/closedServices/ClosedServicesView';
import { PaymentView } from 'components/views/backoffice/payments/PaymentView';
import { ProvidersView } from 'components/views/backoffice/providers/Providers';
import { Text } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { intl } from 'utilities/i18n/intl.utility';

export const Router: FC = () => {
  return (
    <Routes>
      <Route path={AppRoute.HOME} element={<Home />} />
      <Route
        path={AppRoute.SELECT_BABYSITTER}
        element={
          <ProtectedRoute>
            <SelectBabySitterView />
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoute.BABYSITTER_DESCRIPTION}
        element={
          <ProtectedRoute>
            <BabysitterView />
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoute.CLIENTS}
        element={
          <AdminProtectedRoute>
            <ClientsView />
          </AdminProtectedRoute>
        }
      />
      <Route
        path={AppRoute.PAYMENT}
        element={
          <AdminProtectedRoute>
            <PaymentView />
          </AdminProtectedRoute>
        }
      />
      <Route
        path={AppRoute.PROVIDERS}
        element={
          <AdminProtectedRoute>
            <ProvidersView />
          </AdminProtectedRoute>
        }
      />
      <Route
        path={AppRoute.ACTIVE_SERVICES}
        element={
          <AdminProtectedRoute>
            <ActiveServicesView />
          </AdminProtectedRoute>
        }
      />
      <Route
        path={AppRoute.CLOSED_SERVICES}
        element={
          <AdminProtectedRoute>
            <ClosedServicesView />
          </AdminProtectedRoute>
        }
      />
      <Route
        path={AppRoute.ADDITIONAL_DATA_CHILD}
        element={<AdditionalDataChildView />}
      />
      <Route
        path={AppRoute.CREATE_BABYSITTER_ACOUNT}
        element={<CreateBabySitterAccountView />}
      />

      <Route
        path={AppRoute.MERCADOPAGO_FAIL}
        element={
          <ProtectedRoute>
            <MercadopagoFailureView />
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoute.BABYSITTER_PANEL}
        element={
          <ProtectedRoute>
            <BabysitterPanelView />
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoute.SERVICE_BEFORE_PAYMENT}
        element={
          <ProtectedRoute>
            <ServiceBeforePaymentView />
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoute.USER_PANEL}
        element={
          <ProtectedCustomerRoute>
            <UserPanerView />
          </ProtectedCustomerRoute>
        }
      />

      <Route path={AppRoute.LOGIN} element={<LoginView />} />

      <Route path={AppRoute.FORGOT_PASSWORD_EMAIL} element={<ForgotPasswordView />} />

      <Route path={AppRoute.REGISTER} element={<Register />} />

      <Route
        path={AppRoute.WORK_WITH_US}
        element={
          <ProtectedRouteFromUser>
            <WorkWithUsView />
          </ProtectedRouteFromUser>
        }
      />

      <Route path={AppRoute.OUR_SERVICE} element={<OurServiceView />} />
      <Route
        path={AppRoute.REVIEW_BABYSITTER}
        element={
          <ProtectedCustomerRoute>
            <BabysitterReviewView />{' '}
          </ProtectedCustomerRoute>
        }
      />

      <Route
        path={AppRoute.TERMS_AND_CONDITIONS}
        element={<TermsAndConditions />}
      />
      <Route path={AppRoute.PRIVACY} element={<Privacy />} />
      <Route
        path={AppRoute.BUSSINESS_PACKAGES}
        element={<BussinessPackagesView />}
      />
      <Route
        path={AppRoute.CONTACT}
        element={<ContactPage />}
      />
      <Route
        path={AppRoute.CONTACT_THANKS}
        element={<ThanksPage />}
      />
      <Route
        path={AppRoute.CONTACT_THANKS_TWO}
        element={<ThanksPage />}
      />
      <Route
        path="*"
        element={
          <>
            <Text.h2>{intl.translate({ id: 'Error 404' })}</Text.h2>

            <Text.p>
              {intl.translate(
                {
                  id: "We couldn't find what you were looking for. If you whish you can <a>Go Home</a>",
                },
                {
                  a: (label) => <a href={AppRoute.HOME}>{label}</a>,
                },
              )}
            </Text.p>
          </>
        }
      />
    </Routes>
  );
};
