import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {
  getBabysitters,
  updateBabysitterStatus
} from 'api/babysitters/babysitters.api';
import { KeyboardArrowDownOkidsIcon } from 'components/views/icons/KeyboardArrowDownIcon';
import { KeyboardArrowUpOkidsIcon } from 'components/views/icons/KeyboardArrowUpOkidsIcon';
import { Button } from 'design';
import { BabysitterStatus } from 'enums/auth-type.enum';
import { getDayMonthyear } from 'helpers/date.helpers';
import { dayOfWeek } from 'helpers/days.of.week.helpers';
import { FC, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BabysitterDataType } from 'types/babysitter-props';
import { intl } from 'utilities/i18n/intl.utility';

const emptyField = '--';

const notAvailable = intl.translate({ id: 'No disponible' });

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledSecondDiv = styled.div`
  width: 100%;
`;

const StyledTableContainer = styled(TableContainer)`
  height: 70vh;
`;

const StyledApproveButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  color: white !important;
  font-weight: 600 !important;
`;

const StyledDisallowButton = styled(Button)`
  background-color: red !important;
  color: white !important;
  font-weight: 600 !important;
`;

const StyledTitleTableCell = styled(TableCell)`
  font-weight: 800 !important;
  font-size: 1rem !important;
`;

const StyledViewContainerDiv = styled.div`
  height: 100vh;
  overflow-y: hidden;
`;

const TextNowrap = styled.span`
  white-space: nowrap;
`;

export const ProvidersTable: FC = () => {
  const [babysitterData, setBabysitterData] = useState<BabysitterDataType[]>(
    [],
  );

  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    const getBabysitterList = async () => {
      const response = await getBabysitters();

      if (response && response !== undefined) {
        setBabysitterData(
          response.sort(
            (a, b) => a.user?.name.localeCompare(b.user?.name || '') || 0,
          ),
        );
      } else {
        return [];
      }
    };
    getBabysitterList();
  }, [reload]);

  const handleClickActive = async (
    babysitter: BabysitterDataType,
  ): Promise<BabysitterDataType> => {
    if (babysitter.status !== BabysitterStatus.ACTIVE) {
      await updateBabysitterStatus(babysitter.id, true);
      setReload(!reload);
      return babysitter;
    } else {
      await updateBabysitterStatus(babysitter.id, false);
      setReload(!reload);
      return babysitter;
    }
  };

  function createData(
    checkApprovement: ReactElement,
    babysitter: BabysitterDataType,
  ) {
    const name = babysitter.user?.name || emptyField;

    const lastname = babysitter.user?.lastname || emptyField;

    const email = babysitter.user?.email || emptyField;

    const address = babysitter.address || emptyField;

    const birthDate = babysitter.dateOfBirth;

    const registerDate =
      babysitter.dateOfRegister || emptyField;

    const completedServices = babysitter.completedServices.toString() || 0;

    const monday = babysitter.check_Lunes;

    const tuesday = babysitter.check_Martes;

    const wednesday = babysitter.check_Miercoles;

    const thursday = babysitter.check_Jueves;

    const friday = babysitter.check_Viernes;

    const saturday = babysitter.check_Sabado;
    4;

    const sunday = babysitter.check_Domingo;

    const fromMondayHours = new Date(
      babysitter.startTimeAvailabilityMonday,
    ).getUTCHours();

    const fromTuesdayHours = new Date(
      babysitter.startTimeAvailabilityTuesday,
    ).getUTCHours();

    const fromWednesdayHours = new Date(
      babysitter.startTimeAvailabilityWednesday,
    ).getUTCHours();

    const fromThursdayHours = new Date(
      babysitter.startTimeAvailabilityThursday,
    ).getUTCHours();

    const fromFridayHours = new Date(
      babysitter.startTimeAvailabilityFriday,
    ).getUTCHours();

    const fromSaturdayHours = new Date(
      babysitter.startTimeAvailabilitySaturday,
    ).getUTCHours();

    const fromSundayHours = new Date(
      babysitter.startTimeAvailabilitySunday,
    ).getUTCHours();

    const toMondayHours = new Date(
      babysitter.endTimeAvailabilityMonday,
    ).getUTCHours();

    const toTuesdayHours = new Date(
      babysitter.endTimeAvailabilityTuesday,
    ).getUTCHours();

    const toWednesdayHours = new Date(
      babysitter.endTimeAvailabilityWednesday,
    ).getUTCHours();

    const toThursdayHours = new Date(
      babysitter.endTimeAvailabilityThursday,
    ).getUTCHours();

    const toFridayHours = new Date(
      babysitter.endTimeAvailabilityFriday,
    ).getUTCHours();

    const toSaturdayHours = new Date(
      babysitter.endTimeAvailabilitySaturday,
    ).getUTCHours();

    const toSundayHours = new Date(
      babysitter.endTimeAvailabilitySunday,
    ).getUTCHours();

    return {
      name,
      lastname,
      email,
      address,
      birthDate,
      registerDate,
      completedServices,
      checkApprovement,
      availability: [
        {
          day: monday ? dayOfWeek[0] : notAvailable,
          from: monday ? `${fromMondayHours} hrs` : emptyField,
          to: monday ? `${toMondayHours} hrs` : emptyField,
        },
        {
          day: tuesday ? dayOfWeek[1] : notAvailable,
          from: tuesday ? `${fromTuesdayHours} hrs` : emptyField,
          to: tuesday ? `${toTuesdayHours} hrs` : emptyField,
        },
        {
          day: wednesday ? dayOfWeek[2] : notAvailable,
          from: wednesday ? `${fromWednesdayHours} hrs` : emptyField,
          to: wednesday ? `${toWednesdayHours} hrs` : emptyField,
        },
        {
          day: thursday ? dayOfWeek[3] : notAvailable,
          from: thursday ? `${fromThursdayHours} hrs` : emptyField,
          to: thursday ? `${toThursdayHours} hrs` : emptyField,
        },
        {
          day: friday ? dayOfWeek[4] : notAvailable,
          from: friday ? `${fromFridayHours} hrs` : emptyField,
          to: friday ? `${toFridayHours} hrs` : emptyField,
        },
        {
          day: saturday ? dayOfWeek[5] : notAvailable,
          from: saturday ? `${fromSaturdayHours} hrs` : emptyField,
          to: saturday ? `${toSaturdayHours} hrs` : emptyField,
        },
        {
          day: sunday ? dayOfWeek[6] : notAvailable,
          from: sunday ? `${fromSundayHours} hrs` : emptyField,
          to: sunday ? `${toSundayHours} hrs` : emptyField,
        },
      ],
    };
  }

  function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;

    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              color="primary"
            >
              {open ? (
                <KeyboardArrowUpOkidsIcon />
              ) : (
                <KeyboardArrowDownOkidsIcon />
              )}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="right">{row.lastname}</TableCell>
          <TableCell align="right">{row.email}</TableCell>
          <TableCell align="right">{row.address}</TableCell>
          <TableCell align="right"><TextNowrap>{getDayMonthyear(new Date(row.birthDate))}</TextNowrap></TableCell>
          <TableCell align="right"><TextNowrap>{getDayMonthyear(new Date(row.registerDate))}</TextNowrap></TableCell>
          <TableCell align="right">{row.completedServices}</TableCell>
          <TableCell align="right">{row.checkApprovement}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {intl.translate({ id: 'Horario:' })}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {intl.translate({ id: 'Día de la semana' })}
                      </TableCell>
                      <TableCell>
                        {intl.translate({ id: 'Disponible desde' })}
                      </TableCell>
                      <TableCell>
                        {intl.translate({ id: 'Disponible hasta' })}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.availability.map((babysitter, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {babysitter.day}
                        </TableCell>
                        <TableCell key={index}>
                          {babysitter.from.toString()}
                        </TableCell>
                        <TableCell key={index}>
                          {babysitter.to.toString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <StyledViewContainerDiv>
      <StyledDiv>
        <StyledSecondDiv>
          <StyledTableContainer>
            <Table aria-label="collapsible table" size='small'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <StyledTitleTableCell>
                    {intl.translate({ id: 'Nombre' })}
                  </StyledTitleTableCell>
                  <StyledTitleTableCell align="right">
                    {intl.translate({ id: 'Apellido' })}
                  </StyledTitleTableCell>
                  <StyledTitleTableCell align="right">
                    {intl.translate({ id: 'Email' })}
                  </StyledTitleTableCell>
                  <StyledTitleTableCell align="right">
                    {intl.translate({ id: 'Dirección' })}
                  </StyledTitleTableCell>
                  <StyledTitleTableCell align="right">
                    {intl.translate({ id: 'Fecha de nacimiento' })}
                  </StyledTitleTableCell>
                  <StyledTitleTableCell align="right">
                    {intl.translate({ id: 'Fecha de registro' })}
                  </StyledTitleTableCell>
                  <StyledTitleTableCell align="right">
                    {intl.translate({ id: 'Servicios completados' })}
                  </StyledTitleTableCell>
                  <StyledTitleTableCell align="right">
                    {intl.translate({ id: 'Habilitar/Deshabilitar' })}
                  </StyledTitleTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {babysitterData.map((babysitter, index) => {
                  if (babysitter.status === BabysitterStatus.ACTIVE) {
                    return (
                      <Row
                        key={index}
                        row={createData(
                          <StyledDisallowButton
                            fullWidth
                            onClick={() => handleClickActive(babysitter)}
                          >
                            {intl.translate({ id: 'Deshabilitar' })}
                          </StyledDisallowButton>,
                          babysitter,
                        )}
                      />
                    );
                  } else {
                    return (
                      <Row
                        key={index}
                        row={createData(
                          <StyledApproveButton
                            fullWidth
                            onClick={() => handleClickActive(babysitter)}
                          >
                            {intl.translate({ id: 'Habilitar' })}
                          </StyledApproveButton>,
                          babysitter,
                        )}
                      />
                    );
                  }
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </StyledSecondDiv>
      </StyledDiv>
    </StyledViewContainerDiv>
  );
};
