import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'components/Toast/Toast';
import { config } from 'config';
import { DesignProvider } from 'design/DesignContext';
import { DesignSystem } from 'design/enums/design-system.enum';
import { reduxStore } from 'global-state';
import React from 'react';

import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'utilities/i18n/IntlContext';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
  const tagManagerArgs = {
    gtmId: 'GTM-NDW82KKJ'
  }
  TagManager.initialize(tagManagerArgs)
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const clientId = config.google.appId;
root.render(
  <GoogleOAuthProvider clientId={clientId}>
    <React.StrictMode>
      <DesignProvider system={DesignSystem.MATERIAL_UI}>
        <IntlProvider>
          <ReduxProvider store={reduxStore}>
            <BrowserRouter>
              <App />

              <ToastContainer position="bottom-right" />
            </BrowserRouter>
          </ReduxProvider>
        </IntlProvider>
      </DesignProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
