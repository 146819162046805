import DeleteIcon from '@mui/icons-material/Delete';
import { Chip, ListItem } from '@mui/material';
import List from '@mui/material/List';
import { HomeContainer } from 'components/Container/HomeContainer';
import { Form } from 'components/Form/Form';
import { defaultValuesMaps } from 'components/Map/defaultValuesMaps';
import { AddressModal } from 'components/Modal/AddressModal';
import { BackgroundCheckAvailabilityForm } from 'components/background/BackgroundCheckAvailabilityForm';
import { MobileBackgroundCheckAvailabilityForm } from 'components/background/MobileBackgroundCheckAvailabilityForm';
import { Button, Select, Text } from 'design';
import { DateTimePicker } from 'design/input/DateTimePicker/DateTimePicker';
import { AppRoute } from 'enums/app-route.enum';
import { FormikHelpers } from 'formik';
import { setServiceDataWhileLoginAction } from 'global-state/actions';
import { selectAuthToken } from 'global-state/selectors';
import {
  addHours,
  calcHours,
  checkIfDateIsBeforeAnother,
  formatDate,
  subtractMinutes,
} from 'helpers/date.helpers';
import { genericErrorHandler } from 'helpers/error.helpers';
import { Align } from 'layout';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ServiceDataProps } from 'types/service-props';
import { intl } from 'utilities/i18n/intl.utility';
import * as Yup from 'yup';

const DATE_VALIDATION_TOLERANCE_MIN = 15;

const StyledHeightSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  max-height: 80vh;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3rem 1rem;
    max-height: 100%;
  }
`;

const StyledLeftSideDiv = styled.div`
  height: 100%;
  width: 50vw;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 0 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-bottom: 1rem;
    height: auto;
  }
`;

const StyledLeftSideTextH2 = styled(Text.h2)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 1.7rem;
    padding: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    text-align: center;
    padding: 0 2rem;
    font-size: 2rem;
  }
`;

const StyledAlignHrDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    justify-content: center;
  }
`;

const StyledLineHr = styled.hr`
  border-top: 5px solid ${({ theme }) => theme.palette.primary.main};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 80%;
  }
`;

const StyledLeftSideTextH3 = styled(Text.h3)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 900;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 0.9rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StyledLeftSideTextH4 = styled(Text.h3)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 600;
  font-size: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 0.8rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 1rem 2rem;
  }
`;

const StyledLeftSideBoldTextH4 = styled(Text.h3)`
  color: ${({ theme }) => theme.palette.secondary.dark};
  font-weight: 600;
  font-size: 1rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 0.8rem;
  }
`;

const StyledRightSideDiv = styled.div`
  width: 50vw;
  margin-right: 2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledScheduleCallButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  margin-top: 0.5rem !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 1.5rem !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

const StyledSpaceBr = styled.br`
  height: 0.5rem;
`;

const StyledScheduleCallWrapperDiv = styled.div`
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 0.5rem 2rem 3rem;
  }
`;

const StyledList = styled(List)`
  background-color: #efeded;
  width: '100%';
`;


const StyledListItem = styled(ListItem)`
  display: flex;
  width: 100%;
  justifycontent: space-between;
  align-items: center;
  padding: 0 1rem;
  flex-direction: column;
`;

const StyledSelectedTimeAlign = styled(Align)`
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  width: 100%;
`;

const todayPlus24Hours = addHours(new Date(), 24);
const todayPlus28Hours = addHours(todayPlus24Hours, 4);

function roundTime(date: Date) {
  const minutes = 60;
  const ms = 1000 * 60 * minutes;
  const rounderStartServiceTime = new Date(Math.ceil(date.getTime() / ms) * ms);
  return rounderStartServiceTime;
}
const roundStartTime = roundTime(todayPlus24Hours);
const roundEndTime = roundTime(todayPlus28Hours);

const timePickerViewsOptions = ['year', 'day', 'hours'];

const initialValues = {
  startDateTimeValue: roundStartTime,
  endDateTimeValue: roundEndTime,
  address: { lat: 0, long: 0, address: '' },
  numberOfChildren: 1,
};

const checkMinAndMaxDurationInHours = (
  startingDate: Date,
  endingDate: Date,
) => {
  const hours = calcHours(endingDate, startingDate);
  return hours < 4 || hours > 8 ? false : true;
};

const validationSchema = Yup.object({
  startDateTimeValue: Yup.date()
    .typeError('Debe ingresar un formato de fecha y hora válido')
    .required(
      intl.translate({
        id: 'Debe ingresar una fecha de inicio del servicio válida',
      }),
    )
    .test(
      'end_date_is_after_start_date',
      'La fecha y hora de finalización debe ser mayor a la fecha y hora de inicio',
      function (startDateTimeValue) {
        const { endDateTimeValue } = this.parent;
        if (startDateTimeValue && endDateTimeValue) {
          return checkIfDateIsBeforeAnother(
            startDateTimeValue,
            endDateTimeValue,
          );
        } else {
          return false;
        }
      },
    ),

  endDateTimeValue: Yup.date()
    .typeError('Debe ingresar un formato de fecha y hora válido')
    .required(
      intl.translate({
        id: 'La fecha y hora de finalización debe ser mayor a la fecha y hora de inicio',
      }),
    )
    .test(
      'end_date_is_after_start_date',
      'La fecha y hora de finalización debe ser mayor a la fecha y hora de inicio',
      function (endDateTimeValue) {
        const { startDateTimeValue } = this.parent;
        if (startDateTimeValue && endDateTimeValue) {
          return checkIfDateIsBeforeAnother(
            startDateTimeValue,
            endDateTimeValue,
          );
        } else {
          return false;
        }
      },
    ),

  address: Yup.object({ lat: Yup.number(), long: Yup.number() }).required(
    intl.translate({
      id: 'Debe indicar una dirección válida',
    }),
  ),
  numberOfChildren: Yup.number()
    .oneOf(
      [1, 2, 3],
      intl.translate({
        id: 'La contratación por web tiene un máximo de 3 niños.',
      }),
    )
    .required('Debe ingresar cantidad de niños a cuidar')
    .min(1),
});

type FormValues = {
  startDateTimeValue: Date;
  endDateTimeValue: Date;
  address: { lat: number; long: number; address: string };
  numberOfChildren: number;
};

interface HomeComponentProps {
  onClick: () => void;
}

export const CheckAvailabilityForm: FC<HomeComponentProps> = ({ onClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { targetId } = useLocation()?.state || {};
  const [long, setLong] = useState(defaultValuesMaps.defautCoords.lng);
  const [lat, setLat] = useState(defaultValuesMaps.defautCoords.lat);
  const authToken = useSelector(selectAuthToken);
  const [dateRanges, setDateRanges] = useState<{ start: any; end: any }[]>([]);
  const [isDateRangeAdded, setIsDateRangeAdded] = useState(false);
  useEffect(() => {
    const scrollToServiceForm = document.getElementById(targetId);
    if (scrollToServiceForm) {
      scrollToServiceForm.scrollIntoView();
    }
  }, [targetId]);

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    const addressName = values.address.address;
    if (authToken) {
      if (values.address !== initialValues.address) {
        try {
          const todayPlus24HoursWithTolerance = subtractMinutes(
            todayPlus24Hours,
            DATE_VALIDATION_TOLERANCE_MIN,
          );

          const todayPlus25HoursWithTolerance = subtractMinutes(
            todayPlus28Hours,
            DATE_VALIDATION_TOLERANCE_MIN,
          );

          for (const range of dateRanges) {
            if (range.start < todayPlus24HoursWithTolerance) {
              helpers.setFieldError(
                'startDateTimeValue',
                intl.translate(
                  {
                    id: 'Debe ser posterior a {startTime}',
                  },
                  { startTime: formatDate(todayPlus24HoursWithTolerance) },
                ),
              );
              return;
            }

            if (range.end.getDate() - range.start.getDate() >= 7) {
              helpers.setFieldError(
                'endDateTimeValue',
                intl.translate({
                  id: 'after.six.days',
                }),
              );
              return;
            }

            if (
              range.end < todayPlus25HoursWithTolerance ||
              range.end < range.start
            ) {
              helpers.setFieldError(
                'endDateTimeValue',
                intl.translate({
                  id: 'Debe ser posterior al inicio del servicio',
                }),
              );
              return;
            }
          }
          helpers.resetForm();
          navigate(
            `${AppRoute.SELECT_BABYSITTER
            }?lat=${lat}&lng=${long}&address=${addressName}&numberOfChildren=${values.numberOfChildren
            }&dateRanges=${encodeURIComponent(JSON.stringify(dateRanges))}`,
          );
        } catch (error) {
          genericErrorHandler(error);
        }
      } else {
        toast.warning(
          intl.translate({
            id: 'Para realizar una consulta de tarifa primero debe ingresar una dirección',
          }),
        );
      }
    } else {
      const serviceData: ServiceDataProps = {
        startServiceDateAndTime: values.startDateTimeValue,
        endServiceDateAndTime: values.endDateTimeValue,
        locationLat: lat,
        locationLng: long,
        address: addressName,
        numberOfChildren: values.numberOfChildren,
        dateRanges,
      };

      dispatch(setServiceDataWhileLoginAction(serviceData));
      navigate(AppRoute.LOGIN);
    }
  };

  return (
    <BackgroundCheckAvailabilityForm>
      <MobileBackgroundCheckAvailabilityForm>
        <HomeContainer>
          <StyledHeightSection id="formSection">
            <StyledLeftSideDiv>
              <StyledLeftSideTextH2>
                {intl.translate({
                  id: 'Consulte sin costo tarifas y conozca a las niñeras',
                })}
              </StyledLeftSideTextH2>

              <StyledSpaceBr />

              <StyledAlignHrDiv>
                <StyledLineHr />
              </StyledAlignHrDiv>

              <StyledSpaceBr />

              <StyledLeftSideTextH3>
                {intl.translate({
                  id: 'Elija la fecha y hora en la que precisaría que cuiden de sus hijos',
                })}
              </StyledLeftSideTextH3>

              <StyledLeftSideTextH4>
                {intl.translate({
                  id: 'check.availability.form.sub.title',
                })}
              </StyledLeftSideTextH4>
              <StyledScheduleCallWrapperDiv>
                <StyledLeftSideBoldTextH4>
                  {intl.translate({
                    id: 'La contratación por web tiene un máximo de 3 niños. Si desea cuidados para 4 niños o más, agende una llamada con la empresa.',
                  })}
                </StyledLeftSideBoldTextH4>
                <StyledScheduleCallButton
                  color="primary"
                  variant="filled"
                  onClick={onClick}
                >
                  {intl.translate({ id: 'Agendar llamada' })}
                </StyledScheduleCallButton>
              </StyledScheduleCallWrapperDiv>
            </StyledLeftSideDiv>

            <StyledRightSideDiv>
              <Form
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                {(formik) => {
                  const handleAddDateRange = () => {
                    const { startDateTimeValue, endDateTimeValue } =
                      formik.values;
                    if (startDateTimeValue && endDateTimeValue) {
                      setDateRanges([
                        ...dateRanges,
                        { start: startDateTimeValue, end: endDateTimeValue },
                      ]);
                      formik.setFieldValue(
                        'startDateTimeValue',
                        startDateTimeValue,
                      );
                      formik.setFieldValue(
                        'endDateTimeValue',
                        endDateTimeValue,
                      );
                      setIsDateRangeAdded(true);
                    }
                    formik.setFieldError('startDateTimeValue', '');
                    formik.setFieldError('endDateTimeValue', '');
                  };

                  const handleDeleteDateRange = (index: any) => {
                    setDateRanges(
                      dateRanges.filter((_: any, i: any) => i !== index),
                    );
                  };

                  return (
                    <div>
                      <Align column h-stretch gap={2}>
                        <DateTimePicker
                          name="startDateTimeValue"
                          label={intl.translate({
                            id: 'Fecha y hora de llegada',
                          })}
                          value={formik.values.startDateTimeValue}
                          onChange={(event) => {
                            const date = event.target.value;
                            formik.setFieldValue('startDateTimeValue', date);
                          }}
                          required
                          error={formik.errors.startDateTimeValue}
                          fullWidth
                          minutesStep={60}
                          views={timePickerViewsOptions}
                        />

                        <DateTimePicker
                          name="endDateTimeValue"
                          label={intl.translate({
                            id: 'Fecha y hora de salida',
                          })}
                          value={formik.values.endDateTimeValue}
                          onChange={(event) => {
                            const date = event.target.value;
                            formik.setFieldValue('endDateTimeValue', date);
                          }}
                          required
                          error={formik.errors.endDateTimeValue}
                          fullWidth
                          minutesStep={60}
                          views={timePickerViewsOptions}
                        />

                        {dateRanges.length !== 0 && (
                          <StyledList>
                            {dateRanges.map((range: any, index: any) => (
                              <StyledListItem key={index} disableGutters>
                                <StyledSelectedTimeAlign>
                                  <Text.p>{`${range.start.toLocaleString()} - ${range.end.toLocaleString()}`}</Text.p>
                                  <Chip
                                    icon={<DeleteIcon />}
                                    onClick={() => handleDeleteDateRange(index)}
                                  />
                                </StyledSelectedTimeAlign>
                              </StyledListItem>
                            ))}
                          </StyledList>
                        )}

                        <Button
                          type="button"
                          variant="filled"
                          onClick={handleAddDateRange}
                        >
                          Agregar horario
                        </Button>

                        <Select
                          label={intl.translate({ id: 'Cantidad de hijos' })}
                          name="numberOfChildren"
                          id="numberOfChildren"
                          value={formik.values.numberOfChildren}
                          error={formik.errors.numberOfChildren}
                          options={[
                            { label: intl.translate({ id: 'Uno' }), value: 1 },
                            { label: intl.translate({ id: 'Dos' }), value: 2 },
                            { label: intl.translate({ id: 'Tres' }), value: 3 },
                          ]}
                          onChange={formik.handleChange}
                          variant="outlined"
                        />

                        <AddressModal
                          setLong={setLong}
                          setLat={setLat}
                          setAddress={(address: {
                            lat: number;
                            long: number;
                          }) => {
                            formik.setFieldValue('address', address);
                          }}
                        />
                      </Align>
                      <StyledButton
                        type="submit"
                        variant="filled"
                        disabled={
                          !isDateRangeAdded ||
                          !formik.values.address ||
                          !formik.values.numberOfChildren
                        }
                      >
                        Consulte sin cargo
                      </StyledButton>
                    </div>
                  );
                }}
              </Form>
            </StyledRightSideDiv>
          </StyledHeightSection>
        </HomeContainer>
      </MobileBackgroundCheckAvailabilityForm>
    </BackgroundCheckAvailabilityForm>
  );
};
