import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import mobileBgSelectBabysitter from './assets/mobile-bg-select-babysitter.svg';

const StyledSelectBabysitterBackgroundDiv = styled.div`
  background-image: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-image: url(${mobileBgSelectBabysitter});
    background-repeat: no-repeat;
    background-size: 125vh;
    background-position: bottom 0 left -57vw;
    height: 100%;
  }

`;

export const MobileBackgroundSelectBabysitter: FC<FcDefaultProps> = ({
  children,
}) => {
  return (
    <StyledSelectBabysitterBackgroundDiv>
      {children}
    </StyledSelectBabysitterBackgroundDiv>
  );
};
