import { intl } from 'utilities/i18n/intl.utility';

type coords = {
  lat: number;
  lng: number;
};

interface locationPinProps {
  addressText: string;
  lat: number;
  lng: number;
}

interface mapProps {
  defautCoords: coords;
  pins: locationPinProps[];
  title: string;
}

const defaultCoords: coords = {
  lat: -34.909504,
  lng: -56.167208,
};

const defaultPin: locationPinProps = {
  addressText: 'Default Address',
  lat: defaultCoords.lat,
  lng: defaultCoords.lng,
};

export const defaultValuesMaps: mapProps = {
  defautCoords: defaultCoords,
  title: intl.translate({
    id: 'Ingrese la dirección donde se prestará el servicio',
  }),
  pins: [defaultPin],
};
