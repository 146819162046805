import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import MobileCheckAvailabilityFormBackgroundSvg from './assets/mobile-flujo-contratacion.svg';

const StyledMobileCheckAvailabilityFormBackgroundDiv = styled.div`
  background-image: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-image: url(${MobileCheckAvailabilityFormBackgroundSvg});
    background-repeat: no-repeat;
    background-size: 100vh;
    background-position: top 40vh left -30vw;
    height: 100vh;
  }
`;

export const BackgroundBabysitterPanelMobile: FC<FcDefaultProps> = ({
  children,
}) => {
  return (
    <StyledMobileCheckAvailabilityFormBackgroundDiv>
      {children}
    </StyledMobileCheckAvailabilityFormBackgroundDiv>
  );
};
