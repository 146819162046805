import { Text } from 'design';
import { ProfileAvatar } from 'design/Avatar/ProfileAvatar';
import { FC } from 'react';
import styled from 'styled-components';
import { User } from 'types/user';
import { intl } from 'utilities/i18n/intl.utility';

const StyledMainInfoDiv = styled.div`
  display: flex;
  margin-top: 5rem;
  align-items: stretch;
  width: 100%;
`;

const StyledBabysitterMainInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  padding-left: 16px;
`;

const StyledTextP = styled(Text.p)`
  font-size: 12.8px;
  text-transform: uppercase;
  font-weight: 900;
  color: #00000070;
`;

const StyledBabysitterNameTextP = styled(Text.p)`
  color: black;
  font-weight: 900;
  font-size: 22.4px;
`;

const StyledBabysitterProfileImageDiv = styled.div`
  display: flex;
`;

interface IUserPanerTopProps {
  userInfo: User | null;
}

export const UserPanelTopDiv: FC<IUserPanerTopProps> = ({ userInfo }) => {
  return (
    <StyledMainInfoDiv>
      <StyledBabysitterProfileImageDiv>
        <ProfileAvatar src={userInfo?.picture} />
      </StyledBabysitterProfileImageDiv>
      <StyledBabysitterMainInfoDiv>
        <StyledTextP>
          {intl.translate({
            id: 'Perfil cliente',
          })}
        </StyledTextP>
        <StyledBabysitterNameTextP>{userInfo?.name}</StyledBabysitterNameTextP>
      </StyledBabysitterMainInfoDiv>
    </StyledMainInfoDiv>
  );
};
