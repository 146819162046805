import { mapId } from 'config/google.config';
import { Button, Text } from 'design';
import { palette } from 'design/theme/palette';
import { FormikHelpers, FormikValues } from 'formik';
import GoogleMapReact from 'google-map-react';
import { useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import { defaultValuesMaps } from './defaultValuesMaps';
import { LocationPin } from './LocationPin';
import { MapOptionsDefault } from './MapStyle';

type coords = {
  lat: number;
  lng: number;
};

interface locationPinProps {
  addressText: string;
  lat: number;
  lng: number;
}

interface mapProps {
  defautCoords: coords;
  setAddress: (address: any) => void;
  handleTitle: (title: string) => any;
  handleClose: () => void;
  pins: locationPinProps[];
  title: string;
  startTimeValue?: Date;
  endTimeValue?: Date;
  handleSubmit?: (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>,
  ) => Promise<void>;
}

interface Place {
  label: string;
  value: {
    description: string;
    matched_substrings: {
      length: number;
      offset: number;
    }[];
    place_id: string;
    reference: string;
    structured_formatting: {
      main_text: string;
      main_text_matched_substrings: {
        length: number;
        offset: number;
      }[];
      secondary_text: string;
    };
    terms: {
      offset: number;
      value: string;
    }[];
    types: string[];
  };
}

const StyledMainDiv = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 80vh;
  border: 0.25rem;
  border-color: ${({ theme }) => theme.palette.primary.main};
  border-style: solid;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.invert};
`;

const StyledModalHeaderDiv = styled.div``;

const StyledCloseButton = styled(Button)`
  background-color: transparent !important;
  border: 2px solid ${({ theme }) => theme.palette.grey.main} !important;
  position: absolute !important;
  min-width: auto !important;
  height: 2rem !important;
  width: 2rem !important;
  top: 2% !important;
  right: 2% !important;
  color: ${({ theme }) => theme.palette.grey.main} !important;
  padding: 0 !important;
`;

const StyledTitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  padding: 1rem 0;
  font-family: ${({ theme }) => theme.fontFamily};
`;

const StyledTexth2 = styled(Text.h2)`
  color: ${({ theme }) => theme.palette.primary.invert};
  font-size: 2rem;
  margin-right: 2.4rem;
  margin-left: 1rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 1.5rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 1rem;
  }
`;

const StyledMapDiv = styled.div`
  height: 57vh;
  border-radius: 0 0 15px 15px;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 60vh;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    height: 65vh;
  }
`;

const StyledSearchDiv = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  width: 50%;
  margin-left: 25%;
  margin-bottom: 1rem;
`;

const StyledNextButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  color: white !important;
  border-color: ${({ theme }) => theme.palette.primary.invert} !important;
  width: 40vw;
  position: absolute !important;
  top: 80% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0.5rem !important;
`;

export const Map = (props: mapProps) => {
  const [value, setValue] = useState<null | object>(null);

  const [pins, setPins] = useState(props.pins);

  const [center, setCenter] = useState(props.defautCoords);

  const mapsApiKey = mapId || '';

  const availableAreas = [
    {
      name: 'Montevideo',
      latMin: -34.9518,
      latMax: -34.8504,
      lngMin: -56.2372,
      lngMax: -56.0091,
    },
    {
      name: 'Ciudad de la Costa, Canelones',
      latMin: -34.9,
      latMax: -34.73,
      lngMin: -56.1,
      lngMax: -55.69,
    },
  ];

  const areCoordinatesInAvailableArea = (lat: number, lng: number): boolean => {
    for (const area of availableAreas) {
      if (
        lat >= area.latMin &&
        lat <= area.latMax &&
        lng >= area.lngMin &&
        lng <= area.lngMax
      ) {
        return true;
      }
    }
    return false;
  };

  const handleCoordsChange = async (place: Place) => {
    const id = place.value.place_id;
    const newCoords = await getCoords(id);
    if (!areCoordinatesInAvailableArea(newCoords.lat, newCoords.lng)) {
      toast.error(
        intl.translate({
          id: 'Este lugar no puede ser seleccionado para nuestro servicio',
        }),
        { autoClose: 500 },
      );
      return;
    }
    const convertCoords: locationPinProps = {
      lat: newCoords.lat,
      lng: newCoords.lng,
      addressText: place.label,
    };
    setCenter(newCoords);
    setValue(place);
    setPins([convertCoords]);
    props.handleTitle(convertCoords.addressText);
  };

  const handleClickCoords = async (data: coords) => {
    const convertCoords: locationPinProps = {
      lat: data.lat,
      lng: data.lng,
      addressText: intl.translate({ id: 'Lugar seleccionado' }),
    };
    const addressName = await getAddressName(
      convertCoords.lat,
      convertCoords.lng,
      mapsApiKey,
    );
    convertCoords.addressText = addressName;
    setCenter(data);
    setPins([convertCoords]);
    props.handleTitle(addressName);
  };

  const getCoords = async (id: string) => {
    try {
      const geoPlaceById = await geocodeByPlaceId(id);
      return await getLatLng(geoPlaceById[0]);
    } catch (error) {
      return { lat: 0, lng: 0 };
    }
  };

  const getAddressName = async (
    lat: number,
    lng: number,
    key: string,
  ): Promise<string> => {
    const res = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${key}`,
    );
    if (!res) {
      throw new Error('no se ha podido cargar la dirección');
    }
    const response = await res.json();
    const addressName = response.results[0].formatted_address;
    return addressName;
  };

  const handleMapClick = ({ lat, lng }: { lat: number; lng: number }) => {
    if (!areCoordinatesInAvailableArea(lat, lng)) {
      toast.error(
        intl.translate({
          id: 'Este lugar no puede ser seleccionado para nuestro servicio',
        }),
        { autoClose: 500 },
      );
      return;
    }

    handleClickCoords({ lat, lng });
  };

  return (
    <StyledMainDiv>
      <StyledModalHeaderDiv>
        <StyledCloseButton onClick={() => props.handleClose()}>
          X
        </StyledCloseButton>
        <StyledTitleDiv>
          <StyledTexth2>{props.title}</StyledTexth2>
        </StyledTitleDiv>
        <StyledSearchDiv>
          <GooglePlacesAutocomplete
            apiKey={mapId}
            autocompletionRequest={{
              componentRestrictions: {
                country: ['UY'],
              },
            }}
            apiOptions={{ language: 'es' }}
            selectProps={{
              value,
              placeholder: intl.translate({ id: 'Lugar seleccionado' }),
              onChange: (place: Place) => handleCoordsChange(place),
              styles: {
                input: (provided: object) => ({
                  ...provided,
                  color: palette.primary.main,
                }),
                option: (provided: object) => ({
                  ...provided,
                  color: palette.primary.main,
                }),
                singleValue: (provided: object) => ({
                  ...provided,
                  color: palette.primary.main,
                }),
              },
            }}
          />
        </StyledSearchDiv>
      </StyledModalHeaderDiv>
      <StyledMapDiv>
        <GoogleMapReact
          onClick={({ lat, lng }) => handleMapClick({ lat, lng })}
          bootstrapURLKeys={{
            key: mapId,
            libraries: 'places',
          }}
          defaultCenter={props.defautCoords}
          center={center}
          defaultZoom={15}
          options={{ styles: MapOptionsDefault }}
        >
          {pins.map((pin, index) => {
            return (
              <LocationPin
                addressText={pin.addressText}
                lat={pin.lat}
                lng={pin.lng}
                key={index}
              />
            );
          })}
        </GoogleMapReact>
      </StyledMapDiv>
      <StyledNextButton
        onClick={() => {
          if (
            pins[0].lat === defaultValuesMaps.defautCoords.lat &&
            pins[0].lng === defaultValuesMaps.defautCoords.lng
          ) {
            toast.warning(
              intl.translate({
                id: 'Para realizar una consulta de tarifa primero debe ingresar una dirección',
              }),
            );
            return;
          }
          props.setAddress({
            lat: pins[0].lat,
            long: pins[0].lng,
            address: pins[0].addressText,
          });
          props.handleClose();
        }}
      >
        {intl.translate({ id: 'siguiente' })}
      </StyledNextButton>
    </StyledMainDiv>
  );
};
