import { Button, Text } from 'design';
import { Loading } from 'design/Button/LoadingButton';
import { OkidsModal } from 'design/Modal/Modal';
import { FC } from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const StyledCardAlignDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-height: 30rem;
  background-color: white;
  width: 75%;
  align-items: flex-start;
  align-self: center;
  border-radius: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 60%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 45%;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledTextP = styled(Text.p)`
  align-self: center;
  margin-bottom: 1.5rem;
`;

const StyledButton = styled(Button)`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 1rem !important;
  }
  color: red !important;
`;

interface Props {
  open: boolean;
  onClose: any;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
}

export const CancelServiceModal: FC<Props> = ({
  open,
  onClose,
  onClick,
  loading,
}) => {
  return (
    <OkidsModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledCardAlignDiv>
        <StyledTextP>
          {intl.translate({ id: 'Esta seguro/a de cancelar el servicio' })}
        </StyledTextP>
        <StyledDiv>
          {loading ? (
            <Loading
              loading={loading}
              loadingPosition="center"
              variant="contained"
            >
              {intl.translate({ id: 'Cargando...' })}
            </Loading>
          ) : (
            <StyledButton variant="outlined" onClick={onClick}>
              {intl.translate({ id: 'Cancelar servicio' })}
            </StyledButton>
          )}
          <Button variant="outlined" onClick={onClose}>
            {intl.translate({ id: 'Volver atrás' })}
          </Button>
        </StyledDiv>
      </StyledCardAlignDiv>
    </OkidsModal>
  );
};
