export enum AppRoute {
  HOME = '/',
  CATALOG = '/catalogo',
  STATE = '/estado',
  LOGIN = '/iniciar-sesion',
  FORGOT_PASSWORD_EMAIL = '/email-olvidaste-contraseña',
  SELECT_BABYSITTER = '/seleccione-acompanante',
  BABYSITTER_DESCRIPTION = '/acompanante',
  REGISTER = '/registrarse',
  TEST = '/test',
  CREATE_BABYSITTER_ACOUNT = '/crear-cuenta-de-acompanante',
  ADDITIONAL_DATA_CHILD = '/datos-del-nino',
  MERCADOPAGO_PENDING = '/esperando-respuesta-de-mercadopago',
  MERCADOPAGO_FAIL = '/pago-rechazado',
  WORK_WITH_US = '/trabaja-con-nosotros',
  COMPANIES = '/empresas',
  USERS = '/usuarios',
  MORE = '/mas',
  MAP = '/map',
  BACK_OFFICE = '/admin',
  CLIENTS = '/clientes',
  PROVIDERS = '/prestadores',
  ACTIVE_SERVICES = '/servicios-activos',
  CLOSED_SERVICES = '/servicios-cerrados',
  BABYSITTER_PANEL = '/perfil-ninera',
  BUSSINESS_PACKAGES = '/paquetes-empresariales',
  OUR_SERVICE = '/nuestro-servicio',
  USER_PANEL = '/perfil-usuario',
  TERMS_AND_CONDITIONS = '/terminos-y-condiciones',
  PRIVACY = '/privacidad',
  REVIEW_BABYSITTER = '/evaluacion-ninera',
  PAYMENT = '/pagos',
  SERVICE_BEFORE_PAYMENT = '/formulario-antes-pago',
  CONTACT = '/contact',
  CONTACT_THANKS = '/contact/gracias',
  CONTACT_THANKS_TWO = '/contact/gracias-2',
}
