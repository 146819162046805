import { createReducer } from '@reduxjs/toolkit';
import { StorageItem } from 'enums/storage-item.enum';
import {
  removeAuthTokenAction,
  removeBabysitterFullDataAction,
  removeBillDataAction,
  removeSelectedBabysitterFullDataAction,
  removeServiceDataAction,
  removeServiceDataWhileLoginAction,
  removeUserDataAction,
  setAuthTokenAction,
  setBabysitterFullDataAction,
  setBillDataAction,
  setSelectedBabysitterFullDataAction,
  setServiceDataAction,
  setServiceDataWhileLoginAction,
  setUserDataAction,
} from 'global-state/actions/auth.actions';
import { storage } from 'helpers/storage.helpers';
import { BabysitterDataType } from 'types/babysitter-props';
import { ServiceDataProps } from 'types/service-props';
import { User } from 'types/user';

export type BillData = {
  billAddress: string,
  billDni: string;
  billCity: string;
}
export type AuthState = {
  authToken: string | null;
  user: User | null;
  babysitter: BabysitterDataType[] | null;
  service: ServiceDataProps | null;
  serviceLogin: ServiceDataProps | null;
  selectedBabysitter: BabysitterDataType | null;
  billData: BillData | null;
};

const initialState: AuthState = {
  authToken: storage.local.get(StorageItem.AUTH_TOKEN),
  user: storage.local.get(StorageItem.USER),
  babysitter: storage.session.get(StorageItem.BABYSITTER),
  service: storage.session.get(StorageItem.SERVICE),
  serviceLogin: storage.session.get(StorageItem.SERVICE_LOGIN),
  selectedBabysitter: storage.session.get(StorageItem.SELECTED_BABYSITTER),
  billData: storage.session.get(StorageItem.BILL_DATA)
};

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setAuthTokenAction, (state, action) => {
      state.authToken = action.payload;
      storage.local.set(StorageItem.AUTH_TOKEN, action.payload);
    })
    .addCase(removeAuthTokenAction, (state) => {
      state.authToken = null;

      storage.local.remove(StorageItem.AUTH_TOKEN);
    })
    .addCase(setUserDataAction, (state, action) => {
      state.user = action.payload;
      storage.local.set(StorageItem.USER, action.payload);
    })
    .addCase(removeUserDataAction, (state) => {
      state.user = null;

      storage.local.remove(StorageItem.USER);
    })
    .addCase(setBabysitterFullDataAction, (state, action) => {
      state.babysitter = action.payload;
      storage.session.set(StorageItem.BABYSITTER, action.payload);
    })
    .addCase(removeBabysitterFullDataAction, (state) => {
      state.babysitter = null;

      storage.session.remove(StorageItem.BABYSITTER);
    })
    .addCase(setSelectedBabysitterFullDataAction, (state, action) => {
      state.selectedBabysitter = action.payload;
      storage.session.set(StorageItem.SELECTED_BABYSITTER, action.payload);
    })
    .addCase(removeSelectedBabysitterFullDataAction, (state) => {
      state.selectedBabysitter = null;

      storage.session.remove(StorageItem.SELECTED_BABYSITTER);
    })
    .addCase(setServiceDataAction, (state, action) => {
      state.service = action.payload;
      storage.session.set(StorageItem.SERVICE, action.payload);
    })
    .addCase(removeServiceDataAction, (state) => {
      state.service = null;

      storage.session.remove(StorageItem.SERVICE);
    })
    .addCase(setServiceDataWhileLoginAction, (state, action) => {
      state.service = action.payload;
      storage.session.set(StorageItem.SERVICE, action.payload);
    })
    .addCase(removeServiceDataWhileLoginAction, (state) => {
      state.serviceLogin = null;

      storage.session.remove(StorageItem.SERVICE_LOGIN);
    })
    .addCase(setBillDataAction, (state, action) => {
      state.billData = action.payload;
      storage.session.set(StorageItem.BILL_DATA, action.payload);
    })
    .addCase(removeBillDataAction, (state) => {
      state.billData = null;

      storage.session.remove(StorageItem.BILL_DATA);
    })
});
