import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import guaranteesMobileBg from './assets/mobile-guarantees-bg.svg';

const StyledMobileBackgroundGuaranteesDiv = styled.div`
  background-image: none;
  background-size: cover;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-image: url(${guaranteesMobileBg});
    background-repeat: no-repeat;
    background-position-x: revert;
    background-position-y: center;
  }
`;

export const BackgroundGuaranteesMobile: FC<FcDefaultProps> = ({
  children,
}) => {
  return (
    <StyledMobileBackgroundGuaranteesDiv>
      {children}
    </StyledMobileBackgroundGuaranteesDiv>
  );
};
