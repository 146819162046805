export enum StorageItem {
  AUTH_TOKEN = 'AUTH_TOKEN',
  LOCALE = 'LOCALE',
  THEME_MODE = 'THEME_MODE',
  USER = 'USER',
  BABYSITTER = 'BABYSITTER',
  SERVICE = 'SERVICE',
  SERVICE_LOGIN = 'SERVICE_LOGIN',
  SELECTED_BABYSITTER = 'SELECTED_BABYSITTER',
  BILL_DATA = 'BILL_DATA'
}
