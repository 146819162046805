import { Text } from 'design';
import { MercadoPagoFailure } from 'design/Icon/MercadoPagoFailure';
import { FC } from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const RedFailureDiv = styled.div`
  height: 40vh;
  background-color: #f86f6f;
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-height: 839px) {
    padding-top: 3rem;
  }
`;

const StyledBannerTextH2 = styled(Text.h2)`
  margin: 2rem;
  color: ${({ theme }) => theme.palette.primary.invert};
  @media (max-height: 839px) {
    font-size: 15px;
  }
`;

export const ErrorSectionMessage: FC = () => {
  return (
    <RedFailureDiv>
      <MercadoPagoFailure />
      <StyledBannerTextH2>
        {intl.translate({
          id: 'Ooops! Hubo un error al procesar tu pago',
        })}
      </StyledBannerTextH2>
    </RedFailureDiv>
  );
};
