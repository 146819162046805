import { http } from 'utilities/http/http';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

type MercadoPagoResponse = {
  success: boolean;
  data: string;
};

export async function pay(
  payerId: string,
  babysitterUserId: string,
  quantity: number,
  serviceId: string,
  startDate: Date,
  endDate: Date
): Promise<string> {
  try {
    const mercadopagoResponse = await http.post<MercadoPagoResponse>(
      '/mercadopago/pay',
      {
        payerId,
        babysitterUserId,
        quantity,
        serviceId,
        startDate,
        endDate
      },
    );

    if (mercadopagoResponse.success) {
      return mercadopagoResponse.data;
    } else {
      throw new Error(
        intl.translate({ id: 'Error al crear link de mercadopago' }),
      );
    }
  } catch (error) {
    logger.warn('Failed request');
    throw new Error(
      intl.translate({
        id: 'Error al crear link de mercadopago',
      }),
    );
  }
}
