import { User } from 'types/user';
import { http } from 'utilities/http/http';
import { intl } from 'utilities/i18n/intl.utility';

type Data = {
  token: string;
  user: User;
};

type TokenResponse = {
  success: boolean;
  data: Data;
};
export const googleLoginUser = async (token: string): Promise<Data> => {
  const response = await http.post<TokenResponse>('/auth/google/login', {
    idToken: token,
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(
      intl.translate({
        id: 'Token inválido, porfavor envie el token de Google correcto.',
      }),
    );
  }
};

export const googleRegisterUser = async (token: string): Promise<Data> => {
  const response = await http.post<TokenResponse>('/auth/google/register', {
    idToken: token,
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(
      intl.translate({
        id: 'Token inválido, porfavor envie el token de Google correcto.',
      }),
    );
  }
};
