import { useIconColor } from 'design/hooks/use-icon-color.hook';
import { IconProps } from 'design/types/icon-props';
import { FC } from 'react';

export const OkidsLogo: FC<IconProps & { width?: number | string }> = ({
  color,
  width = '100%',
  className,
  invert = false,
}) => {
  const _color = useIconColor(color, invert);

  return (
    <svg
      className={className}
      width={width}
      color={_color}
      viewBox="0 0 958 322"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M120.4 83.4C50.8 83.4 0.200012 132.9 0.200012 201.1C0.200012 270.1 50.7 320.2 120.4 320.2C190.1 320.2 240.6 270.1 240.6 201.1C240.6 132.9 190.1 83.4 120.4 83.4ZM121.4 260.7C93.5 260.7 70.8 234.1 70.8 201.5C70.8 168.7 93 142.9 121.4 142.9C149.1 142.9 170 168.1 170 201.5C170 234.6 148.6 260.7 121.4 260.7Z"
        fill="currentColor"
      />
      <path
        d="M509.8 316.8H564.3C567.6 316.8 570.3 314 570.3 310.5V151.4C570.3 147.9 567.6 145.1 564.3 145.1H509.8C506.5 145.1 503.8 147.9 503.8 151.4V310.5C503.9 313.9 506.5 316.8 509.8 316.8Z"
        fill="currentColor"
      />
      <path
        d="M713.7 153.8C713.7 155 713 156.1 712 156.5C711.7 156.6 711.4 156.7 711.1 156.7C710.3 156.7 709.6 156.4 709.1 155.7C697.9 142.2 682.6 135.1 664.8 135.1C618.9 135.1 589.3 170.8 589.3 226.1C589.3 282.8 619.7 319.5 666.7 319.5C683.7 319.5 698.3 312.5 709 299.2C709.5 298.6 710.3 298.2 711 298.2C711.3 298.2 711.6 298.3 711.9 298.4C713 298.8 713.7 299.9 713.7 301.1V310.5C713.7 314 716.4 316.8 719.7 316.8H774.2C777.5 316.8 780.2 314 780.2 310.5V78.2C780.2 74.7 777.5 71.9 774.2 71.9H719.7C716.4 71.9 713.7 74.7 713.7 78.2V153.8V153.8ZM685.2 266.7C667.7 266.7 656.3 251.5 656.3 228.1C656.3 204.6 667.6 189.5 685.2 189.5C702.3 189.5 713.7 205 713.7 228.1C713.7 251.2 702.3 266.7 685.2 266.7Z"
        fill="currentColor"
      />
      <path
        d="M894.1 203.4C878.5 198.9 867.2 195.7 867.2 187.6C867.2 183.5 870.5 178.7 879.8 178.7C892.3 178.7 910.1 184.3 932.6 195.5C935.5 196.9 938.9 195.7 940.4 192.8L956 161.7C957.6 158.6 956.4 154.6 953.4 153C930.1 140.7 905.9 134.4 881.4 134.4C836.3 134.4 807.2 158.3 807.2 195.4C807.2 234.6 843.7 244.8 870.4 252.2C885.8 256.5 895.9 259.3 895.9 267C895.9 268.9 894.9 275.3 882.4 275.3C864.7 275.3 845.3 269.1 824.5 256.9C821.5 255.1 817.7 256.4 816.2 259.6L802 290.6C800.6 293.5 801.6 297.1 804.3 298.8C826.6 313 852.5 320.2 881.4 320.2C927.9 320.2 957.9 296.7 957.9 260.2C957.8 221.7 921 211.1 894.1 203.4Z"
        fill="currentColor"
      />
      <path
        d="M407.8 321.4C399 321.4 391.1 316.2 387.7 308.1L369.4 264.3C365.5 255.1 356.6 249.1 346.6 249.1C340.9 249.1 335.6 251 331.1 254.6C325.3 259.3 321.9 266.3 321.9 273.8V299.7C321.9 311.7 312.1 321.4 300.2 321.4H284.1C272.1 321.4 262.4 311.6 262.4 299.7V22.5C262.4 10.5 272.2 0.799988 284.1 0.799988H300.2C312.2 0.799988 321.9 10.6 321.9 22.5V81.3C321.9 107 329.5 118.6 336.1 125.8C346.3 137 360.8 143.4 376 143.4C388.1 143.4 399.5 139.5 409 132.1L456 98.9C459.6 96.4 463.9 95.1 468.2 95.1C478.3 95.1 487 102 489.4 111.8L492.9 126.4C495 135.1 491.6 144.1 484.3 149.2L429.8 189.2C419.9 196.1 416.5 209.1 421.7 220L455.2 290.3C458.4 297.1 458 304.9 454 311.3C450 317.6 443.1 321.4 435.6 321.4H407.8Z"
        fill="currentColor"
      />
      <path
        d="M388.3 115.6C359.7 115.6 336.4 92.3 336.4 63.7C336.4 35.1 359.7 11.8 388.3 11.8C419.4 11.8 440.2 32.7 440.2 63.7C440.2 94.7 419.4 115.6 388.3 115.6Z"
        fill="currentColor"
      />
    </svg>
  );
};
