import { CrossIcon } from 'components/views/icons/CloseIcon';
import { Button } from 'design';
import { FC } from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const StyledButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
`;

const StyledCancelButton = styled(Button)`
  background-color: red !important;
  color: white !important;
`;

export const BabysitterDeclinedServices: FC = () => {
  return (
    <StyledButtonsDiv>
      <StyledCancelButton fullWidth>
        {intl.translate({ id: 'Cancelado' })}
        <CrossIcon />
      </StyledCancelButton>
    </StyledButtonsDiv>
  );
};
