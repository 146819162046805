import { User } from 'types/user';
import { http } from 'utilities/http/http';
import { intl } from 'utilities/i18n/intl.utility';

interface Props {
  success: boolean;
  data: User[];
}

export async function getParents(): Promise<User[]> {
  const response = await http.get<Props>('/user/get-clients');
  if (response.success) {
    return response.data;
  } else {
    throw new Error(intl.translate({ id: 'No ha podido traer ningun dato' }));
  }
}

export async function updateUserStatus(id: string, enabled: boolean) {
  const response = await http.patch<Props>('/user/update-clients-status', {
    id: id,
    status: enabled ? 'active' : 'disable',
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(intl.translate({ id: 'No ha podido cambiar el status' }));
  }
}
