import { CancelServiceModal } from 'components/Modal/CancelServiceModal';
import { Button, Text } from 'design';
import { Loading } from 'design/Button/LoadingButton';
import { AcceptIcon } from 'design/Icon/AcceptIcon';
import { CrossIcon } from 'design/Icon/CrossIcon';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const StyledButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
`;

const StyledAcceptButton = styled(Button)`
  margin-right: 0.5rem !important;
  color: green !important;
  border: 1px solid ${({ theme }) => theme.palette.secondary.main} !important;
`;

const StyledDeclineButton = styled(Button)`
  color: red !important;
  border: 1px solid ${({ theme }) => theme.palette.secondary.main} !important;
`;

const StyledSpan = styled.span`
  font-size: 0.8rem;
  padding-top: 1rem;
  font-size: 0.7rem;
  padding-right: 0.5rem;
`;

const StyledAcceptIcon = styled(AcceptIcon)`
  color: green;
`;

const StyledCrossIcon = styled(CrossIcon)`
  color: red;
`;

const StyledImportantSpan = styled.span`
  text-transform: uppercase;
  color: red;
  font-size: 0.7rem;
  display: inline;
`;

const StyledTextP = styled(Text.p)`
  font-size: 0.7rem;
  padding-left: 0.3rem;
  display: inline;
`;

interface Props {
  onClickActive: React.MouseEventHandler<HTMLButtonElement>;
  onClickDeclined: React.MouseEventHandler<HTMLButtonElement>;
  loading: boolean;
}

export const BabysitterPendingService: FC<Props> = ({
  onClickActive,
  onClickDeclined,
  loading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  return (
    <>
      <StyledButtonsDiv>
        {loading ? (
          <Loading
            loading={loading}
            loadingPosition="center"
            variant="contained"
            fullWidth
          />
        ) : (
          <StyledAcceptButton
            variant="outlined"
            fullWidth
            onClick={onClickActive}
          >
            {intl.translate({ id: 'Aceptar' })} <StyledAcceptIcon />
          </StyledAcceptButton>
        )}

        <StyledDeclineButton
          variant="outlined"
          disabled={loading}
          fullWidth
          onClick={handleOpen}
        >
          {intl.translate({ id: 'Cancelar' })} <StyledCrossIcon />
        </StyledDeclineButton>
      </StyledButtonsDiv>
      <StyledSpan>
        <StyledImportantSpan>
          {intl.translate({ id: '*Recuerde' })}
        </StyledImportantSpan>
        <StyledTextP>
          {intl.translate({
            id: 'que debe aceptar o declinar el servicio cuanto antes para no resultar penalizado',
          })}
        </StyledTextP>
      </StyledSpan>
      <CancelServiceModal
        loading={loading}
        onClick={onClickDeclined}
        onClose={() => setIsOpen(false)}
        open={isOpen}
      />
    </>
  );
};
