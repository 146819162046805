import WhatsAppIcon from 'components/WhatsappIcon';
import { useContext } from 'react';
import { Router } from 'Router';
import styled from 'styled-components';
import { IntlContext } from 'utilities/i18n/IntlContext';

const StyledDiv = styled.div`
  background-color: ${({ theme }) => theme.background.back};
  min-height: 100vh;
`;

function App() {
  // necessary to force a render when locale is updated
  useContext(IntlContext);

  return (
    <StyledDiv>
      <Router />
      <WhatsAppIcon />
    </StyledDiv>
  );
}

export default App;
