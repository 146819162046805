import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import MobileCheckAvailabilityFormBackgroundSvg from './assets/mobile-flujo-contratacion.svg';

const StyledMobileCheckAvailabilityFormBackgroundDiv = styled.div`
  background-image: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-color: white;
    background-image: url(${MobileCheckAvailabilityFormBackgroundSvg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top 28vh left -4vw;
  }
`;

export const MobileBackgroundCheckAvailabilityForm: FC<FcDefaultProps> = ({
  children,
}) => {
  return (
    <StyledMobileCheckAvailabilityFormBackgroundDiv>
      {children}
    </StyledMobileCheckAvailabilityFormBackgroundDiv>
  );
};
