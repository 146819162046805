import { Text } from 'design';
import { FC } from 'react';
import Slider from 'react-infinite-logo-slider';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import BlueCross from '../../../assets/EmpresasAliadas/BlueCross.png';
import EduSchool from '../../../assets/EmpresasAliadas/Edu School.jpg';
import ElbioFernandez from '../../../assets/EmpresasAliadas/Elbio Fernandez.png';
import Pulso from '../../../assets/EmpresasAliadas/Pulso.png';
import Sura from '../../../assets/EmpresasAliadas/SURA.png';
import Santander from '../../../assets/EmpresasAliadas/Santander.png';
import ScuolaItaliana from '../../../assets/EmpresasAliadas/Scuola Italiana.png';
import UDE from '../../../assets/EmpresasAliadas/UDE.png';

const StyledContainerSection = styled.section`
  width: 100%;
  overflow-x: hidden;
`;

const StyledIconDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 3.75rem;
  height: 5.625rem;
  margin-right: 1rem;
  object-fit: contain;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex: 0 0 5.625rem;
  }
`;

const StyledTitleTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 4rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 1rem;
  }
`;

const StyledTextH3 = styled(Text.h3)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 3rem;
  font-weight: 900;
  align-self: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 2.6rem;
    align-self: center;
    padding: 0 0.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 2rem;
  }
`;

const StyledLogoImg = styled.img`
  width: 7rem;
  height: auto;
  object-fit: cover;
`;

const StyledLogoImgEdu = styled.img`
  width: 7rem;
  height: 4rem;
  object-fit: contain;
`;

const HomeContainerDiv = styled.div`
  max-width: 100vw;
  margin: 0 auto;
  height: auto;
  object-fit: contain;
  overflow-x: hidden;
  padding: 5rem 0;
`;

export const PartnersCompaniesView: FC = () => {
  return (
    <StyledContainerSection>
      <HomeContainerDiv>
        <StyledTitleTextDiv>
          <StyledTextH3>
            {intl.translate({ id: 'Empresas con las que colaboramos' })}
          </StyledTextH3>
        </StyledTitleTextDiv>
        <Slider
          width="200px"
          duration={40}
          pauseOnHover={false}
          blurBorders={false}
          blurBoderColor={'#fff'}
        >
          <Slider.Slide>
            <StyledIconDiv>
              <StyledLogoImg src={BlueCross} alt="BlueCross" />
            </StyledIconDiv>
          </Slider.Slide>
          <Slider.Slide>
            <StyledIconDiv>
              <StyledLogoImg src={ScuolaItaliana} alt="ScuolaItaliana" />
            </StyledIconDiv>
          </Slider.Slide>
          <Slider.Slide>
            <StyledIconDiv>
              <StyledLogoImg src={Pulso} alt="Pulso" />
            </StyledIconDiv>
          </Slider.Slide>

          <Slider.Slide>
            <StyledIconDiv>
              <StyledLogoImg src={Santander} alt="Santander" />
            </StyledIconDiv>
          </Slider.Slide>
          <Slider.Slide>
            <StyledIconDiv>
              <StyledLogoImg src={ElbioFernandez} alt="ElbioFernandez" />
            </StyledIconDiv>
          </Slider.Slide>
          <Slider.Slide>
            <StyledIconDiv>
              <StyledLogoImg src={UDE} alt="UDE" />
            </StyledIconDiv>
          </Slider.Slide>
          <Slider.Slide>
            <StyledIconDiv>
              <StyledLogoImgEdu src={EduSchool} alt="EduSchool" />
            </StyledIconDiv>
          </Slider.Slide>
          <Slider.Slide>
            <StyledIconDiv>
              <StyledLogoImg src={Sura} alt="Sura" />
            </StyledIconDiv>
          </Slider.Slide>
        </Slider>
      </HomeContainerDiv>
    </StyledContainerSection>
  );
};
