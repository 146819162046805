import { Modal } from '@mui/material';
import { Button, Text } from 'design';
import { parseDateDdMmYyyy } from 'helpers/parseDate';
import { Align } from 'layout';
import { StarsRating } from 'rating/StarsRating';
import * as React from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import { ReadMore } from './ReadMore';
import { CrossIcon } from './icons/CloseIcon';

const StyledCardAlignDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: white;
  width: 75%;
  align-items: flex-start;
  align-self: center;
  border-radius: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 60%;
    max-height: 30rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 45%;
  }
`;

const StyledAvatarAlign = styled(Align)`
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
  justify-content: flex-start;
`;

const StyledNameP = styled(Text.p)`
  line-height: 1.5rem;
  font-size: 1.2rem;
`;

const StyledLocationP = styled(Text.p)`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.3rem;
  max-width: 19rem;
`;

const StyledStarsAlign = styled(Align)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  width: 100%;
  flex-direction: column;
`;

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledAvatarAndCloseButtonAlign = styled(Align)`
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  flex-direction: column;
`;

const StyledCrossButton = styled(Button)`
  padding: 0 !important;
  display: flex !important;
  justify-content: flex-end !important;
`;

const StyledCardAlign = styled(Align)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.3rem;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
  }
`;

interface Props {
  open: boolean;
  onClose: any;
  name: string;
  location: string;
  src: string;
  alt: string;
  date: string;
  firstNameChild: string;
  comment: string;
  rating: number;
  readonly?: boolean;
}

export const CommentsModal: React.FC<Props> = ({
  open,
  onClose,
  location,
  alt,
  src,
  date,
  firstNameChild,
  comment,
  name,
  rating,
  readonly,
}) => {
  const capitalizeFirstLetter = (name: string) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledCardAlignDiv>
        <StyledAvatarAndCloseButtonAlign>
          <StyledCrossButton onClick={onClose}>
            <CrossIcon color="primary" />
          </StyledCrossButton>
          <StyledAvatarAlign gap={0.5}>
            <StyledCardAlign>
              <StyledNameP color="dark">
                <strong>{name}</strong>
              </StyledNameP>
              <StyledLocationP color="light">
                {intl.translate({ id: 'Mamá/Papá de' })}{' '}
                <strong>{capitalizeFirstLetter(firstNameChild)}</strong>
              </StyledLocationP>
            </StyledCardAlign>
          </StyledAvatarAlign>
        </StyledAvatarAndCloseButtonAlign>
        <ReadMore comment={comment} />

        <StyledStarsAlign>
          <StarsRating initialValue={rating} readonly={readonly} size={35} />
          <StyledLocationP color="light">
            {parseDateDdMmYyyy(new Date(date))}
          </StyledLocationP>
        </StyledStarsAlign>
      </StyledCardAlignDiv>
    </StyledModal>
  );
};
