import { login } from 'api/auth.api';
import { Form } from 'components/Form/Form';
import { OkidsLogo } from 'components/icons/OkidsLogo';
import { Button, Card, Text, TextInput } from 'design';
import { Loading } from 'design/Button/LoadingButton';
import { LoadingIcon } from 'design/Icon/LoadingIcon';
import { AppRoute } from 'enums/app-route.enum';
import { FormikHelpers } from 'formik';
import {
  removeAuthTokenAction,
  removeUserDataAction,
  setAuthTokenAction,
  setUserDataAction
} from 'global-state/actions';
import {
  selectAuthToken,
  selectCurrentServiceData,
  selectUser
} from 'global-state/selectors';
import { genericErrorHandler } from 'helpers/error.helpers';
import { isPath } from 'helpers/navigation.helpers';
import { Align } from 'layout';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';
import * as Yup from 'yup';
import { requestForToken } from '../../firebase';
import { SignInWithGoogle } from './SignInWithGoogle';

const StyledRightSideDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 90%;
    padding: 0;
    height: auto;
  }
`;

const StyledCardBase = styled(Card.Base)`
  width: 100%;
  background-color: transparent;
  max-width: 37.5rem;
`;

const StyledCardHeading = styled(Card.Heading)`
  width: 100%;
`;

const StyledCardBody = styled(Card.Body)`
  width: 100%;
`;

const StyledOkidsLogo = styled(OkidsLogo)`
  margin-bottom: 1rem;
  padding: 0 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StyledSpaceDiv = styled.div`
  margin-top: 1rem;
`;

const StyledQuestionTextH5 = styled(Text.h5)`
  margin: 0.8rem 0;
  font-weight: bold;
  color: #b0b0b0;
  text-align: center;
`;

const StyledForgotPasswordQuestionTextH5 = styled(Text.h5)`
  margin: 0.5rem 0;
  font-weight: bold;
  color: #b0b0b0;
  text-align: center;
`;

const StyledCardButtonAlign = styled(Align)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledLinkToRegisterTextH6 = styled(Text.h6)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  font-size: 1.5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 1.5rem !important;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 1.4rem !important;
  }
`;

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Debe ingresar un correo electrónico válido')
    .required(intl.translate({ id: 'Campo requerido' })),
  password: Yup.string()
    .min(
      8,
      intl.translate({
        id: 'Ingrese al menos 8 caracteres',
      }),
    )
    .required(intl.translate({ id: 'Campo requerido' })),
});

type FormValues = {
  email: string;
  password: string;
};

export const LoginForm: FC<FcDefaultProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const serviceData = useSelector(selectCurrentServiceData);
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);

      dispatch(removeAuthTokenAction());
      dispatch(removeUserDataAction());

      const { token, user } = await login(values.email, values.password);

      dispatch(setAuthTokenAction(token));
      dispatch(setUserDataAction(user));

      requestForToken();

      toast.success(
        intl.translate({ id: 'Bienvenido {user}' }, { user: values.email }),
      );

      helpers.resetForm();
    } catch (error: any) {
      genericErrorHandler(error);
      toast.warning(error.message);
    } finally {
      setLoading(false);
    }
  };

  const user = useSelector(selectUser);

  useEffect(() => {
    if (authToken) {
      const path = (location.state as { origin: unknown })?.origin;

      if (isPath(path)) {
        navigate(path);
      } else if (user?.type === 'babysitter' && user?.status === 'active') {
        navigate(AppRoute.BABYSITTER_PANEL);
      } else if (
        serviceData &&
        serviceData.startServiceDateAndTime &&
        serviceData.endServiceDateAndTime &&
        serviceData.locationLat &&
        serviceData.locationLng &&
        serviceData.address
      ) {
        navigate(
          `${AppRoute.SELECT_BABYSITTER}?lat=${serviceData.locationLat}&lng=${serviceData.locationLng}&inicio=${serviceData.startServiceDateAndTime}&fin=${serviceData.endServiceDateAndTime}&address=${serviceData.address}`,
        );
      } else {
        navigate(AppRoute.HOME);
      }
    }
  }, [authToken, location.state, navigate, serviceData, user]);

  return (
    <StyledRightSideDiv>
      <StyledCardBase>
        <StyledOkidsLogo color="primary" />
        <StyledCardHeading>
          <Align column h-stretch>
            <StyledSpaceDiv />

            <SignInWithGoogle />

            <StyledSpaceDiv />

            {/* <LoginWithFacebookButton /> */}
            <StyledSpaceDiv />
          </Align>
        </StyledCardHeading>

        <Form
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Align column>
              <StyledCardBody>
                <Align column gap={0.7}>
                  <TextInput
                    label={intl.translate({
                      id: 'Dirección de correo electrónico',
                    })}
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.errors.email}
                    fullWidth
                    required
                  />

                  <TextInput
                    label={intl.translate({ id: 'Contraseña' })}
                    id="password"
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.errors.password}
                    fullWidth
                    required
                  />
                  <StyledSpaceDiv />
                </Align>
                <StyledCardButtonAlign column h-center>
                  {loading ? (
                    <Loading
                      endIcon={<LoadingIcon />}
                      loading={loading}
                      loadingPosition="center"
                      variant="contained"
                    ></Loading>
                  ) : (
                    <Button type="submit" color="primary" variant="filled">
                      {intl.translate({ id: 'Ingresar' })}
                    </Button>
                  )}

                  <Link
                    to={AppRoute.FORGOT_PASSWORD_EMAIL}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      textDecoration: 'none',
                    }}
                  >
                    <StyledForgotPasswordQuestionTextH5>
                      {intl.translate({
                        id: 'forgot.password.question',
                      })}
                    </StyledForgotPasswordQuestionTextH5>
                  </Link>

                  <StyledQuestionTextH5>
                    {intl.translate({
                      id: '¿Aún no tienes cuenta?',
                    })}
                  </StyledQuestionTextH5>
                  <Link
                    to={AppRoute.REGISTER}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      textDecoration: 'none',
                      fontSize: '2rem',
                    }}
                  >
                    <StyledLinkToRegisterTextH6>
                      {intl.translate({
                        id: 'Registrate acá!',
                      })}
                    </StyledLinkToRegisterTextH6>
                  </Link>
                </StyledCardButtonAlign>
              </StyledCardBody>
            </Align>
          )}
        </Form>
      </StyledCardBase>
    </StyledRightSideDiv>
  );
};
