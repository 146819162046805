import { saveTokenNotification } from 'api/notification.api';
import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCRHgVc-ZJR9TV7WyB4AaE2yQNTZupl4-Y',
  authDomain: 'okids-972aa.firebaseapp.com',
  projectId: 'okids-972aa',
  storageBucket: 'okids-972aa.appspot.com',
  messagingSenderId: '404178220584',
  appId: '1:404178220584:web:eef9e75492406be11f271d',
  measurementId: 'G-9G9JWJHL6D',
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export const loginFirebase = () => {
  signInAnonymously(getAuth());
};

export const requestForToken = async () => {
  // For now i will leave the console.logs to iderificate in a future implementation where i need to work
  console.log('Requesting permission...');
  Notification.requestPermission();

  return getToken(messaging, {
    vapidKey:
      'BEbVwPWTGJuneyQRGZPLE4bvIz62pSQf7CzOcxtSzEd93YuD6ufLQNuLEhP5R2W6Jr66TctpCNk7H3IhPO930qk',
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        saveTokenNotification(currentToken);
        // Perform any other neccessary action with the token
        // will be save in other card
      } else {
        console.log(
          'No registration token available. Request permission to generate one.',
        );
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = (): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

// onMessageListener()
// .then((payload) => {
//   toast.success(payload.notification?.body);
//   console.log(payload);
// })
// .catch((err) => console.log('failed: ', err));
