import { recoverPassword, sendNewPasswordMail } from 'api/users/users.api';
import { Form } from 'components/Form/Form';
import { OkidsLogo } from 'components/icons/OkidsLogo';
import { Button, Card, Text, TextInput } from 'design';
import { Loading } from 'design/Button/LoadingButton';
import { LoadingIcon } from 'design/Icon/LoadingIcon';
import { AppRoute } from 'enums/app-route.enum';
import { FormikHelpers } from 'formik';
import { genericErrorHandler } from 'helpers/error.helpers';
import { Align } from 'layout';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';
import * as Yup from 'yup';

type FormValues =
  | {
      email: string;
    }
  | { password: string; passwordConfirmation: string };

export const ForgotPasswordForm: FC<FcDefaultProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);

      if ('password' in values && token) {
        try {
          const response = await recoverPassword(values.password, token);
          if (response) {
            toast.success(
              intl.translate({ id: 'password.reset.confirmation.correct' }),
            );
            navigate(AppRoute.LOGIN);
          }
        } catch (e) {
          toast.success(intl.translate({ id: 'repeat.password.input.denied' }));
        }
      } else if ('email' in values) {
        const resp = await sendNewPasswordMail(values.email);
        if (resp) {
          toast.success(
            intl.translate({ id: 'email.restore.password.send.mail' }),
          );
        }
      }

      helpers.resetForm();
    } catch (error) {
      genericErrorHandler(error);
      toast.warning(
        intl.translate({
          id: 'No se ha podido actualizar los datos',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setToken(searchParams.get('token'));
  }, [searchParams]);

  const validationSchemaWithEmail = Yup.object({
    email: Yup.string()
      .email('Debe ingresar un correo electrónico válido')
      .required(intl.translate({ id: 'Campo requerido' })),
  });

  const validationSchemaWithPassword = Yup.object({
    password: Yup.string().required(intl.translate({ id: 'Campo requerido' })),
    passwordConfirmation: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        intl.translate({ id: 'password.confirmation.error' }),
      )
      .required(intl.translate({ id: 'Campo requerido' })),
  });

  return (
    <StyledRightSideDiv>
      <StyledCardBase>
        <StyledOkidsLogo color="primary" />

        <Form
          validationSchema={
            token ? validationSchemaWithPassword : validationSchemaWithEmail
          }
          initialValues={
            token
              ? {
                  password: '',
                  passwordConfirmation: '',
                }
              : { email: '' }
          }
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Align column>
              <StyledCardBody>
                <Align column gap={0.7}>
                  <StyledTitleh3>
                    {token
                      ? intl.translate({
                          id: 'reset.password.input.title',
                        })
                      : intl.translate({
                          id: 'reset.email.input.title',
                        })}
                  </StyledTitleh3>
                  <TextInput
                    label={intl.translate({
                      id: token
                        ? 'Contraseña'
                        : 'Dirección de correo electrónico',
                    })}
                    id={token ? 'password' : 'email'}
                    name={token ? 'password' : 'email'}
                    type={token ? 'password' : 'email'}
                    onChange={formik.handleChange}
                    value={
                      (token
                        ? formik.values.password
                        : formik.values.email) as string
                    }
                    error={token ? formik.errors.password : formik.errors.email}
                    fullWidth
                    required
                  />
                  {token && (
                    <TextInput
                      label={intl.translate({
                        id: 'confirm.password.label',
                      })}
                      id={'passwordConfirmation'}
                      name={'passwordConfirmation'}
                      type={token ? 'password' : 'email'}
                      onChange={formik.handleChange}
                      value={formik.values.passwordConfirmation as string}
                      error={formik.errors.passwordConfirmation}
                      fullWidth
                      required
                    />
                  )}
                  <StyledSpaceDiv />
                </Align>
                <StyledCardButtonAlign column h-center>
                  {loading ? (
                    <Loading
                      endIcon={<LoadingIcon />}
                      loading={loading}
                      loadingPosition="center"
                      variant="contained"
                    />
                  ) : (
                    <Button type="submit" color="primary" variant="filled">
                      {intl.translate({ id: 'send' })}
                    </Button>
                  )}

                  <Link
                    to={AppRoute.LOGIN}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      textDecoration: 'none',
                    }}
                  >
                    <StyledForgotPasswordQuestionTextH5>
                      {intl.translate({
                        id: 'forgot.password.remember',
                      })}
                    </StyledForgotPasswordQuestionTextH5>
                  </Link>
                </StyledCardButtonAlign>
              </StyledCardBody>
            </Align>
          )}
        </Form>
      </StyledCardBase>
    </StyledRightSideDiv>
  );
};

const StyledRightSideDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 90%;
    padding: 0;
    height: auto;
  }
`;

const StyledCardBase = styled(Card.Base)`
  width: 100%;
  background-color: transparent;
  max-width: 37.5rem;
`;

const StyledCardBody = styled(Card.Body)`
  width: 100%;
`;

const StyledOkidsLogo = styled(OkidsLogo)`
  margin-bottom: 1rem;
  padding: 0 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StyledSpaceDiv = styled.div`
  margin-top: 1rem;
`;

const StyledForgotPasswordQuestionTextH5 = styled(Text.h5)`
  margin: 0.5rem 0;
  font-weight: bold;
  color: #b0b0b0;
  text-align: center;
`;

const StyledCardButtonAlign = styled(Align)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledTitleh3 = styled(Text.h3)`
  align-self: center;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 500;
`;
