import { styled as styledMui } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Text } from 'design';
import { TimePicker } from 'design/input/TimePicker/TimePicker';
import { OnChangeHandlerFn } from 'design/types/on-change-handler-fn';
import { FormikErrors } from 'formik';
import { getTimeMinusOne } from 'helpers/date.helpers';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const StyledLetterATextP = styled(Text.p)`
  padding: 0 0.5rem;
`;

const StyledCheckBox = styledMui(Checkbox)`
  margin: 0;
  padding: 0;
`;

const StyledDayTextP = styled(Text.p)`
  padding: 0 0.5rem;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.2rem;
  font-weight: 700;
  @media (max-width: 650px) {
    ::first-letter {
      font-size: 1.5rem;
      visibility: visible;
    }
    visibility: hidden;
    width: 2rem;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  valueCheckBox: any;
  onChange: OnChangeHandlerFn<Date | null>;
  error:
  | string
  | string[]
  | FormikErrors<any>
  | FormikErrors<any>[]
  | undefined;
  onChangeCheckBox: any;
  valueTimePicker: Date | null;
  endValueTimePicker: Date | null;
  day: string;
  minTimeEnd: number;
  nameStart: string;
  nameEnd: string;
  spanishName?: string;
  maxTime: Date;
}

export const TimeAvailavilityForm: FC<Props> = ({
  valueCheckBox,
  onChangeCheckBox,
  valueTimePicker,
  onChange,
  error,
  endValueTimePicker,
  day,
  minTimeEnd,
  nameStart,
  nameEnd,
  maxTime,
  spanishName,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const viewHours = ['hours'];
  const dayOfTheWeek = spanishName ? spanishName : day;
  return (
    <>
      <StyledDiv>
        <StyledCheckBox
          value={valueCheckBox}
          onChange={onChangeCheckBox}
          name={`check_${day}`}
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 60,
            },
          }}
          onClick={() => {
            setIsChecked(!isChecked);
          }}
          checked={valueCheckBox}
        />
        <StyledDayTextP>{dayOfTheWeek}</StyledDayTextP>
      </StyledDiv>
      <StyledDiv>
        <TimePicker
          name={nameStart}
          label={intl.translate({
            id: 'Inicio',
          })}
          disabled={!valueCheckBox}
          value={valueTimePicker}
          onChange={onChange}
          error={error}
          views={viewHours}
          ampm={false}
          maxTime={getTimeMinusOne(maxTime)}

        />
        <StyledLetterATextP>{intl.translate({ id: 'a' })}</StyledLetterATextP>
        <TimePicker
          name={nameEnd}
          label={intl.translate({
            id: 'Cierre',
          })}
          disabled={!valueCheckBox}
          value={endValueTimePicker}
          onChange={onChange}
          error={error}
          ampm={false}
          views={viewHours}
          minTime={minTimeEnd}
        />
      </StyledDiv>
    </>
  );
};
