import { DataGrid, esES, GridColDef } from '@mui/x-data-grid';
import { getAllPreviousServices } from 'api/auth.api';
import { getRate } from 'helpers/children.helpers';
import { handleServiceStatus } from 'helpers/handleServiceStatus';
import { parseDateDdMmYyyyHhMm } from 'helpers/parseDate';
import { StarsRating } from 'rating/StarsRating';
import { useEffect, useState } from 'react';
import { ServicesProps, ServiceUserBabysitterProps } from 'types/service-props';

const columns: GridColDef[] = [
    { field: 'babysitterName', headerName: 'Niñera', width: 150, align: 'center', headerAlign: 'center' },
    { field: 'startTime', headerName: 'Inicio del servicio', width: 240, align: 'center', headerAlign: 'center' },
    { field: 'endTime', headerName: 'Finalización del servicio', width: 240, align: 'center', headerAlign: 'center' },
    { field: 'status', headerName: 'Estado del servicio', width: 250, align: 'center', headerAlign: 'center' },
    { field: 'parentName', headerName: 'Padre', width: 150, align: 'center', headerAlign: 'center' },
    {
        field: 'rating', headerName: 'Rating del servicio', width: 150, align: 'center', headerAlign: 'center',
        renderCell: (params) => <StarsRating readonly allowFraction initialValue={params.row.rating} />
    },
];

export const PreviousServicesTable = () => {
    const [services, setServices] = useState<ServiceUserBabysitterProps[]>([]);

    const [filerServices, setFilerServices] =
        useState<ServicesProps[]>();

    useEffect(() => {
        getAllPreviousServices().then(setServices)
    }, []);


    useEffect(() => {
        const rowsData = services?.map((service) => {
            return {
                id: service.id,
                babysitterName: service.UserBabysitter?.user?.name,
                startTime: parseDateDdMmYyyyHhMm(service.startTime),
                endTime: parseDateDdMmYyyyHhMm(service.endTime),
                status: handleServiceStatus(service.status),
                parentName: service.name,
                rating: getRate(service?.review?.rate || 0),
            }
        })
        setFilerServices(rowsData as any)
    }, [services]);


    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid
                rows={filerServices ? filerServices : []}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                loading={!filerServices?.length}
            />
        </div>
    );
}