import { Text } from 'design';
import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledLeftSideDiv = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
    height: auto;
  }
`;

const StyledTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  margin-top: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: center;
    margin-top: 0;
  }
`;

const StyledTitleTextH2 = styled(Text.h2)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.8rem;
  font-weight: 900;
  text-align: start;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 1.8rem;
    line-height: 1.8rem;
    text-align: center;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 2rem;
  }
`;

const StyledSpaceDiv = styled.div`
  margin-top: 1rem;
`;

const StyledLineHr = styled.hr`
  color: ${({ theme }) => theme.palette.primary.main};
  border-top: 5px solid ${({ theme }) => theme.palette.primary.main};
  width: 85%;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 90%;
  }
`;

export const OurServiceLeftSideContent: FC<FcDefaultProps> = () => {
  return (
    <StyledLeftSideDiv>
      <StyledTitleDiv>
        <StyledTitleTextH2>
          {intl.translate({
            id: 'Somos la empresa de bienestar infantil que apoya a la familia en el cuidado y desarrollo de los niños y niñas, aportando valor y conexión con los padres durante toda la experiencia.',
          })}
        </StyledTitleTextH2>
        <StyledSpaceDiv />
        <StyledLineHr />
      </StyledTitleDiv>
    </StyledLeftSideDiv>
  );
};
