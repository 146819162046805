import { getTimePlusOne } from 'helpers/date.helpers';
import { FC } from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import { TimeAvailavilityForm } from './TimeAvailavilityForm';

const StyledWeekDaysAndTimeDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const StyledCheckBoxDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
  justify-content: space-between;
`;

interface FormProps {
  formik: any;
}

export const BabysitterSchedule: FC<FormProps> = ({ formik }) => {

  return (
    <StyledWeekDaysAndTimeDiv>
      <StyledCheckBoxDiv>
        <TimeAvailavilityForm
          valueCheckBox={formik.values.check_Lunes}
          onChangeCheckBox={formik.handleChange}
          valueTimePicker={formik.values.startTimeAvailabilityMonday}
          endValueTimePicker={formik.values.endTimeAvailabilityMonday}
          onChange={formik.handleChange}
          error={formik.errors.endTimeAvailabilityMonday}
          day={intl.translate({ id: 'Lunes' })}
          minTimeEnd={getTimePlusOne(formik.values.startTimeAvailabilityMonday)}
          nameStart="startTimeAvailabilityMonday"
          nameEnd="endTimeAvailabilityMonday"
          maxTime={formik.values.endTimeAvailabilityMonday}
        />
      </StyledCheckBoxDiv>
      <StyledCheckBoxDiv>
        <TimeAvailavilityForm
          valueCheckBox={formik.values.check_Martes}
          onChangeCheckBox={formik.handleChange}
          valueTimePicker={formik.values.startTimeAvailabilityTuesday}
          endValueTimePicker={formik.values.endTimeAvailabilityTuesday}
          onChange={formik.handleChange}
          error={formik.errors.endTimeAvailabilityTuesday}
          day={intl.translate({ id: 'Martes' })}
          minTimeEnd={getTimePlusOne(formik.values.startTimeAvailabilityTuesday)}
          nameStart="startTimeAvailabilityTuesday"
          nameEnd="endTimeAvailabilityTuesday"
          maxTime={formik.values.endTimeAvailabilityTuesday}

        />
      </StyledCheckBoxDiv>
      <StyledCheckBoxDiv>
        <TimeAvailavilityForm
          valueCheckBox={formik.values.check_Miercoles}
          onChangeCheckBox={formik.handleChange}
          valueTimePicker={formik.values.startTimeAvailabilityWednesday}
          endValueTimePicker={formik.values.endTimeAvailabilityWednesday}
          day={intl.translate({ id: 'Miercoles' })}
          onChange={formik.handleChange}
          error={formik.errors.endTimeAvailabilityWednesday}
          minTimeEnd={getTimePlusOne(formik.values.startTimeAvailabilityWednesday)}
          nameStart="startTimeAvailabilityWednesday"
          nameEnd="endTimeAvailabilityWednesday"
          spanishName={intl.translate({ id: 'Miércoles' })}
          maxTime={formik.values.endTimeAvailabilityWednesday}

        />
      </StyledCheckBoxDiv>
      <StyledCheckBoxDiv>
        <TimeAvailavilityForm
          valueCheckBox={formik.values.check_Jueves}
          onChangeCheckBox={formik.handleChange}
          valueTimePicker={formik.values.startTimeAvailabilityThursday}
          day={intl.translate({ id: 'Jueves' })}
          endValueTimePicker={formik.values.endTimeAvailabilityThursday}
          onChange={formik.handleChange}
          error={formik.errors.endTimeAvailabilityThursday}
          minTimeEnd={getTimePlusOne(formik.values.startTimeAvailabilityThursday)}
          nameStart="startTimeAvailabilityThursday"
          nameEnd="endTimeAvailabilityThursday"
          maxTime={formik.values.endTimeAvailabilityThursday}

        />
      </StyledCheckBoxDiv>
      <StyledCheckBoxDiv>
        <TimeAvailavilityForm
          valueCheckBox={formik.values.check_Viernes}
          onChangeCheckBox={formik.handleChange}
          valueTimePicker={formik.values.startTimeAvailabilityFriday}
          endValueTimePicker={formik.values.endTimeAvailabilityFriday}
          onChange={formik.handleChange}
          day={intl.translate({ id: 'Viernes' })}
          error={formik.errors.endTimeAvailabilityFriday}
          minTimeEnd={getTimePlusOne(formik.values.startTimeAvailabilityFriday)}
          nameStart="startTimeAvailabilityFriday"
          nameEnd="endTimeAvailabilityFriday"
          maxTime={formik.values.endTimeAvailabilityFriday}

        />
      </StyledCheckBoxDiv>
      <StyledCheckBoxDiv>
        <TimeAvailavilityForm
          valueCheckBox={formik.values.check_Sabado}
          onChangeCheckBox={formik.handleChange}
          valueTimePicker={formik.values.startTimeAvailabilitySaturday}
          day={intl.translate({ id: 'Sabado' })}
          endValueTimePicker={formik.values.endTimeAvailabilitySaturday}
          spanishName={intl.translate({ id: 'Sábado' })}
          onChange={formik.handleChange}
          error={formik.errors.endTimeAvailabilitySaturday}
          minTimeEnd={getTimePlusOne(formik.values.startTimeAvailabilitySaturday)}
          nameStart="startTimeAvailabilitySaturday"
          nameEnd="endTimeAvailabilitySaturday"
          maxTime={formik.values.endTimeAvailabilitySaturday}

        />
      </StyledCheckBoxDiv>
      <StyledCheckBoxDiv>
        <TimeAvailavilityForm
          valueCheckBox={formik.values.check_Domingo}
          onChangeCheckBox={formik.handleChange}
          day={intl.translate({ id: 'Domingo' })}
          valueTimePicker={formik.values.startTimeAvailabilitySunday}
          endValueTimePicker={formik.values.endTimeAvailabilitySunday}
          onChange={formik.handleChange}
          error={formik.errors.endTimeAvailabilitySunday}
          minTimeEnd={getTimePlusOne(formik.values.startTimeAvailabilitySunday)}
          nameStart="startTimeAvailabilitySunday"
          nameEnd="endTimeAvailabilitySunday"
          maxTime={formik.values.endTimeAvailabilitySunday}

        />
      </StyledCheckBoxDiv>
    </StyledWeekDaysAndTimeDiv>
  );
};