import { ActiveServicesTable } from 'components/ActiveServicesTable/View/ActiveServicesTable';
import { MainContainer } from 'components/Container/MainContainer';
import { AppBar } from 'components/views/AppBar';
import { FC } from 'react';
import styled from 'styled-components';

const StyledTableDesignDiv = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 3rem;
  }
  margin-top: 1.5rem;
`;

export const ActiveServicesView: FC = () => {

  return (
    <>
      <AppBar />
      <MainContainer>
        <StyledTableDesignDiv>
          <ActiveServicesTable />
        </StyledTableDesignDiv>
      </MainContainer>
    </>
  );
};
