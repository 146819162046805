import { AvailabilitySwitcher } from 'components/Buttons/AvailabilitySwitcher';
import { OkidsLogo } from 'components/icons/OkidsLogo';
import { Text } from 'design';
import { FC } from 'react';
import styled from 'styled-components';
import { User } from 'types/user';
import { intl } from 'utilities/i18n/intl.utility';

const StyledMainInfoDiv = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
    align-items: unset;
  }
`;

const StyedLeftTopDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBabysitterMainInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  padding-left: 1rem;
`;

const StyledTextP = styled(Text.p)`
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 900;
  color: #00000070;
`;

const StyledBabysitterNameTextP = styled(Text.p)`
  color: black;
  font-weight: 900;
  text-transform: capitalize;
  font-size: 1.4rem;
`;

const StyledStarsDiv = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StyledRightTopDiv = styled.div`
  display: flex;
  align-content: flex-end;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledBabysitterProfileImageDiv = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
  padding: 0.5rem;
`;

const StyledMobileStarsDiv = styled.div`
  display: inline-block;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 1rem;
    display: none;
  }
`;

const StyledOkidsLogo = styled(OkidsLogo)`
  color: ${({ theme }) => theme.palette.primary.invert} !important;
  font-size: 1rem !important;
  width: 7rem !important;
`;
interface PanelTopProps {
  userInfo: User | null;
}

export const BabysitterPanelTopDiv: FC<PanelTopProps> = ({ userInfo }) => {
  return (
    <StyledMainInfoDiv>
      <StyedLeftTopDiv>
        <StyledBabysitterProfileImageDiv>
          <StyledOkidsLogo small />
        </StyledBabysitterProfileImageDiv>
        <StyledBabysitterMainInfoDiv>
          <StyledTextP>
            {intl.translate({
              id: 'Perfil niñera',
            })}
          </StyledTextP>
          <StyledBabysitterNameTextP>
            {userInfo?.name}
          </StyledBabysitterNameTextP>
          {/* <StyledStarsDiv>
            <StarsRating initialValue={0} readonly />
          </StyledStarsDiv> */}
        </StyledBabysitterMainInfoDiv>
      </StyedLeftTopDiv>
      <StyledRightTopDiv>
        {/* <StyledMobileStarsDiv>
          <StarsRating initialValue={0} />
        </StyledMobileStarsDiv> */}
        <AvailabilitySwitcher />
      </StyledRightTopDiv>
    </StyledMainInfoDiv>
  );
};
