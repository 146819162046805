import { AuthState } from 'global-state/reducers/auth.reducer';

export const selectAuthToken = (state: { auth: AuthState }) =>
  state.auth.authToken;

export const selectUser = (state: { auth: AuthState }) => state?.auth?.user;

export const selectBabysittersList = (state: { auth: AuthState }) =>
  state.auth.babysitter;

export const selectSelectedBabysitters = (state: { auth: AuthState }) =>
  state.auth.selectedBabysitter;

export const selectBillData = (state: { auth: AuthState }) =>
  state.auth.billData;

export const selectServiceData = (state: { auth: AuthState }) =>
  state.auth.service;

export const selectCurrentServiceData = (state: { auth: AuthState }) =>
  state.auth.service;
