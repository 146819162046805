import { OkidsLogo } from 'components/icons/OkidsLogo';
import { Text } from 'design';
import { Align } from 'layout';
import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';
import { ForgotPasswordForm } from './ForgotPasswordForm';

const StyledHeightSection = styled.section`
  height: calc(100vh - 7rem);
  width: 100vw;

  margin-top: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.invert};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding-top: 3rem;
    width: 100vw;
    background-color: transparent;
  }
`;

const StyledLeftSideDiv = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0 1rem;
    margin: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
    margin: 0;
  }
`;

const StyledDiv = styled.div`
  width: 100%;
  max-width: 37.5rem;
`;

const StyledCardLeftAlignDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;

const StyledLeftSideTextH2 = styled(Text.h2)`
  color: ${({ theme }) => theme.palette.primary.invert};
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 0.9;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 1.8rem;
  }
`;

const StyledWhiteOkidsLogo = styled(OkidsLogo)`
  color: white;
`;

const StyledSpaceDiv = styled.div`
  margin-top: 1rem;
`;

const StyledLineHr = styled.hr`
  color: ${({ theme }) => theme.palette.primary.invert};
  border-top: 5px solid ${({ theme }) => theme.palette.primary.invert};
  width: 95%;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
  }
`;

const StyledLeftSideTextH3 = styled(Text.h3)`
  color: ${({ theme }) => theme.palette.primary.invert};

  font-weight: 900;
  font-size: 2.2rem;
  line-height: 0.9;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 1.4rem;
  }
`;

export const ForgotPassword: FC<FcDefaultProps> = () => {
  return (
    <Align>
      <StyledHeightSection>
        <StyledLeftSideDiv>
          <StyledDiv>
            <StyledCardLeftAlignDiv>
              <StyledLeftSideTextH2>
                {intl.translate({ id: 'BIENVENIDO A' })}
              </StyledLeftSideTextH2>
              <StyledWhiteOkidsLogo />
              <StyledSpaceDiv />
              <StyledLineHr />
              <StyledSpaceDiv />

              <StyledLeftSideTextH3>
                {intl.translate({
                  id: 'Por favor, inicie sesión para acceder a nuestros servicios',
                })}
              </StyledLeftSideTextH3>
            </StyledCardLeftAlignDiv>
          </StyledDiv>
        </StyledLeftSideDiv>

        <ForgotPasswordForm />

      </StyledHeightSection>
    </Align>
  );
};
