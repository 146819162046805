import { http } from "utilities/http/http";
import { intl } from "utilities/i18n/intl.utility";

export async function updateNewCVFile(babysitterId: string, file: string) {
    const response = await http.post<any>(
        `storage/newBabysitterFile/${babysitterId}`,
        {
            file,
        },
    );

    if (response.success) {
        return response.data;
    } else {
        throw new Error(
            intl.translate({ id: 'No ha podido guardar nuevo CV' }),
        );
    }
}

export async function deleteFileCV(key: string, fileId: string) {
    const response = await http.delete<any>(
        `storage/delete/${key}?fileId=${fileId}`



    );

    if (response.success) {
        return response.data;
    } else {
        throw new Error(
            intl.translate({ id: 'No ha podido guardar nuevo CV' }),
        );
    }
}