import { useIconColor } from 'design/hooks/use-icon-color.hook';
import { IconProps } from 'design/types/icon-props';
import { FC } from 'react';

export const BlueEyeIcon: FC<IconProps & { width?: number | string }> = ({
  color,
  width = '100%',
  className,
  invert = false,
}) => {
  const _color = useIconColor(color, invert);

  return (
    <svg
      color={_color}
      width={width}
      viewBox="0 0 995 781"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M984.302 240.9C811.002 78.8 651.602 0 497.002 0C342.402 0 183.002 78.8 9.70208 240.9C3.40208 246.7 -0.0979145 255 0.00208548 263.6C0.102085 272.2 3.90208 280.4 10.3021 286.2C183.502 439.7 342.702 514.3 497.002 514.3C651.302 514.3 810.602 439.7 983.702 286.2C990.102 280.4 993.902 272.2 994.002 263.6C994.102 255 990.602 246.7 984.302 240.9ZM104.702 273.7C101.702 271.2 100.102 267.5 100.002 263.6C100.002 259.8 101.702 256.1 104.602 253.5C112.502 246.7 120.102 240.1 127.402 233.6C169.902 196.6 206.502 164.7 251.902 140.8C254.102 139.7 256.102 139.3 257.902 139.3C262.202 139.3 265.302 141.7 266.402 142.7C269.702 145.8 272.402 151.4 269.602 157.9C235.702 236.9 244.302 328.2 290.502 398.7C234.002 373.6 177.902 335.7 104.702 273.7ZM620.002 382.3C568.702 433.6 495.302 448.2 428.502 420.6C362.002 393.1 320.802 331.3 320.802 259.4C320.802 186.1 363.002 123.1 431.002 94.9C453.302 85.6 476.302 81.1 498.902 81.1C543.502 81.1 586.602 98.6 620.102 132.1C653.902 165.9 672.402 210.3 672.402 257.2C672.302 304.1 653.702 348.6 620.002 382.3ZM889.302 273.7L883.702 278.5C832.502 321.9 792.102 356.2 740.402 382C734.302 385 728.602 382.7 725.402 379.6C723.902 378.1 719.102 372.6 722.602 365C759.502 286 753.102 195 707.802 124.7C770.902 153.9 825.902 199.1 889.302 253.7C892.302 256.2 893.902 259.9 893.902 263.8C893.902 267.5 892.302 271.2 889.302 273.7Z"
        fill="currentColor"
      />
      <path
        d="M497.102 142.8H496.502C486.802 142.8 478.102 148.3 473.802 157.1C469.402 166.1 470.502 176.6 476.702 184.5C482.802 192.3 486.102 202 486.102 212C486.102 228.1 478.802 241.4 465.402 249.6C451.202 258.3 433.002 259.4 418.902 252.3C410.302 248 400.202 248.9 392.402 254.8C384.402 260.8 380.602 270.5 382.402 280.3C392.702 335.7 440.902 376 497.002 376C561.302 376 613.502 323.7 613.502 259.5C613.702 192.9 563.602 142.8 497.102 142.8Z"
        fill="currentColor"
      />
      <path
        d="M68.2019 610.4C59.6019 610.4 50.9019 607.5 43.7019 601.6C27.3019 588.1 24.9019 563.8 38.5019 547.3L89.3019 485.6C102.802 469.2 127.102 466.8 143.602 480.4C160.002 493.9 162.402 518.2 148.802 534.7L98.0019 596.4C90.4019 605.6 79.3019 610.4 68.2019 610.4Z"
        fill="currentColor"
      />
      <path
        d="M241.302 736.3C236.202 736.3 231.002 735.3 226.002 733.1C206.502 724.6 197.502 701.9 205.902 682.4L254.102 571C262.602 551.5 285.302 542.5 304.802 550.9C324.302 559.4 333.302 582.1 324.902 601.6L276.702 713C270.402 727.6 256.202 736.3 241.302 736.3Z"
        fill="currentColor"
      />
      <path
        d="M467.002 780.5C466.802 780.5 466.702 780.5 466.502 780.5C445.202 780.2 428.202 762.7 428.502 741.4L430.102 617.8C430.402 596.7 447.602 579.8 468.602 579.8C468.802 579.8 468.902 579.8 469.102 579.8C490.402 580.1 507.402 597.6 507.102 618.9L505.502 742.5C505.302 763.5 488.102 780.5 467.002 780.5Z"
        fill="currentColor"
      />
      <path
        d="M873.502 638.5C862.502 638.5 851.702 633.9 844.002 624.9L784.202 554.2C770.402 537.9 772.502 513.6 788.702 499.9C805.002 486.1 829.302 488.2 843.002 504.4L902.802 575.1C916.602 591.4 914.502 615.7 898.302 629.4C891.102 635.5 882.302 638.5 873.502 638.5Z"
        fill="currentColor"
      />
      <path
        d="M700.302 742.8C687.002 742.8 674.002 735.9 666.902 723.5L609.802 624.7C599.102 606.3 605.502 582.7 623.902 572C642.302 561.3 665.902 567.7 676.602 586.1L733.702 684.9C744.402 703.3 738.002 726.9 719.602 737.6C713.502 741.1 706.802 742.8 700.302 742.8Z"
        fill="currentColor"
      />
    </svg>
  );
};
