import { Text } from 'design';
import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledLeftSideDiv = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 1rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
    height: auto;
  }
`;

const StyledTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: center;
    margin-top: 0;
  }
`;

const StyledTitleTextH2 = styled(Text.h2)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.2rem;

  text-align: start;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 0.9rem;
    text-align: center;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 2rem;
  }
`;
const StyledSpaceDiv = styled.div`
  margin-top: 1rem;
`;

const StyledLineHr = styled.hr`
  color: ${({ theme }) => theme.palette.primary.main};
  border-top: 2.5px solid ${({ theme }) => theme.palette.primary.main};
  width: 90%;
  margin: 1rem 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 90%;
  }
`;

const StyledTextH1 = styled(Text.h1)`
  font-size: 1.7rem;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 900;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 1.4rem;
    align-self: center;
  }
`;

export const BussinessPackagesLeftContent: FC<FcDefaultProps> = () => {
  return (
    <StyledLeftSideDiv>
      <StyledTitleDiv>
        <StyledTextH1>
          {intl.translate({
            id: '¡También tenemos soluciones a medida si eres una empresa!',
          })}
        </StyledTextH1>
        <StyledTitleTextH2>
          {intl.translate({
            id: 'Desde beneficios con cobertura de horas para cuando tus trabajadores tienen emergencias, hasta organizacion en eventos de zonas infantiles donde el personal pueda dejar a sus hijos, en Okids nos adaptamos a las necesidades de cada empresa.',
          })}
        </StyledTitleTextH2>
        <StyledLineHr />
        <Text.p>
          {intl.translate({
            id: 'Somos especialistas en el cuidado y bienestar infantil, por lo que puedes contactarnos y nos encargaremos meticulosamente del armado y  ejecución de espacios destinado a que los niños se diviertan mientras los adultos festejan o acuden a eventos. Podemos atender salones de fiestas, hoteles, salas de espera, centros comerciales o adaptarnos a lo que la empresa necesite. Contáctenos por mayor información.',
          })}
        </Text.p>
        <StyledSpaceDiv />
      </StyledTitleDiv>
    </StyledLeftSideDiv>
  );
};
