import { styled as muiStyled, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { palette } from 'design/theme/palette';
import { InputProps } from 'design/types/input-props';
import { noop } from 'lodash';
import { useCallback } from 'react';

const StyledMobileDatePicker = muiStyled(MobileDatePicker)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '3px',
      borderColor: palette.primary.main,
      borderStyle: 'solid',
    },
    '&:hover fieldset': {
      borderWidth: '3px',
      borderColor: palette.primary.main,
      borderStyle: 'solid',
    },
  },
});

export const DatePicker = (
  props: InputProps<Date | null>,
): JSX.Element | null => {
  const {
    name,
    id,
    onChange = noop,
    label,
    value,
    disabled,
    required,
    helperText,
    maxDate,
    error,
    fullWidth = false,
    size,
    minDate,
    views,
    readOnly = false,
    showToolbar,
  } = props;

  const handleChange = useCallback(
    (value: string | null) => {
      onChange({
        target: {
          name,
          value: value === null ? null : new Date(value),
        },
      });
    },
    [name, onChange],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledMobileDatePicker
        label={label}
        showToolbar={showToolbar}
        value={value}
        views={views}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleChange as (value: unknown) => void}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            fullWidth={fullWidth}
            disabled={disabled}
            required={required}
            helperText={error ? error.toString() : helperText}
            error={!!error}
            size={size}
          />
        )}
      />
    </LocalizationProvider>
  );
};
