import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Icon } from 'design';
import { IconProps } from 'design/types/icon-props';
import { FC } from 'react';
import styled from 'styled-components';

const StyledWarningErrorIcon = styled(ErrorOutlineOutlinedIcon)`
  color: ${({ theme }) => theme.palette.primary.main} !important;
  margin-right: 1rem !important;
`;
export const WarningErrorIcon: FC<IconProps> = (props) => {
  return <Icon material={<StyledWarningErrorIcon />} {...props} />;
};
