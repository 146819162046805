export enum AuthType {
  EMAIL = 'email',
  APPLE = 'apple',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
}

export enum BabysitterStatus {
  ACTIVE = 'active',
  DISABLE = 'disable',
}

export enum FutureServicesStatus {
  ACCEPTED_BY_BABYSITTER = 'accepted',
  DECLINED_BY_BABYSITTER = 'declined by babysitter, waiting to be accepted by recoordination',
  DECLINED_BY_BOTH_BABYSITTERS = 'declined',
  PENDING = 'pending',
  PAYMENT_SUCCESS = 'acceptedPayment',
  PAYMENT_DECLINED = 'declinedPayment',
  DECLINED_BY_PARENT = 'declinedByParent',
}
