import { Document, Page, PDFViewer, Text } from '@react-pdf/renderer';

export const TermsAndConditions = () => {
  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page size="A4">
          <Text>
            acá va a ir la información TERMINOS Y CONDICIONES que deseen por
            parte de SOMOS
          </Text>
        </Page>
      </Document>
    </PDFViewer>
  );
};
