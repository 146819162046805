import { CheckAvailabilityForm } from 'components/CheckAvailabilityForm/CheckAvailabilityForm';
import { AppRoute } from 'enums/app-route.enum';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppBar } from '../AppBar';
import { CommentsCarouselView } from '../CommentsCarouselView';
import { Footer } from '../Footer';
import { Banner } from './Banner';
import { GuaranteesView } from './GuaranteesView';
import { PartnersCompaniesView } from './PartnersCompanies';

const StyledMain = styled.main`
  height: calc(100vh);
`;

export const Home: FC = () => {
  const navigation = useNavigate()



  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);


  return (
    <>
      <StyledMain>
        <AppBar />
        <Banner />
        <CheckAvailabilityForm onClick={() => navigation(AppRoute.CONTACT)} />
        <CommentsCarouselView />
        <PartnersCompaniesView />
        <GuaranteesView />
        {/* <ForwardedComponent ref={sectionRef} /> */}
        <Footer />
      </StyledMain>
    </>
  );
};
