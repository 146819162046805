import { useIconColor } from 'design/hooks/use-icon-color.hook';
import { IconProps } from 'design/types/icon-props';
import { FC } from 'react';

export const TwitterIcon: FC<IconProps & { width?: number | string }> = ({
  color,
  width = '100%',
  className,
  invert = false,
}) => {
  const _color = useIconColor(color, invert);

  return (
    <svg
      width={width}
      className={className}
      color={_color}
      viewBox="0 0 994 995"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M497.2 994.3C223.6 994.3 0.899902 771.3 0.899902 497.1C0.899902 223.3 223.5 0.600098 497.2 0.600098C770.9 0.600098 993.5 223.3 993.5 497.1C993.5 771.2 770.8 994.3 497.2 994.3ZM497.2 49.8001C250.7 49.8001 50.1999 250.5 50.1999 497.1C50.1999 744.1 250.7 945.1 497.2 945.1C743.7 945.1 944.2 744.2 944.2 497.1C944.2 250.5 743.7 49.8001 497.2 49.8001Z"
        fill="currentColor"
      />
      <path
        d="M245.5 655.3C291.2 684.8 345.3 701.7 403.7 701.7C595.6 701.7 703.8 539.9 696.8 395.1C717.2 380.3 734.8 362.1 748.8 341.7C729.8 349.4 710.1 355.1 689 357.9C710.8 345.2 727 324.9 734.7 300.2C715 312.1 692.5 321.3 669.3 325.5C650.3 305.8 623.6 293.1 594.1 293.1C527.3 293.1 478.1 355 493.6 419.7C407.8 415.5 331.9 374 280.6 312.1C254 358.5 266.6 419 313 449.9C295.4 449.2 279.9 444.3 265.9 436.6C264.5 484.4 299 529.4 348.9 539.3C334.1 543.5 318 544.2 302.5 540.7C315.1 582.2 353.1 611.7 398.8 612.4C355.2 646.8 300.4 661.6 245.5 655.3Z"
        fill="currentColor"
      />
    </svg>
  );
};
