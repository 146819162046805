import { getPreviousServicesOfParent } from 'api/babysitters/babysitters.api';
import { Text } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { getRate } from 'helpers/children.helpers';
import { calcHours, getDayMonthyear } from 'helpers/date.helpers';
import { StarsRating } from 'rating/StarsRating';
import { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { servicesType, ServiceUserBabysitterProps } from 'types/service-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledDataTextLabel = styled(Text.label)`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
`;

const StyledDataTextTableLabel = styled(Text.label)`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
`;

const StyledDataTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 1rem;
  ::first-letter {
    text-transform: capitalize;
  }
`;

const StyledServicesContentDiv = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: calc(100vw - 4rem);
  }
`;

const StyledDiv = styled.div`
  padding: 1rem;
  border: 2px solid ${({ theme }) => theme.palette.secondary.main};
  border-radius: 6px;
  margin-top: 1rem;
`;

const StyledTableDiv = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`;

const StyledDateDataDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
`;

const StyledLabelsDiv = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledNoServicesDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;


const StyledNavLink = styled(NavLink)`
color: red;
text-transform: uppercase;
text-decoration: none;
`

interface Props {
  userInfo?: string;
}

export const UserPreviousServices: FC<Props> = ({ userInfo }) => {
  const [servicesFullData, setServicesFullData] = useState<servicesType>({});
  useEffect(() => {
    const getServices = async () => {
      if (userInfo) {
        const response = await getPreviousServicesOfParent(userInfo);
        if (response) {
          setServicesFullData(response);
        }
      }
    };
    getServices();
  }, [userInfo]);

  const getBabysitterFullName = (name: string, lastname: string) => {
    if (name || lastname) {
      return `${name} ${lastname}`;
    }
    return ' ';
  };

  const babysitterIds: string[] = Object.keys(servicesFullData);




  return (
    <StyledServicesContentDiv>
      {babysitterIds.length !== 0 ? (
        babysitterIds.map((babysitter: any) => {
          const babysitterData =
            servicesFullData?.[babysitter]?.services?.[0]?.UserBabysitter?.user;
          return (
            <StyledDiv key={`previous-${babysitter}`}>
              <StyledDataTextLabel>
                {intl.translate({ id: 'Niñera:' })}
              </StyledDataTextLabel>
              <StyledDataTextP>
                {getBabysitterFullName(
                  babysitterData?.name || "",
                  babysitterData?.lastname || "",
                )}
              </StyledDataTextP>
              <>
                <StyledTableDiv>
                  <StyledLabelsDiv>
                    <StyledDataTextTableLabel>
                      {intl.translate({ id: 'Dia' })}
                    </StyledDataTextTableLabel>
                    <StyledDataTextTableLabel>
                      {intl.translate({ id: 'Horas' })}
                    </StyledDataTextTableLabel>
                    <StyledDataTextTableLabel>
                      {intl.translate({ id: 'Calificacion' })}
                    </StyledDataTextTableLabel>
                  </StyledLabelsDiv>
                  {servicesFullData[babysitter].services.map(
                    (service: ServiceUserBabysitterProps) => {
                      const serviceHours = calcHours(
                        service.startTime,
                        service.endTime,
                      );
                      const getDateStart = getDayMonthyear(service.startTime);
                      const getDateEnd = getDayMonthyear(service.endTime);
                      return (
                        <StyledLabelsDiv key={service.id}>
                          <StyledDateDataDiv>
                            <StyledDataTextP>{getDateStart} </StyledDataTextP>
                            <StyledDataTextP> - {getDateEnd} </StyledDataTextP>
                          </StyledDateDataDiv>
                          <StyledDateDataDiv>
                            <StyledDataTextP>{serviceHours}hs</StyledDataTextP>
                          </StyledDateDataDiv>
                          <StyledDateDataDiv>
                            <StyledDataTextP>
                              {service?.review ? <StarsRating readonly allowFraction initialValue={getRate(service.review.rate)} /> : <StyledNavLink to={`${AppRoute.REVIEW_BABYSITTER}?serviceId=${service.id}`}>{intl.translate({ id: 'Pending' })}</StyledNavLink>}
                            </StyledDataTextP>
                          </StyledDateDataDiv>
                        </StyledLabelsDiv>
                      );
                    },
                  )}
                </StyledTableDiv>
              </>
            </StyledDiv>
          );
        })
      ) : (
        <StyledNoServicesDiv>
          <Text.p>
            {intl.translate({
              id: 'Actualmente no se registran servicios anteriores',
            })}
          </Text.p>
        </StyledNoServicesDiv>
      )}
    </StyledServicesContentDiv>
  );
};
