import { updateAvailability } from 'api/babysitters/babysitters.api';
import { getUserDataOrFail } from 'api/users/users.api';
import { Text } from 'design';
import { SwitchButton } from 'design/Button/SwitchButton';
import { selectUser } from 'global-state/selectors';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const StyledRightTopDiv = styled.div`
  display: flex;
  align-content: flex-end;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledActiveTextP = styled(Text.p)`
  align-self: center;
  text-transform: uppercase;
  margin: 0 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledNoActiveTextP = styled(Text.p)`
  text-transform: uppercase;
  margin: 0 1rem;
  align-self: center;
`;

export const AvailabilitySwitcher: FC = () => {
  const userFullData = useSelector(selectUser);
  const [checked, setChecked] = useState<boolean>();

  useEffect(() => {
    const getUserData = async () => {
      const userData = await getUserDataOrFail(userFullData!.id);
      if (userData && userData.babysitter.isActive) {
        setChecked(userData.babysitter.isActive);
      }
    };
    getUserData();
  }, []);

  const handleChange = async () => {
    const updatedBabysitter = await updateAvailability(
      userFullData!.babysitter!.id,
      !checked,
    );
    setChecked(updatedBabysitter.isActive);
  };

  return (
    <StyledRightTopDiv>
      {checked ? (
        <StyledActiveTextP>
          {intl.translate({
            id: 'Disponible',
          })}
        </StyledActiveTextP>
      ) : (
        <StyledNoActiveTextP>
          {intl.translate({
            id: 'No disponible',
          })}
        </StyledNoActiveTextP>
      )}
      <SwitchButton
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </StyledRightTopDiv>
  );
};
