import { CancelServiceModal } from 'components/Modal/CancelServiceModal';
import { Button, Text } from 'design';
import { AcceptIcon } from 'design/Icon/AcceptIcon';
import { CrossIcon } from 'design/Icon/CrossIcon';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const StyledButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
`;

const StyledDeclineButton = styled(Button)`
  color: red !important;
  border: 1px solid ${({ theme }) => theme.palette.secondary.main} !important;
`;

const StyledSpan = styled.span`
  font-size: 0.8rem;
  padding-top: 1rem;
  font-size: 0.7rem;
  padding-right: 0.5rem;
`;

const StyledCrossIcon = styled(CrossIcon)`
  color: red;
`;

const StyledImportantSpan = styled.span`
  text-transform: uppercase;
  color: red;
  font-size: 0.7rem;
  display: inline;
`;

const StyledTextP = styled(Text.p)`
  font-size: 0.7rem;
  padding-left: 0.3rem;
  display: inline;
`;

const StyledConfirmedButton = styled(Button)`
  background-color: green !important;
  color: white !important;
`;

const StyledConfirmedDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  loading: boolean;
}

export const BabysitterAcceptedServices: FC<Props> = ({ onClick, loading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  return (
    <>
      <StyledButtonsDiv>
        <StyledConfirmedButton fullWidth>
          {intl.translate({ id: 'Confirmado' })} <AcceptIcon />
        </StyledConfirmedButton>
      </StyledButtonsDiv>

      <StyledConfirmedDiv>
        <StyledSpan>
          <StyledImportantSpan>
            {intl.translate({ id: '*Atención:' })}
          </StyledImportantSpan>
          <StyledTextP>
            {intl.translate({
              id: 'Cancelar un servicio ya confirmado tiene consecuencias severas',
            })}
          </StyledTextP>
        </StyledSpan>
        <div>
          <StyledDeclineButton
            variant="outlined"
            onClick={handleOpen}
            fullWidth
            disabled={loading}
          >
            {intl.translate({ id: 'Cancelar' })}
            <StyledCrossIcon />
          </StyledDeclineButton>
        </div>
      </StyledConfirmedDiv>
      <CancelServiceModal
        loading={loading}
        onClick={onClick}
        onClose={() => setIsOpen(false)}
        open={isOpen}
      />
    </>
  );
};
