import { CircularProgress } from '@mui/material';
import { getBabysitterNearBy } from 'api/babysitters/babysitters.api';
import { BackgroundGeneral } from 'components/background/BackgroundGeneral';
import { MobileBackgroundSelectBabysitter } from 'components/background/MobileBackgroundSelectBabysitter';
import { OkidsLogo } from 'components/icons/OkidsLogo';
import { Button, Text } from 'design';
import { ProfileAvatar } from 'design/Avatar/ProfileAvatar';
import { AppRoute } from 'enums/app-route.enum';
import {
  setBabysitterFullDataAction,
  setServiceDataAction,
} from 'global-state/actions';
import { calculateAge } from 'helpers/date.helpers';
import { Align } from 'layout';
import { StarsRating } from 'rating/StarsRating';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { BabysitterDataType } from 'types/babysitter-props';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const StyledDiv = styled.div`
  padding: 3rem 1rem;
  min-height: fit-content;
  height: calc(100vh - 7rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: row;
    align-items: center;
  }
`;
const StyledTitleDiv = styled.div`
  min-height: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledLeftSectionDiv = styled.div`
  min-height: 0;
  flex-direction: column;
  display: flex;
  width: 100%;
  padding: 1rem 1.5rem;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  height: 1.9rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 2.3rem;
    width: 8rem;
  }
`;

const displayButton = () => {
  return (
    <StyledButton variant="filled" color="primary">
      <StyledSpan>{intl.translate({ id: 'FILTROS' })}</StyledSpan>
    </StyledButton>
  );
};

const StyledSpan = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 1.5rem;
  }
`;

const StyledPReviews = styled(Text.p)`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
`;

const StyledH3 = styled(Text.h3)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.8rem;
  max-width: 10rem;
  line-height: 1.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 2.8rem;
    max-width: 16rem;
    line-height: 2.5rem;
    font-weight: 900;
    padding-bottom: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 3.5rem;
    max-width: 20rem;
    line-height: 3rem;
    font-weight: 900;
  }
`;

const StyledP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  line-height: 1.1rem;
  font-weight: 400;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 25rem;
    line-height: 1.3rem;
    padding: 1rem 0;
    font-size: 1.2rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 1.4rem;
    max-width: 25rem;
    line-height: 1.4rem;
  }
`;

const StyledDesktopHr = styled.hr`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: block;
    width: 100%;
    margin: 0.5rem 0;
    border-top: 6px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledShowButtonMobileDiv = styled.div`
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StyledMobileHr = styled.hr`
  width: 100%;
  margin: 0.5rem 0;
  border-top: 3px solid ${({ theme }) => theme.palette.primary.main};
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StyledShowButtonDesktopDiv = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: block;
    margin-bottom: 1rem;
  }
`;

const StyledThreeBluePointsDiv = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: flex;
    justify-content: flex-start;
  }
`;

const StyledUserDataP = styled(Text.p)`
  line-height: 1.1rem;
  font-weight: 700;
  font-size: 0.9rem;
`;

const StyledUserAddressP = styled(Text.p)`
  line-height: 1.1rem;
  font-weight: 700;
  font-size: 0.9rem;
  text-transform: uppercase;
`;

const StyledUserNameP = styled(Text.p)`
  line-height: 1.1rem;
  font-weight: 700;
  font-size: 1.1rem;
  ::first-letter {
    text-transform: uppercase;
  }
`;
const StyledUserDataAlign = styled(Align)`
  margin-left: 1.5rem;
`;

const StyledCardAlign = styled(Align)`
  padding: 0.5rem 0;
  width: 100%;
  height: 100%;
`;

const StyledRatingAlign = styled(Align)`
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
`;

const StyledProfileDiv = styled.div`
  overflow: auto;
  justify-content: center;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  width: 100%;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 1rem 2.5rem;
    width: 100%;
  }
  @media (max-height: 700px) {
    padding: 1rem 2.5rem;
    width: 100%;
  }
`;

const StyledContainerDiv = styled.div`
  max-height: 100%;
  overflow: auto;
  justify-content: center;
  display: flex;
`;

const StyledOkidsLogo = styled(OkidsLogo)`
  color: white !important;
  font-size: 1rem !important;
  width: 7rem !important;
`;

const StyledBabysitterProfileImageDiv = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  height: 5.5rem;
  width: 5.5rem;
  border-radius: 50%;
  padding: 0.5rem;
`;

const StyledCardNavLink = styled(NavLink)`
  text-decoration: none;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
`;

const StyledLoaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

interface Props {
  name: string;
  location: string;
  alt: string;
  src: string;
  age: number;
  experience: string;
  rating: number;
  readonly: boolean;
}

export const SelectBabysitter: FC<Props> = ({ readonly }) => {
  const [babysittersArray, setBabysittersArray] = useState<
    BabysitterDataType[]
  >([]);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const lat = searchParams.get('lat');
  const lng = searchParams.get('lng');
  const dateRangesParam = searchParams.get('dateRanges');
  const address = searchParams.get('address');
  const numberOfChildren = searchParams.get('numberOfChildren');
  const [selectedBabysitters, setSelectedBabysitters] = useState<
    Map<number, number>
  >(new Map());
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const babysittersToRender = async () => {
      if (lat && lng && dateRangesParam && address) {
        const latCoord = Number(lat);
        const lngCoord = Number(lng);
        const dateRanges = JSON.parse(decodeURIComponent(dateRangesParam)) as {
          start: string;
          end: string;
        }[];

        const children = Number(numberOfChildren);

        const dateRangesWithDates = dateRanges.map((range) => ({
          start: new Date(range.start),
          end: new Date(range.end),
        }));
        const serviceData = {
          locationLat: latCoord,
          locationLng: lngCoord,
          dateRanges: dateRangesWithDates,
          address,
          numberOfChildren: children,
        };

        setIsLoading(true);

        try {
          const babysitters = await Promise.all(
            dateRangesWithDates.map(async (range) => {
              return getBabysitterNearBy(
                latCoord,
                lngCoord,
                range.start,
                range.end,
                address,
              );
            }),
          );
          const flattenedBabysitters = babysitters.flat();
          if (flattenedBabysitters.length) {
            setBabysittersArray(flattenedBabysitters);
            dispatch(setBabysitterFullDataAction(flattenedBabysitters));
            dispatch(setServiceDataAction(serviceData));
          }
        } catch (error: any) {
          toast.warning(
            intl.translate({
              id: 'Algo falló con la dirección o la fechas ingresadas, intente de nuevo.',
            }),
          );
          logger.error(error.message);
        } finally {
          setIsLoading(false);
        }
      }
    };

    babysittersToRender();
  }, [lat, lng, dateRangesParam, address, numberOfChildren, dispatch]);

  const dateRanges = JSON.parse(
    decodeURIComponent(dateRangesParam || '[]'),
  ) as { start: string; end: string }[];
  const groupedBabysitters = dateRanges.map((range, index) => ({
    dateRange: range,
    babysitters: babysittersArray.slice(index * 3, index * 3 + 3), // Assuming 3 babysitters per date range
  }));

  const allDateRangesSelected = groupedBabysitters.every((_, index) =>
    selectedBabysitters.has(index),
  );

  const handleBabysitterClick = (
    groupIndex: number,
    babysitterIndex: number,
  ) => {
    setSelectedBabysitters((prev) => {
      const newSelected = new Map(prev);
      newSelected.set(groupIndex, babysitterIndex);
      return newSelected;
    });
  };

  const handleProceed = () => {
    if (allDateRangesSelected) {
      const selectedBabysittersQuery = Array.from(selectedBabysitters.entries())
        .map(([groupIndex, babysitterIndex]) => `${groupIndex}=${babysitterIndex}`)
        .join('&');

      navigate(`${AppRoute.BABYSITTER_DESCRIPTION}?${selectedBabysittersQuery}`);
    }
  };

  return (
    <BackgroundGeneral>
      <MobileBackgroundSelectBabysitter>
        <StyledDiv>
          <StyledLeftSectionDiv>
            <StyledTitleDiv>
              <StyledH3>
                <strong>
                  {intl.translate({ id: 'Seleccionar su niñera' })}
                </strong>
              </StyledH3>
              <StyledDesktopHr />
              <StyledShowButtonMobileDiv>
                {displayButton()}
              </StyledShowButtonMobileDiv>
            </StyledTitleDiv>
            <StyledP>
              <strong>
                {intl.translate({
                  id: 'Puedes elegir la mejor niñera para el cuidado de tus hijos.',
                })}
              </strong>
              <br />
              {intl.translate({
                id: 'Por cuidado de privacidad, solo podrás visualizar el perfil completo luego de la contratación.',
              })}
            </StyledP>
            <StyledMobileHr />
          </StyledLeftSectionDiv>

          <StyledContainerDiv>
            <StyledProfileDiv>
              {isLoading && !babysittersArray.length ? (
                <StyledLoaderDiv>
                  <CircularProgress size={70} thickness={5.5} />
                </StyledLoaderDiv>
              ) : groupedBabysitters.length > 0 ? (
                groupedBabysitters.map((group, groupIndex) => (
                  <div key={groupIndex}>
                    <Text.h4>
                      {intl.translate(
                        {
                          id: `Disponibles desde ${new Date(
                            group.dateRange.start,
                          ).toLocaleDateString()} hasta ${new Date(
                            group.dateRange.end,
                          ).toLocaleDateString()}`,
                        },
                        {
                          start: new Date(
                            group.dateRange.start,
                          ).toLocaleDateString(),
                          end: new Date(
                            group.dateRange.end,
                          ).toLocaleDateString(),
                        },
                      )}
                    </Text.h4>
                    {group.babysitters.length > 0 ? (
                      <div>
                        {group.babysitters.map((babysitter, index) => {
                          const isSelected =
                            selectedBabysitters.get(groupIndex) === index;
                          return (
                            <div

                              key={index}
                              onClick={() =>
                                handleBabysitterClick(groupIndex, index)
                              }
                              style={{
                                boxShadow: isSelected
                                  ? 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                                  : 'none',
                                cursor: 'pointer',
                                padding: '1rem',
                                borderRadius: '10px',
                              }}
                            >
                              <StyledCardAlign>
                                {babysitter.user?.picture ? (
                                  <ProfileAvatar
                                    large
                                    alt={babysitter.user?.name}
                                    src={babysitter.user?.picture}
                                  />
                                ) : (
                                  <StyledBabysitterProfileImageDiv>
                                    <StyledOkidsLogo small />
                                  </StyledBabysitterProfileImageDiv>
                                )}

                                <StyledUserDataAlign column>
                                  <StyledUserNameP color="dark">
                                    {babysitter.user?.name}
                                  </StyledUserNameP>
                                  <StyledUserDataP>
                                    {intl.translate(
                                      { id: 'edad/ {age} años' },
                                      {
                                        age: calculateAge(
                                          babysitter.dateOfBirth.toString(),
                                        ),
                                      },
                                    )}
                                  </StyledUserDataP>
                                  <StyledUserDataP>
                                    {intl.translate({ id: 'Formación:' })}{' '}
                                    {babysitter.experience}
                                  </StyledUserDataP>
                                  <StyledRatingAlign h-center>
                                    <StarsRating
                                      initialValue={Number(
                                        babysitter.calculatedRate,
                                      )}
                                      allowFraction
                                      readonly={readonly}
                                    />
                                    <StyledPReviews>
                                      (
                                      <small>
                                        {Number(babysitter.numberOfReviews)}
                                      </small>
                                      )
                                    </StyledPReviews>
                                  </StyledRatingAlign>
                                </StyledUserDataAlign>
                              </StyledCardAlign>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <StyledUserNameP>
                        {intl.translate({
                          id: 'Lo sentimos, en este momento no tenemos niñeras disponibles para la fecha seleccionada en ese domicilio, puede intentarlo nuevamente cambiando la dirección del servicio o los horarios del mismo',
                        })}
                      </StyledUserNameP>
                    )}
                  </div>
                ))
              ) : (
                <StyledUserNameP>
                  {intl.translate({
                    id: 'Lo sentimos, en este momento no tenemos niñeras disponibles para la fecha seleccionada en ese domicilio, puede intentarlo nuevamente cambiando la dirección del servicio o los horarios del mismo',
                  })}
                </StyledUserNameP>
              )}
              <StyledButton
                variant="filled"
                fullWidth
                disabled={groupedBabysitters.some(
                  (_, index) => !selectedBabysitters.has(index),
                )}
                onClick={handleProceed}
              >
                {intl.translate({ id: 'Continuar' })}
              </StyledButton>
            </StyledProfileDiv>
          </StyledContainerDiv>

        </StyledDiv>
      </MobileBackgroundSelectBabysitter>
    </BackgroundGeneral>
  );

};
