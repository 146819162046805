import { styled as muiStyled, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { palette } from 'design/theme/palette';
import { InputProps } from 'design/types/input-props';
import { noop } from 'helpers/nodash.helpers';
import { useCallback, useState } from 'react';

const StyledMobileTimePicker = muiStyled(MobileTimePicker)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '3px',
      borderColor: palette.primary.main,
      borderStyle: 'solid',
      height: '3.5rem',
      width: '6rem',
    },
    '&:hover fieldset': {
      borderWidth: '3px',
      borderColor: palette.primary.main,

      height: '3.5rem',
      width: '6rem',

      borderStyle: 'solid',
    },
  },
});

const StyledTimePicker = muiStyled(MobileTimePicker)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '3px',
      borderColor: palette.secondary.main,
      borderStyle: 'solid',
      height: '3.5rem',
      width: '6rem',
    },
    '&:hover fieldset': {
      borderWidth: '3px',
      borderColor: palette.secondary.main,

      height: '3.5rem',
      width: '6rem',

      borderStyle: 'solid',
    },
  },
});

const StyledTextField = muiStyled(TextField)({
  width: '6rem',
  height: '3rem',
});

export const TimePicker = (
  props: InputProps<Date | null>,
): JSX.Element | null => {
  const {
    name,
    id,
    onChange = noop,
    label,
    value,
    disabled,
    required,
    helperText,
    error,
    fullWidth = false,
    size,
    views,
    maxTime,
    ampm,
    minTime,
    showToolbar = false,
    disableIgnoringDatePartForTimeValidation = false,
  } = props;

  const [timeValue, setTimeValue] = useState(value);

  const handleChange = useCallback(
    (value: Date | null) => {
      onChange({
        target: {
          name,
          value: value ?? null,
        },
      });
      setTimeValue(value);
    },
    [name, onChange],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {!disabled ? (
        <StyledMobileTimePicker
          disableIgnoringDatePartForTimeValidation={
            disableIgnoringDatePartForTimeValidation
          }
          label={label}
          ampm={ampm}
          value={timeValue}
          disabled={disabled}
          minTime={minTime}
          maxTime={maxTime}
          views={views}
          showToolbar={showToolbar}
          onChange={handleChange as (value: unknown) => void}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              name={name}
              id={id}
              fullWidth={fullWidth}
              disabled={disabled}
              required={required}
              helperText={error ? error.toString() : helperText}
              error={!!error}
              size={size}
            />
          )}
        />
      ) : (
        <StyledTimePicker
          label={label}
          value={timeValue}
          disabled={disabled}
          minTime={minTime}
          maxTime={maxTime}
          views={views}
          onChange={handleChange as (value: unknown) => void}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              id={id}
              fullWidth={fullWidth}
              disabled={disabled}
              required={required}
              helperText={error ? error.toString() : helperText}
              error={!!error}
              size={size}
            />
          )}
        />
      )}
    </LocalizationProvider>
  );
};
