import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Icon } from 'design';
import { IconProps } from 'design/types/icon-props';
import { FC } from 'react';
import styled from 'styled-components';

const StyledMercadoPagoFailure = styled(HighlightOffOutlinedIcon)`
  color: ${({ theme }) => theme.palette.primary.invert} !important;
  font-size: 150px !important;
`;

export const MercadoPagoFailure: FC<IconProps> = (props) => {
  return <Icon material={<StyledMercadoPagoFailure />} {...props} />;
};
