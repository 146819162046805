import styled from 'styled-components';
import { AppBar } from './AppBar';
import TypeformScript from './TypeformScript';


const StyledMain = styled.main`
  height: calc(100vh);
  padding-top: 7rem;
`;

const IframeContainer = styled.div`
    > div:first-child {
    height: 100vh !important;
  }
`;

const ContactPage = () => {
  return (
    <StyledMain>
      <AppBar />
      <IframeContainer data-tf-live="01J687C40F9XREJEYAGBA88A8T"></IframeContainer>
      <TypeformScript></TypeformScript>
    </StyledMain>
  );
};
export default ContactPage;
