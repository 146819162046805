import { Collapse, IconButton, styled as styledMui, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getPayments, updatePaymentStatus } from 'api/payments/payments.api';
import { KeyboardArrowDownOkidsIcon } from 'components/views/icons/KeyboardArrowDownIcon';
import { KeyboardArrowUpOkidsIcon } from 'components/views/icons/KeyboardArrowUpOkidsIcon';
import { Button, Text } from 'design';
import { PaymentStatus } from 'enums/payment.types.enum';
import { parseDateDdMmYyyy } from 'helpers/parseDate';
import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';


interface PaymentRow {
    id: string;
    babysitter: string;
    paymentStatus: string;
    paymentTotalDayHours: string;
    paymentTotalNightHours: string;
    subRows: PaymentRow[];
}

type BabysitterPayment = {
    [key: string]: {
        babysitterId: string;
        paymentStatus: string;
        totalDayHours: number;
        totalNightHours: number;
        paymentData: {
            serviceId: string;
            babysitterName: string;
            babysitterLastName: string;
            serviceStartTime: string;
            serviceEndTime: string;
            totalDayHours: number;
            totalNightHours: number;
            paymentStatus: string;
        }[]

    }
}

export type PaymentDataProps = {
    babysitterId: number;
    paymentStatus: string;
    paymentData: any[];

};


const StyledSubRowDiv = styled.div`
    background-color: #d7b0d2;
`
const emptyField = '--';

const notAvailable = intl.translate({ id: 'No disponible' });

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledSecondDiv = styled.div`
   width: 100%;
  height: 100vh;
  display: flex;
`;

const StyledTableContainer = styled(TableContainer)`
  height: 70vh;
  padding: 5rem;
`;

const StyledApproveButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  color: white !important;
  font-weight: 600 !important;
`;

const StyledDisallowButton = styled(Button)`
  color:  #c83d3d !important;
`;

const StyledTitleTableCell = styled(TableCell)`
  font-weight: 500 !important;
  font-size: 1rem !important;
 
`;



const StyledSubTitleTableCell = styled(TableCell)`
  font-weight: 600 !important;
  font-size: 1rem !important;
  color: white !important;
`;
const StyledViewContainerDiv = styled.div`
  height: 100vh;
  overflow-y: hidden;
`;

const TextNowrap = styled.span`
  white-space: nowrap;
`;

const StyledTableRow = styledMui(TableRow)`
  background-color: #c3bcbc;
`;

const StyledCompletedStatusTextP = styled(Text.p)`
    color : #5cd85c
`

const StyledPendingStatusTextP = styled(Text.p)`
    color : #c83d3d
`
export const PaymentTable = () => {
    const [reload, setReload] = useState<boolean>(false);

    const [paymentData, setPaymentData] = useState<any>([]);
    const [paymentDataFilter, setPaymentDataFilter] = useState<PaymentRow[]>([]);
    const [paymentStatusMap, setPaymentStatusMap] = useState<{ [key: string]: string }>({});


    const [paymentStatuses, setPaymentStatuses] = useState<string[]>([]);

    useEffect(() => {
        const getPaymentData = async () => {
            const response = await getPayments();
            setPaymentData(response);
            setPaymentDataFilter(response)
        };
        getPaymentData();
    }, [reload]);

    useEffect(() => {
        const initialStatuses = Object.values(paymentData).map(
            (payment: any) => payment.paymentStatus
        );
        setPaymentStatuses(initialStatuses);
    }, [paymentData]);



    const handlePayedButtonClick = async (babysitterId: string, status: PaymentStatus) => {

        await updatePaymentStatus(babysitterId, status);
        setReload(!reload);
        setPaymentStatusMap({
            ...paymentStatusMap,
            [babysitterId]: status,
        });


    };

    function createData(
        payment: any,
        payedButton: ReactElement

    ) {

        const name = payment.paymentData[0].babysitterName || emptyField;
        const paymentStatus = payment.paymentStatus;
        const totalDayHoursToPay = payment.totalDayHours;
        const totalNightHoursToPay = payment.totalNightHours;

        const availability = payment.paymentData.map((data: any) => ({
            paymentData: data,

        }));
        return {
            name, paymentStatus, totalDayHoursToPay, totalNightHoursToPay, availability, payedButton

        }

    }
    function Row(props: { row: ReturnType<typeof createData> }) {
        const { row } = props;

        const [isOpen, setIsOpen] = useState(false);

        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>

                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setIsOpen(!isOpen)}
                            color="primary"
                        >
                            {isOpen ? (
                                <KeyboardArrowUpOkidsIcon />
                            ) : (
                                <KeyboardArrowDownOkidsIcon />
                            )}
                        </IconButton>
                    </TableCell>

                    <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell align="left">{row.payedButton}</TableCell>
                    <TableCell align="left">{row.totalNightHoursToPay}</TableCell>
                    <TableCell align="left">{row.totalDayHoursToPay}</TableCell>


                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <div >

                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <StyledTableRow>

                                            <StyledSubTitleTableCell>
                                                {intl.translate({ id: 'Id servicio' })}
                                            </StyledSubTitleTableCell>
                                            <StyledSubTitleTableCell align="left">
                                                {intl.translate({ id: 'Estado de Pago' })}
                                            </StyledSubTitleTableCell>
                                            <StyledSubTitleTableCell align="left">
                                                {intl.translate({ id: 'Fecha del servicio realizado' })}
                                            </StyledSubTitleTableCell>
                                            <StyledSubTitleTableCell align="left">
                                                {intl.translate({ id: 'Horas nocturnas' })}
                                            </StyledSubTitleTableCell>
                                            <StyledSubTitleTableCell align="left">
                                                {intl.translate({ id: 'Horas diurnas' })}
                                            </StyledSubTitleTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.availability.map((payment: any, index: number) => (
                                            < TableRow sx={{ '& > *': { height: '3.5rem' } }} key={index}>
                                                <>
                                                    <TableCell component="th" scope="row">
                                                        {payment.paymentData.serviceId}
                                                    </TableCell>
                                                    <TableCell key={index}>
                                                        {payment.paymentData.paymentStatus === PaymentStatus.COMPLETED ? <StyledCompletedStatusTextP>{intl.translate({ id: 'Completado' })}_</StyledCompletedStatusTextP> : <StyledPendingStatusTextP>{intl.translate({ id: 'Pendiente' })}_</StyledPendingStatusTextP>}
                                                    </TableCell>
                                                    <TableCell key={index}>
                                                        {parseDateDdMmYyyy(new Date(payment.paymentData.serviceStartTime))} - {parseDateDdMmYyyy(new Date(payment.paymentData.serviceEndTime))}
                                                    </TableCell>
                                                    <TableCell key={index}>
                                                        {payment.paymentData.totalNightHours}
                                                    </TableCell >
                                                    <TableCell key={index}>
                                                        {payment.paymentData.totalDayHours}
                                                    </TableCell>
                                                </>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );

    }

    return (
        <StyledViewContainerDiv>

            <StyledDiv>
                <StyledSecondDiv>
                    <StyledTableContainer>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <StyledTitleTableCell>
                                        {intl.translate({ id: 'Niñera' })}
                                    </StyledTitleTableCell>
                                    <StyledTitleTableCell align="left">
                                        {intl.translate({ id: 'Estado de Pago' })}
                                    </StyledTitleTableCell>
                                    <StyledTitleTableCell align="left">
                                        {intl.translate({ id: 'Total de horas a pagar nocturnas' })}
                                    </StyledTitleTableCell>
                                    <StyledTitleTableCell align="left">
                                        {intl.translate({ id: 'Total de horas a pagar diurnas' })}
                                    </StyledTitleTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(paymentData).map((babysitterId: string, index: number) => {
                                    const paymentObjects = paymentData[babysitterId];
                                    const paymentStatus = paymentObjects.paymentStatus;
                                    const payedButton = paymentStatus === 'pending' ? (
                                        <StyledDisallowButton
                                            onClick={() => handlePayedButtonClick(babysitterId, PaymentStatus.COMPLETED)}
                                        >
                                            {intl.translate({ id: 'Pendiente' })}
                                        </StyledDisallowButton>
                                    ) : (
                                        <Button disabled={true}>
                                            {intl.translate({ id: 'Completado' })}
                                        </Button>
                                    );

                                    return (
                                        <Row
                                            key={index}
                                            row={createData(
                                                paymentObjects, payedButton
                                            )}
                                        />
                                    );
                                })}


                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                </StyledSecondDiv>
            </StyledDiv>
        </StyledViewContainerDiv>
    );

}
