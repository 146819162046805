import uploadFile from 'api/storageFiles/uploadFile.api';
import { checkFileType } from 'helpers/file.helpers';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { intl } from 'utilities/i18n/intl.utility';

const useFileUpload = (onValueChange: any) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');

  const handleUpload = useCallback(
    async (e: any) => {
      const file = e.target.files[0];
      if (checkFileType(e)) {


        try {
          if (!file) {
            return;
          }
          const dataFile = await uploadFile(file, setProgress);

          if (dataFile.fileUrl) {
            onValueChange(dataFile);

            setProgress(0);
            return dataFile;
          }
        } catch (err) {
          setProgress(0);

        }
      } else {
        toast.error(intl.translate({
          id: 'El formato debe de ser PDF',
        }));
      }
    },
    [onValueChange],
  );

  return [handleUpload];
};

export default useFileUpload;
