import { AppRoute } from 'enums/app-route.enum';
import { selectAuthToken } from 'global-state/selectors';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';

export const ProtectedRouteFromUser: FC<FcDefaultProps> = ({ children }) => {
  const location = useLocation();
  const authToken = useSelector(selectAuthToken);

  if (authToken) {
    toast.info(
      intl.translate({
        id: 'Para ir a esta ruta tiene que cerrar sesión',
      }),
    );
    return <Navigate to={AppRoute.HOME} />;
  }

  return <>{children}</>;
};
