import { userParentData } from 'api/babysitters/babysitters.api';
import { BackgroundBabysitterPanel } from 'components/background/BackgroundBabysitterPanel';
import { BackgroundBabysitterPanelMobile } from 'components/background/BackgroundBabysitterPanelMobile';
import { UserPanelTopDiv } from 'components/UserPanel/UserPanelTopDiv';
import { UserPersonalData } from 'components/UserPanel/UserPersonalData';
import { UserPreviousServices } from 'components/UserPanel/UserPreviousServices';
import { UserFutureServices } from 'components/UserPanel/UsersFutureServices';
import { OkidsAccordion } from 'design/Accordion/OkidsAccordion';
import { selectUser } from 'global-state/selectors';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IUserDataResponse } from 'types/babysitter-props';
import { intl } from 'utilities/i18n/intl.utility';
import { AppBar } from './AppBar';
import { userFirstRender } from './BabysitterPanelView';

const StyledHeightSection = styled.section`
  height: 100%;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  margin: 7rem auto 0 auto;
  padding: 0 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin: 7rem 1rem 0 1rem;
    padding: 0;
    justify-content: stretch;
  }
`;

export const UserPanerView: FC = () => {
  const user = useSelector(selectUser);

  const [userFullData, setUserFullData] =
    useState<IUserDataResponse>(userFirstRender);

  useEffect(() => {
    const getUserData = async () => {
      if (!user) {
        throw new Error('user logged not found');
      }
      const response = await userParentData(user?.id);
      if (response) {
        setUserFullData(response);
      }
    };
    getUserData();
  }, [user]);

  const cardsArray = [
    {
      title: `${intl.translate({
        id: 'Datos del cliente',
      })}`,
      content: <UserPersonalData userInfo={userFullData} />,
    },
    {
      title: `${intl.translate({
        id: 'Próximos servicios agendados',
      })}`,
      content: <UserFutureServices userInfo={user?.id} />,
    },
    {
      title: `${intl.translate({
        id: 'Servicios anteriores',
      })}`,

      content: <UserPreviousServices userInfo={user?.id} />,
    },
  ];

  return (
    <>
      <AppBar />
      <BackgroundBabysitterPanel>
        <BackgroundBabysitterPanelMobile>
          <StyledHeightSection>
            <UserPanelTopDiv userInfo={user} />
            {cardsArray.map((card, index) => (
              <OkidsAccordion
                key={index}
                title={card.title}
                content={card.content}
              />
            ))}
          </StyledHeightSection>
        </BackgroundBabysitterPanelMobile>
      </BackgroundBabysitterPanel>
    </>
  );
};
