import Checkbox from '@mui/material/Checkbox';
import { createService } from 'api/auth.api';
import { pay } from 'api/mercadopago.api';
import { getCouponCode, getHourlyPrices } from 'api/services.api';
import { Form } from 'components/Form/Form';
import { addYears } from 'date-fns';
import { Button, Text, TextInput } from 'design';
import { Loading } from 'design/Button/LoadingButton';
import { FutureServicesStatus } from 'enums/auth-type.enum';
import { CouponType } from 'enums/coupon-enum';
import { setBillDataAction } from 'global-state/actions';
import { selectServiceData, selectUser } from 'global-state/selectors';
import { genericErrorHandler } from 'helpers/error.helpers';
import { Align } from 'layout';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { BabysitterDataType } from 'types/babysitter-props';
import { servicesMercadoPagoProps } from 'types/user';
import { intl } from 'utilities/i18n/intl.utility';
import * as Yup from 'yup';
import { onMessageListener } from '../../firebase';

const StyledFormContainer = styled('div')`
  height: 80vh;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledChildDataWebP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.8rem;
  font-weight: 700;
  padding: 1rem 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StyledPriceDataWebP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.8rem;
  font-weight: 700;
  padding: 0.5rem 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;
const StyledFatherDataMobileTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.3rem;
  font-weight: 700;
  padding: 1.5rem 0;
  align-self: center;
  text-transform: uppercase;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StyledPriceDataMobileTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.3rem;
  font-weight: 700;
  padding: 1rem 0;
  align-self: center;
  text-transform: uppercase;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StyledInputAlign = styled(Align)`
  min-height: 90%;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 1rem;
  }
`;

const StyledButton = styled(Button)`
  height: 3rem;
  margin: 2rem 0 !important;
`;

const StyledTextP = styled(Text.p)`
  text-align: initial;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 0.9rem;
  font-weight: 400;
`;

const StyledCheckBox = styled(Checkbox)`
  margin: 0;
  padding: 0;
`;

const StyledCheckBoxDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledPriceTextP = styled(Text.p)`
  font-size: 2.5rem;
`;

const StyledHrefA = styled.a`
  font-size: 0.7rem;
  padding: 0;
  color: ${({ theme }) => theme.palette.primary.main};
  white-space: nowrap;
  text-decoration: none;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fontFamily};
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 0.9rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 1.1rem;
  }
`;

const currentDate = new Date();

const oldestAgeforChild = addYears(currentDate, -18);

const initialValues = {
  dni: '',
  healthInsurance: '',
  secondEmergencyContact: '',
  otherPeopleHouse: '',
  securityCompany: '',
  mobileEmergency: '',
  billAddress: '',
  billCity: '',
  billDni: '',
  couponCode: '',
};

const validateCI = (ci: string) => {
  // Remove any non-digit characters
  ci = ci.replace(/\D/g, '');

  // Ensure length is correct
  if (ci.length !== 8) {
    return false;
  }

  // Calculate verification digit
  const factors = [2, 9, 8, 7, 6, 3, 4];
  let sum = 0;
  for (let i = 0; i < factors.length; i++) {
    sum += factors[i] * parseInt(ci.charAt(i), 10);
  }
  const verificationDigit = (10 - (sum % 10)) % 10;

  // Validate verification digit
  return parseInt(ci.charAt(7), 10) === verificationDigit;
};

const validationSchema = Yup.object({
  dni: Yup.string()
    .required(intl.translate({ id: 'Documento del padre' }))
    .test(
      'ci-validation',
      intl.translate({ id: 'El número de CI es inválido' }),
      function (value) {
        return validateCI(value as string);
      },
    ),

  healthInsurance: Yup.string().required(
    intl.translate({ id: 'health.insurance.input' }),
  ),
  secondEmergencyContact: Yup.string().required(
    intl.translate({ id: 'second.emergency.contact.input' }),
  ),
  otherPeopleHouse: Yup.string().required(
    intl.translate({ id: 'other.people.house.input' }),
  ),

  securityCompany: Yup.string().required(
    intl.translate({ id: 'security.company.input' }),
  ),
  mobileEmergency: Yup.string().required(
    intl.translate({ id: 'Emergencia Movil' }),
  ),
  billDni: Yup.string()
    .required(intl.translate({ id: 'Documento para facturar' }))
    .test(
      'ci-validation',
      intl.translate({ id: 'El número de CI es inválido' }),
      function (value) {
        return validateCI(value as string);
      },
    ),
  billAddress: Yup.string().required(intl.translate({ id: 'Dirección' })),
  billCity: Yup.string().required(intl.translate({ id: 'Ciudad' })),
});

type FormValues = {
  dni: string;
  healthInsurance: string;
  secondEmergencyContact: string;
  otherPeopleHouse: string;
  securityCompany: string;
  mobileEmergency: string;
  billAddress: string;
  billCity: string;
  billDni: string;
  couponCode: string;
};

interface BabysittersProps {
  selectedBabysitter?: BabysitterDataType;
}

export const AdditionalDataForm: FC<BabysittersProps> = ({
  selectedBabysitter,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const serviceData = useSelector(selectServiceData);
  const [serviceCreatedData, setServiceCreatedData] =
    useState<servicesMercadoPagoProps>();
  const [isChecked, setIsChecked] = useState(false);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [updatedPrice, setUpdatedPrice] = useState<number | null>(null);
  const [couponCode1, setCouponCode1] = useState<string>('');
  const [debouncedCouponCode, setDebouncedCouponCode] = useState<string>('');

  const handleSubmit = async (values: FormValues) => {
    if (loading) {
      return;
    }
    let couponCodeId: string | undefined = undefined;
    setLoading(true);
    if (values.couponCode) {
      try {
        const couponCode = await getCouponCode(values.couponCode);
        couponCodeId = couponCode.id;
      } catch (error) {
        genericErrorHandler(error);
        setLoading(false);
        return;
      }
    }
    try {
      const status = FutureServicesStatus.PENDING;
      try {
        setLoading(true);
        const billValues = {
          billAddress: values.billAddress,
          billCity: values.billCity,
          billDni: values.billDni,
        };
        dispatch(setBillDataAction(billValues));
        if (user?.id && selectedBabysitter?.id && serviceData) {
          const response = await createService(
            user?.id,
            selectedBabysitter?.id,
            serviceData,
            status,
            values,
            couponCodeId,
          );
          if (response) {
            setServiceCreatedData(response);
          }
        }
      } catch (error) {
        throw new Error(
          intl.translate({
            id: 'Error al crear servicio',
          }),
        );
      }
      onMessageListener()
        .then((payload) => {
          toast.success(payload.notification?.body);
        })
        .catch((err) => console.log('failed: ', err));
    } catch (error) {
      genericErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const DEBOUNCE_DELAY = 500;

  useEffect(() => {
    const updatePriceWithCoupon = async () => {
      try {
        if (debouncedCouponCode && serviceData && serviceData.dateRanges) {
          setLoading(true);
          let updatedPrice = serviceData.price;
          const discount = await getCouponCode(debouncedCouponCode);
          const hoursService = await getHourlyPrices(
            new Date(serviceData?.dateRanges?.[0]?.start ?? new Date()),
            new Date(serviceData?.dateRanges?.[0]?.end ?? new Date())
          );

          if (discount.max_uses > 0) {
            if (discount.type === CouponType.PERCENTAGE) {
              const discountPercentage = discount.value / 100;
              const discountedAmount = serviceData.price * discountPercentage;
              updatedPrice -= discountedAmount;
            } else if (discount.type === CouponType.FIXED_VALUE) {
              updatedPrice -= discount.value;
            } else if (discount.type === CouponType.COMBINED) {
              const fixedDiscount = discount.fixed_value || 0;
              updatedPrice -= fixedDiscount;
              const percentageDiscount = discount.percentage_value || 0;
              const percentageDiscountAmount =
                updatedPrice * (percentageDiscount / 100);
              updatedPrice -= percentageDiscountAmount;
            } else if (discount.type === CouponType.HOURS_PLUS_PERCENTAGE) {
              const hoursDiscount = discount.hours_discount || 0;
              const percentageDiscount = discount.percentage_value || 0;

              const hoursToDiscount = Math.min(
                hoursDiscount,
                hoursService.length,
              );

              let totalDiscount = 0;
              for (let i = 0; i < hoursToDiscount; i++) {
                totalDiscount += hoursService[i];
              }

              let discountedPrice = Math.max(0, updatedPrice - totalDiscount);
              discountedPrice =
                discountedPrice - discountedPrice * (percentageDiscount / 100);
              updatedPrice = Math.max(0, discountedPrice);
            }

            if (updatedPrice < 0) {
              updatedPrice = 0;
            }

            setUpdatedPrice(updatedPrice);
          } else {
            toast.warn(
              intl.translate({ id: 'Cupón no tiene más usos habilitados' }),
            );
          }
        } else {
          setUpdatedPrice(null);
        }
      } catch (error) {
        genericErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    updatePriceWithCoupon();
  }, [debouncedCouponCode, serviceData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedCouponCode(couponCode1);
    }, DEBOUNCE_DELAY);

    return () => {
      clearTimeout(timer);
    };
  }, [couponCode1]);

  const handleCouponCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCouponCode1(event.target.value);
  };

  useEffect(() => {
    const createMercadopagoLink = async () => {
      try {
        if (
          serviceCreatedData?.UserParent?.id &&
          serviceCreatedData?.UserBabysitter?.id
        ) {
          const link = await pay(
            serviceCreatedData?.UserParent?.id,
            serviceCreatedData?.UserBabysitter?.id,
            1,
            serviceCreatedData?.id,
            serviceCreatedData.startTime,
            serviceCreatedData.endTime,
          );

          window.location.href = link;
        }
      } catch (error) {
        throw new Error(
          intl.translate({
            id: 'Error al crear link de mercadopago',
          }),
        );
      } finally {
        setLoading(false);
      }
    };

    if (serviceCreatedData) {
      createMercadopagoLink();
    }
  }, [serviceCreatedData]);

  return (
    <StyledFormContainer>
      <Form
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <>
            <StyledInputAlign column gap={0.7}>
              <StyledChildDataWebP>
                {intl
                  .translate({ id: 'medical.emergency.title' })
                  .toUpperCase()}
              </StyledChildDataWebP>
              <StyledFatherDataMobileTextP>
                {intl.translate({ id: 'medical.emergency.title' })}
              </StyledFatherDataMobileTextP>
              <TextInput
                label={intl.translate({ id: 'health.insurance.input' })}
                placeHolder={intl.translate({
                  id: 'health.insurance.input.explanation',
                })}
                id="healthInsurance"
                name="healthInsurance"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.healthInsurance}
                error={formik.errors.healthInsurance}
                fullWidth
                required
                multiline={true}
                rows="3"
              />
              <StyledFatherDataMobileTextP>
                {intl.translate({ id: 'Emergencia Movil' })}
              </StyledFatherDataMobileTextP>
              <TextInput
                label={intl.translate({ id: 'Emergencia Movil' })}
                placeHolder={intl.translate({
                  id: 'Emergencia Movil',
                })}
                id="mobileEmergency"
                name="mobileEmergency"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.mobileEmergency}
                error={formik.errors.mobileEmergency}
                fullWidth
                required
                multiline={true}
                rows="3"
              />
              <StyledChildDataWebP>
                {intl.translate({ id: 'security.title' }).toUpperCase()}
              </StyledChildDataWebP>
              <StyledFatherDataMobileTextP>
                {intl.translate({ id: 'security.title' })}
              </StyledFatherDataMobileTextP>
              <TextInput
                label={intl.translate({ id: 'security.company.input' })}
                id="securityCompany"
                name="securityCompany"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.securityCompany}
                error={formik.errors.securityCompany}
                fullWidth
                required
                multiline={true}
                rows="3"
              />
              <TextInput
                label={intl.translate({ id: 'second.emergency.contact.input' })}
                placeHolder={intl.translate({
                  id: 'second.emergency.contact.input.explanation',
                })}
                id="secondEmergencyContact"
                name="secondEmergencyContact"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.secondEmergencyContact}
                error={formik.errors.secondEmergencyContact}
                fullWidth
                required
                multiline={true}
                rows="3"
              />
              <TextInput
                label={intl.translate({ id: 'other.people.house.input' })}
                placeHolder={intl.translate({
                  id: 'other.people.house.input.explanation',
                })}
                id="otherPeopleHouse"
                name="otherPeopleHouse"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.otherPeopleHouse}
                error={formik.errors.otherPeopleHouse}
                fullWidth
                required
                multiline={true}
                rows="3"
              />
              <StyledChildDataWebP>
                {intl
                  .translate({ id: 'Datos del representante' })
                  .toUpperCase()}
              </StyledChildDataWebP>
              <StyledFatherDataMobileTextP>
                {intl.translate({ id: 'Datos del representante' })}
              </StyledFatherDataMobileTextP>
              <TextInput
                label={intl.translate({ id: 'Cédula de identidad' })}
                id="dni"
                name="dni"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.dni}
                error={formik.errors.dni}
                fullWidth
                required
              />
              <StyledChildDataWebP>
                {intl.translate({ id: 'Datos de Facturación' }).toUpperCase()}
              </StyledChildDataWebP>
              <StyledFatherDataMobileTextP>
                {intl.translate({ id: 'Datos de Facturación' })}
              </StyledFatherDataMobileTextP>
              <TextInput
                label={intl.translate({ id: 'Cédula de identidad' })}
                id="billDni"
                name="billDni"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.billDni}
                error={formik.errors.billDni}
                fullWidth
                required
              />
              <TextInput
                label={intl.translate({ id: 'Domicilio' })}
                id="billAddress"
                name="billAddress"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.billAddress}
                error={formik.errors.billAddress}
                fullWidth
                required
              />
              <TextInput
                label={intl.translate({ id: 'Ciudad' })}
                id="billCity"
                name="billCity"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.billCity}
                error={formik.errors.billCity}
                fullWidth
                required
              />
              <TextInput
                label={intl.translate({ id: 'Código de descuento' })}
                id="coupon-code"
                name="couponCode"
                type="text"
                onChange={(event: any) => {
                  handleCouponCodeChange(event);
                  formik.handleChange(event);
                }}
                value={formik.values.couponCode}
                error={formik.errors.couponCode}
                fullWidth
              />
              <StyledPriceDataWebP>
                {intl.translate({ id: 'Cotización final' }).toUpperCase()}
              </StyledPriceDataWebP>
              <StyledPriceDataMobileTextP>
                {intl.translate({ id: 'Cotización final' })}
              </StyledPriceDataMobileTextP>
              <StyledPriceTextP>
                $
                {updatedPrice !== null ? (
                  updatedPrice
                ) : serviceData ? (
                  serviceData.price
                ) : (
                  <Loading />
                )}
              </StyledPriceTextP>
            </StyledInputAlign>
            {loading ? (
              <Loading loading={loading} variant="contained" fullWidth>
                {intl.translate({ id: 'Cargando...' })}
              </Loading>
            ) : (
              <>
                <StyledTextP>
                  *Si desea contratar más de 1 día, debe realizar una solicitud
                  de servicio por cada día, de modo que usted pueda elegir su
                  niñera. Si desea servicios de más de 8 horas, debe realizar
                  dos contrataciones seguidas con distintas niñeras
                </StyledTextP>
                <StyledCheckBoxDiv>
                  <StyledCheckBox
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 60,
                      },
                    }}
                    onClick={() => {
                      setIsChecked((prevState) => !prevState);
                    }}
                    checked={isChecked}
                  />
                  <StyledHrefA
                    href="/contratoDeServiciosOKids.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.translate({ id: 'Contrato de servicios OKids' })}
                  </StyledHrefA>
                </StyledCheckBoxDiv>
                <StyledButton
                  type="submit"
                  color="primary"
                  variant="filled"
                  disabled={!isChecked}
                  fullWidth
                >
                  <strong>{intl.translate({ id: 'Pagar' })}</strong>
                </StyledButton>
              </>
            )}
          </>
        )}
      </Form>
    </StyledFormContainer>
  );
};
