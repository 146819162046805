import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import CheckAvailabilityFormBackgroundSvg from './assets/fondo-flujo-contratacion.svg';

const StyledCheckAvailabilityFormBackgroundDiv = styled.div`
  background-image: url(${CheckAvailabilityFormBackgroundSvg});
  background-repeat: no-repeat;
  background-size: 90vw;
  height: 100vh;
  align-content: center;
  background-position: top -15vh left -40vw;
  width: 100vw;
  overflow-x: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    background-position: top -9vh left -50vw;
    background-size: 100vh;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-image: none;
  }
`;

export const BackgroundBabysitterPanel: FC<FcDefaultProps> = ({ children }) => {
  return (
    <StyledCheckAvailabilityFormBackgroundDiv>
      {children}
    </StyledCheckAvailabilityFormBackgroundDiv>
  );
};
