import { Text } from 'design';
import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledLeftSideDiv = styled.div`
  height: 100%;
  width: 50vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 80vw;
  }
`;

const StyledTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  margin-top: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    justify-content: center;
  }
`;

const StyledOl = styled.ol`
  margin-top: 3rem;
`;

const StyledLi = styled.li`
  font-size: 1.25rem;
  font-family: 'Gilroy';
  color: #555;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
  }
`;

const StyledTextP = styled(Text.p)`
  font-size: 1.25rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 1rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 1rem;
  }
`;

const StyledTitleTextH2 = styled(Text.h2)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 50vw;
  font-weight: 900;
  line-height: 4rem;
  text-align: start;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 2.5rem;
    line-height: 2.8rem;
    max-width: 45rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 1.8rem;
    line-height: 1.8rem;
    text-align: center;
  }
`;

const StyledSpaceDiv = styled.div`
  margin-top: 1rem;
`;

const StyledLineHr = styled.hr`
  color: ${({ theme }) => theme.palette.primary.main};
  border-top: 5px solid ${({ theme }) => theme.palette.primary.main};
  width: 85%;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 90%;
  }
`;

const StyledListDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-left: 1rem;
  margin-right: 2rem;
`;

export const LeftSideContent: FC<FcDefaultProps> = () => {
  return (
    <StyledLeftSideDiv>
      <StyledTitleDiv>
        <StyledTitleTextH2>
          {intl.translate({
            id: 'Te estamos esperando para formar parte de nuestro staff',
          })}
        </StyledTitleTextH2>
        <StyledSpaceDiv />
        <StyledLineHr />
        <StyledListDiv>
          <StyledOl>
            <StyledLi>
              <StyledTextP>
                {intl.translate({
                  id: 'Postular su CV para ser analizada por nuestros psicólogos',
                })}
              </StyledTextP>
            </StyledLi>
            <StyledLi>
              <StyledTextP>
                {intl.translate({
                  id: 'Superar proceso de evaluación psicotécnica',
                })}
              </StyledTextP>
            </StyledLi>
            <StyledLi>
              <StyledTextP>
                {intl.translate({
                  id: 'Recibir capacitación e inscripción en la empresa',
                })}
              </StyledTextP>
            </StyledLi>
          </StyledOl>
        </StyledListDiv>
      </StyledTitleDiv>
    </StyledLeftSideDiv>
  );
};
