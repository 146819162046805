import {
  serviceRecoordination,
  updateServiceStatus,
} from 'api/babysitters/babysitters.api';
import { FutureServicesStatus } from 'enums/auth-type.enum';
import { selectUser } from 'global-state/selectors';
import { genericErrorHandler } from 'helpers/error.helpers';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { FutureServicesType } from 'types/babysitter-props';
import { BabysitterAcceptedServices } from './BabySitterServicesStatus/BabysitterAcceptedServices';
import { BabysitterDeclinedServices } from './BabySitterServicesStatus/BabysitterDeclinedServices';
import { BabysitterPendingService } from './BabySitterServicesStatus/BabysitterPendingService';

interface Props {
  data: FutureServicesType;
  onChangeStatus: () => void;
}

export const BabySitterServicesStatus: FC<Props> = ({
  data,
  onChangeStatus,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const babysitter = useSelector(selectUser);

  const [acceptedServiceStatus, setAcceptedServiceStatus] = useState(
    data.status,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const handleClickActive = async (id: string) => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      const updatedServices = await updateServiceStatus(
        id,
        FutureServicesStatus.ACCEPTED_BY_BABYSITTER,
      );
      if (updatedServices) {
        setAcceptedServiceStatus(FutureServicesStatus.ACCEPTED_BY_BABYSITTER);
      }
    } catch (e) {
      genericErrorHandler(e);
    } finally {
      setLoading(false);
    }
  };

  const handleClickDeclined = async (id: string) => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const updatedStatus = await updateServiceStatus(
        id,
        FutureServicesStatus.DECLINED_BY_BABYSITTER,
      );
      if (updatedStatus) {
        onChangeStatus();
        setAcceptedServiceStatus(FutureServicesStatus.DECLINED_BY_BABYSITTER);
      }
      if (babysitter && babysitter.babysitter) {
        await serviceRecoordination(
          babysitter?.babysitter.id,
          data.locationLat,
          data.locationLng,
          data.startTime,
          data.endTime,
          id,
        );
      }
    } catch (e) {
      genericErrorHandler(e);
    } finally {
      setLoading(false);
    }
  };

  if (
    acceptedServiceStatus === FutureServicesStatus.PENDING ||
    acceptedServiceStatus === FutureServicesStatus.PAYMENT_SUCCESS
  ) {
    return (
      <BabysitterPendingService
        loading={loading}
        onClickActive={() => handleClickActive(data.id)}
        onClickDeclined={() => handleClickDeclined(data.id)}
      />
    );
  } else if (
    acceptedServiceStatus === FutureServicesStatus.ACCEPTED_BY_BABYSITTER
  ) {
    return (
      <BabysitterAcceptedServices
        loading={loading}
        onClick={() => handleClickDeclined(data.id)}
      />
    );
  } else {
    return <BabysitterDeclinedServices />;
  }
};
