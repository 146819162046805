import { Button, Text } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
// import videoUrl from './assets/Video_promo.mp4';
import backgroundImage from './assets/mainOkidsBackground.jpg';

const StyledBannerMainContentDiv = styled.div`
  padding-top: 7rem;
`;

const StyledBannerDiv = styled.div`
  position: relative;
`;

const StyledTextH2 = styled(Text.h2)`
  color: white;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: 10vw;
  margin-bottom: 7vh;
  font-size: 5vw;
  font-weight: 400;
  line-height: 5vw;
  padding: 0 2rem 2.5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-left: 3vw;
    margin-bottom: 1vh;
  }
`;

const StyledSpan = styled.span`
  font-weight: 700;
`;

const StyledCardDiv = styled.div`
  background-color: #faf0e6;
  padding: 2rem 5rem;
  max-width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: space-around;
    padding: 1.5rem 2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
  }
`;

const StyledTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 1.7rem;
  padding-right: 2rem;
  width: 50vw;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    line-height: 1.3rem;
    word-spacing: 2px;
    font-size: 1.4rem;
    padding-right: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.5rem;
  }
`;

const StyledButtonDiv = styled.div`
  width: 50vw;
  display: flex;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 50vw;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100vw;
    padding: 0 1rem;
  }
`;

const StyledButton = styled(Button)`
  align-self: center;
  width: 80%;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 1 rem;
  }
`;

const StyledButtonTextP = styled(Text.p)`
  font-size: 1.1rem;
  font-weight: 900;
  color: ${({ theme }) => theme.palette.primary.invert};
  word-spacing: 4px;
  padding: 0.5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 0.9rem;
  }
`;

// const StyledBannerVideo = styled.video`
//   width: 100%;
// `;

const StyledBackgroundImageDiv = styled.img`
width:100%;
`

export const Banner: FC = () => {
  const navigate = useNavigate();

  const getIntoServiceForm = () => {
    navigate(AppRoute.HOME, { state: { targetId: 'formSection' } });
  };

  return (
    <StyledBannerMainContentDiv>
      <StyledBannerDiv>
        <StyledBackgroundImageDiv src={backgroundImage} />
        {/* <StyledBannerVideo
          autoPlay
          loop
          muted
          src={videoUrl}
          preload="auto"
          playsInline={true}
        /> */}

        <StyledTextH2>
          {intl.translate(
            {
              id: 'Nos dedicamos <br></br> al <span>bienestar <br></br> y cuidado infantil</span>',
            },
            {
              br: () => <br />,
              span: (label) => <StyledSpan>{label}</StyledSpan>,
            },
          )}
        </StyledTextH2>
      </StyledBannerDiv>
      <StyledCardDiv>
        <StyledTextP>
          {intl.translate(
            {
              id: 'Trabajamos únicamente con <br></br> profesionales en formación',
            },
            { br: () => <br /> },
          )}
        </StyledTextP>
        <StyledButtonDiv>
          <StyledButton
            variant="filled"
            color="primary"
            fullWidth
            onClick={getIntoServiceForm}
          >
            <StyledButtonTextP>
              {intl.translate({ id: 'CONOCÉ LAS MEJORES NIÑERAS' })}
            </StyledButtonTextP>
          </StyledButton>
        </StyledButtonDiv>
      </StyledCardDiv>
    </StyledBannerMainContentDiv>
  );
};
