import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import GuaranteesBackground from './assets/okids-logo.svg';

const StyledBackgroundCreateBabysitterAccountDiv = styled.div`
  background-image: url(${GuaranteesBackground});
  background-repeat: no-repeat;
  background-size: 100vw;
  background-position: top 0 left -31vw;
  background-attachment: fixed;
  height: 195vh;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 200vh;
  }
  @media (max-height: 840px) {
    height: 107.375rem;
  }
`;

export const BackgroundCreateBabysitterAccount: FC<FcDefaultProps> = ({
  children,
}) => {
  return (
    <StyledBackgroundCreateBabysitterAccountDiv>
      {children}
    </StyledBackgroundCreateBabysitterAccountDiv>
  );
};
