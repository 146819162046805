import { MainContainer } from 'components/Container/MainContainer';
import { PreviousServicesTable } from 'components/PreviousServicesTable/View/PreviousServicesTable';
import { AppBar } from 'components/views/AppBar';
import { FC } from 'react';
import styled from 'styled-components';

const StyledTableDesignDiv = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 3rem;
  }
  margin-top: 1.5rem;
`;

function createData(name: string, location: string, age: number) {
  return { name, location, age };
}
const rows = [
  createData('Noelia Caballero1', 'Pablo de Maria', 22),
  createData('Tomas Bilar2', 'Mario Ruffino', 27),
  createData('Noelia Caballero3', 'Pablo de Maria', 22),
  createData('Tomas Bilar4', 'Mario Ruffino', 27),
  createData('Noelia Caballero5', 'Pablo de Maria', 22),
  createData('Tomas Bilar6', 'Mario Ruffino', 27),
];

export const ClosedServicesView: FC = () => {
  const titleData = ['nombre', 'direccion', 'edad'];

  return (
    <>
      <AppBar />
      <MainContainer>
        <StyledTableDesignDiv>
          <PreviousServicesTable />
        </StyledTableDesignDiv>
      </MainContainer>
    </>
  );
};
