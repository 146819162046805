import { config } from 'config';
import wpIcon from '../../assets/whatsapp-icon.webp';

const whatsappPhoneNumber = config.whatsapp.whatsappPhoneNumber;
const phoneNumber = `https://wa.me/+${whatsappPhoneNumber}`;

const WhatsAppIcon = () => {
  const openWhatsApp = () => {
    window.open(phoneNumber, '_blank');
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        cursor: 'pointer',
      }}
      onClick={openWhatsApp}
    >
      <img
        src={wpIcon}
        alt="WhatsApp"
        width="50"
        height="50"
      />
    </div>
  );
};

export default WhatsAppIcon;
