import { getUTCDate } from "./getUTCDate";

export const parseDateDdMmYyyyHhMm = (date: Date) =>
    getUTCDate(date).toLocaleString('es-ES', { timeZone: 'UTC' })

export const parseDateDdMmYyyy = (date: Date) =>
    getUTCDate(date).toLocaleString('es-ES', {
        timeZone: 'UTC',
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
    }
    )
