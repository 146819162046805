import { getFutureServicesOfParent } from 'api/babysitters/babysitters.api';
import { declineServiceByParent } from 'api/services.api';
import { CancelServiceModal } from 'components/Modal/CancelServiceModal';
import { Button, Text } from 'design';
import { Loading } from 'design/Button/LoadingButton';
import { FutureServicesStatus } from 'enums/auth-type.enum';
import { calcHours, getDayMonthyear } from 'helpers/date.helpers';
import { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { servicesType, ServiceUserBabysitterProps } from 'types/service-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledDataTextLabel = styled(Text.label)`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
`;

const StyledDataTextTableLabel = styled(Text.label)`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  align-self: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.main};
`;

const StyledDataTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 1rem;
  align-self: flex-start;
  ::first-letter {
    text-transform: capitalize;
  }
`;

const StyledDataHoursP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 1rem;
  align-self: center;
  ::first-letter {
    text-transform: capitalize;
  }
`;
const StyledServicesContentDiv = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: calc(100vw - 4rem);
  }
`;

const StyledDiv = styled.div`
  padding: 1rem;
  border: 2px solid ${({ theme }) => theme.palette.secondary.main};
  border-radius: 6px;
  margin-top: 1rem;
`;

const StyledTableDiv = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`;

const StyledDateDataDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;
`;

const StyledLabelsDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const StyledNoServicesDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
`;

interface Props {
  userInfo?: string;
}

export const UserFutureServices: FC<Props> = ({ userInfo }) => {
  const [servicesFullData, setServicesFullData] = useState<servicesType>({});
  const [selectedService, setSelectedService] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const removeCanceledServices = (services: servicesType) => {
    const servicesByBabysitterFiltered: servicesType = {}
    Object.keys(services).forEach((babysitterId: string) => {
        if (services[babysitterId].services.length) {
            const servicesFiltered = services[babysitterId].services.filter(s => 
                s.status !== FutureServicesStatus.DECLINED_BY_PARENT &&
                s.status !== FutureServicesStatus.PENDING
            );
            if (servicesFiltered.length) {
                servicesByBabysitterFiltered[babysitterId] = {
                    services: servicesFiltered,
                    babysitter: babysitterId
                }
            }
        }
    })
    return servicesByBabysitterFiltered;
}

  const handleOnClick = (serviceId: string) => {
    setSelectedService(serviceId);
    setIsOpen(true);
  }

  const handleDeclineServiceByParent = async (serviceId: string) => {
    try {
      setLoading(true);
      await declineServiceByParent(serviceId);
      await getServices();
      toast.success(intl.translate({ id: 'Servicio cancelado exitosamente' }))
    } catch (error) {
      console.log(error);
      toast.error(intl.translate({ id: 'Lo sentimos, hubo un error' }))
    } finally {
      setLoading(false);
      setIsOpen(false);
      setSelectedService('');
    }
  }

  const getServices = useCallback(async () => {
    if (userInfo) {
      const response = await getFutureServicesOfParent(userInfo);
      if (response) {
        const filteredServices = removeCanceledServices(response);
        setServicesFullData(filteredServices);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    getServices();
  }, [getServices]);

  const getBabysitterFullName = (name: string, lastname: string) => {
    if (name || lastname) {
      return `${name} ${lastname}`;
    }
    return ' ';
  };

  const babysitterIds: string[] = Object.keys(servicesFullData);

  return (
    <StyledServicesContentDiv>
      {babysitterIds.length !== 0 ? (
        babysitterIds.map((babysitter) => {
          const babysitterData =
            servicesFullData?.[babysitter]?.services?.[0]?.UserBabysitter?.user;
          return (
            <StyledDiv key={`future-${babysitter}`}>
              <StyledDataTextLabel>
                {intl.translate({ id: 'Niñera:' })}
              </StyledDataTextLabel>
              <StyledDataTextP>
                {getBabysitterFullName(
                  babysitterData?.name || "",
                  babysitterData?.lastname || "",
                )}
              </StyledDataTextP>
              <>
                <StyledTableDiv>
                  <StyledLabelsDiv>
                    <StyledDataTextTableLabel>
                      {intl.translate({ id: 'Dia' })}
                    </StyledDataTextTableLabel>
                    <StyledDataTextTableLabel>
                      {intl.translate({ id: 'Horas' })}
                    </StyledDataTextTableLabel>
                    <StyledDataTextTableLabel />

                    {/* <TableCell>
                          <StyledDataTextTableLabel>
                            {intl.translate({ id: 'Calificacion' })}
                          </StyledDataTextTableLabel>
                            </TableCell> */}
                  </StyledLabelsDiv>
                  {servicesFullData[babysitter].services.map(
                    (service: ServiceUserBabysitterProps) => {
                      const serviceHours = calcHours(
                        service.startTime,
                        service.endTime,
                      );
                      const getDate = getDayMonthyear(service.startTime);
                      return (
                        <StyledLabelsDiv key={service.id}>
                          <StyledDateDataDiv>
                            <StyledDataTextP>{getDate}</StyledDataTextP>
                          </StyledDateDataDiv>
                          <StyledDateDataDiv>
                            <StyledDataHoursP>
                              {serviceHours}hs
                            </StyledDataHoursP>
                          </StyledDateDataDiv>
                          <StyledDateDataDiv>
                            {loading && selectedService === service.id ? (
                              <Loading
                                loading={selectedService === service.id}
                                loadingPosition="center"
                                variant="contained"
                                fullWidth
                              >
                                {intl.translate({ id: 'Cargando...' })}
                              </Loading>
                            ) : (
                              <StyledButton onClick={() => handleOnClick(service.id)}>
                                {intl.translate({ id: 'Cancelar' })}
                              </StyledButton>
                            )}
                          </StyledDateDataDiv>
                          {/* <StyledDateDataDiv>
                             <StyledDataTextP>
                              <StarsRating initialValue={4} />
                            </StyledDataTextP> 
                          </StyledDateDataDiv> */}
                        </StyledLabelsDiv>
                      );
                    },
                  )}
                </StyledTableDiv>
              </>
            </StyledDiv>
          );
        })
      ) : (
        <StyledNoServicesDiv>
          <Text.p>
            {intl.translate({
              id: 'Actualmente no se registran servicios anteriores',
            })}
          </Text.p>
        </StyledNoServicesDiv>
      )}

      <CancelServiceModal
        loading={!!loading}
        onClick={() => handleDeclineServiceByParent(selectedService)}
        onClose={() => setIsOpen(false)}
        open={isOpen}
      />
    </StyledServicesContentDiv>
  );
};
