import { AppBar } from 'components/views/AppBar';
import { FC } from 'react';
import styled from 'styled-components';
import { ProvidersTable } from '../ProvidersTable';

const StyledTableDesignDiv = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 3rem;
  }
  margin-top: 1.5rem;
`;

const StyledMainContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  padding-top: 7rem;
  align-self: center;
  max-width: ${({ theme }) => theme.breakpoints.xxl}px;
  margin: 0 auto;
`;


export const ProvidersView: FC = () => {
  return (
    <>
      <AppBar />
      <StyledMainContainer>
        <StyledTableDesignDiv>
          <ProvidersTable />
        </StyledTableDesignDiv>
      </StyledMainContainer>
    </>
  );
};
