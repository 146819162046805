import { Text } from 'design';
import { WarningErrorIcon } from 'design/Icon/WarningErrorIcon';
import { AppRoute } from 'enums/app-route.enum';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const StyledErrorInfoSection = styled.section`
  height: 50vh;
  margin-top: 5rem;
  margin: 5rem 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-height: 839px) {
    height: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    height: 100%;
  }
`;

const StyledTextH2 = styled(Text.h2)`
  color: #676464;
  font-weight: 800;
`;

const StyledTextP = styled(Text.p)`
  color: #908e8e;
`;

const StyledAlertMessageDiv = styled.div`
  width: 60vw;
  background-color: #7fc1ff5f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  height: 7rem;

  @media (max-width: 685px) {
    height: 100%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    align-self: center;
  }
`;

const StyledWarningTextP = styled(Text.p)`
  font-size: 20px;
  color: #908e8e;
`;

const StyledLinkBackToHome = styled(Link)`
  text-decoration: none;
  margin-top: 2rem;
`;

const StyledLinkTextP = styled(Text.p)`
  font-size: 20px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 800;
`;

const StyledContactTextP = styled(Text.p)`
  @media (max-height: 839px) {
    margin-top: 1rem;
  }
`;

export const ErrorDetailDescription: FC = () => {
  return (
    <StyledErrorInfoSection>
      <StyledTextH2>
        {intl.translate({
          id: '¿Que pasó?',
        })}
      </StyledTextH2>
      <StyledTextP>
        {intl.translate({
          id: 'No pudimos avanzar con el pago, si lo desea puede intentar con otro método de pago.',
        })}
      </StyledTextP>
      <StyledAlertMessageDiv>
        <WarningErrorIcon />
        <StyledWarningTextP>
          {intl.translate({
            id: 'Si ves el pago reflejado en tu resumen de cuenta, no te preocupes, desaparecerá en unos dias.',
          })}
        </StyledWarningTextP>
      </StyledAlertMessageDiv>

      <StyledLinkBackToHome to={AppRoute.HOME}>
        <StyledLinkTextP>
          {intl.translate({
            id: 'Volver a comprar',
          })}
        </StyledLinkTextP>
      </StyledLinkBackToHome>

      <StyledContactTextP>
        {intl.translate({
          id: 'Si la información no fue suficiente o tienes algún problema, contáctanos.',
        })}
      </StyledContactTextP>
    </StyledErrorInfoSection>
  );
};
