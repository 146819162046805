import { useIconColor } from 'design/hooks/use-icon-color.hook';
import { IconProps } from 'design/types/icon-props';
import { FC } from 'react';

export const InstagramIcon: FC<IconProps & { width?: number | string }> = ({
  color,
  width = '100%',
  className,
  invert = false,
}) => {
  const _color = useIconColor(color, invert);

  return (
    <svg
      width={width}
      color={_color}
      className={className}
      viewBox="0 0 993 995"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M496.4 994.3C222.8 994.3 0.0996094 771.6 0.0996094 497.8C0.0996094 223.6 222.7 0.600098 496.4 0.600098C770 0.600098 992.7 223.7 992.7 497.8C992.6 771.5 770 994.3 496.4 994.3ZM496.4 49.8001C249.9 49.8001 49.3996 250.8 49.3996 497.8C49.3996 744.4 249.9 945.1 496.4 945.1C742.9 945.1 943.4 744.4 943.4 497.8C943.4 250.7 742.9 49.8001 496.4 49.8001Z"
        fill="currentColor"
      />
      <path
        d="M716.399 605.4C716.399 667.3 665.799 717.2 603.899 717.2H388.799C326.999 717.2 276.999 667.2 276.999 605.4V389.5C276.999 327.6 326.899 277.7 388.799 277.7H603.899C665.799 277.7 716.399 327.6 716.399 389.5V605.4ZM766.999 389.5C766.999 299.5 693.899 226.3 603.899 226.3H388.799C298.799 226.3 225.699 299.5 225.699 389.5V605.4C225.699 695.4 298.799 768.6 388.799 768.6H603.899C693.899 768.6 766.999 695.4 766.999 605.4V389.5V389.5ZM497.099 586.4C449.999 586.4 412.099 548.4 412.099 501.3C412.099 453.5 450.099 415.5 497.099 415.5C544.899 415.5 582.899 453.5 582.899 501.3C582.799 548.4 544.899 586.4 497.099 586.4ZM497.099 367C423.999 367 363.499 427.5 363.499 501.3C363.499 574.4 423.999 634.9 497.099 634.9C570.899 634.9 631.399 574.4 631.399 501.3C631.299 427.4 570.899 367 497.099 367ZM638.999 326.1C621.399 326.1 606.699 340.2 606.699 357.8C606.699 376.1 621.499 390.2 638.999 390.2C656.599 390.2 670.599 376.1 670.599 357.8C670.699 340.2 656.599 326.1 638.999 326.1Z"
        fill="currentColor"
      />
    </svg>
  );
};
