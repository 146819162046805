import { defaultValuesMaps } from 'components/Map/defaultValuesMaps';
import { Map } from 'components/Map/Map';
import { TextInput } from 'design';
import { OkidsModal } from 'design/Modal/Modal';
import { ModalProps } from 'design/types/modal-props';
import * as React from 'react';

export const AddressModal = (props: ModalProps): JSX.Element | null => {
  const { handleSubmit, setAddress } = props;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleTitle = (title: string) => {
    setButtonTitle(title);
  };

  const [buttonTitle, setButtonTitle] = React.useState(
    'Dirección donde se recibirá el servicio',
  );

  return (
    <>
      <TextInput
        name="addressModal"
        id="addressModal"
        type="text"
        value={buttonTitle}
        fullWidth
        onClick={handleOpen}
      />
      <OkidsModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Map
          pins={defaultValuesMaps.pins}
          defautCoords={defaultValuesMaps.defautCoords}
          title={defaultValuesMaps.title}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          setAddress={setAddress}
          handleTitle={handleTitle}
        />
      </OkidsModal>
    </>
  );
};
