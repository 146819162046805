import { styled as styledMui } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import {
  getBabysitterReview,
  getPriceService,
} from 'api/babysitters/babysitters.api';
import { OkidsLogo } from 'components/icons/OkidsLogo';
import { Button, Text } from 'design';
import { Loading } from 'design/Button/LoadingButton';
import { AppRoute } from 'enums/app-route.enum';
import {
  setSelectedBabysitterFullDataAction,
  setServiceDataAction,
} from 'global-state/actions';
import { selectServiceData } from 'global-state/selectors';
import { calculateAge } from 'helpers/date.helpers';
import { genericErrorHandler } from 'helpers/error.helpers';
import { Align } from 'layout';
import { StarsRating } from 'rating/StarsRating';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BabysitterDataType } from 'types/babysitter-props';
import { intl } from 'utilities/i18n/intl.utility';
import {
  getServiceDay,
  getServiceEndTime,
  getServiceMonth,
  getServiceStartTime,
} from '../../helpers/babysitter.helpers';
import { ReviewsModal } from './ReviewsModal';
import { TickIcon } from './icons/TickIcon';

const StyledContainerDiv = styled.div`
  height: calc(100vh - 7rem);
  overflow: hidden;
  padding-top: 7rem;
  align-self: center;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  margin: 0 auto;
`;

const StyledSectionDiv = styled.div`
  overflow: scroll;
  height: 100%;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledCardAlignDiv = styled.div`
  padding: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledCardBodyDiv = styled.div`
  display: flex;
  width: 100%;
`;

const StyledAvatar = styledMui(Avatar)`
height: 5.5rem;
width: 5.5rem;
border: 3px solid ${({ theme }) => theme.palette.primary.main};
border-radius: 50%;
`;

const StyledUserDataP = styled(Text.p)`
  line-height: 1.1rem;
  font-weight: 700;
  font-size: 0.9rem;
  text-transform: capitalize;
`;

const StyledUserNameP = styled(Text.p)`
  line-height: 1.1rem;
  font-weight: 700;
  font-size: 1.1rem;
  text-transform: capitalize;
`;

const StyledRatingDiv = styled.div`
  justify-content: space-between;
  flex-direction: column;
`;

const StyledH4 = styled(Text.h4)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
`;

const StyledButtonsAlign = styled(Align)`
  width: 100%;
  justify-content: space-between;
  padding: 1rem 0;
`;

const StyledDateTimeAlign = styled(Align)`
  width: 100%;
  justify-content: space-around;
  padding: 0.5rem 0;
`;

const StyledDateAlign = styled(Align)`
  width: 50%;
`;

const StyledTimeAlign = styled(Align)`
  width: 50%;
`;

const StyledTitleDateP = styled(Text.p)`
  color: 'light';
  font-size: 0.8rem;
  font-weight: 800;
  text-transform: uppercase;
`;

const StyledDateP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.2rem;
  font-weight: 800;
  padding: 0.5rem 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 1.9rem;
  }
`;

const StyledH5 = styled(Text.h5)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 800;
  align-self: flex-start;
  padding: 1rem 0 0 0;
  font-size: 1.8rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 2.6rem;
  }
`;

const StyledHr = styled.hr`
  border-top: 4px solid ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  align-self: center;
`;

const StyledPriceP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 2.3rem;
  font-weight: 800;
  padding: 0.5rem 0.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 2.8rem;
  }
`;

const StyledTypeCurrencyP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.4rem;
  font-weight: 800;
  padding: 0.5rem 0 0 1rem;
`;

const StyledBottomP = styled(Text.p)`
  font-size: 0.8rem;
  line-height: 1rem;
  padding: 1rem 0;
`;

const StyledLeftDiv = styled.div`
  padding: 0 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    justify-content: center;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0 2rem;
    width: 100%;
  }
`;

const StyledRightDiv = styled.div`
  padding: 0 1rem;
  height: 10%;
  margin-bottom: 10rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 50%;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    height: auto;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0 2rem;
    width: 100%;
    margin: 0;
  }
  @media (max-height: 750px) and (min-width: ${({ theme }) =>
    theme.breakpoints.sm}px) {
    overflow: auto;
    max-height: calc(100vh - 10rem);
  }
`;

const StyledEndButton = styled(Button)`
  width: 100%;
  padding: 3.5rem;
  height: 2.5rem;
  margin: 2rem;
  align-self: center;
`;

const StyledButtonDiv = styled.div`
  bottom: 4rem;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StyledWebButtonDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  min-width: 6rem;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StyledTickIconDiv = styled.div`
  position: relative;
  top: 67px;
  right: 25px;
`;

const StyledBabysitterProfileImageDiv = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  padding: 0.5rem;
`;

const StyledOkidsLogo = styled(OkidsLogo)`
  color: white !important;
  font-size: 1rem !important;
  width: 7rem !important;
`;

const StyledTickIcon = styled(TickIcon)`
  color: ${({ theme }) => theme.palette.primary.main} !important;
`;

const StyledScheduleCallLink = styled(NavLink)`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 0.9rem;
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  margin-top: 0.5rem !important;
  padding: 0.7rem 1rem;
  text-decoration: none;
  color: white;
  border-radius: 4px;
  text-transform: uppercase;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
    align-items: center;
  }
`;

const StyledLeftSideBoldTextH4 = styled(Text.h3)`
  color: ${({ theme }) => theme.palette.secondary.dark};
  font-weight: 600;
  font-size: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 0.8rem;
  }
`;

const StyledScheduleCallWrapperDiv = styled.div`
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  color: ${({ theme }) => theme.palette.primary.invert} !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  height: 3rem !important;
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 0.9rem !important;
  }
`;

const StyledButtonReviews = styled(Button)`
  border: initial !important;
  font-size: 0.8rem !important;
`;

interface BabysittersProps {
  babysitter?: BabysitterDataType;
}

interface ProfileBabySitterProps extends BabysittersProps {
  dateRange: any;
  isLastSlide: boolean;
  handleNext: any;
}


export const ProfileBabySitter: FC<ProfileBabySitterProps> = ({ babysitter, dateRange, isLastSlide, handleNext }) => {
  const serviceData = useSelector(selectServiceData);
  const [price, setPrice] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const babysitterAge = babysitter
    ? calculateAge(babysitter.dateOfBirth.toString())
    : undefined;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getIntoServiceForm = () => {
    navigate(AppRoute.HOME, { state: { targetId: 'formSection' } });
  };

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviews = await getBabysitterReview(babysitter!.id);
        setModalData(reviews);
      } catch (error) {
        console.error('Error fetching babysitter reviews:', error);
      }
    };

    if (babysitter) {
      fetchReviews();
    }
  }, [babysitter]);

  useEffect(() => {
    const fetchPriceService = async () => {
      setLoading(true);
      try {
        if (!serviceData) return;
        if (serviceData?.dateRanges) {
          const price = await getPriceService(serviceData.dateRanges);
          if (price) {
            setPrice(price);
          }
        }
      } catch (error) {
        genericErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPriceService();
  }, [serviceData]);

  const babysitterFirstName = babysitter?.user?.name
    .split(/\s+/)
    .slice(0, 1)
    .join(' ');

  const handleRedirect = () => {
    if (isLastSlide) {
      dispatch(setSelectedBabysitterFullDataAction(babysitter!));
      navigate(AppRoute.SERVICE_BEFORE_PAYMENT);
    } else {
      handleNext();
    }
  };
  useEffect(() => {
    if (price !== null) {
      const updatedServiceData = { price: price, ...serviceData };
      dispatch(setServiceDataAction(updatedServiceData));
    }
  }, [price, serviceData?.price, dispatch]);
  return (
    <StyledContainerDiv>
      <StyledSectionDiv>
        <StyledLeftDiv>
          <StyledCardAlignDiv>
            <StyledCardBodyDiv>
              {babysitter?.user?.picture ? (
                <StyledAvatar
                  alt="Remy Sharp"
                  src={babysitter?.user?.picture}
                />
              ) : (
                <StyledBabysitterProfileImageDiv>
                  <StyledOkidsLogo small />
                </StyledBabysitterProfileImageDiv>
              )}

              <StyledTickIconDiv>
                <StyledTickIcon color="secondary" />
              </StyledTickIconDiv>
              <Align column>
                <StyledUserNameP color="dark">
                  <strong>{babysitterFirstName}</strong>
                </StyledUserNameP>
                <StyledUserDataP>
                  {intl.translate({ id: 'edad' })}/ {babysitterAge} años
                </StyledUserDataP>
                <StyledUserDataP>
                  {intl.translate({ id: 'experiencia' })}
                </StyledUserDataP>
                <StyledRatingDiv>
                  <StyledUserDataP>{babysitter?.experience}</StyledUserDataP>
                  <StarsRating
                    initialValue={
                      babysitter?.calculatedRate ? babysitter.calculatedRate : 0
                    }
                    allowFraction
                    readonly={true}
                  />
                  <StyledButtonReviews
                    variant="outlined"
                    onClick={() => setIsOpen(true)}
                  >
                    Ver reseñas de clientes
                  </StyledButtonReviews>
                  <ReviewsModal
                    open={isOpen}
                    modalData={modalData ? modalData : []}
                    onClose={() => setIsOpen(false)}
                  />
                </StyledRatingDiv>
              </Align>
            </StyledCardBodyDiv>
          </StyledCardAlignDiv>
          <StyledScheduleCallWrapperDiv>
            <StyledLeftSideBoldTextH4>
              {intl.translate({
                id: 'La contratación por web está sujeta a un máximo de 2 niños. Si desea cuidados para 3 niños o más, agende una llamada con la empresa.',
              })}
            </StyledLeftSideBoldTextH4>
            <StyledScheduleCallLink to={AppRoute.CONTACT}>
              {intl.translate({ id: 'Agendar llamada' })}
            </StyledScheduleCallLink>
          </StyledScheduleCallWrapperDiv>
        </StyledLeftDiv>
        <StyledRightDiv>
          <StyledDateTimeAlign>
            <StyledDateAlign column>
              <StyledTitleDateP>
                {intl.translate({ id: 'fecha' })}
              </StyledTitleDateP>
              <StyledDateP>
                {getServiceDay(dateRange.start)}/{getServiceMonth(dateRange.start)} - {getServiceDay(dateRange.end)}/{getServiceMonth(dateRange.end)}
              </StyledDateP>
            </StyledDateAlign>
            <StyledTimeAlign column>
              <StyledTitleDateP>
                {intl.translate({ id: 'horario' })}
              </StyledTitleDateP>
              <StyledDateP>
                {getServiceStartTime(dateRange.start)} a {getServiceEndTime(dateRange.end)} hs
              </StyledDateP>
            </StyledTimeAlign>
          </StyledDateTimeAlign>
          <div onClick={getIntoServiceForm}>
            <Button variant="outlined" fullWidth color="primary">
              {intl.translate({ id: 'modificar' })}
            </Button>
          </div>
          {price && (
            <>
              <Align column>
                <StyledH5>
                  {intl.translate({ id: 'Cotización final' })}
                </StyledH5>
                <StyledHr />
              </Align>
              <Align v-center h-start>
                <StyledPriceP>${price}</StyledPriceP>
                <StyledTypeCurrencyP>
                  {intl.translate({ id: 'Pesos URU' })}
                </StyledTypeCurrencyP>
              </Align>
            </>
          )}

          <StyledBottomP>
            {intl.translate({ id: 'Detalle de presupuesto' })}
          </StyledBottomP>
          <StyledWebButtonDiv>
            {loading ? (
              <Loading
                loading={loading}
                loadingPosition="center"
                variant="contained"
                fullWidth
              >
                {intl.translate({ id: 'Cargando...' })}
              </Loading>
            ) : (
              <StyledButton onClick={handleRedirect}>
                {' '}
                {isLastSlide ? "Confirmar niñeras" : intl.translate({ id: 'Siguiente' })}
              </StyledButton>
            )}
          </StyledWebButtonDiv>
          <StyledButtonDiv>
            <StyledButton onClick={handleRedirect}>
              {' '}
              {isLastSlide ? "Confirmar niñeras" : intl.translate({ id: 'Siguiente' })}
            </StyledButton>
          </StyledButtonDiv>
        </StyledRightDiv>
      </StyledSectionDiv>
    </StyledContainerDiv>
  );
};
