import { BackgroundGeneral } from 'components/background/BackgroundGeneral';
import { MainContainer } from 'components/Container/MainContainer';
import { FC } from 'react';
import { AppBar } from './AppBar';
import { SelectBabysitter } from './SelectBabysitter';

export const SelectBabySitterView: FC = () => {
  return (
    <>
      <AppBar />
      <BackgroundGeneral>
        <MainContainer>
          <SelectBabysitter
            name="Pedro Gimenez Perez"
            location="montevideo"
            alt="Men with long hair"
            src="https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector-Transparent.png"
            age={20}
            experience="Amplia"
            rating={3}
            readonly={true}
          />
        </MainContainer>
      </BackgroundGeneral>
    </>
  );
};
