import { Palette } from 'design/types/palette';

export const palette: Palette = {
  primary: {
    main: '#1883fe',
    light: '#84BBF8',
    dark: '#0047AB',
    invert: '#FFFFFF',
  },
  secondary: {
    main: '#a0a0a0',
    light: '#ba68c8',
    dark: '#706e6e',
    invert: '#fff',
  },
  error: {
    main: '#d32f2f',
    light: '#ef5350',
    dark: '#c62828',
    invert: '#fff',
  },
  warning: {
    main: '#ed6c02',
    light: '#ff9800',
    dark: '#e65100',
    invert: '#fff',
  },
  info: {
    main: '#FFFFFF',
    light: '#03a9f4',
    dark: '#01579b',
    invert: '#fff',
  },
  success: {
    main: '#00bd86',
    light: '#00bd86',
    dark: '#1b5e20',
    invert: '#fff',
  },
  grey: {
    main: '#d5d6d7',
    light: '#ddd',
    dark: '#333',
    invert: '#fff',
  },
  blue: {
    main: '#0000FF',
    dark: '#0000FF',
    light: '#0000FF',
    invert: '#0000FF',
  },
  white: {
    main: '#fff',
    light: '#ecf49d',
    dark: '#aaae6c',
    invert: '#fff',
  },
};
