import { FormControlLabel, styled as styledMui } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { registerBabysitter } from 'api/auth.api';
import { LoginButton } from 'components/Buttons/LoginButton';
import { Form } from 'components/Form/Form';
import { defaultValuesMaps } from 'components/Map/defaultValuesMaps';
import { AddressModal } from 'components/Modal/AddressModal';
import { addYears } from 'date-fns';
import { Button, Card, Text, TextInput } from 'design';
import { Loading } from 'design/Button/LoadingButton';
import { CrossIcon } from 'design/Icon/CrossIcon';
import useFileUpload from 'design/hooks/useFileUpload';
import { DatePicker } from 'design/input/DatePicker/DatePicker';
import { AppRoute } from 'enums/app-route.enum';
import { FormikHelpers } from 'formik';
import { setAuthTokenAction, setUserDataAction } from 'global-state/actions';
import { genericErrorHandler } from 'helpers/error.helpers';
import { Align } from 'layout';
import { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { http } from 'utilities/http/http';
import { intl } from 'utilities/i18n/intl.utility';
import * as Yup from 'yup';
import { requestForToken } from '../../firebase';
import { BabysitterSchedule } from './AvailavilityDaysOfWeekForm/BabysitterSchedule';
const pdfUrl = "/termsAndConditions.pdf";

const StyledCardBody = styled(Card.Body)`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: row;
    display: flex;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    align-self: center;
  }
`;

const StyledSpaceDiv = styled.div`
  margin-top: 16px;
`;

const StyledRightLabel = styled.label`
  align-self: center;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    align-self: flex-start;
    padding-top: 12.8px;
  }
`;

const StyledLabelP = styled(Text.p)`
  align-self: center;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
  padding: 16px 0;
`;

const StyledAboutMeLabelP = styled(Text.p)`
  align-self: center;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
  padding-top: 16px;
`;

const StyledTextP = styled(Text.p)`
  font-size: 12.8px;
  align-self: center;
`;

const StyledAboutMeTextP = styled(Text.p)`
  font-size: 12.8px;
  align-self: center;
  padding-bottom: 16px;
`;

const StyledTermsConditionsP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 19.2px;
  line-height: 19.2px;
  font-weight: 700;
  max-width: 224px;
`;

const StyledRightSectionAlign = styled(Align)`
  flex-direction: column;
  justify-content: space-around;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 16px;
  }
`;

const StyledBottomNavLinkDiv = styled.div`
  display: flex;
  padding-top: 24px;
`;

const StyledButtonDiv = styled.div`
  margin-top: 2rem;
`;

const StyledCheckboxAlign = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0 16px 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-height: 80px !important;
  }
`;

const StyledInputDiv = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  width: 100%;
`;

const StyledAddressDiv = styled.div`
  width: 100%;
`;

const StyledCheckBox = styledMui(Checkbox)`
  margin: 0;
  padding: 0;
`;

const StyledCVButton = styled(Button)`
  margin-top: 16px !important;
  border: 0.1875rem solid ${({ theme }) => theme.palette.primary.main} !important;
  padding: 8px 16px !important;
  font-size: 16px !important;
`;

const StyledCheckBoxDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  width: 100%;
  justify-content: space-between;
`;

const StyledDiv = styled.div`
display: inline-block;
`;

const StyledHrefA = styled.a`
font-size: 0.9rem;
text-decoration: none;

`;
const minAgeToApply = addYears(new Date(), -18);

const errorMessage = intl.translate({
  id: 'La fecha y hora de finalización debe ser mayor a la fecha y hora de inicio',
});

const today = new Date();

const minStartTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0);
const minEndTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 0);

const initialValues = {
  fullname: '',
  email: '',
  password: '',
  address: { lat: 0, long: 0, address: '' },
  babysitterBirthDate: minAgeToApply,
  check_Lunes: false,
  check_Martes: false,
  check_Miercoles: false,
  check_Jueves: false,
  check_Viernes: false,
  check_Sabado: false,
  check_Domingo: false,
  endTimeAvailabilityMonday: minEndTime,
  startTimeAvailabilityMonday: minStartTime,
  startTimeAvailabilityTuesday: minStartTime,
  endTimeAvailabilityTuesday: minEndTime,
  startTimeAvailabilityWednesday: minStartTime,
  endTimeAvailabilityWednesday: minEndTime,
  startTimeAvailabilityThursday: minStartTime,
  endTimeAvailabilityThursday: minEndTime,
  startTimeAvailabilityFriday: minStartTime,
  endTimeAvailabilityFriday: minEndTime,
  startTimeAvailabilitySaturday: minStartTime,
  endTimeAvailabilitySaturday: minEndTime,
  startTimeAvailabilitySunday: minStartTime,
  endTimeAvailabilitySunday: minEndTime,
  termsAndConditionsAccepted: false,
  experience: '',
};

const validationSchema = Yup.object({
  fullname: Yup.string()
    .matches(
      /^[a-zA-ZñÑ ]*$/,
      'No puede ingresar números o caracteres especiales.',
    )
    .required(intl.translate({ id: 'Debe ingresar un nombre' })),
  experience: Yup.string().required(
    intl.translate({ id: 'Debe ingresar su experiencia' }),
  ),
  address: Yup.object({ lat: Yup.number(), long: Yup.number() }).required(
    intl.translate({
      id: 'Debe indicar una dirección válida',
    }),
  ),

  email: Yup.string()
    .email('Debe ingresar un correo electrónico válido')
    .required(intl.translate({ id: 'Campo requerido' })),
  password: Yup.string()
    .min(
      8,
      intl.translate({
        id: 'Ingrese al menos 8 caracteres',
      }),
    )
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).{8,}$/,
      intl.translate({
        id: 'Debe contener mínimo una mayúscula, una minúscula y carácter especial',
      }),
    )
    .required(intl.translate({ id: 'Campo requerido' })),
  babysitterBirthDate: Yup.date().required(
    intl.translate({ id: 'Debe ingresar fecha de nacimiento' }),
  ),
  check_Lunes: Yup.bool(),
  check_Martes: Yup.bool(),
  check_Miercoles: Yup.bool(),
  check_Jueves: Yup.bool(),
  check_Viernes: Yup.bool(),
  check_Sabado: Yup.bool(),
  check_Domingo: Yup.bool(),
  startTimeAvailabilityMonday: Yup.date().required(errorMessage),
  endTimeAvailabilityMonday: Yup.date().required(errorMessage),
  startTimeAvailabilityThursday: Yup.date().required(errorMessage),
  endTimeAvailabilityThursday: Yup.date().required(errorMessage),
  startTimeAvailabilityTuesday: Yup.date().required(errorMessage),
  endTimeAvailabilityTuesday: Yup.date().required(errorMessage),
  startTimeAvailabilityWednesday: Yup.date().required(errorMessage),
  endTimeAvailabilityWednesday: Yup.date().required(errorMessage),
  startTimeAvailabilityFriday: Yup.date().required(errorMessage),
  endTimeAvailabilityFriday: Yup.date().required(errorMessage),
  startTimeAvailabilitySaturday: Yup.date().required(errorMessage),
  endTimeAvailabilitySaturday: Yup.date().required(errorMessage),
  startTimeAvailabilitySunday: Yup.date().required(errorMessage),
  endTimeAvailabilitySunday: Yup.date().required(),
  termsAndConditionsAccepted: Yup.bool().required(
    intl.translate({
      id: 'Debe aceptar los términos y condiciones',
    }),
  ),
});

type FormValues = {
  email: string;
  password: string;
  fullname: string;
  babysitterBirthDate: Date;
  check_Lunes: boolean;
  check_Martes: boolean;
  check_Miercoles: boolean;
  check_Jueves: boolean;
  check_Viernes: boolean;
  check_Sabado: boolean;
  check_Domingo: boolean;
  startTimeAvailabilityMonday: Date;
  endTimeAvailabilityMonday: Date;
  startTimeAvailabilityThursday: Date;
  endTimeAvailabilityThursday: Date;
  startTimeAvailabilityTuesday: Date;
  endTimeAvailabilityTuesday: Date;
  startTimeAvailabilityWednesday: Date;
  endTimeAvailabilityWednesday: Date;
  startTimeAvailabilityFriday: Date;
  endTimeAvailabilityFriday: Date;
  startTimeAvailabilitySaturday: Date;
  endTimeAvailabilitySaturday: Date;
  startTimeAvailabilitySunday: Date;
  endTimeAvailabilitySunday: Date;
  termsAndConditionsAccepted: boolean;
  address: { lat: number; long: number; address: string };

  experience: string;
};


interface fileProps {
  fileId: string;
  fileUrl: string;
  fileName: string;
}




export const RegisterBabySitterForm: FC = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState<null | object>(null);
  const [buttonTitle, setButtonTitle] = useState<string>('Direccion');
  const [loading, setLoading] = useState<boolean>(false);

  const [long, setLong] = useState(defaultValuesMaps.defautCoords.lng);
  const [lat, setLat] = useState(defaultValuesMaps.defautCoords.lat);
  const dispatch = useDispatch();

  const [file, setFile] = useState<fileProps>();
  const [uploadFile] = useFileUpload(setFile);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current!.click();
  };

  const uploadCV = async (tempFile: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (tempFile) {
        await uploadFile(tempFile);
      }
    } catch (e) {
      toast.error(intl.translate({ id: 'No se pudo guardar el archivo' }));
    }
  };




  const handleClickDownload = async () => {
    const key = file!.fileName;
    if (file) {
      const response: any = await http.get(`/storage/uploads/${key}`, {
        responseType: 'blob',
      });

      const file = new Blob([response], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(file);
      const alink = document.createElement('a');

      alink.href = fileURL;
      alink.download = key;
      alink.click();
    }
  };

  const handleCloseClick = async () => {
    setFile(undefined);
  };

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    if (loading) {
      return;
    }

    if (
      values.check_Lunes ||
      values.check_Martes ||
      values.check_Miercoles ||
      values.check_Jueves ||
      values.check_Viernes ||
      values.check_Sabado ||
      values.check_Domingo
    ) {
      if (values.address !== initialValues.address) {

        if (values.termsAndConditionsAccepted) {
          try {
            setLoading(true);
            if (values.babysitterBirthDate <= minAgeToApply) {
              if (
                values.startTimeAvailabilityMonday >
                values.endTimeAvailabilityMonday ||
                values.startTimeAvailabilityTuesday >
                values.endTimeAvailabilityTuesday ||
                values.startTimeAvailabilityWednesday >
                values.endTimeAvailabilityWednesday ||
                values.startTimeAvailabilityThursday >
                values.endTimeAvailabilityThursday ||
                values.startTimeAvailabilityFriday >
                values.endTimeAvailabilityFriday ||
                values.startTimeAvailabilitySaturday >
                values.endTimeAvailabilitySaturday ||
                values.startTimeAvailabilitySunday >
                values.endTimeAvailabilitySunday
              ) {
                toast.warning(
                  intl.translate({
                    id: 'Cierre debe ser posterior al inicio de disponibilidad',
                  }),
                );
                helpers.setFieldError(
                  'toSchedule',
                  intl.translate({
                    id: 'Debe ser posterior al inicio de disponibilidad',
                  }),
                );
              } else {
                try {
                  const response = await registerBabysitter(values, file?.fileId);

                  dispatch(setAuthTokenAction(response.token));
                  dispatch(setUserDataAction(response.user));
                  requestForToken();
                  toast.success(
                    intl.translate({
                      id: 'La solicitud para la creación de su cuenta de niñera se envió correctamente. Nos contactaremos cuando haya sido aprobada',
                    }),
                  );
                  helpers.resetForm();
                  navigate(AppRoute.HOME);
                } catch (error) {
                  toast.warn(
                    intl.translate({
                      id: 'Hubo un error al crear su cuenta, correo ya registrado',
                    }),
                  );
                }
              }
            } else {
              helpers.setFieldError(
                'babysitterBirthDate',
                intl.translate({
                  id: 'Lo sentimos, para postularse debe ser mayor de edad',
                }),
              );
            }
          } catch (error) {
            genericErrorHandler(error);
          } finally {
            setLoading(false);
          }
        } else {
          toast.warn(
            intl.translate({ id: 'Debe aceptar los términos y condiciones' }),
          );
        }
      }
    } else {
      toast.warn(intl.translate({ id: 'Debe seleccionar mínimo un día' }));
    }
  };

  return (
    <Form
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <StyledCardBody>
          <StyledRightSectionAlign>
            <>
              <StyledInputDiv>
                <StyledRightLabel>
                  <StyledLabelP>
                    {intl.translate({
                      id: 'Nombre y Apellido',
                    })}
                  </StyledLabelP>
                </StyledRightLabel>
                <TextInput
                  label={intl.translate({
                    id: 'Nombre y Apellido',
                  })}
                  id="fullname"
                  name="fullname"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.fullname}
                  error={formik.errors.fullname}
                  fullWidth
                  required
                />
              </StyledInputDiv>
              <StyledInputDiv>
                <StyledRightLabel>
                  <StyledLabelP>
                    {intl.translate({
                      id: 'Mail',
                    })}
                  </StyledLabelP>
                </StyledRightLabel>
                <TextInput
                  label={intl.translate({
                    id: 'Dirección de correo electrónico',
                  })}
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.errors.email}
                  fullWidth
                  required
                />
              </StyledInputDiv>
              <StyledInputDiv>
                <StyledRightLabel>
                  <StyledLabelP>
                    {intl.translate({
                      id: 'Contraseña',
                    })}
                  </StyledLabelP>
                </StyledRightLabel>
                <TextInput
                  label={intl.translate({ id: 'Contraseña' })}
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={formik.errors.password}
                  fullWidth
                  required
                />
              </StyledInputDiv>
              <StyledInputDiv>
                <StyledRightLabel>
                  <StyledLabelP>
                    {intl.translate({
                      id: 'Dirección',
                    })}
                  </StyledLabelP>
                </StyledRightLabel>
                <AddressModal
                  setLong={setLong}
                  setLat={setLat}
                  setAddress={(address: {
                    lat: number;
                    long: number;
                  }) => {
                    formik.setFieldValue('address', address);
                  }}
                />


              </StyledInputDiv>

              <StyledInputDiv>
                <StyledRightLabel>
                  <StyledLabelP>
                    {intl.translate({
                      id: 'Fecha de nacimiento',
                    })}
                  </StyledLabelP>
                </StyledRightLabel>
                <DatePicker
                  fullWidth
                  name="babysitterBirthDate"
                  label={intl.translate({
                    id: 'Fecha de nacimiento',
                  })}
                  maxDate={minAgeToApply}
                  value={formik.values.babysitterBirthDate}
                  onChange={formik.handleChange}
                  error={formik.errors.babysitterBirthDate}
                />
              </StyledInputDiv>
              <StyledInputDiv>
                <StyledRightLabel>
                  <StyledLabelP>
                    {intl.translate({
                      id: 'Experiencia',
                    })}
                  </StyledLabelP>
                </StyledRightLabel>
                <TextInput
                  label={intl.translate({
                    id: 'Resumen de formación profesional',
                  })}
                  id="experience"
                  name="experience"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.experience}
                  error={formik.errors.experience}
                  fullWidth
                  required
                />
              </StyledInputDiv>
              <StyledButtonDiv>
                <Button variant="outlined" onClick={() => handleClick()}>
                  <input
                    accept='application/pdf'
                    ref={hiddenFileInput}
                    onChange={(e) => uploadCV(e)}
                    type="file"
                    hidden
                  />
                  {intl.translate({
                    id: 'Subir CV',
                  })}
                </Button>
                {file ? (
                  <>
                    <Button onClick={handleClickDownload}>
                      {intl.translate({
                        id: 'Descargar CV',
                      })}
                    </Button>
                    <Button onClick={handleCloseClick}>
                      <CrossIcon color={'warning'} />
                    </Button>
                  </>
                ) : null}
              </StyledButtonDiv>
              <StyledRightLabel>
                <StyledAboutMeLabelP>
                  {intl.translate({
                    id: 'No se preocupe, puede realizar cambios después de registrarse',
                  })}
                </StyledAboutMeLabelP>
              </StyledRightLabel>

              <StyledRightLabel>
                <StyledAboutMeLabelP>
                  {intl.translate({
                    id: 'Disponibilidad horaria:',
                  })}
                </StyledAboutMeLabelP>
                <BabysitterSchedule formik={formik} />
                <StyledCheckboxAlign v-center h-center>
                  <FormControlLabel
                    control={<StyledCheckBox
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 48 },
                      }}
                      name="termsAndConditionsAccepted"
                      value={formik.values.termsAndConditionsAccepted}
                      onChange={formik.handleChange}

                    />}
                    label={<StyledDiv><StyledHrefA href={pdfUrl} target="_blank" rel="noopener noreferrer">
                      <StyledTermsConditionsP>
                        {intl.translate({
                          id: 'Estoy de acuerdo con los Términos de Uso',
                        })}
                      </StyledTermsConditionsP> </StyledHrefA></StyledDiv>}
                  />
                </StyledCheckboxAlign>
                <StyledTextP color="light">
                  {intl.translate({
                    id: 'Para obtener detalles sobre la recopilación y el uso de su información, consulte nuestraPolítica de privacidad.',
                  })}
                </StyledTextP>
                <StyledSpaceDiv />
                {loading ? (
                  <Loading
                    fullWidth
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                  >
                    {intl.translate({ id: 'Cargando...' })}
                  </Loading>
                ) : (
                  <Button
                    type="submit"
                    color="primary"
                    fullWidth
                    variant="filled"
                  >
                    {intl.translate({ id: 'Finalizar' })}
                  </Button>
                )}

                <StyledBottomNavLinkDiv>
                  <Text.p color="light">
                    {intl.translate({ id: '¿Ya tienes una cuenta?' })}
                  </Text.p>
                  <LoginButton />
                </StyledBottomNavLinkDiv>
              </StyledRightLabel>
            </>
          </StyledRightSectionAlign>
        </StyledCardBody>
      )}

    </Form>

  );
};
