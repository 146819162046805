
enum MimeType {
    PDF = 'application/pdf',
}


export const checkFileType = (file: any) => {

    if (!file) {
        return null;
    }

    const fileType = file.target.files[0].type;

    if (fileType === MimeType.PDF) {
        return file;
    } else {
        return null;
    }
};
