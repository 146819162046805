import { DesignContext } from 'design/DesignContext';
import { isColor } from 'design/helpers/type.helpers';
import { ColorProp } from 'design/types/color-prop';
import { useContext, useMemo } from 'react';

export function useIconColor(color?: ColorProp, invert = false) {
  const { theme } = useContext(DesignContext);

  const _color = useMemo(() => {
    if (!color) {
      return color;
    }

    if (isColor(color)) {
      return color.main;
    }

    return theme.palette[color][invert ? 'invert' : 'main'];
  }, [color, theme]);

  return _color;
}
