import { useIconColor } from 'design/hooks/use-icon-color.hook';
import { IconProps } from 'design/types/icon-props';
import { FC } from 'react';

export const OtherUsersTestimonials: FC<
  IconProps & { width?: number | string }
> = ({ color, width = '100%', className, invert = false }) => {
  const _color = useIconColor(color, invert);

  return (
    <svg
      width={width}
      color={_color}
      viewBox="0 0 987 1022"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 1015.57L5.63884 1021.51H0V1015.57Z" fill="#1280FF" />
      <path
        d="M436.377 928.215L436.147 928.701L436.032 929.186C436.032 929.065 436.032 928.822 435.917 928.701H94.0195C94.0195 928.579 94.0195 928.337 94.1346 928.215H436.377Z"
        fill="currentColor"
      />
      <path
        d="M436.032 929.064C436.032 928.942 436.032 928.7 435.917 928.578H94.0193C94.0193 928.457 94.0193 928.214 94.1343 928.093C98.2772 900.673 110.36 875.437 129.463 855.419C151.904 831.76 181.364 818.05 212.78 816.594H218.419C252.137 816.594 283.783 830.425 307.489 855.54C326.477 875.437 338.676 900.673 342.819 927.972H436.147C433.155 894.243 423.143 861.485 406.802 832.245C389.425 801.186 365.374 774.858 336.719 755.446C357.779 727.055 368.941 692.235 368.251 655.715C367.56 614.1 351.68 575.155 323.6 545.915C295.406 516.797 258.236 500.66 218.649 500.66C204.61 500.66 191.03 502.723 177.911 506.605C153.975 513.642 131.995 526.988 113.813 545.915C102.305 557.805 92.9836 571.272 85.7336 585.953C75.2615 607.306 69.5076 631.086 69.0473 655.715C68.4719 692.113 79.5194 727.055 100.579 755.446C71.9242 774.858 47.8729 801.186 30.4961 832.245C14.0399 861.485 4.14312 894.122 1.15109 927.972V928.457C0.575698 935.373 0.230469 942.288 0.230469 949.325V1015.57L5.8693 1021.51H344.545V1022H437.068V949.568C436.953 942.652 436.607 935.858 436.032 929.064ZM177.911 616.648C188.729 605.244 202.998 599.056 218.189 599.056C233.379 599.056 247.764 605.244 258.581 616.648C269.168 627.81 275.268 643.34 275.268 659.112C275.268 675.128 269.399 690.293 258.581 701.698C247.764 712.981 233.379 719.29 218.304 719.29C203.113 719.29 188.729 712.981 177.911 701.698C167.209 690.293 161.225 675.249 161.225 659.234C161.225 643.34 167.324 627.81 177.911 616.648Z"
        fill="currentColor"
      />
      <path
        d="M344.315 1021.51V1022H6.09996L5.63965 1021.51H344.315Z"
        fill="currentColor"
      />
      <path
        d="M436.146 928.7L436.031 929.185C436.031 929.064 436.031 928.821 435.916 928.7H436.146Z"
        fill="currentColor"
      />
      <path
        d="M399.783 122V215.3H204.149C190.34 215.3 177.681 228.767 177.681 243.69V436.721C165.598 440.239 149.602 446.184 132.686 456.982C109.9 471.541 94.5949 488.648 85.5037 500.781C85.5037 481.369 85.3887 461.835 85.3887 442.423C85.849 383.094 86.3093 285.427 86.7696 226.098C86.7696 166.163 143.848 122 202.884 122H399.783V122Z"
        fill="currentColor"
      />
      <path
        d="M774.707 832.852C774.707 847.775 762.163 861.242 748.239 861.242H397.25V954.542H749.39C808.425 954.542 857.333 909.166 865.619 850.444H867V832.852V563.75V505.392H866.885H774.707V832.852Z"
        fill="currentColor"
      />
      <path
        d="M953.194 36.1014C931.674 13.5347 903.135 0.795387 872.869 0.552734H458.818C434.881 0.674061 411.981 8.31763 392.418 22.8768C372.854 37.436 358.354 57.6975 350.299 81.4775L349.839 82.9334L343.164 415.246C343.279 447.641 355.247 478.215 377.112 501.024C398.862 523.834 427.747 536.33 458.242 536.33H481.373V632.906C481.373 652.682 492.536 670.396 509.797 678.039C515.551 680.466 521.42 681.679 527.519 681.679C539.948 681.679 551.571 676.583 560.202 667.363L684.371 536.209H837.771L908.774 529.9L910.27 529.415C932.71 520.922 951.928 505.635 965.738 485.009C979.547 464.384 986.797 440.24 986.797 415.004V122C986.912 89.485 974.944 59.0321 953.194 36.1014ZM894.159 414.64C894.044 427.743 883.917 438.541 871.488 438.541H665.038C652.725 438.541 641.102 443.515 632.356 452.736L573.436 515.098V487.436C573.436 460.623 552.722 438.784 527.289 438.784H458.818C446.274 438.784 436.147 427.986 436.147 414.882V121.879C436.032 115.449 438.449 109.504 442.707 104.893C446.965 100.404 452.719 97.8565 458.818 97.8565H871.604C884.032 97.8565 894.274 108.655 894.274 121.758L894.159 414.64Z"
        fill="currentColor"
      />
      <path
        d="M630.86 390.86C618.661 390.86 606.808 385.643 598.177 376.543L529.36 303.99C520.615 294.769 515.781 282.515 515.781 269.533C515.781 256.552 520.615 244.298 529.36 235.077C538.106 225.856 549.729 220.76 562.043 220.76C574.356 220.76 585.979 225.856 594.725 235.077L598.177 238.717L630.86 273.295L735.581 162.524C744.327 153.303 755.95 148.207 768.263 148.207C780.576 148.207 792.199 153.303 800.945 162.402C818.897 181.208 819.128 212.025 801.521 231.073L663.657 376.543C655.026 385.643 643.058 390.86 630.86 390.86Z"
        fill="currentColor"
      />
    </svg>
  );
};
