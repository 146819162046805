import {
  add,
  format as formatDateFns,
  intervalToDuration,
  isBefore,
  sub,
} from 'date-fns';
import { es } from 'date-fns/locale';

export enum DateFormat {
  DATE_AND_TIME = 'Pp',
}

export const minStartTime = new Date(0, 0, 0, 10, 0);
export const minEndTime = new Date(0, 0, 0, 18, 0);

export function checkIfDateIsBeforeAnother(date1: Date, date2: Date) {
  return isBefore(date1, date2);
}
export function getTimePlusOne(date: Date) {
  return date.getTime() + 60 * 60 * 1000
}

export function getTimeMinusOne(date: Date) {
  return date.getTime() - 60 * 60 * 1000
}

export function formatDate(
  date: Date,
  format = DateFormat.DATE_AND_TIME,
): string {
  return formatDateFns(date, format, { locale: es });
}

export function getDay(date: Date) {
  return date.getUTCDate() + 1;
}

export function getMonth(date: Date) {
  return date.getUTCMonth() + 1;
}

export function getYear(date: Date) {
  return date.getUTCFullYear();
}

export function calcHours(startDate: Date, endDate: Date) {
  const getStartDate = new Date(startDate);
  const getEndDate = new Date(endDate);

  const hoursPerDay = Math.abs(getStartDate.getHours() - getEndDate.getHours()) +
    Math.abs(getStartDate.getMinutes() - getEndDate.getMinutes()) / 60;

  const daysDifference = Math.ceil((getEndDate.getTime() - getStartDate.getTime()) / (1000 * 3600 * 24));

  const totalHours = hoursPerDay * daysDifference;
  return totalHours;
}


export function getFullDate(data: Date) {
  const getDate = new Date(data);
  return getDate.toUTCString();
}

export function getDayMonth(startTime: Date) {
  const getStartDate = new Date(startTime);
  return `${getStartDate.getUTCDate()}/${getStartDate.getUTCMonth() + 1}`;
}

export function getAgeAndBirthYear(age: Date) {
  const birthDate = new Date(age);
  const today = new Date();
  const ageInYears = today.getFullYear() - birthDate.getFullYear();
  const ageInMonths =
    today.getMonth() - birthDate.getMonth() + 12 * (ageInYears - 1);
  const ageString =
    ageInYears > 1 ? `${+ageInYears} años` : `${+ageInMonths} meses`;
  return ageString;
}
export function getDayMonthyear(startTime: Date) {
  const getStartDate = new Date(startTime);
  const date = `${getStartDate.getUTCDate()} / ${getStartDate.getUTCMonth() + 1
    } / ${getStartDate.getUTCFullYear()} `;
  return date;
}
export function getTime(time: Date) {
  const getTime = new Date(time);
  const date = `${getTime.getUTCHours()} : ${getTime.getUTCMinutes()}hs`;
  return date;
}

export function addYears(date: Date, yearsToAdd: number): Date {
  return add(date, {
    years: yearsToAdd,
  });
}

export function addHours(date: Date, hoursToAdd: number): Date {
  return add(date, {
    hours: hoursToAdd,
  });
}

export function addMinutes(date: Date, minutesToAdd: number): Date {
  return add(date, {
    minutes: minutesToAdd,
  });
}

export function subtractHours(date: Date, hoursToSubtract: number): Date {
  return sub(date, {
    hours: hoursToSubtract,
  });
}

export function subtractMinutes(date: Date, minutesToSubtract: number): Date {
  return sub(date, {
    minutes: minutesToSubtract,
  });
}

export const calculateAge = (DateToCompare: string): number => {
  const interval = intervalToDuration({
    start: new Date(DateToCompare),
    end: new Date(),
  });

  return interval.years ? interval.years : 0;
};
