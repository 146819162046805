import { FutureServicesStatus } from 'enums/auth-type.enum';
import { PublicError } from 'errors/public.error';
import { getHoursInUTC } from 'helpers/getHoursInUTC';
import { CouponCodeEntity } from 'types/couponCodeEntity.type';
import { http } from 'utilities/http/http';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

interface HourlyPriceResponse {
  success: boolean;
  data: number[];
}
export async function declineServiceByParent(serviceId: string): Promise<void> {
  try {
    await http.patch(`/services/updateStatus/${serviceId}`, {
      status: FutureServicesStatus.DECLINED_BY_PARENT,
    });
  } catch (error) {
    logger.warn('Failed request to declineServiceByParent');
  }
}
export async function getCouponCode(couponCode: string) {
  try {
    const response = await http.get<{
      success: boolean;
      data: CouponCodeEntity;
    }>(`/payment/coupon-code/${couponCode}`);
    return response.data;
  } catch (error) {
    throw new PublicError(intl.translate({ id: 'El cupón no existe' }));
  }
}

export async function getHourlyPrices(
  startServiceDateAndTime: Date,
  endServiceDateAndTime: Date,
): Promise<any> {
  try {
    const UtcStartDate = getHoursInUTC(startServiceDateAndTime);
    const UtcEndDate = getHoursInUTC(endServiceDateAndTime);
    const requestData = {
      UtcStartDate,
      UtcEndDate,
    };
    const response = await http.post<{
      success: boolean;
      data: HourlyPriceResponse;
    }>('/services/hourly-price', requestData);

    return response.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'Unknown error';
    throw new PublicError(
      intl.translate({
        id: `Error al retornar  precios de las horas: ${errorMessage}`,
      }),
    );
  }
}
