import axios from 'axios';
import { PromiseProps, UploadProps } from 'design/types/uploadFile-props';
import { StorgeFileProps } from 'types/storage-props';
import { http } from 'utilities/http/http';

const uploadFile = async (
  file: UploadProps,
  onProgress: React.Dispatch<React.SetStateAction<number>>,
): Promise<PromiseProps> => {
  try {
    const response = await http.post<StorgeFileProps>('/storage', {
      originalName: file?.name,
      mimeType: file?.type,
    });
    if (!response) {
      throw { response };
    }
    const { link, savedFile } = response.data;

    const config = {
      onUploadProgress: (ev: { loaded: number; total: number }) =>
        onProgress(Math.round((ev.loaded / ev.total) * 100)),
      headers: {
        'Content-Type': file?.type,
        'x-amz-acl': 'private',
      },
    };

    try {
      await axios.put(link.link, file, config);
    } catch (error: any) {
      throw new Error(error.message);
    }

    const dataFile = {
      fileId: response.data.savedFile.id,
      fileUrl: link,
      fileName: savedFile.fileName,
    };
    return dataFile;
  } catch (error: any) {
    throw new Error(`Error when trying to post file ${error.message} `);
  }
};

export default uploadFile;
