import { Text } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  font-weight: 700;
`;

const StyledP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  padding-left: 1rem;
`;

export const LoginButton: FC = () => {
  return (
    <StyledNavLink to={AppRoute.LOGIN}>
      <StyledP>{intl.translate({ id: 'Iniciar sesión' })}</StyledP>
    </StyledNavLink>
  );
};
