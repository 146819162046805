import { HomeContainer } from 'components/Container/HomeContainer';
import { BackgroundGeneral } from 'components/background/BackgroundGeneral';
import { MobileBackgroundCheckAvailabilityForm } from 'components/background/MobileBackgroundCheckAvailabilityForm';
import { Text } from 'design';
import { FC } from 'react';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { intl } from 'utilities/i18n/intl.utility';
import videoIG2 from '../views/Home/assets/Cliente 1.mp4';
import videoTestimonio from '../views/Home/assets/testimonioMarcela.mp4';
import videoIG from '../views/Home/assets/video_ig.mp4';
import videoUrl from '../views/Home/assets/video_influencer.mp4';
import { BabySitterList } from './BabySitterList';

const StyledCarouselSectionDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  padding: 2rem;
  overflow: hidden;
`;

const StyledH3 = styled(Text.h3)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 2.2rem;
  font-weight: 700;
  padding: 2rem 0;
  line-height: 1.5rem;
  align-self: center;
  margin-bottom: 0.5rem;
  text-decoration: upercase;
  padding-top: 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 2.5rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 3.5rem;
    align-self: center;
  }
`;

const StyledCardContainerDiv = styled.div`
  margin: 4rem 0;
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: row;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 1rem;
  }
`;

const StyledBannerFirstVideo = styled.video`
  height: 30rem;
  object-fit: contain;
  object-position: center;
  align-self: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
    margin-top: 1rem;
  }
  @media (min-width: 780px) and (max-width: 890px) {
    margin-top: 4rem;
  }
`;
const StyledBannerDiv = styled.div`
  display: flex;
  align-items: space-between;
  justify-content: space-around;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

const StyledInfluencersDiv = styled.div`
  padding: 2rem 0;
  gap: 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
  }
`;

export const CommentsCarouselView: FC = () => {
  return (
    <BackgroundGeneral>
      <MobileBackgroundCheckAvailabilityForm>
        <HomeContainer>
          <StyledCarouselSectionDiv>
            <StyledBannerDiv>
              <StyledH3> {intl.translate({ id: 'Testimonios' })}</StyledH3>
              <StyledInfluencersDiv>
                <StyledBannerFirstVideo src={videoIG} controls />
                <StyledBannerFirstVideo src={videoUrl} controls />

              </StyledInfluencersDiv>
              <StyledInfluencersDiv>
                <StyledBannerFirstVideo src={videoIG2} controls />
                <StyledBannerFirstVideo src={videoTestimonio} controls />
              </StyledInfluencersDiv>

              <StyledCardContainerDiv>
                <BabySitterList />
              </StyledCardContainerDiv>
            </StyledBannerDiv>
          </StyledCarouselSectionDiv>
        </HomeContainer>
      </MobileBackgroundCheckAvailabilityForm>
    </BackgroundGeneral>
  );
};
