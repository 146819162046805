import { AuthType, FutureServicesStatus } from 'enums/auth-type.enum';
import { getHoursInUTC } from 'helpers/getHoursInUTC';
import { getOffsetInTime } from 'helpers/getOffsetInTime';
import {
  ServiceDataProps,
  ServiceUserBabysitterProps,
} from 'types/service-props';
import { User, servicesMercadoPagoProps } from 'types/user';
import { http } from 'utilities/http/http';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

export type Data = {
  token: string;
  user: User;
  id: string;
  email: string;
};

type Offset = {
  offset: number;
};

type TokenResponse = {
  success: boolean;
  data: Data;
  message: string;
  statusCode: number;
};

type BabysitterData = {
  email: string;
  password: string;
  fullname: string;
  babysitterBirthDate: Date;
  check_Lunes: boolean;
  check_Martes: boolean;
  check_Miercoles: boolean;
  check_Jueves: boolean;
  check_Viernes: boolean;
  check_Sabado: boolean;
  check_Domingo: boolean;
  startTimeAvailabilityMonday: Date;
  endTimeAvailabilityMonday: Date;
  startTimeAvailabilityThursday: Date;
  endTimeAvailabilityThursday: Date;
  startTimeAvailabilityTuesday: Date;
  endTimeAvailabilityTuesday: Date;
  startTimeAvailabilityWednesday: Date;
  endTimeAvailabilityWednesday: Date;
  startTimeAvailabilityFriday: Date;
  endTimeAvailabilityFriday: Date;
  startTimeAvailabilitySaturday: Date;
  endTimeAvailabilitySaturday: Date;
  startTimeAvailabilitySunday: Date;
  endTimeAvailabilitySunday: Date;
  termsAndConditionsAccepted: boolean;
  address: { lat: number; long: number; address: string };
  experience: string;
};

export async function login(email: string, password: string): Promise<Data> {
  const authType = AuthType.EMAIL;

  try {
    const okidsLoginResponse = await http.post<TokenResponse>('/user/signin', {
      email,
      password,
      authType,
    });
    if (okidsLoginResponse.success) {
      return okidsLoginResponse.data;
    } else {
      throw new Error(
        intl.translate({
          id: 'Hubo un error al iniciar sesión, por favor intente de nuevo',
        }),
      );
    }
  } catch (error: any) {
    if (error.status === 404) {
      throw new Error(
        intl.translate({
          id: 'El mail ingresado no se encuentra registrado',
        }),
      );
    } else if (error.status === 401) {
      throw new Error(
        intl.translate({
          id: 'Email o contraseña incorrecto',
        }),
      );
    }
    throw new Error(
      intl.translate({
        id: 'Hubo un error al iniciar sesión, por favor intente de nuevo',
      }),
    );
  }
}

export async function registerEmail(
  name: string,
  lastname: string,
  email: string,
  ci: string,
  password: string,
  type: string,
  phone: string,
  authType: string,
  picture: string,
): Promise<Data> {
  const newUser = await http.post<TokenResponse>('/user/register', {
    name,
    lastname,
    email,
    ci,
    password,
    type,
    phone,
    authType,
    picture,
  });
  if (newUser.success) {
    return newUser.data;
  } else {
    throw new Error(intl.translate({ id: 'signup error' }));
  }
}

export async function registerFacebook(
  accessToken: string,
  authType: string,
  ci: string,
  email: string,
  id: string,
  lastname: string,
  name: string,
  password: string,
  phone: string,
  picture: string,
  type: string,
): Promise<Data> {
  try {
    const response = await http.post<TokenResponse>('/facebook/register', {
      accessToken,
      authType,
      ci,
      email,
      id,
      lastname,
      name,
      password,
      phone,
      picture,
      type,
    });

    if (response.success) {
      return response.data;
    } else {
      throw new Error(intl.translate({ id: 'signup error' }));
    }
  } catch (error) {
    logger.warn(
      intl.translate({
        id: 'Hubo un error al crear su cuenta, por favor intente de nuevo',
      }),
      {
        path: '/facebook/register',
      },
    );
    throw new Error(
      intl.translate({
        id: 'Hubo un error al crear su cuenta, por favor intente de nuevo',
      }),
    );
  }
}

export async function loginFacebook(
  accessToken: string,
  id: string,
  email: string,
): Promise<Data> {
  try {
    const response = await http.post<TokenResponse>('/facebook/login', {
      accessToken,
      id,
      email,
    });
    if (response.success) {
      return response.data;
    } else {
      throw new Error(intl.translate({ id: 'signup error' }));
    }
  } catch (error) {
    logger.warn(
      intl.translate({
        id: 'Hubo un error al iniciar sesión, por favor intente de nuevo',
      }),
      {
        path: '/facebook/register',
      },
    );
    throw new Error(
      intl.translate({
        id: 'Hubo un error al iniciar sesión, por favor intente de nuevo',
      }),
    );
  }
}

export async function registerBabysitter(
  values: BabysitterData,
  file: any,
): Promise<Data> {
  const dateOfRegister = getHoursInUTC(new Date());
  const completedServices = 0;
  const paymentMethod = 'mercadoPago';
  const rate = 3;
  const status = 'disable';
  const experience = values.experience;
  const name = values.fullname;
  const type = 'babysitter';
  const authType = 'email';
  const lastName = values.fullname;
  const dateOfBirth = getHoursInUTC(values.babysitterBirthDate);
  const address = values.address.address;
  const locationLat = values.address.lat;
  const locationLng = values.address.long;
  const check_Lunes = values.check_Lunes;
  const check_Martes = values.check_Martes;
  const check_Miercoles = values.check_Miercoles;
  const check_Jueves = values.check_Jueves;
  const check_Viernes = values.check_Viernes;
  const check_Sabado = values.check_Sabado;
  const check_Domingo = values.check_Domingo;
  const startTimeAvailabilityMonday = getHoursInUTC(
    values.startTimeAvailabilityMonday,
  );
  const endTimeAvailabilityMonday = getHoursInUTC(
    values.endTimeAvailabilityMonday,
  );
  const startTimeAvailabilityThursday = getHoursInUTC(
    values.startTimeAvailabilityThursday,
  );
  const endTimeAvailabilityThursday = getHoursInUTC(
    values.endTimeAvailabilityThursday,
  );
  const startTimeAvailabilityTuesday = getHoursInUTC(
    values.startTimeAvailabilityTuesday,
  );
  const endTimeAvailabilityTuesday = getHoursInUTC(
    values.endTimeAvailabilityTuesday,
  );
  const startTimeAvailabilityWednesday = getHoursInUTC(
    values.startTimeAvailabilityWednesday,
  );
  const endTimeAvailabilityWednesday = getHoursInUTC(
    values.endTimeAvailabilityWednesday,
  );
  const startTimeAvailabilityFriday = getHoursInUTC(
    values.startTimeAvailabilityFriday,
  );
  const endTimeAvailabilityFriday = getHoursInUTC(
    values.endTimeAvailabilityFriday,
  );
  const startTimeAvailabilitySaturday = getHoursInUTC(
    values.startTimeAvailabilitySaturday,
  );
  const endTimeAvailabilitySaturday = getHoursInUTC(
    values.endTimeAvailabilitySaturday,
  );
  const startTimeAvailabilitySunday = getHoursInUTC(
    values.startTimeAvailabilitySunday,
  );
  const endTimeAvailabilitySunday = getHoursInUTC(
    values.endTimeAvailabilitySunday,
  );

  try {
    const response = await http.post<TokenResponse>(
      '/user/register-babysitter',
      {
        locationLat,
        locationLng,
        name,
        email: values.email,
        dateOfBirth,
        check_Lunes,
        check_Martes,
        check_Miercoles,
        check_Jueves,
        check_Viernes,
        check_Sabado,
        check_Domingo,
        startTimeAvailabilityMonday,
        endTimeAvailabilityMonday,
        startTimeAvailabilityThursday,
        endTimeAvailabilityThursday,
        startTimeAvailabilityTuesday,
        endTimeAvailabilityTuesday,
        startTimeAvailabilityWednesday,
        endTimeAvailabilityWednesday,
        startTimeAvailabilityFriday,
        endTimeAvailabilityFriday,
        startTimeAvailabilitySaturday,
        endTimeAvailabilitySaturday,
        startTimeAvailabilitySunday,
        endTimeAvailabilitySunday,
        completedServices,
        dateOfRegister,
        paymentMethod,
        rate,
        status,
        experience,
        password: values.password,
        type,
        authType,
        lastName,
        address,
        file,
      },
    );
    if (response.success) {
      return response.data;
    } else {
      throw new Error(intl.translate({ id: 'signup error' }));
    }
  } catch (error) {
    logger.warn(
      intl.translate({
        id: 'Hubo un error al crear su cuenta, correo ya registrado',
      }),
      {
        path: '/user/register-babysitter',
      },
    );
    throw new Error(
      intl.translate({
        id: 'Hubo un error al crear su cuenta, por favor intente de nuevo',
      }),
    );
  }
}

export interface serviceResponse {
  success: boolean;
  data: servicesMercadoPagoProps;
}

export interface GetServiceResponse {
  success: boolean;
  data: ServiceUserBabysitterProps[];
}

export type valuesServiceData = {
  dni: string;
  healthInsurance: string;
  secondEmergencyContact: string;
  otherPeopleHouse: string;
  securityCompany: string;
  mobileEmergency: string;
};

export async function createService(
  userId: string,
  babysitterId: string,
  serviceData: ServiceDataProps,
  status: FutureServicesStatus,
  values: valuesServiceData,
  couponCodeId?: string,
): Promise<servicesMercadoPagoProps> {
  try {
    const dateRanges = serviceData.dateRanges?.map((range) => {
      const startTime = new Date(range.start);
      const endTime = new Date(range.end);
      return {
        startTime: getHoursInUTC(startTime),
        endTime: getHoursInUTC(endTime),
      };
    });
    const serviceResponse = await http.post<serviceResponse>(
      `/services/create-service/${userId}`,
      {
        location: serviceData.address,
        lat: serviceData.locationLat,
        long: serviceData.locationLng,
        dateRanges,
        status,
        babysitterId: babysitterId,
        numberOfChildren: serviceData.numberOfChildren,
        dni: values.dni,
        healthInsurance: values.healthInsurance,
        secondEmergencyContact: values.secondEmergencyContact,
        otherPeopleHouse: values.otherPeopleHouse,
        securityCompany: values.securityCompany,
        mobileEmergency: values.mobileEmergency,
        couponCode: couponCodeId,
      },
    );

    if (serviceResponse.success) {
      return serviceResponse.data;
    } else {
      throw new Error(
        intl.translate({ id: 'error cuando se crea el servicio' }),
      );
    }
  } catch (error) {
    throw new Error(
      intl.translate({
        id: 'Hubo un error al crear el servicio, por favor intente nuevamente',
      }),
    );
  }
}

export const getAllPreviousServices = async () => {
  return await http
    .get<GetServiceResponse>(
      `/services/previous-all-services?offset=${getOffsetInTime()}`,
    )
    .then(({ data }) => data);
};

export const getAllCurrentServices = async () => {
  return await http
    .get<GetServiceResponse>(
      `/services/all-current-services?offset=${getOffsetInTime()}`,
    )
    .then(({ data }) => data);
};
