import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import GuaranteesBackground from './assets/okids-logo.svg';

const StyledBackgroundGeneralDiv = styled.div`
  background-image: url(${GuaranteesBackground});
  background-repeat: no-repeat;
  background-size: 100vw;
  background-position: top 0 left -31vw;
  background-color: white;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-image: none;
  }
`;

export const BackgroundGeneral: FC<FcDefaultProps> = ({ children }) => {
  return <StyledBackgroundGeneralDiv>{children}</StyledBackgroundGeneralDiv>;
};
