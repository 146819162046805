import { useIconColor } from 'design/hooks/use-icon-color.hook';
import { IconProps } from 'design/types/icon-props';
import { FC } from 'react';

export const TickIcon: FC<
  IconProps & { width?: number | string } & { height?: number | string }
> = ({ color, width = '100%', className, invert = false }) => {
  const _color = useIconColor(color);

  return (
    <svg
      className={className}
      width={width}
      color={_color}
      viewBox="0 0 838 810"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M419.2 0.699951C188.2 0.699951 0.399902 182 0.399902 405C0.399902 628 188.2 809.3 419.2 809.3C650.2 809.3 838 628 838 405C838 182 650.2 0.699951 419.2 0.699951Z"
        fill="#1280FF"
      />
      <path
        d="M658.7 277.4C658.7 288.1 654.2 298.4 646.2 305.9L382.5 560.6C374.8 568.1 364.3 572.2 353.3 572.2C342.4 572.2 331.9 568 324.2 560.6L192.3 433.2C184.3 425.8 179.7 415.6 179.6 404.9C179.5 394.2 183.8 383.9 191.7 376.3C199.5 368.7 210.2 364.5 221.3 364.7C232.4 364.8 242.9 369.2 250.6 376.9L353.3 476.1L588 249.4C595.5 242 605.6 238 616.3 237C627.4 237 638.2 241 646.2 248.5C654.2 256 658.7 266.6 658.7 277.4Z"
        fill="#F8F8F8"
      />
    </svg>
  );
};
