import { Button, Text } from 'design';
import { parseDateDdMmYyyy } from 'helpers/parseDate';
import { Align } from 'layout';
import { StarsRating } from 'rating/StarsRating';
import { FC, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import { CommentsModal } from './CommentsModal';
import { ArrowIcon } from './icons/ArrowIcon';



const StyledCardAlignDiv = styled.div`
  display: flex;
  flex-direction: column;

  max-height: 13rem;

  align-self: center;
`;

const StyledAvatarAlign = styled(Align)`
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledNameP = styled(Text.p)`
  line-height: 0.8rem;
  align-self: center;
  self-align: center;
  align-items: center;
`;

const StyledLocationP = styled(Text.p)`
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.3rem;
  max-width: 19rem;
`;

const StyledP = styled(Text.p)`
  font-size: 0.7rem;
  height: 3rem;
  font-weight: 400;
  line-height: 1rem;
  padding: 0.3rem 0 0.3rem 0;
  max-width: 19rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 0.9rem;
    padding: 0.3rem 0 1.3rem 0;
  }
`;

const StyledStarsAlign = styled(Align)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.3rem;
  width: 100%;
`;

const StyledCardAlign = styled(Align)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.3rem;
  width: 100%;
`;

const StyledCardDateServiceAlign = styled(Align)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
`;

const StyledCardDiv = styled.div`
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  max-height: 12rem;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const StyledCardsDiv = styled.div`
  background-size: contain;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

interface Service {
  startTime: string;
  firstNameChild: string;
}

interface IBabySitter {
  id: number;
  name: string;
  location: string;
  src: string;
  alt: string;
  service: Service;
  comment: string;
  rate: number;
  readonly: boolean;
}

const modalDataInit: IBabySitter = {
  id: 0,
  name: '',
  location: '',
  src: '',
  alt: '',
  service: {
    startTime: '',
    firstNameChild: '',
  },
  comment: '',
  rate: 0,
  readonly: false,
};

interface Props {
  testimonials: IBabySitter;
}

export const BabySitterData: FC<Props> = ({ testimonials }) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const [modalData, setModalData] = useState<IBabySitter>(modalDataInit);
  const rating = testimonials.rate / 2;
  return (
    <StyledCardsDiv>
      <StyledCardDiv key={testimonials.id}>
        <StyledCardAlignDiv>
          <StyledAvatarAlign gap={0.5}>
            <StyledCardAlign>
              <StyledNameP color="dark">
                <strong>{testimonials.name}</strong>
              </StyledNameP>
              <StyledLocationP color="light">
                {intl.translate({ id: 'Mamá/Papá de' })} <strong>{testimonials.service.firstNameChild}</strong>
              </StyledLocationP>
            </StyledCardAlign>
          </StyledAvatarAlign>
          <StyledP color="dark">
            {testimonials.comment.substring(0, 175)}...
          </StyledP>
          <StyledCardDateServiceAlign>
            <StyledLocationP color="light">{parseDateDdMmYyyy(new Date(testimonials.service.startTime))}</StyledLocationP>
          </StyledCardDateServiceAlign>
          <StyledStarsAlign>
            <StarsRating initialValue={rating} readonly={true} />

            <Button
              onClick={() => {
                setModalData(testimonials);
                handleOpen();
              }}
              color="secondary"
            >
              <small>{intl.translate({ id: 'leer más' })}</small>
              <ArrowIcon color="secondary" small />
            </Button>
          </StyledStarsAlign>
        </StyledCardAlignDiv>
      </StyledCardDiv>
      <CommentsModal
        open={isOpen}
        alt={modalData.alt}
        location={modalData.location}
        name={modalData.name}
        comment={modalData.comment}
        src={modalData.src}
        date={modalData.service.startTime}
        firstNameChild={modalData.service.firstNameChild}
        onClose={() => setIsOpen(false)}
        rating={modalData.rate / 2}
        readonly={true}
      />
    </StyledCardsDiv>
  );
};