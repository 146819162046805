import { IBabysitterDataResponse } from 'types/babysitter-props';
import { CoordinateCallData, IEmailResponse, IUserResponse } from 'types/user';
import { http } from 'utilities/http/http';
import { intl } from 'utilities/i18n/intl.utility';

export async function getUserDataOrFail(id: string) {
  const user = await http.get<IBabysitterDataResponse>(`/user/${id}`);
  if (user.success) {
    return user.data;
  }
  throw new Error('user not found');
}

export async function updateUserInfo(
  phone: string,
  email: string,
  name: string,
  userId: string,
) {
  const response = await http.patch<IUserResponse>(
    '/user/update-personal-data',
    {
      userId,
      name,
      phone,
      email,
    },
  );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(
      intl.translate({ id: 'No se han podido actualizar los datos' }),
    );
  }
}

export async function recoverPassword(password: string, token: string) {
  try {
    const response = await http.post<any>(`/user/password-change`, {
      password,
      token,
    });
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Unexpected response from server');
    }
  } catch (error: any) {
    throw new Error(intl.translate({ id: 'network.error' }));
  }
}
export async function sendNewPasswordMail(email: string) {
  const response = await http.post<any>('/user/password-change-request', {
    email,
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(
      intl.translate({ id: 'No se ha podido actualizar la contraseña' }),
    );
  }
}

export async function sendPaymentConfirmationByMercadopago(
  serviceId: string,
  userEmail: string,
  userId: string,
) {
  const response = await http.get<IEmailResponse>(
    `/user/send-mail/${serviceId}?userEmail=${userEmail}&userId=${userId}`,
  );

  if (response.success && response.data) {
    return;
  } else {
    throw new Error(
      intl.translate({ id: 'No se ha podido enviar mail/notificación' }),
    );
  }
}

export async function sendCoordinateCallMail(data: CoordinateCallData) {
  const response = await http.post<IEmailResponse>(`/user/coordinate-call`, data);

  if (response.success && response.data) {
    return;
  } else {
    throw new Error(
      intl.translate({ id: 'No se ha podido enviar solicitud de llamada informativa' }),
    );
  }
}
