import { getFutureServices } from 'api/babysitters/babysitters.api';
import { AddressUniqueModal } from 'components/Modal/AddressUniqueModal';
import { Text } from 'design';
import { FutureServicesStatus } from 'enums/auth-type.enum';
import { selectUser } from 'global-state/selectors';
import {
  getAgeAndBirthYear,
  getDayMonthyear
} from 'helpers/date.helpers';
import { getUTCDate } from 'helpers/getUTCDate';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FutureServicesType } from 'types/babysitter-props';
import { intl } from 'utilities/i18n/intl.utility';
import { BabySitterServicesStatus } from './BabySitterServicesStatus';

const StyledTitleTextH6 = styled(Text.h6)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 0.8rem;
`;

const StyledTitleDiv = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  margin-top: 0.5rem;
  justify-content: space-between;
`;

const StyledDataTextLabel = styled(Text.label)`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 400;
`;

const StyledDataTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  font-size: 1rem;
`;

const StyledServicesContentDiv = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: calc(100vw - 4rem);
  }
`;

const StyledDiv = styled.div`
  padding: 0 1rem;
`;

const StyledHr = styled.hr`
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

const StyledNumberSpan = styled.span`
  border-radius: 50%;
  padding: 0.2rem 0.4rem;
  font-size: 0.7rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  margin-right: 0.2rem;
`;

const StyledStepperSpan = styled.span`
  font-size: 0.7rem;
`;

const StyledLocationDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledMapsIconDiv = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledDateDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledTextP = styled(Text.p)`
  padding: 0 2rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledDataCommentP = styled(Text.p)`
  max-width: 100%;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1rem;
  font-weight: bold;
`;

const StyledTextCHildP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.3rem;
  font-weight: 800;
  padding: 0.5rem 0;
`;

const StyledNoServicesDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BabysitterServices: FC = () => {
  const [futureServicesData, setFutureServicesData] = useState<
    FutureServicesType[]
  >([]);
  const [shouldUpdateData, setShouldUpdateData] = useState<boolean>(false);

  const user = useSelector(selectUser);

  const getFutureServicesData = async (id: string) => {
    const response = await getFutureServices(id);
    setFutureServicesData(response);
  };

  useEffect(() => {
    if (user?.babysitter) {
      const id = user!.babysitter.id;
      if (id) {
        getFutureServicesData(id);
      }
    }
  }, [user?.babysitter, user, shouldUpdateData]);

  const weekday = new Array(7);
  weekday[0] = intl.translate({ id: 'Domingo' });
  weekday[1] = intl.translate({ id: 'Lunes' });
  weekday[2] = intl.translate({ id: 'Martes' });
  weekday[3] = intl.translate({ id: 'Miércoles' });
  weekday[4] = intl.translate({ id: 'Jueves' });
  weekday[5] = intl.translate({ id: 'Viernes' });
  weekday[6] = intl.translate({ id: 'Sábado' });

  return (
    <StyledServicesContentDiv>
      {futureServicesData.length !== 0 ? (
        futureServicesData.map((data) => {
          const startTimeDate = getUTCDate(new Date(data.startTime));
          const endTimeDate = getUTCDate(data.endTime);
          const dayOfServiceStart = weekday[startTimeDate.getUTCDate()];
          const dayOfServiceEnd = weekday[endTimeDate.getUTCDate()];
          const getHours = startTimeDate.getUTCHours();
          const getHoursEnd = endTimeDate.getUTCHours();
          const getDateStart = getDayMonthyear(data.startTime);
          const getDateEnd = getDayMonthyear(data.endTime);
          const getAge = getAgeAndBirthYear(data.ageOfChild);
          const getDateOfBirth = getDayMonthyear(data.ageOfChild);
          return (
            <>
              <StyledTitleDiv key={data.id}>
                <StyledTitleTextH6>
                  <strong>
                    {intl.translate({ id: 'Servicios Pendientes' })}
                  </strong>
                </StyledTitleTextH6>
                <StyledStepperSpan>
                  <StyledNumberSpan>
                    {futureServicesData.indexOf(data) + 1}
                  </StyledNumberSpan>
                  {intl.translate({ id: 'de' })} {futureServicesData.length}
                </StyledStepperSpan>
              </StyledTitleDiv>
              <StyledHr />
              <StyledDiv>
                <StyledDataTextLabel>
                  {intl.translate({ id: 'Cliente:' })}
                </StyledDataTextLabel>
                <StyledDataTextP>
                  {data.name} {data.lastname}
                </StyledDataTextP>

                <StyledDataTextLabel>
                  {intl.translate({ id: 'Día:' })}
                </StyledDataTextLabel>
                <StyledDateDiv>
                  <StyledDataTextP>
                    <StyledDataTextP>{dayOfServiceStart}</StyledDataTextP>
                    {getDateStart}
                  </StyledDataTextP>
                  <StyledTextP>{intl.translate({ id: 'al' })}</StyledTextP>
                  <StyledDataTextP>
                    <StyledDataTextP>{dayOfServiceEnd}</StyledDataTextP>
                    {getDateEnd}
                  </StyledDataTextP>
                </StyledDateDiv>
                <StyledDataTextLabel>
                  {intl.translate({ id: 'Horario:' })}
                </StyledDataTextLabel>
                <StyledDataTextP>
                  {intl.translate(
                    {
                      id: '{getHours}hs a {getHoursEnd}hs',
                    },
                    { getHours, getHoursEnd },
                  )}
                </StyledDataTextP>
                <StyledDataTextLabel>
                  {intl.translate({ id: 'Dirección:' })}
                </StyledDataTextLabel>
                <StyledLocationDiv>
                  <StyledDataTextP>{data.location}</StyledDataTextP>
                  <StyledMapsIconDiv>
                    <AddressUniqueModal
                      dataLocationLat={+data.locationLat}
                      dataLocationLong={+data.locationLng}
                    />
                  </StyledMapsIconDiv>
                </StyledLocationDiv>
                <StyledDataTextLabel>
                  {intl.translate({ id: 'Cédula de identidad' })}
                </StyledDataTextLabel>
                <StyledDataTextP>{data.dni}</StyledDataTextP>
                {!data.firstNameChild ||
                  data.status == FutureServicesStatus.DECLINED_BY_BABYSITTER ||
                  data.status ==
                  FutureServicesStatus.DECLINED_BY_BOTH_BABYSITTERS ? null : (
                  <>
                    <StyledTextCHildP>
                      {intl.translate({ id: 'Datos del menor' })}
                    </StyledTextCHildP>
                    <StyledDataTextLabel>
                      {intl.translate({ id: 'Nombre' })}
                    </StyledDataTextLabel>
                    <StyledDataTextP>{data.firstNameChild}</StyledDataTextP>
                    <StyledDataTextLabel>
                      {intl.translate({ id: 'Edad' })}
                    </StyledDataTextLabel>
                    <StyledDataTextP>
                      {getAge} - {getDateOfBirth}
                    </StyledDataTextP>
                    <StyledDataTextLabel>
                      {intl.translate({ id: 'Comentarios a tener en cuenta' })}
                    </StyledDataTextLabel>
                    <StyledDataTextP>{data.comments}</StyledDataTextP>
                    <StyledDataTextLabel>
                      {intl.translate({ id: 'other.children.home' })}
                    </StyledDataTextLabel>
                    <StyledDataCommentP>
                      {data.othersChildrens}
                    </StyledDataCommentP>
                    <StyledDataTextLabel>
                      {intl.translate({ id: 'important.children.comments' })}
                    </StyledDataTextLabel>
                    <StyledDataCommentP>
                      {data.importantChildrenComments}
                    </StyledDataCommentP>
                    <StyledDataTextLabel>
                      {intl.translate({ id: 'pet.house.input' })}
                    </StyledDataTextLabel>
                    <StyledDataCommentP> {data.petHouse}</StyledDataCommentP>

                    <StyledTextCHildP>
                      {intl.translate({ id: 'Emergencia Médica' })}
                    </StyledTextCHildP>
                    <StyledDataTextLabel>
                      {intl.translate({ id: 'medical.emergency.title' })}
                    </StyledDataTextLabel>
                    <StyledDataCommentP>
                      {data.healthInsurance}
                    </StyledDataCommentP>
                    <StyledDataTextLabel>
                      {intl.translate({ id: 'Emergencia Movil' })}
                    </StyledDataTextLabel>
                    <StyledDataCommentP>
                      {data.mobileEmergency}
                    </StyledDataCommentP>
                    <StyledTextCHildP>
                      {intl.translate({ id: 'Seguridad' })}
                    </StyledTextCHildP>
                    <StyledDataTextLabel>
                      {intl.translate({ id: 'security.title' })}
                    </StyledDataTextLabel>
                    <StyledDataCommentP>
                      {data.securityCompany}
                    </StyledDataCommentP>
                    <StyledDataTextLabel>
                      {intl.translate({ id: 'other.people.house.input' })}
                    </StyledDataTextLabel>
                    <StyledDataCommentP>
                      {data.otherPeopleHouse}
                    </StyledDataCommentP>
                  </>
                )}
                <BabySitterServicesStatus
                  data={data}
                  onChangeStatus={() =>
                    setShouldUpdateData((prevState) => !prevState)
                  }
                />
              </StyledDiv>
            </>
          );
        })
      ) : (
        <StyledNoServicesDiv>
          <Text.p>
            {intl.translate({
              id: 'Actualmente no se registran próximos servicios',
            })}
          </Text.p>
        </StyledNoServicesDiv>
      )}
    </StyledServicesContentDiv>
  );
};
