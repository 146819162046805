import { updateServiceStatus } from 'api/babysitters/babysitters.api';
import { sendPaymentConfirmationByMercadopago } from 'api/users/users.api';
import { BackgroundGeneral } from 'components/background/BackgroundGeneral';
import { TickIcon } from 'components/icons/TickIcon';
import { Card, Text } from 'design';
import { FutureServicesStatus } from 'enums/auth-type.enum';
import { selectUser } from 'global-state/selectors';
import { Align } from 'layout';
import { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import { AdditionalDataChildForm } from '../Form/AdditionalDataChildForm';
import { AppBar } from './AppBar';

const StyledCardBody = styled(Card.Body)`
  width: 100vw;
  display: flex;
  padding: 0 1rem;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 9rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    justify-content: center;
  }
`;

const StyledViewAlign = styled(Align)`
  height: 100vh;
`;

const StyledSectionAlign = styled(Align)`
  height: 100%;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: row;
  }
  @media (max-height: 824px) {
    margin-top: 5rem;
  }
  @media (max-height: 650px) {
    margin-top: 7rem;
  }
`;

const MainContainerDiv = styled.div`
  overflow: hidden;
  padding-top: 1rem;
  align-self: center;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  margin: 0 auto;
`;

const StyledLeftSectionDiv = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`;

const StyledTickIcon = styled(TickIcon)`
  height: 5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    height: 7rem;
  }
`;

const StyledSuccedPaymentTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 2.1rem;
  font-weight: 700;
  max-width: 17rem;

  line-height: 2.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    color: ${({ theme }) => theme.palette.primary.light};
    padding-top: 1.5rem;
  }
`;

const StyledChildDataMobileTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.3rem;
  font-weight: 700;
  padding: 1.5rem 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

export const AdditionalDataChildView: FC = () => {
  const [searchParams] = useSearchParams();

  const serviceId: string | null = searchParams.get('id');

  const user = useSelector(selectUser);

  const isDataSented = useRef(false);

  const updateStatus = async (id: string, status: FutureServicesStatus) => {
    const response = await updateServiceStatus(id, status);
  };

  const sendPaymentNotifications = async (
    serviceId: string,
    userEmail: string,
    userId: string,
  ) => {
    await sendPaymentConfirmationByMercadopago(serviceId, userEmail, userId);
  };

  useEffect(() => {
    if (!isDataSented.current && serviceId && user && user.id) {
      updateStatus(serviceId, FutureServicesStatus.PAYMENT_SUCCESS);

      toast.success(
        intl.translate({
          id: 'Solicitud enviada a la niñera. Se le enviará una notificación cuando la misma confirme',
        }),
      );

      sendPaymentNotifications(serviceId, user.email, user.id);

      isDataSented.current = true;
    }
  }, [serviceId, user]);

  return (
    <BackgroundGeneral>
      <StyledViewAlign>
        <AppBar />
        <MainContainerDiv>
          <StyledSectionAlign>
            <StyledLeftSectionDiv>
              <StyledTickIcon />
              <StyledSuccedPaymentTextP>
                {intl.translate({ id: 'Pago recibido con éxito' })}
              </StyledSuccedPaymentTextP>
              <StyledChildDataMobileTextP>
                {intl.translate({ id: 'Últimos datos de tu hijo' })}
              </StyledChildDataMobileTextP>
            </StyledLeftSectionDiv>
            <StyledCardBody>
              <AdditionalDataChildForm serviceId={serviceId} />
            </StyledCardBody>
          </StyledSectionAlign>
        </MainContainerDiv>
      </StyledViewAlign>
    </BackgroundGeneral>
  );
};
