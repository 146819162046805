import { config } from 'config';
import { Button, Text } from 'design';
import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledRightSideDiv = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 100%;
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledTextP = styled(Text.p)`
  font-size: 0.9rem;
  color: #686868;
  font-weight: 600;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 1.2rem;
  }
`;

const StyledLinkA = styled.a`
  text-decoration: none;
  width: 100%;
`;

const StyledRegisterButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  color: ${({ theme }) => theme.palette.primary.invert} !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  height: 3rem !important;
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 0.9rem !important;
  }
`;

export const BussinessPackagesRightContent: FC<FcDefaultProps> = () => {
  const whatsappPhoneNumber = config.whatsapp.whatsappPhoneNumber;
  const phoneNumber = `https://wa.me/+${whatsappPhoneNumber}`;

  return (
    <StyledRightSideDiv>
      <StyledTextP>
        {intl.translate({
          id: 'En Okids apuntamos a un servicio de excelencia, por lo que solo trabajamos con profesionales en formación que tengan conocimientos en el cuidado de bebes y niños. Además, somos muy meticulosos con todos los detalles, ¡los padres depositan toda su confianza sobre nosotros!',
        })}
      </StyledTextP>
      <StyledLinkA
        href={phoneNumber}
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledRegisterButton fullWidth>
          {intl.translate({ id: `Contactar a representante de Okids` })}
        </StyledRegisterButton>
      </StyledLinkA>
    </StyledRightSideDiv>
  );
};
