import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

const StyledDiv = styled.div`
  height: 100vh;
  overflow: hidden;
  padding-top: 7rem;
  align-self: center;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  margin: 0 auto;
`;

export const MainContainer: FC<Props> = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>;
};
