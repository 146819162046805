import { getBabysitterCV } from 'api/babysitters/babysitters.api';
import { deleteFileCV, updateNewCVFile } from 'api/storageFiles/file.api';
import { Button, Card, Text } from 'design';
import { CrossIcon } from 'design/Icon/CrossIcon';
import useFileUpload from 'design/hooks/useFileUpload';
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { IUserDataResponse } from 'types/babysitter-props';
import { User } from 'types/user';
import { http } from 'utilities/http/http';
import { intl } from 'utilities/i18n/intl.utility';
import { UpdateBabysitterPersonalInfoForm } from './UpdateBabysitterPersonalInfoForm';

const StyledSpaceDiv = styled.div`
  height: 1rem;
  width: 100%;
`;

const StyledButtonRegisterTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  align-self: flex-end !important;
`;

const StyledDataTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1.1rem;
`;

const StyledBodyDiv = styled.div`
  padding: 0 3rem;
  width: 100%;
  width: 80vw;
  max-width: 970px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0.5rem;
  }
`;

const StyledLabelTextP = styled(Text.p)`
  width: 30%;
  font-size: 1.1rem;
  font-weight: 800;
  text-transform: uppercase;
  color: #555c;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: auto;
  }
`;

const StyledButtonDiv = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row-reverse;
`;

const StyledCardBody = styled(Card.Body)`
  max-width: 100%;
  padding: 0;
`;

const StyledSpanCVName = styled.span`
  padding: 0 0.5rem;
  font-weight: 600;
`

const StyledCVDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
`
interface PanelTopProps {
  userInfo: User | null;
  userFullData: IUserDataResponse;
}

export const BabysitterPersonalInfo: FC<PanelTopProps> = ({
  userInfo,
  userFullData,
}) => {
  const [modifyActive, setModifyActive] = useState(false);
  const navigate = useNavigate();
  const [file, setFile] = useState<any>();
  const [newFile, setNewFile] = useState<any>();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [uploadFile] = useFileUpload(setNewFile);

  const uploadCV = async (tempFile: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (tempFile) {
        await uploadFile(tempFile);
      }

    } catch (e) {
      toast.error(intl.translate({ id: 'No se pudo guardar el archivo' }));
    }
  };

  useEffect(() => {
    if (newFile) {
      const updateNewFile = async () => {
        await updateNewCVFile(userFullData!.babysitter!.id, newFile)
      }
      updateNewFile()
    }
  }, [newFile]);


  useEffect(() => {
    if (userInfo?.type !== 'babysitter') {
      toast.info(
        intl.translate({
          id: 'No tiene permiso para acceder',
        }),
        { toastId: 'info1' },
      ),
        navigate('/');
    }
  }, []);

  useEffect(() => {
    const getFile = async () => {
      if (userFullData && userInfo) {
        const file = await getBabysitterCV(userFullData!.babysitter!.id)
        if (file) {
          setFile(file)
        }
      }


    }
    getFile()
  }, [userFullData]);


  const handleClickDownload = async () => {
    let key = '';
    if (file) {
      key = file.fileName
    } else if (newFile) {
      key = newFile.fileName
    }
    if (file || newFile) {
      const response: any = await http.get(`/storage/uploads/${key}`, {
        responseType: 'blob',
      });

      const file = new Blob([response], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(file);
      const alink = document.createElement('a');

      alink.href = fileURL;
      alink.download = key;
      alink.click();
    }
  };


  const handleClickCV = () => {
    hiddenFileInput.current!.click();
  };

  const handleCloseClick = async () => {
    let key = '';
    let fileId = '';
    if (file) {
      key = file.fileName
      fileId = file!.id
    } else if (newFile) {
      key = newFile.fileName
      fileId = newFile!.fileId
    }
    if (file || newFile) {

      const deleteFile = await deleteFileCV(key, fileId)
      if (deleteFile) {
        setFile(undefined)
        setNewFile(undefined)
      }

    }




  };


  const buttonMainValue = intl.translate({ id: 'Modificar' });

  const buttonSecondaryValue = intl.translate({ id: 'Cancelar' });

  return (
    <StyledCardBody>
      {modifyActive ? (
        <UpdateBabysitterPersonalInfoForm
          userInfo={userInfo}
          userFullData={userFullData}
        />
      ) : (
        <>
          <StyledDiv>
            <StyledLabelTextP>
              {intl.translate({ id: 'Nombre' })}:
            </StyledLabelTextP>
            <StyledDataTextP>{userFullData.name}</StyledDataTextP>
          </StyledDiv>
          <StyledSpaceDiv />

          <StyledDiv>
            <StyledLabelTextP>
              {intl.translate({ id: 'Correo electrónico' })}
            </StyledLabelTextP>
            <StyledDataTextP>{userFullData.email}</StyledDataTextP>
          </StyledDiv>
          <StyledSpaceDiv />

          <StyledDiv>
            <StyledLabelTextP>
              {intl.translate({ id: 'Dirección' })}
            </StyledLabelTextP>
            <StyledDataTextP>{userFullData.babysitter.address}</StyledDataTextP>
          </StyledDiv>
          <StyledSpaceDiv />

          <StyledDiv>
            <StyledLabelTextP>
              {intl.translate({ id: 'Teléfono' })}
            </StyledLabelTextP>
            <StyledDataTextP>{userFullData.phone}</StyledDataTextP>
          </StyledDiv>
          <StyledSpaceDiv />
          <StyledDiv>
            <StyledLabelTextP>
              {intl.translate({ id: 'CV' })}
            </StyledLabelTextP>

            {file || newFile ? (
              <StyledCVDiv>
                <Button onClick={handleClickDownload}><StyledSpanCVName><strong>CV</strong></StyledSpanCVName>
                  {file ? <StyledDataTextP> {decodeURIComponent(file.fileName).slice(-15)}</StyledDataTextP> : <StyledDataTextP>{decodeURIComponent(newFile.fileName).slice(-15)} </StyledDataTextP>}
                </Button>
                <Button onClick={handleCloseClick}>
                  <CrossIcon color={'warning'} />
                </Button>
              </StyledCVDiv>
            ) : <Button variant="outlined" onClick={() => handleClickCV()}>
              <input
                accept='application/pdf'
                ref={hiddenFileInput}
                onChange={(e) => uploadCV(e)}
                type="file"
                hidden
              />
              {intl.translate({
                id: 'Subir CV',
              })}
            </Button>}
          </StyledDiv>
          <StyledDiv>
            <StyledLabelTextP>
              {intl.translate({ id: 'Disponibilidad' })}
            </StyledLabelTextP>
            <StyledDataTextP>
              {intl.translate({
                id: 'click en modificar para ver sus horarios',
              })}
            </StyledDataTextP>
          </StyledDiv>
          <StyledSpaceDiv />
        </>
      )}
      <StyledButtonDiv>
        <StyledButton
          type="button"
          variant="outlined"
          onClick={() => setModifyActive(!modifyActive)}
        >
          <StyledButtonRegisterTextP>
            {modifyActive ? buttonSecondaryValue : buttonMainValue}
          </StyledButtonRegisterTextP>
        </StyledButton>
      </StyledButtonDiv>
    </StyledCardBody>
  );
};
