import { DataGrid, esES, GridColDef } from '@mui/x-data-grid';
import { getParents } from 'api/clients/clients.api';
import { parseDateDdMmYyyy } from 'helpers/parseDate';
import { useEffect, useState } from 'react';
import { User } from 'types/user';

const columns: GridColDef[] = [
    { field: 'name', headerName: 'Nombre', width: 130, align: 'center', headerAlign: 'center' },
    { field: 'lastname', headerName: 'Apellido', width: 130, align: 'center', headerAlign: 'center' },
    { field: 'mail', headerName: 'E-mail', width: 200, align: 'center', headerAlign: 'center' },
    { field: 'registerDate', headerName: 'Fecha de registro', width: 210, align: 'center', headerAlign: 'center' },
    // To do : add completedServices prop to users
    // { field: 'completedServices', headerName: 'Servicios completados', width: 210, align: 'center', headerAlign: 'center' },
];

export const ClientsTable = () => {
    const [parentData, setParentData] = useState<User[]>([]);
    const [parentDataFilter, setParentDataFilter] = useState<Client[]>([]);


    useEffect(() => {
        const getClientData = async () => {
            const response = await getParents();
            setParentData(response);
        };
        getClientData();
    }, []);

    useEffect(() => {
        const rowsData = parentData?.map((parent) => {
            return {
                id: parent.id,
                name: parent.name,
                lastname: parent.lastname,
                mail: parent.email,
                address: parent.location,
                registerDate: parseDateDdMmYyyy(new Date(parent.createdAt)),
                // To do : add completedServices prop to users
                // completedServices: parent.completedServices,
                futureServices: parent.futureServices,
                previousServices: parent.previousServices,
            }
        })
        setParentDataFilter(rowsData as any[])
    }, [parentData]);




    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid
                rows={parentDataFilter ? parentDataFilter : []}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                loading={!parentDataFilter?.length}
            />
        </div>
    );
};
