import { addDataChildToService } from 'api/babysitters/babysitters.api';
import { emitMemoryETicket } from 'api/memory.api';
import { Form } from 'components/Form/Form';
import { addMonths, addYears } from 'date-fns';
import { Button, Text, TextInput } from 'design';
import { Loading } from 'design/Button/LoadingButton';
import { DatePicker } from 'design/input/DatePicker/DatePicker';
import { AppRoute } from 'enums/app-route.enum';
import { FormikHelpers } from 'formik';
import { selectBillData } from 'global-state/selectors';
import { genericErrorHandler } from 'helpers/error.helpers';
import { Align } from 'layout';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import * as Yup from 'yup';

const StyledFormContainer = styled('div')`
  height: 80vh;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledChildDataWebP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.8rem;
  font-weight: 700;
  padding: 1rem 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;
const StyledFatherDataMobileTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.3rem;
  font-weight: 700;
  padding: 1.5rem 0;
  align-self: center;
  text-transform: uppercase;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StyledInputAlign = styled(Align)`
  min-height: 90%;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 1rem;
  }
`;

const StyledButton = styled(Button)`
  height: 3rem;
  margin: 2rem 0 !important;
`;

const StyledTextP = styled(Text.p)`
  text-align: initial;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 0.9rem;
  font-weight: 400;
`;

const currentDate = new Date();

const oldestAgeforChild = addYears(currentDate, -18);

const oldestDate = addMonths(oldestAgeforChild, -1);

const initialValues = {
  firstname: '',
  ageOfChild: currentDate,
  comments: '',
  otherChildrens: '',
  importantChildrenComments: '',
  ageOfSecondChild: currentDate,
  petHouse: '',

};

const validateCI = (ci: string) => {
  // Remove any non-digit characters
  ci = ci.replace(/\D/g, '');

  // Ensure length is correct
  if (ci.length !== 8) {
    return false;
  }

  // Calculate verification digit
  const factors = [2, 9, 8, 7, 6, 3, 4];
  let sum = 0;
  for (let i = 0; i < factors.length; i++) {
    sum += factors[i] * parseInt(ci.charAt(i), 10);
  }
  const verificationDigit = (10 - (sum % 10)) % 10;

  // Validate verification digit
  return parseInt(ci.charAt(7), 10) === verificationDigit;
}

const validationSchema = Yup.object({
  firstname: Yup.string().required(intl.translate({ id: 'Nombre y Apodo' })),
  ageOfChild: Yup.date().required(
    intl.translate({ id: 'Debe ingresar mes, año y dia de nacimiento' }),
  ),
  comments: Yup.string().required(intl.translate({ id: 'Comentarios' })),
  otherChildrens: Yup.string().required(
    intl.translate({ id: 'other.children.home' }),
  ),
  importantChildrenComments: Yup.string().required(
    intl.translate({ id: 'important.children.comments' }),
  ),
  petHouse: Yup.string().required(intl.translate({ id: 'pet.house.input' })),
  ageOfSecondChild: Yup.date().required(
    intl.translate({ id: 'Debe ingresar mes, año y día de nacimiento' }),
  ),
});

type FormValues = {
  firstname: string;
  ageOfChild: Date;
  ageOfSecondChild: Date;
  comments: string;
  otherChildrens: string;
  importantChildrenComments: string;
  petHouse: string;
};

interface Props {
  serviceId: string | null;
}

export const AdditionalDataChildForm: FC<Props> = ({ serviceId }) => {
  const [searchParams] = useSearchParams();
  const payment_id: string | null = searchParams.get("payment_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const billData = useSelector(selectBillData);

  const viewsMonthYearDay = ['month', 'year', 'day'];

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);

      if (values.ageOfChild >= oldestDate) {
        if (serviceId) {
          const childData = await addDataChildToService(
            serviceId,
            values.firstname,
            values.ageOfChild,
            values.ageOfSecondChild,
            values.otherChildrens,
            values.importantChildrenComments,
            values.comments,
            values.petHouse,



          );
          if (billData && payment_id) {
            await emitMemoryETicket(payment_id, {
              billAddress: billData.billAddress,
              billCity: billData.billCity,
              billDni: billData.billDni
            });
          }
          if (childData) {
            toast.success(
              intl.translate({ id: 'Datos ingresados correctamente' }),
            );
            navigate(AppRoute.HOME);
            helpers.resetForm();
          } else {
            toast.warning(
              intl.translate({ id: 'No se han podido guardar los datos' }),
            );
          }
        }
      } else {
        helpers.setFieldError(
          'ageOfChild',
          intl.translate({
            id: 'Lo sentimos, debe ser menor de edad',
          }),
        );
      }
    } catch (error) {
      genericErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledFormContainer>
      <Form
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <>
            <StyledInputAlign column gap={0.7}>
              <StyledChildDataWebP>
                {intl
                  .translate({ id: 'Datos de los niños a cuidar' })
                  .toUpperCase()}
              </StyledChildDataWebP>

              <TextInput
                label={intl.translate({ id: 'Nombre y Apodo de primer niño' })}
                id="firstname"
                name="firstname"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.firstname}
                error={formik.errors.firstname}
                fullWidth
                required
              />

              <DatePicker
                fullWidth
                name="ageOfChild"
                label={intl.translate({
                  id: 'Fecha de nacimiento primer niño',
                })}
                views={viewsMonthYearDay}
                maxDate={currentDate}
                minDate={oldestAgeforChild}
                value={formik.values.ageOfChild}
                onChange={formik.handleChange}
                error={formik.errors.ageOfChild}
                showToolbar={false}
              />
              <TextInput
                label={intl.translate({ id: 'Nombre y Apodo del segundo niño' })}
                placeHolder={intl.translate({
                  id: 'Nombre y Apodo del segundo niño',
                })}
                id="otherChildrens"
                name="otherChildrens"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.otherChildrens}
                error={formik.errors.otherChildrens}
                fullWidth
                required
                multiline={true}
                rows="3"
              />

              <DatePicker
                fullWidth
                name="ageOfChild"
                label={intl.translate({
                  id: 'Fecha de nacimiento segundo niño',
                })}
                views={viewsMonthYearDay}
                maxDate={currentDate}
                minDate={oldestAgeforChild}
                value={formik.values.ageOfSecondChild}
                onChange={formik.handleChange}
                error={formik.errors.ageOfSecondChild}
                showToolbar={false}
              />


              <TextInput
                label={intl.translate({
                  id: 'important.children.comments.explanation',
                })}
                placeHolder={intl.translate({
                  id: 'important.children.comments.explanation',
                })}
                id="importantChildrenComments"
                name="importantChildrenComments"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.importantChildrenComments}
                error={formik.errors.importantChildrenComments}
                fullWidth
                required
                multiline={true}
                rows="3"
              />
              <TextInput
                label={intl.translate({ id: 'Comentarios a tener en cuenta' })}
                id="comments"
                name="comments"
                placeHolder={intl.translate({ id: 'Comentarios' })}
                type="text"
                onChange={formik.handleChange}
                value={formik.values.comments}
                error={formik.errors.comments}
                fullWidth
                required
                multiline={true}
                rows="4"
              />
              <TextInput
                label={intl.translate({ id: 'pet.house.input' })}
                placeHolder={intl.translate({
                  id: 'pet.house.input.explanation',
                })}
                id="petHouse"
                name="petHouse"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.petHouse}
                error={formik.errors.petHouse}
                fullWidth
                required
                multiline={true}
                rows="3"
              />



            </StyledInputAlign>
            {loading ? (
              <Loading
                loading={loading}
                variant="contained"
                fullWidth
              >
                {intl.translate({ id: 'Cargando...' })}
              </Loading>
            ) : (
              <>
                <StyledTextP>
                  *Si desea contratar más de 1 día, debe realizar una solicitud
                  de servicio por cada día, de modo que usted pueda elegir su
                  niñera. Si desea servicios de más de 8 horas, debe realizar
                  dos contrataciones seguidas con distintas niñeras
                </StyledTextP>
                <StyledButton
                  type="submit"
                  color="primary"
                  variant="filled"
                  fullWidth
                >
                  <strong>{intl.translate({ id: 'Finalizar' })}</strong>
                </StyledButton>
              </>
            )}
          </>
        )}
      </Form>
    </StyledFormContainer>
  );
};
