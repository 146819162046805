import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { Text } from 'design/Text';
import * as React from 'react';
import styled from 'styled-components';
import { User } from 'types/user';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  border: `2px solid #1883fe`,
  backgroundColor: '#ffffff0',
  marginTop: '1.5rem',
  borderRadius: '10px',
  width: '100%',
});

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          fontSize: '0.9rem',
          color: `#1883fe`,
        }}
      />
    }
    {...props}
  />
))({
  backgroundColor: '#ffffff0',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '1rem',
  },
});

const AccordionDetails = styled(MuiAccordionDetails)`
  padding: '150px';
`;

const StyledTitleP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  text-transform: uppercase;
  font-weight: 600;
`;

interface CardsProps {
  title: string;
  content: string | JSX.Element;
  component?: string;
  userInfo?: User | null;
}

export const OkidsAccordion: React.FC<CardsProps> = ({ title, content }) => {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <StyledTitleP>{title}</StyledTitleP>
      </AccordionSummary>
      <AccordionDetails>
        <div>{content}</div>
      </AccordionDetails>
    </Accordion>
  );
};
