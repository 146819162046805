import { registerEmail } from 'api/auth.api';
import { Form } from 'components/Form/Form';
import { SignInWithGoogle } from 'components/SignIn/SignInWithGoogle';
import { SignupBackgroundSvg } from 'components/background/SignupBackgroundSvg';
import { OkidsLogo } from 'components/icons/OkidsLogo';
import { AppBar } from 'components/views/AppBar';
import { Button, Card, Text, TextInput } from 'design';
import { Loading } from 'design/Button/LoadingButton';
import { LoadingIcon } from 'design/Icon/LoadingIcon';
import { AppRoute } from 'enums/app-route.enum';
import { FormikHelpers } from 'formik';
import { setAuthTokenAction, setUserDataAction } from 'global-state/actions';
import { selectAuthToken, selectCurrentServiceData, selectUser } from 'global-state/selectors';
import { genericErrorHandler } from 'helpers/error.helpers';
import { isPath } from 'helpers/navigation.helpers';
import { Align } from 'layout';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';
import * as Yup from 'yup';
import { requestForToken } from '../../firebase';

const StyledMainContainerDiv = styled.div`
  height: 100%;
  padding-top: 8rem;
  align-self: center;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  margin: 0 auto;
`;

const StyledHeightSection = styled.section`
  height: calc(100vh - 7rem);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    height: auto;
    width: 100vw;
    padding: 0;
  }
`;

const StyledLeftSideDiv = styled.div`
  height: 100%;
  width: 50vw;
  padding-left: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
    margin: 0;
  }
`;

const StyledCardLeftAlignDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
`;

const StyledDiv = styled.div`
  width: 100%;
`;

const StyledCardBody = styled(Card.Body)`
  width: 100% !important;
  padding: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 20rem !important;
  }
`;

const StyledRegisterTextH2 = styled(Text.h2)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 900;
  font-size: 3rem;
  line-height: 0.9;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 2.7rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    font-size: 3.5rem;
  }
`;

const StyledSpaceDiv = styled.div`
  margin-top: 1rem;
`;

const StyledLineHr = styled.hr`
  color: ${({ theme }) => theme.palette.primary.main};
  border-top: 5px solid ${({ theme }) => theme.palette.primary.main};
  width: 85%;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 90%;
  }
`;

const StyledRegisterTextH3 = styled(Text.h3)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.2rem;
  font-weight: 900;
  line-height: 0.9;
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    font-size: 1.4rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 1rem;
  }
`;

const StyledRightSideDiv = styled.div`
  height: 100%;
  width: 50vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledOkidsLogoContainerDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledOkidsLogo = styled(OkidsLogo)`
  margin-bottom: 1rem;
  padding: 0 1rem;
  width: 60%;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StyledRightSideCardBase = styled(Card.Base)`
  width: 100%;
  background-color: transparent;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

const StyledLogoTexth2 = styled(Text.h2)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 2.1rem;
  font-weight: 900;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const StylesButtonRegisterTextP = styled(Text.p)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.invert};
  align-self: center;
`;

const StyledEndFormAlign = styled(Align)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: row;
  }
`;

const StyledQuestionTextLabel = styled(Text.label)`
  color: rgb(170 170 170);
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 0;
  margin-bottom: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 0.8rem;
  }
`;

const StyledLinkBlueTextP = styled(Text.p)`
  font-weight: bold;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: 0;
  padding-top: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 0.8rem;
  }
`;


const initialValues = {
  name: '',
  lastname: '',
  email: '',
  ci: '',
  password: '',
  type: 'customer',
  phone: '',
  admissionDate: new Date(),
  authType: 'email',
  picture: '',
};

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(
      /^[a-zA-ZñÑ ]*$/,
      'No puede ingresar números o caracteres especiales.',
    )
    .required(intl.translate({ id: 'Debe ingresar su nombre' })),
  lastname: Yup.string()
    .matches(
      /^[a-zA-ZñÑ ]*$/,
      'No puede ingresar números o caracteres especiales.',
    )
    .required(intl.translate({ id: 'Debe ingresar su apellido' })),

  email: Yup.string()
    .email(intl.translate({ id: 'Debe ingresar su correo electrónico' }))
    .required(intl.translate({ id: 'Debe ingresar su correo electrónico' })),
  password: Yup.string()
    .min(
      8,
      intl.translate({
        id: 'Ingrese al menos 8 caracteres',
      }),
    )
    .required('Campo obligatorio'),
  phone: Yup.string().matches(
    /^[\d()+-]+$/,
    'Solo puede ingresar caracteres numéricos y (+ )',
  ),
});

type FormValues = {
  name: string;
  lastname: string;
  email: string;
  ci: string;
  password: string;
  type: string;
  phone: string;
  admissionDate: Date;
  authType: string;
  picture: string;
};

export const Register: FC<FcDefaultProps> = () => {
  const serviceData = useSelector(selectCurrentServiceData);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const { token, user } = await registerEmail(
        values.name,
        values.lastname,
        values.email,
        values.ci,
        values.password,
        values.type,
        values.phone,
        values.authType,
        values.picture,
      );
      dispatch(setAuthTokenAction(token));
      dispatch(setUserDataAction(user));
      requestForToken();
      helpers.resetForm();
    } catch (error) {
      if (error == 'Error: Request failed with status code 409') {
        toast.info(
          intl.translate({
            id: 'Este email ya se encuentra en uso',
          }),
        );
      } else {
        toast.info(
          intl.translate({
            id: 'No se ha podido crear el usuario',
          }),
        );
        genericErrorHandler(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const user = useSelector(selectUser);

  useEffect(() => {
    if (authToken) {
      const path = (location.state as { origin: unknown })?.origin;

      if (isPath(path)) {
        navigate(path);
      } else if (user?.type === 'babysitter' && user?.status === 'active') {
        navigate(AppRoute.BABYSITTER_PANEL);
      } else if (
        serviceData &&
        serviceData.startServiceDateAndTime &&
        serviceData.endServiceDateAndTime &&
        serviceData.locationLat &&
        serviceData.locationLng &&
        serviceData.address
      ) {
        navigate(
          `${AppRoute.SELECT_BABYSITTER}?lat=${serviceData.locationLat}&lng=${serviceData.locationLng}&inicio=${serviceData.startServiceDateAndTime}&fin=${serviceData.endServiceDateAndTime}&address=${serviceData.address}`,
        );
      } else {
        navigate(AppRoute.HOME);
      }
    }
  }, [authToken, location.state, navigate, serviceData, user]);


  return (
    <SignupBackgroundSvg>
      <AppBar />
      <StyledMainContainerDiv>
        <StyledHeightSection>
          <StyledLeftSideDiv>
            <StyledDiv>
              <StyledCardBody>
                <StyledCardLeftAlignDiv>
                  <StyledRegisterTextH2>
                    {intl.translate({ id: 'Registrate' })}
                  </StyledRegisterTextH2>
                  <StyledRegisterTextH2>
                    {intl.translate({
                      id: 'o Inicia Sesión',
                    })}
                  </StyledRegisterTextH2>
                  <StyledSpaceDiv />
                  <StyledLineHr />
                  <StyledSpaceDiv />
                  <StyledRegisterTextH3>
                    {intl.translate({ id: `Solo resta este último paso` })}
                  </StyledRegisterTextH3>
                  <StyledSpaceDiv />
                </StyledCardLeftAlignDiv>
              </StyledCardBody>
            </StyledDiv>
          </StyledLeftSideDiv>

          <StyledRightSideDiv>
            <StyledRightSideCardBase>
              <StyledLogoTexth2>
                {intl.translate({ id: 'Bienvenido a' })}
              </StyledLogoTexth2>
              <StyledOkidsLogoContainerDiv>
                <StyledOkidsLogo color="primary" />
              </StyledOkidsLogoContainerDiv>
              <Card.Heading>
                <StyledSpaceDiv />

                <SignInWithGoogle />

                <StyledSpaceDiv />

                {/* <LoginWithFacebookButton /> */}

                <StyledSpaceDiv />
              </Card.Heading>
              <StyledSpaceDiv />
              <Card.Body>
                <Form
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  {(formik) => (
                    <>
                      <Align column gap={0.7}>
                        <TextInput
                          label={intl.translate({ id: 'Nombre' })}
                          id="name"
                          name="name"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          error={formik.errors.name}
                          fullWidth
                          required
                        />
                        <TextInput
                          label={intl.translate({ id: 'Apellido' })}
                          id="lastname"
                          name="lastname"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.lastname}
                          error={formik.errors.lastname}
                          fullWidth
                          required
                        />
                        <TextInput
                          label={intl.translate({
                            id: 'Dirección de correo electrónico',
                          })}
                          id="email"
                          name="email"
                          type="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          error={formik.errors.email}
                          fullWidth
                          required
                        />
                        <TextInput
                          label={intl.translate({ id: 'Contraseña' })}
                          id="password"
                          name="password"
                          type="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          error={formik.errors.password}
                          fullWidth
                          required
                        />
                        <TextInput
                          label={intl.translate({ id: 'Teléfono' })}
                          id="phone"
                          name="phone"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                          error={formik.errors.phone}
                          fullWidth
                          required
                        />
                      </Align>
                      <StyledSpaceDiv />
                      {loading ? (
                        <Loading
                          endIcon={<LoadingIcon />}
                          loading={loading}
                          loadingPosition="center"
                          variant="contained"
                          fullWidth
                        >
                          {intl.translate({ id: 'Cargando' })}
                        </Loading>
                      ) : (
                        <Button
                          type="submit"
                          color="primary"
                          variant="filled"
                          fullWidth
                        >
                          <StylesButtonRegisterTextP>
                            {intl.translate({ id: 'Registrate' })}
                          </StylesButtonRegisterTextP>
                        </Button>
                      )}
                      <StyledSpaceDiv />
                      <StyledEndFormAlign>
                        <StyledQuestionTextLabel>
                          {intl.translate({ id: '¿Ya Tenés una cuenta?' })}
                        </StyledQuestionTextLabel>
                        <Link
                          to={AppRoute.LOGIN}
                          style={{
                            marginLeft: '15px',
                            textDecorationColor: '#1280ff',
                          }}
                        >
                          <StyledLinkBlueTextP>
                            {intl.translate({ id: 'Iniciar sesión' })}!
                          </StyledLinkBlueTextP>
                        </Link>
                      </StyledEndFormAlign>
                    </>
                  )}
                </Form>
              </Card.Body>
            </StyledRightSideCardBase>
          </StyledRightSideDiv>
        </StyledHeightSection>
      </StyledMainContainerDiv>
    </SignupBackgroundSvg>
  );
};
