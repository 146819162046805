const getNumberWithCero = (number: number): string => number.toString().length === 1 ? `0${number}` : number.toString();

/**
 * This function is to disguise our date in utc and that the back does not modify it
 */
export const getHoursInUTC = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${getNumberWithCero(year)}-${getNumberWithCero(month)}-${getNumberWithCero(day)}T${getNumberWithCero(hour)}:${getNumberWithCero(minutes)}:${getNumberWithCero(seconds)}.000Z`
}
