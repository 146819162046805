import { useIconColor } from 'design/hooks/use-icon-color.hook';
import { IconProps } from 'design/types/icon-props';
import { FC } from 'react';

export const InterviewYourBabysitter: FC<
  IconProps & { width?: number | string }
> = ({ color, width = '100%', className, invert = false }) => {
  const _color = useIconColor(color, invert);

  return (
    <svg
      width={width}
      className={className}
      color={_color}
      viewBox="0 0 1044 828"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M250.3 827.3C192.3 827.3 145.1 781 145.1 724.1V477.7C56.9 435.8 0 347.7 0 252.2C0 113.3 115.2 0.199707 256.9 0.199707C355.8 0.199707 446.4 56.3997 488.9 143.6H701.7C729.6 143.6 756.5 155.3 776.6 175.9L852 101.9C863.6 90.4997 879.3 83.8997 895.2 83.6997C897.4 83.3997 899.6 83.2997 901.7 83.2997C919.9 83.2997 932.2 93.2997 939.9 100.9L1021.7 180.1L1025 183.3C1049 206.8 1050.1 245.1 1027.4 268.6L808 485.8V724.1C808 781 760.8 827.3 702.8 827.3H250.3ZM225.6 724.1C225.6 737.4 236.2 748.2 249.2 748.2H701.7C714.1 748.2 725.3 736.7 725.3 724.1V565.8L711.8 579C689.5 600.9 663.5 618.9 634.5 632.5L550.4 671.1C540.5 676 529 678.6 517.3 678.6C496.4 678.6 477.4 670.8 462.4 656.1C439.1 633.3 433.1 599.3 447 569.6L486.3 486.1C500.3 457.5 518.7 432 541 410.1L720.3 232.3C715.7 227.1 708.9 223.8 701.8 223.8H511.4C512.7 232.3 513.8 241.7 513.8 251.1C513.8 390 398.6 503.1 256.9 503.1C245.7 503.1 235.6 502 225.6 500.6V724.1ZM532.7 594.5L606.7 564.5L555.1 513.9L523.8 585.3C521 591.1 526.9 597.1 532.7 594.5ZM653 523.8C653.5 523.4 654 522.9 654.4 522.5L859.1 321.8L802.1 265.9L596.5 468.6L596.1 468.8L653 523.8ZM256.9 80.3997C160.2 80.3997 81.6 157.5 81.6 252.2C81.6 346.9 160.3 424 256.9 424C353.5 424 432.2 346.9 432.2 252.2C432.2 157.5 353.6 80.3997 256.9 80.3997ZM916.7 264.3L955.2 226.6L898.2 170.7L859.7 208.4L916.7 264.3Z"
        fill="currentColor"
      />
      <path
        d="M230.7 351.2C220 351.2 209.4 346.6 200.9 338.3L151.5 285.5C144.2 277.6 140.2 267.5 140.4 256.9C140.6 246 145.2 236.1 153.3 228.8C161.1 221.9 171.1 218 181.5 218C192.8 218 203.2 222.5 210.7 230.6L230.6 250.9L294.5 183.5C302.4 175.7 313.2 171.4 324.2 171.4C334.5 171.4 344.3 175.1 351.8 181.8C368.4 196.6 369.8 221.7 355.1 239.1L261 337.8C253.8 346.1 242.2 351.2 230.7 351.2Z"
        fill="currentColor"
      />
    </svg>
  );
};
