import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { AppRoute } from 'enums/app-route.enum';
import { Align } from 'layout';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import { FacebookIcon } from './footerIcons/FacebookIcon';
import { InstagramIcon } from './footerIcons/InstagramIcon';
import { TwitterIcon } from './footerIcons/TwitterIcon';
const termsAndConditionsPdfUrl = "/termsAndConditions.pdf";
const privatePolicyPdfUrl = '/privatePolicy.pdf'

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  justify-content: space-around;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: flex;
    flex-direction: row;
    padding: 3rem 2rem;
  }
`;

const StyledWebAlign = styled(Align)`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
  }
`;

const StyledAlign = styled(Align)`
  flex-direction: row;
  justify-content: center;
  height: 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
  }
`;

const StyledNavLink = styled(NavLink)`
  font-size: 0.7rem;
  padding: 0;
  color: white;
  white-space: nowrap;
  text-decoration: none;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fontFamily};
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 0.9rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 1.1rem;
  }
`;

const StyledIconsAlign = styled(Align)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledIconsDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const StyledTwitterIcon = styled(TwitterIcon)`
  color: ${({ theme }) => theme.palette.primary.invert};
`;

const StyledFacebookIcon = styled(FacebookIcon)`
  margin: 0 1rem;
  color: ${({ theme }) => theme.palette.primary.invert};
`;

const StyledLinkedInIcon = styled(LinkedInIcon)`
  margin: 0 1rem;
  color: ${({ theme }) => theme.palette.primary.invert};
`;

const StyledInstagramIcon = styled(InstagramIcon)`
  color: ${({ theme }) => theme.palette.primary.invert};
`;

const StyledHrefA = styled.a`
  font-size: 0.7rem;
  padding: 0;
  color: white;
  white-space: nowrap;
  text-decoration: none;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fontFamily};
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 0.9rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 1.1rem;
  }
`;

export const Footer: FC = () => {
  return (
    <StyledFooter>
      <StyledWebAlign gap={0.5}>
        <StyledNavLink to={AppRoute.BUSSINESS_PACKAGES}>
          {intl.translate({ id: 'Paquetes Empresariales' })}
        </StyledNavLink>

        <StyledNavLink to={AppRoute.WORK_WITH_US} >
          {intl.translate({ id: 'Trabaja con nosotros' })}
        </StyledNavLink>
      </StyledWebAlign>


      <StyledAlign gap={0.5}>
        <StyledHrefA href={privatePolicyPdfUrl} rel="noreferrer" target="_blank">
          {intl.translate({ id: 'Privacidad' })}
        </StyledHrefA>

        <StyledNavLink to={AppRoute.OUR_SERVICE}>
          {intl.translate({ id: 'Nuestro Servicio' })}
        </StyledNavLink>
      </StyledAlign>
      <StyledAlign gap={0.5}>
        <StyledHrefA href={termsAndConditionsPdfUrl} target="_blank" rel="noreferrer">
          {intl.translate({ id: 'Términos y condiciones' })}
        </StyledHrefA>
      </StyledAlign>
      <StyledIconsAlign gap={0.5}>
        <StyledIconsDiv>
          <StyledHrefA
            href="
https://www.linkedin.com/company/okidsuy"
            target="_blank"
          >
            <StyledLinkedInIcon fontSize="large" />
          </StyledHrefA>

          <StyledHrefA href="https://twitter.com/OKids_Uy" target="_blank">
            <StyledTwitterIcon width="2rem" />
          </StyledHrefA>

          <StyledHrefA
            href="
https://www.facebook.com/OKids.uy"
            target="_blank"
          >
            <StyledFacebookIcon width="2rem" />
          </StyledHrefA>

          <StyledHrefA
            href="https://www.instagram.com/okids.uy/"
            target="_blank"
          >
            <StyledInstagramIcon width="2rem" />
          </StyledHrefA>
        </StyledIconsDiv>
        <StyledHrefA href="mailto:contacto@OKids.uy">
          contacto@OKids.uy
        </StyledHrefA>
      </StyledIconsAlign>
    </StyledFooter>
  );
};
