import { format } from 'date-fns';

export const getServiceMonth = (date: Date | undefined) => {
  if (date) {
    const serviceMonth = format(date, 'MMM');
    return serviceMonth;
  }
};

export const getServiceDay = (date: Date | undefined) => {
  if (date) {
    const serviceDay = format(date, 'd');
    return serviceDay;
  }
};

export const getServiceStartTime = (date: Date | undefined) => {
  if (date) {
    const serviceMonth = date.getHours();
    return serviceMonth;
  }
};

export const getServiceEndTime = (date: Date | undefined) => {
  if (date) {
    const serviceMonth = date.getHours();
    return serviceMonth;
  }
};
