import { config } from 'config';
import { Button, Text } from 'design';
import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledRightSideDiv = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1.3rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 100%;
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledSubtitleTexth4 = styled(Text.h4)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: 2rem;
  font-size: 1.6875rem;
  font-weight: 900;
  align-self: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 1.3rem;
  }
`;

const StyledTextP = styled(Text.p)`
  font-size: 1.3rem;
  color: #686868;
  font-weight: 600;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 1.1rem;
  }
`;

const StyledLinkA = styled.a`
  text-decoration: none;
  width: 100%;
`;

const StyledRegisterButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  color: ${({ theme }) => theme.palette.primary.invert} !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  height: 3rem !important;
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
`;

export const OurServiceRightSideContent: FC<FcDefaultProps> = () => {
  const whatsappPhoneNumber = config.whatsapp.whatsappPhoneNumber;
  const phoneNumber = `https://wa.me/+${whatsappPhoneNumber}`;
  return (
    <StyledRightSideDiv>
      <StyledSubtitleTexth4>
        {intl.translate({ id: `Servicio de Niñeras Profesionales` })}
      </StyledSubtitleTexth4>
      <StyledTextP>
        {intl.translate({
          id: 'Puedes contratar dentro de Montevideo según las horas que necesites. En la página principal, ingresa fecha y hora que precisas servicio y se te mostrará cotización. Los padres podrán elegir la niñera de su preferencia, pudiendo consultar reseñas de otros padres que ya las han contratado. Todas las niñeras en Okids son estudiantes universitarias de alto nivel, y brindarán a sus hijos cuidados y cálida atención',
        })}
      </StyledTextP>
      <StyledLinkA
        href={phoneNumber}
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledRegisterButton fullWidth>
          {intl.translate({ id: `CONTACTAR A REPRESENTANTE DE OKIDS` })}
        </StyledRegisterButton>
      </StyledLinkA>
    </StyledRightSideDiv>
  );
};
