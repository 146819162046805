import { Modal } from '@mui/material';
import { Button, Text } from 'design';
import { Align } from 'layout';
import * as React from 'react';
import styled from 'styled-components';
import { CrossIcon } from './icons/CloseIcon';
import { StarsRating } from 'rating/StarsRating';
import { parseDateDdMmYyyyHhMm } from 'helpers/parseDate';
import { Loading } from 'design/Button/LoadingButton';

const StyledCardAlignDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  max-height: 30rem;
  background-color: white;
  width: 100%;
  margin: 0 1rem;
  align-items: flex-start;
  align-self: center;
  border-radius: 1rem;
  overflow: hidden;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 60%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 45%;
  }
`;
const StyledEmptyCardAlignDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  max-height: 30rem;
  background-color: white;
  width: 100%;
  margin: 0 1rem;
  align-items: flex-start;
  align-self: center;
  border-radius: 1rem;
  overflow: hidden;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 60%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 45%;
  }
`;
const StyledAvatarAlign = styled(Align)`
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledNameP = styled(Text.p)`
  line-height: 1rem;
  font-size: 1rem;
  font-weight: 400;
`;

const StyledStarsAlign = styled(Align)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  width: 100%;
`;

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledAvatarAndCloseButtonAlign = styled(Align)`
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
`;

const StyledTitleCommentAlign = styled(Align)`
  justify-content: flex-end;
  width: 100%;
`;

const StyledCrossButton = styled(Button)`
  padding: 0 !important;
  display: flex !important;
  justify-content: flex-end !important;
`;

const StyledReviewAlign = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 20rem;
  overflow-y: auto;
  margin-top: 0;
  width: 100%;
  padding: 1rem;
`;

const StyledReviewItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
  justify-content: flex-start;
`;

interface Props {
  open: boolean;
  onClose: any;
  name?: string;
  location?: string;
  src?: string;
  alt?: string;
  comment?: string;
  rating?: number;
  readonly?: boolean;
  modalData: any;
}

export const ReviewsModal: React.FC<Props> = ({ open, onClose, modalData }) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {modalData ? (
        modalData.length > 0 ? (
          <StyledCardAlignDiv>
            <StyledAvatarAndCloseButtonAlign>
              <StyledTitleCommentAlign>
                <StyledCrossButton onClick={onClose}>
                  <CrossIcon color="primary" />
                </StyledCrossButton>
              </StyledTitleCommentAlign>
              <StyledReviewAlign>
                {modalData.map((review: any, index: any) => (
                  <StyledReviewItem key={index}>
                    <Text.h6>{review.service.name}</Text.h6>
                    <Text.p>
                      <small>
                        {parseDateDdMmYyyyHhMm(review.service.startTime)} -{' '}
                        {parseDateDdMmYyyyHhMm(review.service.endTime)}
                      </small>
                    </Text.p>
                    <StyledNameP color="dark">
                      <strong>{review.comment}</strong>
                    </StyledNameP>
                    <StyledStarsAlign>
                      <StarsRating
                        allowFraction
                        initialValue={review.rate ? review.rate : 2}
                        readonly={true}
                      />
                    </StyledStarsAlign>
                  </StyledReviewItem>
                ))}
              </StyledReviewAlign>
            </StyledAvatarAndCloseButtonAlign>
          </StyledCardAlignDiv>
        ) : (
          <StyledEmptyCardAlignDiv>
            <StyledAvatarAndCloseButtonAlign>
              <StyledReviewAlign>
                <StyledAvatarAlign gap={0.5}>
                  <Align column h-start v-between>
                    <Text.p>Niñera sin comentarios aún.</Text.p>
                  </Align>
                </StyledAvatarAlign>
              </StyledReviewAlign>
            </StyledAvatarAndCloseButtonAlign>
                <StyledCrossButton onClick={onClose}>
                  <CrossIcon color="primary" />
                </StyledCrossButton>
          </StyledEmptyCardAlignDiv>
        )
      ) : (
        <Loading />
      )}
    </StyledModal>
  );
};
