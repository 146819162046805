import { userData } from 'api/babysitters/babysitters.api';
import { BabysitterPanelTopDiv } from 'components/BabysitterPanel/BabysitterPanelTopDiv';
import { BabysitterPersonalInfo } from 'components/BabysitterPanel/BabysitterPersonalInfo';
import { BabysitterPreviousServices } from 'components/BabysitterPanel/BabysitterPreviousServices';
import { BabysitterServices } from 'components/BabysitterPanel/BabysitterServices';
import { BackgroundBabysitterPanel } from 'components/background/BackgroundBabysitterPanel';
import { BackgroundBabysitterPanelMobile } from 'components/background/BackgroundBabysitterPanelMobile';
import { OkidsAccordion } from 'design/Accordion/OkidsAccordion';
import { BabysitterStatus } from 'enums/auth-type.enum';
import { selectUser } from 'global-state/selectors';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IUserDataResponse } from 'types/babysitter-props';
import { intl } from 'utilities/i18n/intl.utility';
import { AppBar } from './AppBar';

const pepe = {
  address: '',
  id: '',
  name: '',
  lastName: '',
  email: '',
  location: '',
  dateOfBirth: new Date(),
  dateOfRegister: new Date(),
  locationLat: 0,
  locationLng: 0,
  experience: '',
  paymentMethod: '',
  endWorkingHours: 0,
  check_Lunes: false,
  check_Martes: false,
  check_Miercoles: false,
  check_Jueves: false,
  check_Viernes: false,
  check_Sabado: false,
  check_Domingo: false,
  startTimeAvailabilityMonday: new Date(),
  endTimeAvailabilityMonday: new Date(),
  startTimeAvailabilityTuesday: new Date(),
  endTimeAvailabilityTuesday: new Date(),
  startTimeAvailabilityWednesday: new Date(),
  endTimeAvailabilityWednesday: new Date(),
  startTimeAvailabilityThursday: new Date(),
  endTimeAvailabilityThursday: new Date(),
  startTimeAvailabilityFriday: new Date(),
  endTimeAvailabilityFriday: new Date(),
  startTimeAvailabilitySaturday: new Date(),
  endTimeAvailabilitySaturday: new Date(),
  startTimeAvailabilitySunday: new Date(),
  endTimeAvailabilitySunday: new Date(),
  completedServices: 0,
  isActive: false,
  rate: 0,
  status: BabysitterStatus.ACTIVE,
};

export const userFirstRender = {
  authtype: '',
  babysitter: pepe,
  ci: '',
  createdAt: new Date(),
  deletedAt: null,
  email: '',
  id: '',
  lastname: '',
  name: '',
  phone: '',
  picture: '',
  status: '',
  type: '',
  updatedAt: new Date(),
  verified: true,
};

const StyledHeightSection = styled.section`
  height: 100%;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  margin: 7rem auto 0 auto;
  padding: 0 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin: 7rem 0.5rem 0 1rem;
    padding: 0;
    justify-content: stretch;
  }
`;

export const BabysitterPanelView: FC = () => {
  const [userFullData, setUserFullData] =
    useState<IUserDataResponse>(userFirstRender);

  const user = useSelector(selectUser);

  useEffect(() => {
    const getUserData = async () => {
      if (!user) {
        throw new Error('user logged not found');
      }
      const response = await userData(user?.id);

      if (response) {
        setUserFullData(response);
      }
    };
    getUserData();
  }, [user]);
  const cardsArray = [
    {
      key: '1',
      title: `${intl.translate({
        id: 'Datos Personales',
      })}`,
      content: (
        <BabysitterPersonalInfo userInfo={user} userFullData={userFullData} />
      ),
    },
    {
      key: '2',
      title: `${intl.translate({
        id: 'Próximos servicios',
      })}`,
      content: <BabysitterServices />,
    },
    {
      key: '3',
      title: `${intl.translate({
        id: 'Servicios anteriores',
      })}`,
      content: <BabysitterPreviousServices />,
    },
  ];
  return (
    <>
      <AppBar />
      <BackgroundBabysitterPanel>
        <BackgroundBabysitterPanelMobile>
          <StyledHeightSection>
            <BabysitterPanelTopDiv userInfo={user} />
            {cardsArray.map((card, index) => (
              <OkidsAccordion
                key={index}
                title={card.title}
                content={card.content}
                component={'span'}
                userInfo={user}
              />
            ))}
          </StyledHeightSection>
        </BackgroundBabysitterPanelMobile>
      </BackgroundBabysitterPanel>
    </>
  );
};
