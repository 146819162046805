import { SignupBackgroundSvg } from 'components/background/SignupBackgroundSvg';
import { AppBar } from 'components/views/AppBar';
import { FC } from 'react';
import { FcDefaultProps } from 'types/fc-default-props';
import { Login } from './SignIn';

export const LoginView: FC<FcDefaultProps> = () => {
  return (
    <>
      <SignupBackgroundSvg>
        <AppBar />
        <Login />
      </SignupBackgroundSvg>
    </>
  );
};
