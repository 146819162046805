import { useGoogleLogin } from '@react-oauth/google';
import { ReactComponent as GoogleLogo } from 'assets/GoogleLogo.svg';
import { config } from 'config';
import { Button } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import {
  removeAuthTokenAction,
  setAuthTokenAction,
  setUserDataAction,
} from 'global-state/actions';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import { requestForToken } from '../../firebase';
import { googleLoginUser, googleRegisterUser } from './GoogleAuthentication';

const StyledGoogleButton = styled(Button)`
  display: flex !important;
  background: white;
  color: #444 !important;
  border-radius: 0.3125rem !important;
  border: thin solid #888 !important;
  box-shadow: 1px 1px 1px grey !important;
  white-space: nowrap !important;
  height: 3.125rem;
  width: 100%;
  justify-content: center !important;
`;

export const SignInWithGoogle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientId = config.google.appId;
  const location = useLocation().pathname;

  const onSuccess = async (idToken: string) => {
    if (location === AppRoute.LOGIN) {
      try {
        const data = await googleLoginUser(idToken);
        dispatch(setUserDataAction(data.user));
        dispatch(setAuthTokenAction(data.token));
        requestForToken();
        navigate(AppRoute.BABYSITTER_DESCRIPTION);
      } catch (error) {
        toast.warning(
          intl.translate({
            id: 'Su email no se encuentra asociado a ninguna cuenta. Por favor registrese para poder continuar',
          }),
        );
        navigate(AppRoute.REGISTER);
      }
    } else if (location === AppRoute.REGISTER) {
      try {
        const data = await googleRegisterUser(idToken);
        dispatch(setUserDataAction(data.user));
        dispatch(setAuthTokenAction(data.token));
        requestForToken();
        navigate(AppRoute.BABYSITTER_DESCRIPTION);
      } catch (error) {
        dispatch(removeAuthTokenAction());
        toast.warning(
          intl.translate({
            id: 'No ha podido registrarse a la página por favor, pruebe nuevamente',
          }),
        );
        navigate(AppRoute.LOGIN);
      }
    }
  };

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (t) => {
      onSuccess(t.code);
    },
  });

  return (
    <StyledGoogleButton onClick={() => login()}>
      <GoogleLogo />
      {intl.translate({
        id: 'Iniciar Sesión con Google',
      })}
    </StyledGoogleButton>
  );
};
