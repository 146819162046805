import { mapId } from 'config/google.config';
import { Button } from 'design';
import GoogleMapReact from 'google-map-react';
import { useState } from 'react';
import styled from 'styled-components';
import { LocationPin } from './LocationPin';
import { MapOptionsDefault } from './MapStyle';

type coords = {
  lat: number;
  lng: number;
};

interface mapUniqueLocationProps {
  pins: locationPinProps;
  dataLocation: coords;
  handleClose: () => void;
}

interface locationPinProps {
  addressText: string;
  lat: number;
  lng: number;
}
const StyledMainDiv = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 80vh;
  border: 0.25rem;
  border-color: ${({ theme }) => theme.palette.primary.main};
  border-style: solid;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.invert};
`;

const StyledCloseButton = styled(Button)`
  background-color: transparent !important;
  border: 2px solid ${({ theme }) => theme.palette.grey.main} !important;

  position: absolute !important;
  min-width: auto !important;
  height: 2rem !important;
  width: 2rem !important;
  top: 2% !important;
  right: 2% !important;
  color: ${({ theme }) => theme.palette.grey.main} !important;
  padding: 0 !important;
`;

const StyledMapDiv = styled.div`
  height: 57vh;
  border-radius: 0 0 15px 15px;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 60vh;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    height: 65vh;
  }
`;

export const MapRenderOnlyLocation = (props: mapUniqueLocationProps) => {
  const [pins, setPins] = useState(props.pins);
  const [center, setCenter] = useState(props.dataLocation);

  return (
    <StyledMainDiv>
      <div>
        <StyledCloseButton onClick={() => props.handleClose()}>
          X
        </StyledCloseButton>
      </div>
      <StyledMapDiv>
        <GoogleMapReact
          style={{ width: '30rem', height: '30rem' }}
          bootstrapURLKeys={{
            key: mapId,
            libraries: 'places',
          }}
          defaultCenter={props.dataLocation}
          center={center}
          defaultZoom={15}
          options={{ styles: MapOptionsDefault }}
        >
          <LocationPin
            addressText={pins.addressText}
            lat={pins.lat}
            lng={pins.lng}
          />
        </GoogleMapReact>
      </StyledMapDiv>
    </StyledMainDiv>
  );
};
