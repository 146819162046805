import { intl } from 'utilities/i18n/intl.utility';

export const dayOfWeek = [
  intl.translate({ id: 'Lunes' }),
  intl.translate({ id: 'Martes' }),
  intl.translate({ id: 'Miércoles' }),
  intl.translate({ id: 'Jueves' }),
  intl.translate({ id: 'Viernes' }),
  intl.translate({ id: 'Sábado' }),
  intl.translate({ id: 'Domingo' }),
];
