import { Button } from 'design';
import { ProfileAvatar } from 'design/Avatar/ProfileAvatar';
import { LogOutIcon } from 'design/Icon/LogOutIcon';
import { MenuDesign } from 'design/Menu/Menu';
import { AppRoute } from 'enums/app-route.enum';
import {
  removeAuthTokenAction,
  removeBabysitterFullDataAction,
  removeServiceDataAction,
  removeServiceDataWhileLoginAction,
  removeUserDataAction,
} from 'global-state/actions';
import { selectUser } from 'global-state/selectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const StyledAvatarDiv = styled.div`
  height: 100%;
  border: 2px solid white;
  padding: 0.25rem;
  border-radius: 50%;
  margin: 0 0.5rem;
`;

const StyledMenuDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 0.3rem;
`;

interface StyledProps {
  borderColor?: string;
}

export const DropDownUser: React.FC<StyledProps> = ({ borderColor }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button onClick={handleClick}>
        <StyledAvatarDiv style={{ borderColor }}>
          <ProfileAvatar small src={user?.picture} alt={user?.name} />
        </StyledAvatarDiv>
      </Button>

      <MenuDesign
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <StyledMenuDiv>
          <LogOutIcon />

          <Button
            onClick={() => {
              if (user?.authType === 'facebook') {
                if (window.FB) {
                  window.FB.logout();
                }
                dispatch(removeAuthTokenAction());
                dispatch(removeUserDataAction());
                dispatch(removeBabysitterFullDataAction());
                dispatch(removeServiceDataAction());
                dispatch(removeServiceDataWhileLoginAction());
                navigate(AppRoute.HOME);
              }
              dispatch(removeAuthTokenAction());
              dispatch(removeUserDataAction());
              dispatch(removeBabysitterFullDataAction());
              dispatch(removeServiceDataAction());
              dispatch(removeServiceDataWhileLoginAction());
              navigate(AppRoute.HOME);
            }}
          >
            {intl.translate({ id: 'Cerrar Sesión' })}
          </Button>
        </StyledMenuDiv>
      </MenuDesign>
    </React.Fragment>
  );
};
