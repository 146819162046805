import { FC } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import CheckAvailabilityFormBackgroundSvg from './assets/fondo-flujo-contratacion.svg';

const StyledWorkWithUsBackgroundDiv = styled.div`
  background-image: url(${CheckAvailabilityFormBackgroundSvg});
  background-repeat: no-repeat;
  background-size: 120vh;
  background-position: top -1.375rem left -30vw;
  overflow-y: hidden;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    background-position: top 5rem left -50vw;
    background-size: 110vh;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-size: calc(100vh - 7rem);
  }
`;
export const WorkWithUsBackground: FC<FcDefaultProps> = ({ children }) => {
  return (
    <StyledWorkWithUsBackgroundDiv>{children}</StyledWorkWithUsBackgroundDiv>
  );
};
