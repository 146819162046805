import PlaceIcon from '@mui/icons-material/Place';
import styled from 'styled-components';

interface locationPinProps {
  addressText: string;
  lat: number;
  lng: number;
}

const StyledMainDiv = styled.div`
  position: absolute;
  transform: translate(-1rem, -2rem);
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

const TextStyled = styled.span`
  ${({ theme }) => theme.palette.primary.invert};
`;

export const LocationPin = (props: locationPinProps) => (
  <StyledMainDiv>
    <PlaceIcon
      sx={{
        fontSize: '2rem',
        color: '#1883fe',
      }}
    />
    <TextStyled>{props.addressText}</TextStyled>
  </StyledMainDiv>
);
