import { styled as muiStyled, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/es';
import { palette } from 'design/theme/palette';
import { InputProps } from 'design/types/input-props';

import { noop } from 'helpers/nodash.helpers';
import { useCallback } from 'react';

const locale = 'es';

const StyledDateTimePicker = muiStyled(MobileDateTimePicker)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '3px',
      borderColor: palette.primary.main,
      borderStyle: 'solid',
    },
    '&:hover fieldset': {
      borderWidth: '3px',
      borderColor: palette.primary.main,
      borderStyle: 'solid',
    },
  },
});

export const DateTimePicker = (
  props: InputProps<Date | null>,
): JSX.Element | null => {
  const {
    name,
    id,
    onChange = noop,
    label,
    value,
    required,
    helperText,
    error,
    fullWidth = false,
    size,
    disablePast = true,
    minutesStep,
    views,
  } = props;

  const handleChange = useCallback(
    (value: string | null) => {
      onChange({
        target: {
          name,
          value: value === null ? null : new Date(value),
        },
      });
    },
    [name, onChange],
  );

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <StyledDateTimePicker
        label={label}
        value={value}
        views={views}
        disablePast={disablePast}
        minutesStep={minutesStep}
        onChange={handleChange as (value: unknown) => void}
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => onKeyDown(e)}
            id={id}
            fullWidth={fullWidth}
            required={required}
            helperText={error ? error.toString() : helperText}
            error={!!error}
            size={size}
          />
        )}
      />
    </LocalizationProvider>
  );
};
