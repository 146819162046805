import { http } from 'utilities/http/http';
import { logger } from 'utilities/logger/Logger';

interface BillData {
  billAddress: string;
  billCity: string;
  billDni: string;
}

export async function emitMemoryETicket(payment_id: string, billData: BillData): Promise<void> {
  try {
    await http.post(`/memory/eticket/${payment_id}`, billData);
  } catch (error) {
    logger.warn('Failed request to emitMemoryETicket');
  }
}