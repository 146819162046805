import Modal from '@mui/material/Modal';
import { MapRenderOnlyLocation } from 'components/Map/MapRenderOnlyLocation';
import { Button } from 'design';
import { MapsIcon } from 'design/Icon/MapsIcon';
import { ModalAddressProps } from 'design/types/modal-Address-props';
import * as React from 'react';

export const AddressUniqueModal = (
  props: ModalAddressProps,
): JSX.Element | null => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [buttonTitle, setButtonTitle] = React.useState(
    'Dirección donde se recibirá el servicio',
  );

  const valueCoords: coords = {
    lat: props.dataLocationLat,
    lng: props.dataLocationLong,
  };
  type coords = {
    lat: number;
    lng: number;
  };

  interface locationPinProps {
    addressText: string;
    lat: number;
    lng: number;
  }

  const defaultPin: locationPinProps = {
    addressText: 'Default Adccdress',

    lat: +props.dataLocationLat,
    lng: +props.dataLocationLong,
  };
  return (
    <>
      <Button onClick={handleOpen}>
        <MapsIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MapRenderOnlyLocation
          dataLocation={valueCoords}
          handleClose={handleClose}
          pins={defaultPin}
        />
      </Modal>
    </>
  );
};
