import { NavbarOkidsPlusLogoIcon } from 'components/icons/NavbarOkidsPlusLogoIcon';
import { OkidsLogo } from 'components/icons/OkidsLogo';
import { Pulso } from 'components/icons/PulsoIcon';
import { AppRoute } from 'enums/app-route.enum';
import { UserTypes } from 'enums/user.types.enum';
import { selectAuthToken, selectUser } from 'global-state/selectors';
import { isAdmin } from 'helpers/admin';
import { isUserAuthenticated } from 'helpers/auth.helpers';
import { Align } from 'layout';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import { DropDownUser } from '../DropDownUser/DropDownUser';
import { SideBar } from './SideBar';

const StyledNav = styled.nav`
  height: 7rem;
  width: 100vw;
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  top: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    align-items: space-between;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding-right: 3rem;
  }
  position: fixed;
  z-index: 3;
  transition: background-color 500ms linear;
`;

const StyledWebLinkDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`;
const StyledSideBarDiv = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: flex;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.invert};
  font-size: 1.3rem;
  padding: 0 0.6rem;
  font-weight: 700;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fontFamily};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 1.8vw;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 1.3rem;
  }
`;

const StyledButtonNavLink = styled(NavLink)`
  border-radius: 2px;
  text-decoration: none;
  padding: 1rem;
  align-self: flex-end;
  font-family: ${({ theme }) => theme.fontFamily};
  text-transform: uppercase;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`;

const StyledLogoDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledNavbarOkidsPlusLogoIcon = styled(NavbarOkidsPlusLogoIcon)`
  width: 70%;
  min-width: 13.5rem;
  margin-right: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    min-width: 10.5rem;
  }
`;

const StyledProfileAlign = styled(Align)`
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`;

const StyledPulsoIcon = styled(Pulso)`
  width: 5rem;
`;

const StyledOkidsLogo = styled(OkidsLogo)`
  width: 9rem;
`;

const StyledWhiteNavBarMobileAlign = styled(Align)`
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const AppBar: FC = () => {
  const [isColorInvert, setIsColorInvert] = useState(false);
  const theme = useTheme();
  const location = useLocation();
  const defaultLinkNavbarColorWhenHome = isColorInvert ? 'white' : '#1883fe';
  const [isMobile, setIsMobile] = useState(false);

  const getLinkColor = (destination: AppRoute): string => {
    if (location.pathname === destination) {
      return 'lightgrey';
    }

    if (location.pathname === AppRoute.HOME) {
      return isColorInvert ? 'white' : '#1883fe';
    }
    return isColorInvert ? '#1883fe' : 'white';
  };

  useEffect(() => {
    function handleResize() {
      const isSmaller = window.screen.width <= theme.breakpoints.sm;
      setIsMobile(isSmaller);
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && location.pathname === AppRoute.HOME) {
      window.addEventListener('scroll', () =>
        setIsColorInvert(window.scrollY > 0),
      );
    }

    return () => {
      window.removeEventListener('scroll', () =>
        setIsColorInvert(window.scrollY > 0),
      );
    };
  }, []);

  const scrollToTop = (e: any) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const authToken = useSelector(selectAuthToken);
  const user = useSelector(selectUser);
  const isBabysitter =
    isUserAuthenticated() && user?.type === UserTypes.BABYSITTER;

  const isUser = isUserAuthenticated() && user?.type === UserTypes.CUSTOMER;

  const checkUserType = () => {
    if (isBabysitter) {
      return (
        <StyledNavLink
          to={AppRoute.BABYSITTER_PANEL}
          style={{
            color: getLinkColor(AppRoute.BABYSITTER_PANEL),
          }}
        >
          {intl.translate({ id: 'Panel de niñera' })}
        </StyledNavLink>
      );
    } else if (isUser) {
      return (
        <StyledNavLink
          to={AppRoute.USER_PANEL}
          style={{
            color: getLinkColor(AppRoute.USER_PANEL),
          }}
        >
          {intl.translate({ id: 'Panel de usuario' })}
        </StyledNavLink>
      );
    } else {
      return (
        <StyledNavLink
          to={AppRoute.WORK_WITH_US}
          style={{
            color: getLinkColor(AppRoute.WORK_WITH_US),
          }}
        >
          {intl.translate({ id: 'Trabaja con nosotros' })}
        </StyledNavLink>
      );
    }
  };

  return (
    <>
      <header>
        <StyledNav
          style={{
            backgroundColor:
              location.pathname !== AppRoute.HOME || isColorInvert
                ? '#1883fe'
                : 'white',
          }}
        >
          {isMobile && !isColorInvert && location.pathname === AppRoute.HOME ? (
            <>
              <StyledPulsoIcon />
              <StyledWhiteNavBarMobileAlign>
                <StyledOkidsLogo color="primary" />
              </StyledWhiteNavBarMobileAlign>
            </>
          ) : (
            <Link to={AppRoute.HOME} onClick={(e) => scrollToTop(e)}>
              <StyledLogoDiv>
                <StyledNavbarOkidsPlusLogoIcon
                  color={
                    location.pathname !== AppRoute.HOME || isColorInvert
                      ? 'info'
                      : 'primary'
                  }
                />
              </StyledLogoDiv>
            </Link>
          )}
          {isAdmin() ? (
            <StyledWebLinkDiv>
              <StyledNavLink
                to={AppRoute.PAYMENT}
                style={{
                  color: getLinkColor(AppRoute.PAYMENT),
                }}
              >
                {intl.translate({ id: 'Pagos' })}
              </StyledNavLink>
              <StyledNavLink
                to={AppRoute.CLIENTS}
                style={{
                  color: getLinkColor(AppRoute.CLIENTS),
                }}
              >
                {intl.translate({ id: 'Clientes' })}
              </StyledNavLink>
              <StyledNavLink
                to={AppRoute.PROVIDERS}
                style={{
                  color: getLinkColor(AppRoute.PROVIDERS),
                }}
              >
                {intl.translate({ id: 'Prestadores' })}
              </StyledNavLink>
              <StyledNavLink
                to={AppRoute.ACTIVE_SERVICES}
                style={{
                  color: getLinkColor(AppRoute.ACTIVE_SERVICES),
                }}
              >
                {intl.translate({ id: 'Servicios activos' })}
              </StyledNavLink>
              <StyledNavLink
                to={AppRoute.CLOSED_SERVICES}
                style={{
                  color: getLinkColor(AppRoute.CLOSED_SERVICES),
                }}
              >
                {intl.translate({ id: 'Servicios cerrados' })}
              </StyledNavLink>
            </StyledWebLinkDiv>
          ) : (
            <StyledWebLinkDiv>
              <StyledNavLink
                to={AppRoute.OUR_SERVICE}
                style={{
                  color: getLinkColor(AppRoute.OUR_SERVICE),
                }}
              >
                {intl.translate({ id: 'Nuestro servicio' })}
              </StyledNavLink>
              {authToken && isBabysitter ? (
                <StyledNavLink
                  to={AppRoute.BABYSITTER_PANEL}
                  style={{
                    color: getLinkColor(AppRoute.BABYSITTER_PANEL),
                  }}
                >
                  {intl.translate({ id: 'Panel de niñera' })}
                </StyledNavLink>
              ) : (
                authToken &&
                user && (
                  <StyledNavLink
                    to={AppRoute.USER_PANEL}
                    style={{
                      color: getLinkColor(AppRoute.USER_PANEL),
                    }}
                  >
                    {intl.translate({ id: 'Panel de Usuario' })}
                  </StyledNavLink>
                )
              )}

              {!authToken && (
                <StyledNavLink
                  to={AppRoute.WORK_WITH_US}
                  style={{
                    color: getLinkColor(AppRoute.WORK_WITH_US),
                  }}
                >
                  {intl.translate({ id: 'Trabaja con nosotros' })}
                </StyledNavLink>
              )}

              <StyledNavLink
                to={AppRoute.BUSSINESS_PACKAGES}
                style={{
                  color: getLinkColor(AppRoute.BUSSINESS_PACKAGES),
                }}
              >
                {intl.translate({ id: 'Paquetes Empresariales' })}
              </StyledNavLink>

              <StyledNavLink
                to={AppRoute.CONTACT}
                style={{
                  color: getLinkColor(AppRoute.CONTACT),
                }}
              >
                {intl.translate({ id: 'Contacto' })}
              </StyledNavLink>
            </StyledWebLinkDiv>
          )}

          {authToken ? (
            <StyledProfileAlign>
              <DropDownUser
                borderColor={
                  location.pathname === AppRoute.HOME
                    ? defaultLinkNavbarColorWhenHome
                    : `${'info'}`
                }
              />
            </StyledProfileAlign>
          ) : (
            <StyledButtonNavLink
              to={AppRoute.LOGIN}
              style={{
                color: getLinkColor(AppRoute.LOGIN),
                borderColor:
                  location.pathname === AppRoute.HOME
                    ? defaultLinkNavbarColorWhenHome
                    : `${'info'}`,
              }}
            >
              {intl.translate({ id: 'Iniciar sesión' })}
            </StyledButtonNavLink>
          )}

          <StyledSideBarDiv>
            <SideBar
              color={
                location.pathname !== AppRoute.HOME || isColorInvert
                  ? 'info'
                  : 'primary'
              }
            />
          </StyledSideBarDiv>
        </StyledNav>
      </header>
    </>
  );
};
