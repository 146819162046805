import Menu from '@mui/material/Menu';
import { FC } from 'react';

interface MenuProps {
  children: React.ReactNode;
  anchorEl: null | HTMLElement;
  id: string;
  open: boolean;
  onClose: any;
  onClick: any;
  transformOrigin: any;
  anchorOrigin: any;
}
export const MenuDesign: FC<MenuProps> = ({
  children,
  anchorEl,
  id,
  open,
  onClose,
  onClick,
  transformOrigin,
  anchorOrigin,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      id={id}
      open={open}
      onClose={onClose}
      onClick={onClick}
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
    >
      {children}
    </Menu>
  );
};
