import { BackgroundCreateBabysitterAccount } from 'components/background/BackgroundCreateBabysitterAccount';
import { Text } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { Align } from 'layout';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import { RegisterBabySitterForm } from '../Form/RegisterBabySitterForm';
import { AppBar } from './AppBar';
import { ArrowIcon } from './icons/ArrowIcon';

const StyledAlign = styled(Align)`
  height: calc(100vh- 7rem);
  width: 100vw;
  padding-top: 8.5rem;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const StyledContainerAlign = styled(Align)`
  height: 100vh;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-color: white;
  }
`;

const StyledTextH3 = styled(Text.h3)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.5rem;
  line-height: 1.5rem;
  align-self: center;
  width: 60%;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 70%;
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    align-self: flex-start;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    align-self: flex-start;
    font-size: 3rem;
  }
`;

const StyledTitleDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 40vw;
    align-self: center;
    line-height: 3.5rem;
    text-align: start;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 4rem;
    line-height: 3.5rem;
  }
`;

const StyledHr = styled.hr`
  border: 3px solid ${({ theme }) => theme.palette.primary.main};
  width: 60%;
  border-radius: 4px;
  align-self: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 70%;
    align-self: flex-start;
    border-radius: 5px;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  font-weight: 700;
`;

const StyledP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  padding-left: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0.5rem 0;
    font-size: 1.3rem;
    font-weight: 700;
  }
`;

const StyledLeftSectionDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 90vw;
    margin-left: 1.2rem;
  }
`;

const StyledParentRegisterNavLinkAlign = styled(Align)`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    width: 50%;

    position: fixed;
    top: 50%;
    right: 50%;
    align-self: flex-end;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    align-items: center;
  }
`;

const StyledRightSectionDiv = styled.div`
  width: 50vw;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 90vw;
    display: flex;
  }
`;

export const CreateBabySitterAccountView: FC = () => {
  return (
    <BackgroundCreateBabysitterAccount>
      <StyledContainerAlign>
        <AppBar />
        <StyledAlign column>
          <StyledLeftSectionDiv>
            <StyledParentRegisterNavLinkAlign>
              <StyledTitleDiv>
                <StyledTextH3>
                  <strong>
                    {intl.translate({ id: 'Crea tu cuenta de Niñera' })}
                  </strong>
                </StyledTextH3>
                <StyledHr />

                <Text.p color="light">
                  {intl.translate({ id: '¿Buscas niñera?' })}
                </Text.p>
                <StyledNavLink to={AppRoute.HOME}>
                  <StyledP>
                    {intl.translate({ id: 'Regístrate como padre' })}
                    <ArrowIcon small />
                  </StyledP>
                </StyledNavLink>
              </StyledTitleDiv>
            </StyledParentRegisterNavLinkAlign>
          </StyledLeftSectionDiv>
          <StyledRightSectionDiv>
            <RegisterBabySitterForm />
          </StyledRightSectionDiv>
        </StyledAlign>
      </StyledContainerAlign>
    </BackgroundCreateBabysitterAccount>
  );
};
