import { useIconColor } from 'design/hooks/use-icon-color.hook';
import { IconProps } from 'design/types/icon-props';
import { FC } from 'react';

export const FacebookIcon: FC<IconProps & { width?: number | string }> = ({
  color,
  width = '100%',
  className,
  invert = false,
}) => {
  const _color = useIconColor(color, invert);

  return (
    <svg
      width={width}
      color={_color}
      className={className}
      viewBox="0 0 993 995"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M496.7 994.1C223.1 994.1 0.399902 771.1 0.399902 496.9C0.399902 223.1 223 0.399902 496.7 0.399902C770.3 0.399902 993 223.1 993 496.9C992.9 771 770.3 994.1 496.7 994.1ZM496.7 49.5999C250.2 49.5999 49.6999 250.3 49.6999 496.9C49.6999 743.9 250.2 944.9 496.7 944.9C743.2 944.9 943.7 744 943.7 496.9C943.7 250.3 743.2 49.5999 496.7 49.5999Z"
        fill="currentColor"
      />
      <path
        d="M425 792.4H539.6V504.8H619.7L628.1 408.5H539.5C539.5 408.5 539.5 372.6 539.5 353.6C539.5 331.1 544.4 321.9 566.2 321.9C583.8 321.9 628 321.9 628 321.9V222.1C628 222.1 562.6 222.1 548.6 222.1C462.8 222.1 424.9 259.4 424.9 331.8C424.9 394.4 424.9 408.5 424.9 408.5H365.1V506.2H424.9V792.4H425Z"
        fill="currentColor"
      />
    </svg>
  );
};
