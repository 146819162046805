import {
  Drawer,
  List,
  ListItem,
  styled as styledMaterial,
} from '@mui/material';
import Box from '@mui/material/Box';
import { BurgerMenuIcon } from 'components/icons/BurgerMenuIcon';
import { Button, Text } from 'design';
import { LogOutIcon } from 'design/Icon/LogOutIcon';
import { ColorProp } from 'design/types/color-prop';
import { AppRoute } from 'enums/app-route.enum';
import { UserTypes } from 'enums/user.types.enum';
import {
  removeAuthTokenAction,
  removeUserDataAction,
} from 'global-state/actions';
import { selectAuthToken, selectUser } from 'global-state/selectors';
import { isAdmin } from 'helpers/admin';
import { Align } from 'layout';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const StyledBox = styledMaterial(Box)`
`;

const StyledMenuTopTextDiv = styled.div`
  display: flex;
  margin: 1.5rem 0 1rem;
  width: 100%;
  justify-content: space-around;
`;

const StyledAlign = styled(Align)`
  background-color: #f2dcf2;
  height: 100vh;
`;

const StyledH3 = styled(Text.h3)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 2.2rem;
  font-weight: 700;
  text-transform: uppercase;

  padding: 0 2rem;
`;

const StyledBurgerMenuIcon = styled(BurgerMenuIcon)`
  align-self: center;
  justify-self: flex-end;
  height: 3rem;
  margin-right: 2rem;
`;

const StyledLine = styled.hr`
  border-top: 3px solid ${({ theme }) => theme.palette.primary.main};
  width: 10rem;
  margin: 0 2rem;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: ${({ theme }) => theme.borderRadius.small}rem;
  transition: all 200ms linear;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
  font-size: 1.6rem;
`;
const StyledBurgerMenuButton = styled(Button)`
  width: 4rem !important;
  display: flex !important;
  justify-content: flex-end !important;
`;

const StyledNavLinkP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 2.3rem;
`;

const StyledCloseBurgerMenuIcon = styled(BurgerMenuIcon)`
  align-self: center;
  justify-self: flex-end;
  height: 3rem;
`;

const StyledLogoutP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  align-self: flex-start;
  font-weight: 700;
  font-size: 1rem;
`;

interface Props {
  color: ColorProp | undefined;
}

export const SideBar: FC<Props> = ({ color }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const authToken = useSelector(selectAuthToken);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setMenuOpen(open);
    };
  const dispatchActions = () => {
    dispatch(removeAuthTokenAction());
    dispatch(removeUserDataAction());
  };

  const getMenuItems = () => (
    <StyledBox sx={{ width: 260 }} onClick={toggleDrawer(false)}>
      {isAdmin() ? (
        <StyledAlign column component="nav">
          <StyledMenuTopTextDiv>
            <StyledH3>{intl.translate({ id: 'Menu' })}</StyledH3>
            <StyledBurgerMenuIcon color="primary" />
          </StyledMenuTopTextDiv>
          <StyledLine />

          <List>
            <ListItem>
              <StyledNavLink to={AppRoute.CLIENTS} color="primary">
                <StyledNavLinkP>
                  {intl.translate({ id: 'Clientes' })}
                </StyledNavLinkP>
              </StyledNavLink>
            </ListItem>
            <ListItem>
              <StyledNavLink to={AppRoute.PROVIDERS} color="primary">
                <StyledNavLinkP>
                  {intl.translate({ id: 'Prestadores' })}
                </StyledNavLinkP>
              </StyledNavLink>
            </ListItem>
          </List>
          <StyledLine />
          <List>
            <ListItem>
              <StyledNavLink to={AppRoute.PAYMENT} color="primary">
                <StyledNavLinkP>
                  {intl.translate({ id: 'Pagos' })}
                </StyledNavLinkP>
              </StyledNavLink>
            </ListItem>
            <ListItem>
              <StyledNavLink to={AppRoute.ACTIVE_SERVICES} color="primary">
                <StyledNavLinkP>
                  {intl.translate({ id: 'Servicios activos' })}
                </StyledNavLinkP>
              </StyledNavLink>
            </ListItem>
            <ListItem>
              <StyledNavLink to={AppRoute.CLOSED_SERVICES} color="primary">
                <StyledNavLinkP>
                  {intl.translate({ id: 'Servicios cerrados' })}
                </StyledNavLinkP>
              </StyledNavLink>
            </ListItem>
            <ListItem>
              <ListItem>
                <LogOutIcon color={'primary'} />
                <Button
                  onClick={() => {
                    if (user?.authType === 'facebook') {
                      if (window.FB) {
                        window.FB.logout();
                      }

                      dispatchActions();
                      navigate(AppRoute.HOME);
                    }
                    dispatchActions();
                    navigate(AppRoute.HOME);
                  }}
                >
                  <StyledLogoutP>
                    {intl.translate({ id: 'Cerrar Sesión' })}
                  </StyledLogoutP>
                </Button>
              </ListItem>
            </ListItem>
          </List>
        </StyledAlign>
      ) : (
        <StyledAlign column component="nav">
          <StyledMenuTopTextDiv>
            <StyledH3>{intl.translate({ id: 'Menu' })}</StyledH3>
            <StyledBurgerMenuIcon color="primary" />
          </StyledMenuTopTextDiv>
          <StyledLine />

          <List>
            <ListItem>
              <StyledNavLink to={AppRoute.BUSSINESS_PACKAGES} color="primary">
                <StyledNavLinkP>
                  {intl.translate({ id: 'Paquetes empresariales' })}
                </StyledNavLinkP>
              </StyledNavLink>
            </ListItem>
            <ListItem>
              <StyledNavLink to={AppRoute.OUR_SERVICE} color="primary">
                <StyledNavLinkP>
                  {intl.translate({ id: 'Nuestro servicio' })}
                </StyledNavLinkP>
              </StyledNavLink>
            </ListItem>
            {!authToken && (
              <ListItem>
                <StyledNavLink to={AppRoute.WORK_WITH_US} color="primary">
                  <StyledNavLinkP>
                    {intl.translate({ id: 'Trabaja con nosotros' })}
                  </StyledNavLinkP>
                </StyledNavLink>
              </ListItem>
            )}
            <ListItem>
              <StyledNavLink to={AppRoute.CONTACT} color="primary">
                <StyledNavLinkP>
                  {intl.translate({ id: 'Contacto' })}
                </StyledNavLinkP>
              </StyledNavLink>
            </ListItem>
          </List>
          <StyledLine />
          <ListItem>
            <StyledNavLink to={AppRoute.HOME} color="primary">
              <StyledNavLinkP>
                {intl.translate({ id: 'Términos y condiciones' })}
              </StyledNavLinkP>
            </StyledNavLink>
          </ListItem>
          <List>
            {authToken ? (
              <>
                {user?.type === UserTypes.CUSTOMER && (
                  <ListItem>
                    <StyledNavLink to={AppRoute.USER_PANEL} color="primary">
                      <StyledNavLinkP>
                        {intl.translate({ id: 'Panel de Usuario' })}
                      </StyledNavLinkP>
                    </StyledNavLink>
                  </ListItem>
                )}
                {user?.type === UserTypes.BABYSITTER && (
                  <ListItem>
                    <StyledNavLink
                      to={AppRoute.BABYSITTER_PANEL}
                      color="primary"
                    >
                      <StyledNavLinkP>
                        {intl.translate({ id: 'Panel de Niñera' })}
                      </StyledNavLinkP>
                    </StyledNavLink>
                  </ListItem>
                )}
                <ListItem>
                  <ListItem>
                    <LogOutIcon color={'primary'} />
                    <Button
                      onClick={() => {
                        if (user?.authType === 'facebook') {
                          if (window.FB) {
                            window.FB.logout();
                          }

                          dispatchActions();
                          navigate(AppRoute.HOME);
                        }
                        dispatchActions();
                        navigate(AppRoute.HOME);
                      }}
                    >
                      <StyledLogoutP>
                        {intl.translate({ id: 'Cerrar Sesión' })}
                      </StyledLogoutP>
                    </Button>
                  </ListItem>
                </ListItem>
              </>
            ) : (
              <>
                <ListItem>
                  <StyledNavLink to={AppRoute.REGISTER} color="primary">
                    <StyledNavLinkP>
                      {intl.translate({ id: 'Registrate' })}
                    </StyledNavLinkP>
                  </StyledNavLink>
                </ListItem>
                <ListItem>
                  <StyledNavLink to={AppRoute.LOGIN} color="primary">
                    <StyledNavLinkP>
                      {intl.translate({ id: 'Iniciar sesión' })}
                    </StyledNavLinkP>
                  </StyledNavLink>
                </ListItem>
              </>
            )}
          </List>
        </StyledAlign>
      )}
    </StyledBox>
  );

  return (
    <div>
      <React.Fragment>
        <StyledBurgerMenuButton onClick={toggleDrawer(true)}>
          <StyledCloseBurgerMenuIcon color={color} />
        </StyledBurgerMenuButton>

        <Drawer anchor="right" open={menuOpen} onClose={toggleDrawer(false)}>
          {getMenuItems()}
        </Drawer>
      </React.Fragment>
    </div>
  );
};
