import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

const StyledDiv = styled.div`
  align-self: center;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  margin: 0 auto;
  padding: 0 0.5rem;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HomeContainer: FC<Props> = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>;
};
