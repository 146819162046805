import { FutureServicesStatus } from 'enums/auth-type.enum';
import { getHoursInUTC } from 'helpers/getHoursInUTC';
import { getOffsetInTime } from 'helpers/getOffsetInTime';
import {
  BabysitterDataType,
  BabysitterResponse,
  FutureServicesProps,
  IBabysitterDataResponse,
  IgetBabysittersNearByResponse as IGetBabysittersNearbyResponse,
  IPreviousServicesData,
  IUpdateBabysitterResponse,
  Props,
  ReviewDataResponse,
  UpdateBabysitterDataType
} from 'types/babysitter-props';
import { ServiceUserBabysitterProps, servicesType } from 'types/service-props';

import { http } from 'utilities/http/http';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'workbox-core/_private';

export type babysitterServices = {
  success: boolean;
  data: servicesType
};

export async function getBabysitters(): Promise<BabysitterDataType[]> {
  const response = await http.get<Props>('/babysitter');
  if (response.success) {
    return response.data;
  } else {
    throw new Error(intl.translate({ id: 'No ha podido traer ningun dato' }));
  }
}

export async function updateBabysitterStatus(
  id: string,
  enabled: boolean,
): Promise<BabysitterDataType> {
  const response = await http.patch<IUpdateBabysitterResponse>(
    `/babysitter/${id}`,
    {
      status: enabled ? 'active' : 'disable',
    },
  );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(intl.translate({ id: 'No ha podido cambiar el status' }));
  }
}

export async function updateAvailability(id: string, checked: boolean) {
  const response = await http.patch<BabysitterResponse>(
    `babysitter/babysitterChangeAvailability/${id}`,
    {
      checked,
    },
  );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(
      intl.translate({ id: 'No ha podido cambiar su disponibilidad' }),
    );
  }
}

export async function userData(id: string) {
  const user = await http.get<IBabysitterDataResponse>(`/user/${id}`);
  if (user.success) {
    return user.data;
  }
  throw new Error('user not found');
}

export async function userParentData(id: string) {
  const user = await http.get<IBabysitterDataResponse>(`/user/userData/${id}`);
  if (user.success) {
    return user.data;
  }
  throw new Error('user not found');
}

export async function getBabysitterReview(id: string) {
  const reviews = await http.get<ReviewDataResponse>(`/review/babysitter-reviews/${id}`);
  if (reviews.success) {

    return reviews.data;
  }
  throw new Error('reviews not found');
}

export async function getBabysitterBestReview() {
  const reviews = await http.get<ReviewDataResponse>(`/review/babysitter-best-reviews`);
  if (reviews.success) {
    return reviews.data;
  }
  throw new Error('reviews not found');
}

export async function getFutureServicesOfParent(id: string) {
  const services = await http.get<babysitterServices>(
    `services/user-future-services/${id}?offset=${getOffsetInTime()}`,
  );
  if (services.success) {
    return services.data;
  }
  throw new Error('services not found');
}

export async function getPreviousServicesOfParent(id: string) {
  const services = await http.get<babysitterServices>(
    `services/user-previous-services/${id}?offset=${getOffsetInTime()}`,
  );
  if (services.success) {
    return services.data;
  }
  throw new Error('services not found');
}

export async function updateBabysitterInfo(
  updateBabysitterData: UpdateBabysitterDataType,
) {
  const response = await http.patch<IUpdateBabysitterResponse>(
    '/babysitter/update-babysitter-personal-data',
    updateBabysitterData,
  );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(
      intl.translate({ id: 'No se han podido actualizar los datos' }),
    );
  }
}

export async function getFutureServices(id: string) {
  const response = await http.get<FutureServicesProps>(
    `/services/future-services/${id}?offset=${getOffsetInTime()}`,
    {},
  );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(intl.translate({ id: 'No ha podido traer ningun dato' }));
  }
}

export async function getPreviousServices(id: string) {
  const response = await http.get<IPreviousServicesData>(
    `/services/previous-services/${id}?offset=${getOffsetInTime()}`,
    {},
  );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(intl.translate({ id: 'No ha podido traer ningun dato' }));
  }
}

export async function getPriceService(dateRanges: { start: Date; end: Date }[]) {
  try {
    const formattedDateRanges = dateRanges.map(({ start, end }) => ({
      start: getHoursInUTC(start),
      end: getHoursInUTC(end)
    }));
    const response = await http.post<{
      success: boolean;
      data: string;
    }>('/services/price', { dateRanges: formattedDateRanges });

    if (response.success) {
      return response.data;
    } else {
      throw new Error(intl.translate({ id: 'get.price.error' }));
    }
  } catch (error) {
    console.error('Error fetching price:', error);
    throw error;
  }
}

export async function getServiceData(serviceId: string) {
  const response = await http.get<{
    success: boolean;
    data: ServiceUserBabysitterProps;
  }>(`/services/${serviceId}`, {});
  if (response.success) {
    return response.data;
  } else {
    throw new Error(intl.translate({ id: 'get.service.error' }));
  }
}


export async function createServiceReview(
  rate: number,
  comment: string,
  serviceId: string,
) {
  const response = await http.post<{
    success: boolean;
    data: ServiceUserBabysitterProps;
  }>(`/review/register-review/${serviceId}`, {
    rate,
    comment,
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(intl.translate({ id: 'post.service.review.error' }));
  }
}

export async function updateServiceStatus(
  id: string,
  status: FutureServicesStatus,
) {
  const response = await http.patch<Props>(`/services/updateStatus/${id}`, {
    status: status,
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(intl.translate({ id: 'No ha podido cambiar el status' }));
  }
}

export async function updateDeclineServiceStatusMP(id: string) {
  const response = await http.patch<Props>(`/services/updateStatus/${id}`, {
    status: FutureServicesStatus.PAYMENT_DECLINED,
  });
  if (response.success) {
    return response.data;
  } else {
    throw new Error(intl.translate({ id: 'No ha podido cambiar el status' }));
  }
}


export async function getBabysitterCV(id: string) {
  const response = await http.get<any>(`/storage/babysitterFile/${id}`)
  if (response.success) {
    return response.data;
  } else {
    throw new Error(intl.translate({ id: 'No ha podido cambiar el status' }));
  }
}

export async function addDataChildToService(
  serviceId: string,
  firstName: string,
  ageOfChild: Date,
  ageOfSecondChild: Date,
  otherChildrens: string,
  comments: string,
  importantChildrenComments: string,
  petHouse: string,

) {
  const response = await http.patch<Props>(
    `/services/addDataChild/${serviceId}`,
    {
      firstNameChild: firstName,
      ageOfChild,
      comments,
      otherChildrens,
      petHouse,
      importantChildrenComments,
      ageOfSecondChild
    },
  );
  if (response.success) {
    return response.data;
  } else {
    throw new Error(intl.translate({ id: 'No ha podido agregar los datos' }));
  }
}

export async function getBabysitterNearBy(
  lat: number,
  lng: number,
  start: Date,
  end: Date,
  address?: string,
): Promise<BabysitterDataType[]> {
  try {
    const response = await http.get<IGetBabysittersNearbyResponse>(
      `/babysitter/consulta-por-servicio?start=${getHoursInUTC(start)}&end=${getHoursInUTC(end)}&lat=${lat}&lng=${lng}&address=${address}`,
    );
    if (response.success) {
      return response.data;
    } else {
      throw new Error(intl.translate({ id: 'NO HAY CHANCE PAPA' }));
    }
  } catch (error) {
    logger.warn(
      intl.translate({
        id: 'Hubo un error, intente nuevamente',
      }),
    );
    throw new Error(
      intl.translate({
        id: 'Hubo un error al buscar niñeras disponibles, por favor intente de nuevo',
      }),
    );
  }
}

export async function serviceRecoordination(
  id: string | null | undefined,
  lat: number,
  lng: number,
  start: Date,
  end: Date,
  serviceId: string,
): Promise<BabysitterDataType[]> {
  try {
    const response = await http.get<IGetBabysittersNearbyResponse>(
      `/babysitter/recoordinacion-de-servicio/babysitter/${id}?start=${start}&end=${end}&lat=${lat}&lng=${lng}&service-id=${serviceId}&offset=${getOffsetInTime()}`,
    );
    if (response.success) {
      return response.data;
    } else {
      throw new Error(
        intl.translate({
          id: 'Lo sentimos, algo salió mal en la recoordinación del servicio',
        }),
      );
    }
  } catch (error) {
    logger.warn(
      intl.translate({
        id: 'Hubo un error, intente nuevamente',
      }),
    );
    throw new Error(
      intl.translate({
        id: 'Hubo un error al buscar niñeras disponibles, por favor intente de nuevo',
      }),
    );
  }
}
