export const getUTCDate = (date: Date) => {
    const dateToTransform = new Date(date);
    return new Date(Date.UTC(
        dateToTransform.getUTCFullYear(),
        dateToTransform.getUTCMonth(),
        dateToTransform.getUTCDate(),
        dateToTransform.getUTCHours(),
        dateToTransform.getUTCMinutes(),
        dateToTransform.getUTCSeconds(),
        dateToTransform.getUTCMilliseconds()
    ))
};