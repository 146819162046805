import { StorageItem } from 'enums/storage-item.enum';
import { storage } from 'helpers/storage.helpers';
import { User } from 'types/user';
import { http } from 'utilities/http/http';
import { logger } from 'utilities/logger/Logger';

export async function saveTokenNotification(token: string): Promise<void> {
  try {
    const user = storage.session.get(StorageItem.USER) as User;

    const userId = user.id;

    await http.post('/user/save-token', {
      userId,
      token,
    });
  } catch (error) {
    logger.warn('Failed request');
  }
}
