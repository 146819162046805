import { FC, useState } from 'react';
import { Rating } from 'react-simple-star-rating';

interface Props {
  initialValue?: number | undefined;
  readonly?: boolean | true;
  size?: number;
  onClick?: any;
  onChange?: (rating: number) => void;
  allowFraction?: boolean;
}

export const StarsRating: FC<Props> = ({
  onClick,
  initialValue,
  readonly,
  onChange,
  size = 20,
  allowFraction,
}) => {
  const [rating, setRating] = useState(initialValue);

  const handleRating = (rate: number) => {
    if (!readonly) {
      setRating(rate);
      initialValue = 0;
    }
    if (onChange) {
      onChange(rate);
    }
  };

  return (
    <Rating
      allowFraction={allowFraction}
      readonly={readonly}
      onClick={handleRating}
      initialValue={initialValue && initialValue}
      size={size}
    />
  );
};
