import React from 'react';

export function addPropsToReactElement(element: JSX.Element, props: any) {
  if (React.isValidElement(element)) {
    return React.cloneElement(element, props);
  }
  return element;
}

export function addPropsToChildren(children: JSX.Element, props: any) {
  if (!Array.isArray(children)) {
    return addPropsToReactElement(children, props);
  }
  return children.map((childElement) =>
    addPropsToReactElement(childElement, props),
  );
}

export function getRate(rate: number) {
  return rate / 2;
}
