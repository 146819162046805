import { DesignContext } from 'design/DesignContext';
import { AvatarProps } from 'design/types/Avatar-props';
import { FC, useContext, useMemo } from 'react';

type Props = AvatarProps & {
  material: JSX.Element;
};

export const AvatarDesign: FC<Props> = ({
  material: materialAvatar,
  size,
  small = false,
  medium = false,
  large = false,
  src,
  alt,
}) => {
  const { theme } = useContext(DesignContext);

  const _size = useMemo(() => {
    switch (true) {
      case small:
        return theme.components.avatar.size.small;
      case large:
        return theme.components.avatar.size.large;
      case medium:
      default:
        return theme.components.avatar.size.medium;
    }
  }, [small, large, medium, theme]);

  return (
    <materialAvatar.type
      {...materialAvatar.props}
      sx={{ height: size ?? _size, width: size ?? _size }}
      src={src}
      alt={alt}
    />
  );
};
