import { Text } from 'design';
import { FC } from 'react';
import styled from 'styled-components';
import { IUserDataResponse } from 'types/babysitter-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledDataTextLabel = styled(Text.label)`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
`;

const StyledDataTextP = styled(Text.p)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 1rem;
  ::first-letter {
    text-transform: capitalize;
  }
`;

const StyledServicesContentDiv = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: calc(100vw - 4rem);
  }
`;

const StyledDiv = styled.div`
  padding: 0 1rem;
`;

interface Props {
  userInfo: IUserDataResponse;
}
export const UserPersonalData: FC<Props> = ({ userInfo }) => {
  return (
    <>
      <StyledServicesContentDiv>
        <StyledDiv>
          <StyledDataTextLabel>
            {intl.translate({ id: 'Nombre madre/padre:' })}
          </StyledDataTextLabel>
          <StyledDataTextP>{userInfo.name}</StyledDataTextP>
          <StyledDataTextLabel>
            {intl.translate({ id: 'Dirección:' })}
          </StyledDataTextLabel>
          <StyledDataTextP>{userInfo.location}</StyledDataTextP>
          <StyledDataTextLabel>
            {intl.translate({ id: 'Número de teléfono:' })}
          </StyledDataTextLabel>
          <StyledDataTextP>{userInfo.phone}</StyledDataTextP>
          <StyledDataTextLabel>
            {intl.translate({ id: 'Mail:' })}
          </StyledDataTextLabel>
          <StyledDataTextP>{userInfo.email}</StyledDataTextP>
        </StyledDiv>
      </StyledServicesContentDiv>
    </>
  );
};
