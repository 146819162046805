import { DataGrid, esES, GridColDef } from '@mui/x-data-grid';
import { getAllCurrentServices } from 'api/auth.api';
import { Text } from 'design';
import { handleServiceStatus } from 'helpers/handleServiceStatus';
import { parseDateDdMmYyyyHhMm } from 'helpers/parseDate';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ServicesProps, ServiceUserBabysitterProps } from 'types/service-props';
import { intl } from 'utilities/i18n/intl.utility';

const columns: GridColDef[] = [
    { field: 'babysitterName', headerName: 'Niñera', width: 210, align: 'center', headerAlign: 'center' },
    { field: 'startTime', headerName: 'Inicio del servicio', width: 260, align: 'center', headerAlign: 'center' },
    { field: 'endTime', headerName: 'Finalización del servicio', width: 260, align: 'center', headerAlign: 'center' },
    { field: 'status', headerName: 'Estado del servicio', width: 250, align: 'center', headerAlign: 'center' },
    { field: 'parentName', headerName: 'Padre', width: 210, align: 'center', headerAlign: 'center' },
];

const StyledEmptyListTextP = styled(Text.p)`
  width: 30%;
  font-size: 1.1rem;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 30%;
  margin-left: 30%;
  color: #555c;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: auto;
  }
`;

export const ActiveServicesTable = () => {
    const [services, setServices] = useState<ServiceUserBabysitterProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filerServices, setFilerServices] =
        useState<ServicesProps[]>();

    useEffect(() => {
        getAllCurrentServices().then(setServices)
    }, []);


    useEffect(() => {
        const rowsData = services?.map((service) => {
            return {
                id: service.id,
                babysitterName: service.UserBabysitter?.user?.name,
                startTime: parseDateDdMmYyyyHhMm(service.startTime),
                endTime: parseDateDdMmYyyyHhMm(service.endTime),
                status: handleServiceStatus(service.status),
                parentName: service.name,
            }
        })
        setFilerServices(rowsData as ServicesProps[]);
        setIsLoading(false);
    }, [services]);




    return (
        filerServices?.length ?
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={filerServices ? filerServices : []}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    loading={isLoading}
                />
            </div>
            :
            <StyledEmptyListTextP> {intl.translate({ id: 'No se encontraron servicios activos' })}</StyledEmptyListTextP>
    );
};
