import LoadingButton from '@mui/lab/LoadingButton';
import { LoadingButtonProps } from 'design/types/loading-button-variant-prop';
import { FC } from 'react';

export const Loading: FC<LoadingButtonProps> = ({
  children,
  onClick,
  className,
  variant = 'text',
  fullWidth = false,
  disabled = false,
  component,
  loading = false,
  endIcon,
  loadingPosition,
  loadingIndicator,
}) => {
  return (
    <LoadingButton
      loading={loading}
      onClick={onClick}
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
      endIcon={endIcon}
      loadingPosition={loadingPosition}
      variant={variant}
      loadingIndicator={loadingIndicator}
    >
      {children}
    </LoadingButton>
  );
};
