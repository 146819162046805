import { styled as styledMui } from '@mui/material';
import { Button } from 'design';
import { Align } from 'layout';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ArrowIcon } from './icons/ArrowIcon';

interface Props {
  children: React.ReactNode;
  showButtons?: boolean;
}

const StyledCarouselAlign = styled(Align)`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const StyledSwiper = styledMui(Swiper)`
  height: 100%;
  display: flex;
  justify-content: center;
`;

const StyledSwiperSlide = styledMui(SwiperSlide)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  
`;

const StyledButtonDiv = styled.div`
  margin: 0;
  padding: 0;
  flex: 0 0 2rem;
  justify-content: center;
  display: flex;
  width: 2rem;
  overflow-x: hidden;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 0 2rem;
    height: 4rem;
  }
`;

const StyledIconDiv = styled.div`
  transform: rotate(180deg);
`;

export const Carousel: FC<Props> = ({ children, showButtons = true }) => {
  const [swipe, setSwipe] = useState<any>();

  return (
    <StyledCarouselAlign>
      {showButtons ? (
        <StyledButtonDiv>
          <Button onClick={() => swipe?.slidePrev()}>
            <StyledIconDiv>
              <ArrowIcon color="primary" size={50} />
            </StyledIconDiv>
          </Button>
        </StyledButtonDiv>
      ) : null}
      <StyledSwiper
        onBeforeInit={(swipper) => setSwipe(swipper)}
        spaceBetween={20}
        slidesPerView={1}
      >
        {React.Children.map(children, (child, index) => (
          <StyledSwiperSlide key={index}>{child}</StyledSwiperSlide>
        ))}
      </StyledSwiper>
      {showButtons ? (
        <StyledButtonDiv>
          <Button onClick={() => swipe?.slideNext()}>
            <ArrowIcon color="primary" size={50} />
          </Button>
        </StyledButtonDiv>
      ) : null}
    </StyledCarouselAlign>
  );
};
