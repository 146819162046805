import { PaymentDataProps } from "components/views/backoffice/payments/PaymentTable";
import { getOffsetInTime } from "helpers/getOffsetInTime";
import { http } from "utilities/http/http";
import { intl } from "utilities/i18n/intl.utility";

export async function getPayments(): Promise<any[]> {
    const response = await http.get<any>(`/payment/paymentData?offset=${getOffsetInTime()}`);
    if (response.success) {
        return response.data;
    } else {
        throw new Error(intl.translate({ id: 'No ha podido traer ningun dato' }));
    }
}


export async function updatePaymentStatus(
    id: string,
    paymentStatus: string,
): Promise<PaymentDataProps> {
    const response = await http.patch<any>(
        `/payment/updatePaymentStatus/${id}`,
        {
            status: paymentStatus,
        },
    );
    if (response.success) {
        return response.data;
    } else {
        throw new Error(intl.translate({ id: 'No ha podido cambiar el status' }));
    }
}