import { getBabysitterBestReview } from 'api/babysitters/babysitters.api';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BabySitterData } from './BabySitterData';
import { Carousel } from './Carousel';

const StyledCarousel = styled(Carousel)`
  flex-direction: row;
  width: 100%;

  display: flex;
  margin: 5rem 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: row;
    padding: 6rem 0;
  }
`;

const StyledDiv = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  gap: 2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
  }
`;

export const BabySitterList: FC = () => {
  const [reviews, setReviews] = useState<any>([]);

  useEffect(() => {
    const fetchBestReviews = async () => {
      try {
        const reviews = await getBabysitterBestReview();
        setReviews(reviews);
      } catch (error) {
        console.error('Error fetching babysitter reviews:', error);
      }
    };

    fetchBestReviews();
  }, []);

  const chunkArray = (arr: any[], size: number) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size));
    }
    return chunks;
  };

  const reviewChunks = chunkArray(reviews, 3);

  return (
    <StyledCarousel showButtons={true}>
      {reviewChunks.map((chunk: any, index: number) => (
        <StyledDiv key={index}>
          {chunk.map((testimonials: any) => (
            <BabySitterData key={testimonials.id} testimonials={testimonials} />
          ))}
        </StyledDiv>
      ))}
    </StyledCarousel>
  );
};
