import { AppRoute } from 'enums/app-route.enum';
import { removeAuthTokenAction } from 'global-state/actions';
import { isAdmin } from 'helpers/admin';
import { isUserAuthenticated } from 'helpers/auth.helpers';
import { isBabysitter } from 'helpers/babysitter';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';

export const ProtectedCustomerRoute: FC<FcDefaultProps> = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  if (isBabysitter() || isAdmin() || !isUserAuthenticated()) {
    const { pathname, hash, search } = location;
    dispatch(removeAuthTokenAction());
    toast.info(
      intl.translate({
        id: 'Para ir a esta ruta debe iniciar sesión como padre',
      }),
    );

    return (
      <Navigate
        to={AppRoute.LOGIN}
        state={{ origin: { pathname, hash, search } }}
      />
    );
  }

  return <>{children}</>;
};
