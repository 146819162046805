import { Button, Text } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledRightSideDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1.3rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledSubtitleTexth4 = styled(Text.h4)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: 2rem;
  font-size: 1.6875rem;
  font-weight: 900;
  align-self: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 1.3rem;
  }
`;

const StyledTextP = styled(Text.p)`
  font-size: 1.3rem;
  color: #686868;
  font-weight: 600;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 1.1rem;
  }
`;

const StyledRegisterButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  color: ${({ theme }) => theme.palette.primary.invert} !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  height: 3rem !important;
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
`;

export const HowToWorkWithUs: FC<FcDefaultProps> = () => {
  const navigate = useNavigate();

  const redirectForm = () => {
    navigate(AppRoute.CREATE_BABYSITTER_ACOUNT);
  };

  return (
    <StyledRightSideDiv>
      <StyledSubtitleTexth4>
        {intl.translate({ id: `Se necesitan niñeras temporales!` })}
      </StyledSubtitleTexth4>
      <StyledTextP>
        {intl.translate({
          id: 'En Okids apuntamos a un servicio de excelencia, por lo que solo trabajamos con profesionales en formación que tengan conocimientos en el cuidado de bebes y niños. Además, somos muy meticulosos con todos los detalles, ¡los padres depositan toda su confianza sobre nosotros!',
        })}
      </StyledTextP>
      <StyledRegisterButton fullWidth onClick={redirectForm}>
        {intl.translate({ id: `registrarme como niñera` })}
      </StyledRegisterButton>
    </StyledRightSideDiv>
  );
};
