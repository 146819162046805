import Modal from '@mui/material/Modal';
import { styled } from '@mui/system';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  open: any;
  onClose: any;
}

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const OkidsModal: FC<Props> = ({ children, open, onClose }) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>{children}</>
    </StyledModal>
  );
};
