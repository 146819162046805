import { styled as styledMui } from '@mui/material';
import { Button } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import {
  selectBabysittersList,
  selectServiceData,
} from 'global-state/selectors';
import { addPropsToChildren } from 'helpers/children.helpers';
import { Align } from 'layout';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BabysitterDataType } from 'types/babysitter-props';
import { ProfileBabySitter } from './ProfileBabySitter';
import { ArrowIcon } from './icons/ArrowIcon';

interface Props {
  children?: React.ReactNode;
  showButtons?: boolean;
  babysitter?: BabysitterDataType;
}

const StyledCarouselAlign = styled(Align)`
  align-items: center;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const StyledSwiper = styledMui(Swiper)`
  height: 100%;
  display: flex;
  justify-content: center;
`;

const StyledSwiperSlide = styledMui(SwiperSlide)`
  width: 100%;
  display: flex;
  align-items: center;
  
`;

const StyledButtonDiv = styled.div`
  margin: 0;
  padding: 0;
  flex: 0 0 2rem;
  justify-content: center;
  display: flex;
  width: 2rem;
  overflow-x: hidden;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 0 2rem;
    height: 4rem;
  }
`;

const StyledIconDiv = styled.div`
  transform: rotate(180deg);
`;

export const BabysitterCarousel: FC<Props> = ({ showButtons = false }) => {
  const [swipe, setSwipe] = useState<any>();
  const babysittersList = useSelector(selectBabysittersList);
  const serviceData = useSelector(selectServiceData);
  const [searchParams] = useSearchParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  const getParamUserId = (selectedBabysitterId: string | null) => {
    if (!selectedBabysitterId) {
      return 0;
    }
    return Number(selectedBabysitterId);
  };

  const selectedUserId = getParamUserId(searchParams.get('selectedBabysitter'));

  const selectedBabysitters = new Map<number, number>();
  searchParams.forEach((value, key) => {
    if (!isNaN(Number(key)) && !isNaN(Number(value))) {
      selectedBabysitters.set(Number(key), Number(value));
    }
  });

  const selectedBabysitterList =
    babysittersList !== null
      ? Array.from(selectedBabysitters.entries()).map(
        ([groupIndex, babysitterIndex]) =>
          babysittersList[groupIndex * 3 + babysitterIndex],
      )
      : [];

  const handleProceed = () => {
    const selectedBabysittersQuery = Array.from(selectedBabysitters.entries())
      .map(
        ([groupIndex, babysitterIndex]) => `${groupIndex}=${babysitterIndex}`,
      )
      .join('&');
    navigate(`${AppRoute.BABYSITTER_DESCRIPTION}?${selectedBabysittersQuery}`);
  };

  const handleNext = () => {
    if (currentSlide < selectedBabysitterList.length - 1) {
      swipe?.slideNext();
      setCurrentSlide(currentSlide + 1);
    } else {
      handleProceed();
    }
  };

  return (
    <StyledCarouselAlign>
      {selectedBabysitterList.length > 1 ? (
        <StyledButtonDiv>
          <Button onClick={() => swipe?.slidePrev()}>
            <StyledIconDiv>
              <ArrowIcon color="primary" size={50} />
            </StyledIconDiv>
          </Button>
        </StyledButtonDiv>
      ) : null}
      <StyledSwiper
        onBeforeInit={(swiper) => setSwipe(swiper)}
        spaceBetween={20}
        slidesPerView={1}
        initialSlide={selectedUserId ? selectedUserId : 0}
      >
        {selectedBabysitterList.map((babysitter, index) => (
          <StyledSwiperSlide key={index}>
            {addPropsToChildren(
              <ProfileBabySitter
                babysitter={babysitter}
                dateRange={
                  serviceData?.dateRanges?.[index] ?? {
                    start: new Date(),
                    end: new Date(),
                  }
                }
                isLastSlide={index === selectedBabysitterList.length - 1}
                handleNext={handleNext}
              />,
              { babysitter },
            )}
          </StyledSwiperSlide>
        ))}
      </StyledSwiper>
      {selectedBabysitterList.length > 1 ? (
        <StyledButtonDiv>
          <Button onClick={() => swipe?.slideNext()}>
            <ArrowIcon color="primary" size={50} />
          </Button>
        </StyledButtonDiv>
      ) : null}
    </StyledCarouselAlign>
  );
};
