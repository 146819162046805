import { useIconColor } from 'design/hooks/use-icon-color.hook';
import { IconProps } from 'design/types/icon-props';
import { FC } from 'react';

export const SelectionProcess: FC<IconProps & { width?: number | string }> = ({
  color,
  width = '100%',
  className,
  invert = false,
}) => {
  const _color = useIconColor(color, invert);

  return (
    <svg
      width={width}
      color={_color}
      className={className}
      viewBox="0 0 868 868"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M390.2 347C485.6 347 563.7 268.9 563.7 173.5C563.7 78.1 485.6 0 390.2 0C294.8 0 216.7 78.1 216.7 173.5C216.7 268.9 294.8 347 390.2 347ZM390.2 86.8C437.9 86.8 476.9 125.8 476.9 173.5C476.9 221.2 437.9 260.2 390.2 260.2C342.5 260.2 303.5 221.2 303.5 173.5C303.5 125.8 342.5 86.8 390.2 86.8Z"
        fill="currentColor"
      />
      <path
        d="M650.4 529.2L585.4 594.2L563.7 576.9C546.4 559.6 520.3 559.6 503 576.9C485.7 594.2 485.7 620.3 503 637.6L550.7 685.3C576.7 707 602.7 694 611.4 685.3L706.8 589.9C724.1 572.6 724.1 546.5 706.8 529.2C689.5 511.8 667.8 511.8 650.4 529.2Z"
        fill="currentColor"
      />
      <path
        d="M607.1 347C550.7 347 498.7 364.3 455.3 394.7C433.6 394.7 411.9 390.4 390.3 390.4C173.5 390.4 0 563.9 0 780.7C0 806.7 17.3 824.1 43.4 824.1H464C503 850.1 555.1 867.5 607.1 867.5C750.2 867.5 867.3 750.4 867.3 607.3C867.3 464.2 750.2 347 607.1 347ZM91 737.3C112.7 594.2 234.1 481.5 381.6 477.1C359.9 516.1 346.9 559.5 346.9 607.2C346.9 654.9 359.9 698.3 381.6 737.3H91ZM607.1 780.7C511.7 780.7 433.6 702.6 433.6 607.2C433.6 511.8 511.7 433.8 607.1 433.8C702.5 433.8 780.6 511.9 780.6 607.3C780.6 702.7 702.5 780.7 607.1 780.7Z"
        fill="currentColor"
      />
    </svg>
  );
};
