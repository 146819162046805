import { updateServiceStatus } from 'api/babysitters/babysitters.api';
import { BackgroundGeneral } from 'components/background/BackgroundGeneral';
import { MercadopagoFailure } from 'components/Mercadopago/MercadoPagoFailure';
import { FutureServicesStatus } from 'enums/auth-type.enum';
import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppBar } from './AppBar';

export const MercadopagoFailureView: FC = () => {
  const [searchParams] = useSearchParams();
  const serviceId: string | null = searchParams.get('id');

  const updateStatus = async (id: string, status: FutureServicesStatus) => {
    const response = await updateServiceStatus(id, status);
  };

  useEffect(() => {
    if (serviceId) {
      updateStatus(serviceId, FutureServicesStatus.PAYMENT_DECLINED);
    }
  }, [serviceId]);

  return (
    <>
      <AppBar />
      <BackgroundGeneral>
        <MercadopagoFailure />
      </BackgroundGeneral>
    </>
  );
};
