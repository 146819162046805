import { BackgroundGeneral } from 'components/background/BackgroundGeneral';
import { MobileBackgroundSelectBabysitter } from 'components/background/MobileBackgroundSelectBabysitter';
import { FC } from 'react';
import styled from 'styled-components';
import { AppBar } from './AppBar';
import { BabysitterCarousel } from './BabysitterCarousel';

const StyledContainerDiv = styled.div`
  height: 100vh;
`;

const StyledBabysitterCarousel = styled(BabysitterCarousel)`
  height: 80vh;
`;

export const BabysitterView: FC = () => {
  return (
    <>
      <AppBar />
      <BackgroundGeneral>
        <MobileBackgroundSelectBabysitter>
          <StyledContainerDiv>
            <StyledBabysitterCarousel />
          </StyledContainerDiv>
        </MobileBackgroundSelectBabysitter>
      </BackgroundGeneral>
    </>
  );
};
