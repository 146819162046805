import { FutureServicesStatus } from "enums/auth-type.enum";

export function handleServiceStatus(status: FutureServicesStatus) {
    switch (status) {
        case FutureServicesStatus.DECLINED_BY_BABYSITTER ||
            FutureServicesStatus.DECLINED_BY_PARENT ||
            FutureServicesStatus.DECLINED_BY_BOTH_BABYSITTERS:
            return 'Cancelado';
        case FutureServicesStatus.ACCEPTED_BY_BABYSITTER:
            return 'Aceptado';
        case FutureServicesStatus.PAYMENT_DECLINED:
            return 'Pago rechazado';
        case FutureServicesStatus.PAYMENT_SUCCESS:
            return 'Pago aceptado';
        case FutureServicesStatus.PENDING:
            return 'Pendiente';
        default:
            return 'Estado no encontrado';
    }
}