import { useIconColor } from 'design/hooks/use-icon-color.hook';
import { IconProps } from 'design/types/icon-props';
import { FC } from 'react';

export const BurgerMenuIcon: FC<IconProps & { width?: number | string }> = ({
  color,
  width = '1.9512rem',
  className,
  invert = false,
}) => {
  const _color = useIconColor(color, invert);

  return (
    <svg
      width={width}
      className={className}
      color={_color}
      viewBox="0 0 774 684"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M701.4 656.5H71.9C55.8 656.5 41 647.9 33 634C25 620.1 25 602.9 33 589C41 575.1 55.9 566.5 71.9 566.5H701.3C717.4 566.5 732.2 575.1 740.2 589C748.2 602.9 748.2 620.1 740.2 634C732.3 647.9 717.5 656.5 701.4 656.5ZM701.4 386.7H71.9C55.8 386.7 41 378.1 33 364.2C25 350.3 25 333.1 33 319.2C41 305.3 55.9 296.7 71.9 296.7H701.3C717.4 296.7 732.2 305.3 740.2 319.2C748.2 333.1 748.2 350.3 740.2 364.2C732.3 378.2 717.5 386.7 701.4 386.7ZM701.4 117H71.9C55.8 117 41 108.4 33 94.5C25 80.6 25 63.4 33 49.5C41 35.6 55.9 27 71.9 27H701.3C717.4 27 732.2 35.6 740.2 49.5C748.2 63.4 748.2 80.6 740.2 94.5C732.3 108.4 717.5 117 701.4 117Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};
