import { LoginForm } from 'components/SignIn/SignInForm';
import { WorkWithUsBackground } from 'components/background/BackgroundWorkWithUs';
import { AppBar } from 'components/views/AppBar';
import { LeftSideContent } from 'components/workWithUs/LeftSideContent';
import { Button, Text } from 'design';
import { OkidsModal } from 'design/Modal/Modal';
import { removeAuthTokenAction } from 'global-state/actions';
import { selectUser } from 'global-state/selectors';
import { isUserAuthenticated } from 'helpers/auth.helpers';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';
import { HowToWorkWithUs } from '../workWithUs/HowToWorkWithUs';
import { Footer } from './Footer';

const StyledHeightSection = styled.section`
  max-width: 100vw;
  margin-top: 7rem;
  padding-top: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 7rem - 178px);

  @media (max-height: 850px) {
    height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100vw;
    padding: 0;
    margin-top: 7rem;
    height: 100%;
  }
`;

const StyledMainContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

const StyledButtonsDiv = styled.div`
  display: flex;
  width: 85vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding-top: 1.5rem;
    width: 90vw;
  }
`;

const StyledTopAlreadyRegisterButton = styled(Button) <{ isActive: boolean }>`
  border-color: #d5d6d7 !important;
  background-color: ${({ theme, isActive }) =>
    isActive
      ? theme.palette.primary.main
      : theme.palette.grey.invert}!important;
  color: ${({ theme, isActive }) =>
    isActive
      ? theme.palette.primary.invert
      : theme.palette.primary.main}!important;
  width: 50vw !important;
  font-weight: 700 !important;
  font-size: 1.2rem !important;
  margin-left: 0.5rem !important;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 0.8rem !important;
  }
`;

const StyledTopStartWorkButton = styled(Button) <{ isActive: boolean }>`
  border-color: #d5d6d7 !important;
  background-color: ${({ theme, isActive }) =>
    isActive
      ? theme.palette.grey.invert
      : theme.palette.primary.main}!important;
  color: ${({ theme, isActive }) =>
    isActive
      ? theme.palette.primary.main
      : theme.palette.primary.invert}!important;
  width: 50vw !important;
  font-weight: 700 !important;
  font-size: 1.2rem !important;
  margin-left: 0.5rem !important;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 0.8rem !important;
  }
`;

const StyledContentDiv = styled.div`
  display: flex;
  align-items: flex-start;
  width: 85vw;
  height: auto;
  min-height: 36.25rem;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
  }
`;

const StyledRightSideDiv = styled.div`
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 90vw;
  }
`;

const StyledModalDiv = styled.div`
  background-color: white;
  padding: 1.5rem;
  max-width: 50%;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 90%;
  }
`;

const StyledButton = styled(Button)`
  margin: 1rem 0.5rem !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 0.7rem !important;
  }
`;

const StyledButtonDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
`;

export const WorkWithUsView: FC<FcDefaultProps> = () => {
  const user = useSelector(selectUser);
  const [showLogin, setShowLogin] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(removeAuthTokenAction());
    setShowLogin(true);
    setIsOpen(false);
  };

  return (
    <WorkWithUsBackground>
      <AppBar />
      <StyledHeightSection>
        <StyledMainContainerDiv>
          <StyledButtonsDiv>
            <StyledTopStartWorkButton
              onClick={() => setShowLogin(false)}
              isActive={showLogin}
            >
              {intl.translate({ id: `comenzar a trabajar` })}
            </StyledTopStartWorkButton>

            <StyledTopAlreadyRegisterButton
              onClick={
                isUserAuthenticated()
                  ? () => setIsOpen(true)
                  : () => setShowLogin(true)
              }
              isActive={showLogin}
            >
              {intl.translate({ id: `ya estoy registrada` })}
            </StyledTopAlreadyRegisterButton>
            <OkidsModal onClose={() => setIsOpen(false)} open={isOpen}>
              <StyledModalDiv>
                <Text.p>
                  {intl.translate({
                    id: 'Actualmente ya estas logueado con tu usuario registrado. Para loguearte como niñera precisas cerrar sesión en tu cuenta actual',
                  })}
                </Text.p>
                <StyledButtonDiv>
                  <StyledButton
                    variant="filled"
                    onClick={() => setIsOpen(false)}
                  >
                    {intl.translate({ id: 'Volver atrás' })}
                  </StyledButton>
                  <StyledButton
                    variant="filled"
                    color="primary"
                    onClick={handleClick}
                  >
                    {intl.translate({
                      id: 'Cerrar Sesión',
                    })}
                  </StyledButton>
                </StyledButtonDiv>
              </StyledModalDiv>
            </OkidsModal>
          </StyledButtonsDiv>
          <StyledContentDiv>
            <LeftSideContent />
            <StyledRightSideDiv>
              {showLogin ? <LoginForm /> : <HowToWorkWithUs />}
            </StyledRightSideDiv>
          </StyledContentDiv>
        </StyledMainContainerDiv>
      </StyledHeightSection>
      <Footer />
    </WorkWithUsBackground>
  );
};
